export * from './roleBasePermissionConst';

export interface AuthCookiesOption {
	maxAge: number;
	expires: Date;
	path: string;
}

export const authCookiesOption = (): AuthCookiesOption => {
	const oneMonthFromNow = new Date();
	oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
	return {
		path: '/',
		maxAge: 30 * 24 * 60 * 60,
		expires: oneMonthFromNow,
	};
};
