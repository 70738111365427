import { APIService } from '@services/api/common-services';
import { useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { CalendarDetails, CalendarQuery } from '.';
import { ICalendarDetails } from '@components/calendar';

export const useCalendarMutation = (id?: string): ICalendarDetails => {
	const postCalendarDetailsData = async (calendarFormData: CalendarQuery): Promise<CalendarDetails> => {
		const url = `${APICONFIG.ALL_CALENDAR_DETAILS}/`;
		return await APIService.postData<CalendarDetails>(url, calendarFormData);
	};

	const updateCalendarDetailsData = async (basicFormData: CalendarQuery): Promise<CalendarDetails> => {
		const url = `${APICONFIG.ALL_CALENDAR_DETAILS}/${id}/`;
		return await APIService.putData<CalendarDetails>(url, basicFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: id ? updateCalendarDetailsData : postCalendarDetailsData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
