import { ReactElement } from 'react';
import { IProductCardProps } from '.';
import { PlayButton, PurchaseButton } from '@components/common';
import { useSiteLeadStore } from '@store/siteLeadStore';
import { NavLink } from 'react-router-dom';
import { onImageError } from '@helper/utils';
import { masterFeedbackQuestions } from '@config/feedbackQuestions';

export const ProductCard = (props: IProductCardProps): ReactElement => {
	const { product, productIndex, action, formik } = props;
	const { reason, glue, grade, setFeedbackModal, setProductIndex, setFeedbackResponseValue, setProductId } =
		useSiteLeadStore();
	const showCompetitionTag = productIndex === 0 && glue === 'Competition';
	const showPremiumizationTag =
		productIndex === 0 && glue === 'FV' && grade && ['sh', 'marine'].includes(grade?.toLowerCase());

	const handlePurchase = (val: string): void => {
		const skipFeedback =
			(product.recommended_from === 'analytics' && !product?.questions) ||
			(product.recommended_from === 'master' &&
				reason &&
				masterFeedbackQuestions[reason]?.some((item) => !item.question)) ||
			(product.recommended_from === 'master' &&
				!product?.questions &&
				glue &&
				grade &&
				masterFeedbackQuestions[glue.toLowerCase()]?.some((item) => !item.question) &&
				(grade.toLowerCase() === 'sh' || grade.toLowerCase() === 'marine'));

		if (skipFeedback) {
			setFeedbackModal(false);
			formik.setFieldValue(`productResponse[${productIndex}]`, val);
			return;
		}

		setProductIndex(productIndex);
		setFeedbackResponseValue(val);
		setFeedbackModal(true);
	};

	const handleActivityClick = (): void => {
		setProductId(product?.id);
	};

	return (
		<li className="d-col d-col-2">
			<div className="product-discussion-card" id="product-popup">
				<div className="product-image">
					<img
						role="presentation"
						src={product?.image ?? ''}
						alt="fevicol"
						title="fevicol"
						width="104"
						height="156"
						onError={onImageError}
					/>

					<PlayButton url={product?.av_link} />
				</div>

				<div className="product-details">
					<div>
						<span className="product-title">
							<span className="p-title h3">{product?.product_name}</span>{' '}
							<span className="tag-main-wrapper">
								<span className="p-tag">{product?.product_to_be ? 'Discuss' : 'Demo'}</span>
								{showCompetitionTag && <span className="competition-tag">Competition</span>}
								{showPremiumizationTag && <span className="competition-tag">Premiumization</span>}
							</span>
						</span>

						<div className="date-details">
							<span>
								Last Date of Demo: {product?.demo_date && product?.demo_date !== 'null' ? product?.demo_date : 'N/A'}
							</span>
							<span>
								Last Date of Purchase:{' '}
								{product?.discussed_date && product?.discussed_date !== 'null' ? product?.discussed_date : 'N/A'}
							</span>
						</div>

						<NavLink
							to="/activity-module"
							target="_blank"
							aria-label="activity module url"
							className="activity-module"
							onClick={handleActivityClick}
						>
							<span className="icon-module icon"></span>
							<span className="module-name">Activity Module</span>
						</NavLink>
					</div>

					<div className="discussion-wrapper">
						<span className="discussion-title">Have you done a discussion?</span>

						<span className="purchas-btn-wrapper">
							<PurchaseButton
								label="Yes"
								icon="icon-thumbs-up"
								handleClick={() => handlePurchase('True')}
								isActive={action === 'True'}
							/>
							<PurchaseButton
								label="No"
								icon="icon-thumbs-down"
								handleClick={() => handlePurchase('False')}
								isActive={action === 'False'}
							/>
						</span>
					</div>
				</div>
			</div>
		</li>
	);
};
