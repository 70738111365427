import { RxCollectionCreator } from 'rxdb';

export const endUserCategoryListSchema: RxCollectionCreator = {
	schema: {
		title: 'end user category list schema',
		description: 'End User Category List Schema',
		version: 0,
		primaryKey: 'sfid',
		type: 'object',
		properties: {
			sfid: { type: 'string', maxLength: 250 },
			name: { type: 'string' },
			salesgroupid: { type: 'string' },
			divisionid: { type: 'string' },
			producthierarchycode: { type: 'string' },
			subcategory2: { type: ['string', 'null'] },
			subcategory1: { type: ['string', 'null'] },
			categoryname: { type: ['string', 'null'] },
		},
		required: ['sfid'],
	},
};
