import { IListAPIResponse, IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { SiteSummaryDetails } from '.';
import { APPCONFIG } from '@config/app.config';

export const useSiteSummaryQuery = (
	siteId?: number | null,
	isContractorDetailFetching?: boolean
): IQueryResponse<IListAPIResponse<SiteSummaryDetails[]>> => {
	const fetchSiteSummaryData = async (): Promise<IListAPIResponse<SiteSummaryDetails[]>> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/site-leads/wwa/site-summary/${siteId}/contractor_site_summary`;
		return await APIService.getData<IListAPIResponse<SiteSummaryDetails[]>>(url, {
			limit: '0',
		});
	};

	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['siteSummary'],
		queryFn: fetchSiteSummaryData,
		enabled: !!siteId && !isContractorDetailFetching,
		refetchOnWindowFocus: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
