import { ReactElement } from 'react';

interface IBankingTertiaryCard {
	label: string;
	value?: number | null;
}

export const BankingTertiaryCard = (props: IBankingTertiaryCard): ReactElement => {
	const { label, value } = props;
	return (
		<li>
			<div className="tertiary-details-card">
				<p>{label} :</p>
				<span>{value ?? '-'} Kg</span>
			</div>
		</li>
	);
};
