import { addRxPlugin, createRxDatabase } from 'rxdb/plugins/core';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import {
	adoptedSchema,
	allEndUserSchema,
	clubDropdownSchema,
	competitionBrandSchema,
	dealerSchema,
	gallerySchema,
	ImrCodeSchema,
	leadUserSchema,
	ProductsListSchema,
	meetingsSchema,
	siteSummaryListSchema,
	userCodeSchema,
	profileSchema,
	VideoGallerySchema,
	meetingTypeSchema,
	dealerVisitsSchema,
	dealerVisitProductSchema,
	pcmsSchema,
	productDescriptionListSchema,
	dashboardBannerSchema,
	oneToOneMeetinsListSchema,
	endUserListSchema,
	wssTerritoryListSchema,
	dealersListSchema,
	endUserCategoryListSchema,
} from '@rxdb/schema';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration-schema';
import { TownListSchema } from '@rxdb/schema/townListSchema';

addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBCleanupPlugin);
if (process.env.NODE_ENV !== 'production') {
	addRxPlugin(RxDBDevModePlugin);
}
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBLeaderElectionPlugin);

let dbPromise = null;
let dbInstance = null;
let myDatabase = null;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createDatabase = async () => {
	myDatabase = await createRxDatabase({
		name: 'mpowerwwa',
		storage: getRxStorageDexie(),
		multiInstance: true,
		eventReduce: true,
		cleanupPolicy: {
			minimumDeletedTime: 1000 * 60 * 60,
			minimumCollectionAge: 1000 * 60 * 60,
			runEach: 5 * 60 * 1000,
		},
	});

	window.db = myDatabase;

	await myDatabase.addCollections({
		dashboard_banner: {
			schema: dashboardBannerSchema,
		},
		adopted_contractor: adoptedSchema,
		new_leads: {
			schema: leadUserSchema,
			migrationStrategies: {
				1: async (oldDoc) => {
					return {
						id: oldDoc.id.toString(),
						...oldDoc,
					};
				},
				2: async (oldDoc) => {
					return {
						...oldDoc,
					};
				},
			},
		},
		all: allEndUserSchema,
		club: {
			schema: clubDropdownSchema,
		},
		dealer: {
			schema: dealerSchema,
		},
		competition_brand: {
			schema: competitionBrandSchema,
		},
		gallery: {
			schema: gallerySchema,
		},
		user_list: {
			schema: userCodeSchema,
		},
		site_summary_list: {
			schema: siteSummaryListSchema,
			migrationStrategies: {
				1: async (oldDoc) => {
					return {
						...oldDoc,
					};
				},
				2: async (oldDoc) => {
					return {
						...oldDoc,
					};
				},
				3: async (oldDoc) => {
					return {
						...oldDoc,
					};
				},
			},
		},
		imr_code_list: {
			schema: ImrCodeSchema,
		},
		towns: {
			schema: TownListSchema,
		},
		products: {
			schema: ProductsListSchema,
		},
		meeting_list: {
			schema: meetingsSchema,
			migrationStrategies: {
				1: async (oldDoc) => {
					return {
						id: oldDoc.id.toString(),
						...oldDoc,
					};
				},
			},
		},
		profile: {
			schema: profileSchema,
			migrationStrategies: {
				1: async (oldDoc) => {
					return {
						...oldDoc,
					};
				},
				2: async (oldDoc) => {
					return {
						...oldDoc,
					};
				},
			},
		},
		video_gallery: {
			schema: VideoGallerySchema,
		},
		meeting_types: meetingTypeSchema,
		dealer_visits: dealerVisitsSchema,
		dealer_visit_products: dealerVisitProductSchema,
		pcms_registration_list: pcmsSchema,
		product_description: productDescriptionListSchema,
		one_to_one_meetings: oneToOneMeetinsListSchema,
		end_user_list: endUserListSchema,
		wss_territory_list: wssTerritoryListSchema,
		dealers_list: dealersListSchema,
		enduser_category_list: endUserCategoryListSchema,
	});

	dbInstance = myDatabase;
	return myDatabase;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getDatabase = () => {
	if (!dbPromise) dbPromise = createDatabase();
	return dbPromise;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const cleanupDatabase = async () => {
	if (dbInstance) {
		dbInstance.broadcastChannel?.close();
		const collections = Object.values(dbInstance.collections);

		// Remove all data from collections
		await Promise.all(
			collections.map(async (collection) => {
				await collection.remove();
			})
		);

		// Destroy the database
		await dbInstance.destroy();

		dbInstance = null;
		dbPromise = null;
	}
};
