import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { NonRepeatedUser } from '.';
import { NonAttendedMeetingFilterObject } from '@components/meetingModule/components/meetingParticipants';
import { useDebounceSearch } from '@hooks/useDebounceSearch';

export const useNonAttendedMembersQuery = (
	parameters: NonAttendedMeetingFilterObject
): IQueryResponse<Array<NonRepeatedUser>> => {
	const debouncedSearch = useDebounceSearch(parameters.search);

	const fetchNonAttendedAttendeeData = async (): Promise<Array<NonRepeatedUser>> => {
		const response = await APIService.getData<Array<NonRepeatedUser>>(APICONFIG.NON_REPEAT_ATTENDED, {
			limit: `${parameters.limit}`,
			club_mapping: parameters.club.toString(),
			offset: `${parameters.offset}`,
			search: debouncedSearch,
			...(parameters.isOb && { is_a_ob: parameters.isOb }),
		});

		return response;
	};

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['NonRepeatedMember', { ...parameters, search: debouncedSearch }],
		queryFn: fetchNonAttendedAttendeeData,
		enabled: true,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
