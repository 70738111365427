export * from './site-summary-search';
export * from './site-summary-card';
export * from './site-summary-filter-tag';
export * from './site-summary-filters';

export const getSiteConversionStatus = (siteLeadStatus: string | null): string => {
	if (!siteLeadStatus || siteLeadStatus?.toLowerCase() === 'site not converted') {
		return 'Site Not Converted';
	}
	return 'Site Converted';
};
