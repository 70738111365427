import { IDefaultAddMeetingFormInitialValues } from '@components/meetingModule/components/addNewMeeting';
import { create } from 'zustand';

interface IStepperStore {
	nonAttendedMembers: IDefaultAddMeetingFormInitialValues | null;
	setNonAttendedMembers: (members: IDefaultAddMeetingFormInitialValues) => void;
	resetMembersStore: () => void;
}

const initialState = {
	nonAttendedMembers: null,
};

export const useNonAttendedMembersStore = create<IStepperStore>((set) => ({
	...initialState,
	setNonAttendedMembers: (members: IDefaultAddMeetingFormInitialValues): void => set({ nonAttendedMembers: members }),
	resetMembersStore: (): void => set({ nonAttendedMembers: null }),
}));
