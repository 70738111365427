import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { ICompetitionConversionHeaderProps } from '@components/competition-conversion/components/compeetitionHeader/index';

export const CompetitionConversionHeader = (props: ICompetitionConversionHeaderProps): ReactElement => {
	const { title, breadCrumb } = props;

	return (
		<>
			{title && (
				<div className="competition-conversion-details">
					<p className="h3">{title}</p>
				</div>
			)}

			{breadCrumb && breadCrumb.length > 0 && (
				<div className="breadcrumb-wrapper">
					<ul className="breadcrumb-list">
						{breadCrumb.map((item) => {
							return (
								<li key={item.name}>
									<NavLink
										className={({ isActive }) => (isActive ? 'active' : '')}
										to={item.navLink}
										aria-label={item.name}
										end
									>
										{item.name}
									</NavLink>
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</>
	);
};
