import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { KycDetails } from '.';
import { APPCONFIG } from '@config/app.config';

interface IKYCDetails {
	mutate: UseMutateFunction<KycDetails, Error, KycDetails, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useKYCDetailsMutation = (id: number | null, isEdit?: boolean): IKYCDetails => {
	const postKYCDetailsData = async (kycFormData: KycDetails): Promise<KycDetails> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/wwa/lead-user/${id}/kyc_detail/`;
		return await APIService.putData<KycDetails>(url, kycFormData);
	};
	const updateKYCDetailsData = async (basicFormData: KycDetails): Promise<KycDetails> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/wwa/account/${id}/kyc_detail/`;
		return await APIService.putData<KycDetails>(url, basicFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: isEdit ? updateKYCDetailsData : postKYCDetailsData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
