import { ReactElement } from 'react';
import { IProductImagesProps } from '.';
import { RemoveImageAction } from './removeImageAction';
import { FileUploadDeviceCapture } from '@hooks/common';

export const ProductImages = (props: IProductImagesProps): ReactElement => {
	const { fieldName, formik, handleAddImage, required = false } = props;
	FileUploadDeviceCapture({ id: 'image-upload' });

	return (
		<div className="site-product-images-wrapper">
			<div className="site-product-header">
				<span className={`product-title ${required ? 'required' : ''}`}>Site/Product Images</span>

				<div className={'image-upload-wrapper'}>
					<label htmlFor="image-upload">
						<span className="icon-gallery"></span>
						<span>Add Images</span>
					</label>
					<input
						type="file"
						name="image"
						id="image-upload"
						capture={'environment'}
						accept="image/*,application/pdf"
						multiple
						onChange={handleAddImage}
					/>
				</div>
			</div>

			<div className="upload-images-wrapper">
				{formik.values[fieldName]?.map((pImg: File, idx: number) => (
					<div key={pImg?.name} className="upload-image">
						<div className="wrapper-upload-images">
							<img src={URL.createObjectURL(pImg)} alt={`Uploaded ${idx + 1}`} title="site" width="56" height="56" />
						</div>

						<RemoveImageAction fieldName={fieldName} formik={formik} imgIndex={idx} />
					</div>
				))}
			</div>
		</div>
	);
};
