import { create } from 'zustand';

interface IMapStore {
	currentAddress: string;
	setCurrentAddress: (value: string) => void;
}

const initialState = {
	currentAddress: '',
};

export const useGoogleMapStore = create<IMapStore>((set) => ({
	...initialState,
	setCurrentAddress: (value: string): void => set((state) => ({ ...state, currentAddress: value })),
}));
