import { ReactElement, ReactNode, useCallback } from 'react';

interface ISubmitButton {
	isEditing?: boolean;
	handleSubmitClick: () => void;
	setIsEditing?: (val: boolean) => void;
	isDisabled?: boolean;
	isPending?: boolean;
	label?: string;
	children?: ReactNode | ReactElement;
}

export const SubmitButton = (props: ISubmitButton): ReactElement => {
	const {
		isEditing = false,
		isDisabled = false,
		isPending = false,
		handleSubmitClick,
		setIsEditing,
		label = 'Next',
		children,
	} = props;

	const handleEditClick = useCallback((): void => {
		setIsEditing && setIsEditing(false);
	}, [setIsEditing]);

	return (
		<div className="submit-btn">
			{children}
			{isEditing ? (
				<button type="button" className="btn btn-medium btn-primary" id="edit-btn" onClick={handleEditClick}>
					Edit
				</button>
			) : (
				<button
					type="button"
					className="btn btn-medium btn-primary"
					id="next-page"
					disabled={isDisabled || isPending}
					onClick={handleSubmitClick}
				>
					{isPending ? 'Submitting' : `${label}`}
				</button>
			)}
		</div>
	);
};
