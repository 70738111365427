import { ProductWeightObject } from '@components/pcms';
import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';

interface ProductWeight {
	product_code: string;
}

interface IProductNameResponse {
	mutate: UseMutateFunction<ProductWeightObject[], Error, ProductWeight, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useGetProductWeightMutation = (): IProductNameResponse => {
	const fetchProductWeight = async (params: ProductWeight): Promise<ProductWeightObject[]> => {
		return APIService.getData<ProductWeightObject[]>(`${APICONFIG.PCMS}/product-weight/`, {
			product_code: params.product_code,
			limit: '0',
		});
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: fetchProductWeight,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
