import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { AvailableProductsList } from '.';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { setRxdb } from '@helper/utils';

export const useAvailableProductsListQuery = (): IQueryResponse<AvailableProductsList[]> => {
	const { shouldFetch, isDbChecked } = useCheckRxdb('products');

	const fetchAvailableProductsData = async (): Promise<AvailableProductsList[]> => {
		const url = `${APICONFIG.AVAILABLE_PRODUCTS_LIST}`;
		const response = await APIService.getData<AvailableProductsList[]>(url, {
			limit: '0',
		});
		if (response) {
			await setRxdb('products', response);
		}

		return response;
	};

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['availableProductsList'],
		queryFn: fetchAvailableProductsData,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
