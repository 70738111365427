import { ReactElement } from 'react';
// eslint-disable-next-line import/no-named-as-default
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { IDatePickerProps, Value } from '.';

export const SingleDatePicker = (props: IDatePickerProps): ReactElement => {
	const { formik, required, error, id, name, label, value, disabled, maxDate, minDate, className, onChange, formate } =
		props;

	const handleDateChange = (value: Value): void => {
		formik?.setFieldValue(name, value);

		if (onChange) {
			onChange(value);
		}
	};

	return (
		<div className={`date-input ${className ? className : ''}`}>
			<label htmlFor={id} className={` custom-label ${required ? 'required' : ''}`}>
				{label}
			</label>
			<DatePicker
				id={id}
				name={name}
				format={formate ? formate : 'd-MMM-yy'}
				value={value}
				minDate={minDate}
				maxDate={maxDate}
				onChange={handleDateChange}
				className="date-month-wrapper"
				onBlur={formik?.handleBlur}
				disabled={disabled}
			/>
			{error && <p className={`error-message ${error && 'show'}`}>{error}</p>}
		</div>
	);
};
