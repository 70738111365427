import { RxCollectionCreator } from 'rxdb';

export const dealerVisitProductSchema: RxCollectionCreator = {
	schema: {
		title: 'dealer visit product schema',
		description: 'Dealer Visit product Schema',
		version: 0,
		primaryKey: 'prdgroup3_c',
		type: 'object',
		properties: {
			prdgroup3_c: { type: 'string', maxLength: 250 },
			id: { type: 'number' },
			productgroup3description_c: { type: 'string' },
		},
		required: ['prdgroup3_c'],
	},
};
