import { useRxData } from 'rxdb-hooks';
import { MangoQuery, RxCollection, RxDocument } from 'rxdb/dist/types/types';
import { useCallback, useMemo } from 'react';
import { Dealers } from '@services/hooks/enduser-details';

export type IRxDbResponse<T> = {
	result: RxDocument<T>[];
	isFetching: boolean;
	fetchMore: () => void;
};

export interface ISearchParams {
	key: string;
	value: string;
}

export interface IRxDbProps {
	searchParams: ISearchParams[];
	sortBy?: string;
}

export const useDealerListRxDb = (props: IRxDbProps): IRxDbResponse<Dealers> => {
	const { searchParams, sortBy } = props;

	const searchSelector = useMemo(() => {
		return searchParams.map((param) => {
			return {
				[param.key]: { $regex: `.*${param.value}.*`, $options: 'i' },
			};
		});
	}, [searchParams]);

	const queryConstructor = useCallback(
		(collection: RxCollection<Dealers>) => {
			let querySelector: MangoQuery<Dealers> = {
				selector: {
					$or: searchSelector,
				},
			};

			if (sortBy) {
				querySelector = {
					...querySelector,
					sort: [{ [sortBy]: 'asc' }],
				};
			}

			return collection.find(querySelector);
		},
		[searchSelector, sortBy]
	);

	const { result, isFetching, fetchMore } = useRxData<Dealers>('dealer', queryConstructor, {
		pageSize: 30,
		pagination: 'Infinite',
	});

	return {
		result,
		isFetching,
		fetchMore,
	};
};
