import { useEffect, useRef, useState } from 'react';
import { IChartProps } from '.';
import { GraphDataPoint } from '@components/contractor360/components/contractorNavigation';

export const Chart = (props: IChartProps): JSX.Element => {
	const { chartData, allChartData, isAll, labels } = props;
	const containerRef = useRef<HTMLDivElement>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
	const [tooltip, setTooltip] = useState({ show: false, text: '', x: 0, y: 0 });
	const [isMobile, setIsMobile] = useState(false);

	const calculateYAxisLabels = (data1: GraphDataPoint[], data2: GraphDataPoint[]): number[] => {
		const allValues = [...(data1 || []), ...(data2 || [])].map((point) => point.value);
		const maxValue = Math.max(...allValues, 1);
		const minValue = Math.min(...allValues, 0);

		let range = maxValue - minValue;
		if (range === 0) {
			range = 1;
		}

		const rawStep = range / 4;
		const magnitude = Math.pow(10, Math.floor(Math.log10(rawStep)));
		const step = Math.ceil(rawStep / magnitude) * magnitude;

		const adjustedMin = Math.floor(minValue / step) * step;
		const adjustedMax = Math.ceil(maxValue / step) * step;

		const numSteps = Math.round((adjustedMax - adjustedMin) / step) + 1;
		return Array.from({ length: numSteps }, (_, i) => {
			const value = adjustedMax - i * step;
			return Number(value.toFixed(2));
		});
	};

	useEffect(() => {
		const checkMobile = (): void => {
			setIsMobile(window.innerWidth <= 768);
		};

		const handleResize = (): void => {
			checkMobile();
			if (containerRef.current) {
				setContainerSize({
					width: containerRef.current.clientWidth,
					height: containerRef.current.clientHeight,
				});
			}
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return (): void => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		const data1 = chartData?.[0]?.line1;
		const data2 = chartData?.[0]?.line2;
		const canvas = canvasRef.current;
		if (!canvas || !data1 || !data2 || !labels) return;

		canvas.width = containerSize.width;
		canvas.height = containerSize.height;

		const ctx = canvas.getContext('2d');
		if (!ctx) return;

		const chartWidth = canvas.width - 100;
		const chartHeight = canvas.height - 100;
		const yAxisLabels = calculateYAxisLabels(data1, data2);
		const maxY = Math.max(yAxisLabels[0], 1);

		const scaleData = (data: GraphDataPoint[], maxY: number): number[] => {
			const scaleFactor = chartHeight / maxY;
			return data.map((value) => value.value * scaleFactor);
		};

		const scaledData1 = scaleData(data1, maxY);
		const scaledData2 = scaleData(data2, maxY);

		const drawChart = (ctx: CanvasRenderingContext2D): void => {
			const startX = 50;
			const startY = canvas.height - 50;

			const clear = (): void => {
				canvas && ctx.clearRect(0, 0, canvas.width, canvas.height);
			};

			const drawArea = (data: number[], fillColor: string): void => {
				ctx.beginPath();
				ctx.moveTo(startX, startY);
				ctx.lineTo(startX, startY - data[0]);
				for (let i = 1; i < data.length; i++) {
					ctx.lineTo(startX + i * (chartWidth / (data.length - 1)), startY - data[i]);
				}
				ctx.lineTo(startX + (data.length - 1) * (chartWidth / (data.length - 1)), startY);
				ctx.closePath();
				ctx.fillStyle = fillColor;
				ctx.fill();
			};

			const drawLine = (data: number[], color: string, fillColor: string): void => {
				ctx.strokeStyle = color;
				ctx.lineWidth = 0.2;
				ctx.beginPath();
				ctx.moveTo(startX, startY - data[0]);
				for (let i = 1; i < data.length; i++) {
					ctx.lineTo(startX + i * (chartWidth / (data.length - 1)), startY - data[i]);
				}
				ctx.stroke();

				ctx.fillStyle = fillColor;
				for (let i = 0; i < data.length; i++) {
					ctx.beginPath();
					ctx.arc(startX + i * (chartWidth / (data.length - 1)), startY - data[i], 4, 0, Math.PI * 2);
					ctx.fill();
				}
			};

			const drawLabels = (labels: string[]): void => {
				ctx.font = '12px Arial';
				ctx.textAlign = 'center';
				for (let i = 0; i < labels.length; i++) {
					ctx.fillText(labels[i], startX + i * (chartWidth / (labels.length - 1)), startY + 20);
				}
			};

			const drawYAxisLabels = (labels: number[]): void => {
				ctx.font = '12px Arial';
				ctx.fillStyle = 'rgba(6, 51, 111, 1)';
				ctx.textAlign = 'right';
				labels.forEach((label, index) => {
					const y = startY - ((labels.length - 1 - index) / (labels.length - 1)) * chartHeight;
					ctx.fillText(label.toString(), startX - 10, y);
				});
			};

			const drawHorizontalLines = (labels: number[]): void => {
				ctx.strokeStyle = 'rgba(242, 153, 0, 0.4)';
				ctx.lineWidth = 1;
				labels.forEach((_, index) => {
					const y = startY - ((labels.length - 1 - index) / (labels.length - 1)) * chartHeight;
					ctx.beginPath();
					ctx.moveTo(startX, y);
					ctx.lineTo(startX + chartWidth, y);
					ctx.stroke();
				});
			};

			clear();

			drawArea(scaledData1, 'rgba(6, 51, 111, 0.15)');
			drawArea(scaledData2, 'rgba(242, 153, 0, 0.15)');

			drawYAxisLabels(yAxisLabels);
			drawHorizontalLines(yAxisLabels);
			ctx.font = '14px Arial';
			ctx.textAlign = 'center';
			drawLabels(labels);
			drawLine(scaledData1, 'rgba(33, 36, 39, 1)', 'rgba(242, 153, 0, 1)');
			drawLine(scaledData2, 'rgba(33, 36, 39, 1)', 'rgba(242, 153, 0, 1)');
		};

		drawChart(ctx);

		const handleInteraction = (event: MouseEvent | TouchEvent): void => {
			const rect = canvas.getBoundingClientRect();
			let x, y;

			if (event instanceof MouseEvent) {
				x = event.clientX - rect.left;
				y = event.clientY - rect.top;
			} else {
				x = event.touches[0].clientX - rect.left;
				y = event.touches[0].clientY - rect.top;
			}
			x = (x * canvas.width) / rect.width;
			y = (y * canvas.height) / rect.height;

			const chartWidth = canvas.width - 100;
			const startX = 50;
			const startY = canvas.height - 50;
			const updatedChartData = isAll ? allChartData : chartData;

			if (!updatedChartData || updatedChartData.length === 0) return;

			for (let i = 0; i < updatedChartData[0].line1.length; i++) {
				const dotX = startX + i * (chartWidth / (updatedChartData[0].line1.length - 1));
				const dotY1 = startY - scaledData1[i];
				const dotY2 = startY - scaledData2[i];

				if (Math.sqrt((x - dotX) ** 2 + (y - dotY1) ** 2) < 20) {
					const tooltipText = updatedChartData
						.map((product) => `${product.product}: ${product.line1[i].value}`)
						.join('\n');
					setTooltip({
						show: true,
						text: isAll
							? `Financial Year: ${updatedChartData[0].line1[i].fy}\n${tooltipText}`
							: `${updatedChartData[0].line1[i].fy}: ${updatedChartData[0].line1[i].value}`,
						x: dotX,
						y: dotY1 - 10,
					});
					return;
				}

				if (Math.sqrt((x - dotX) ** 2 + (y - dotY2) ** 2) < 20) {
					const tooltipText = updatedChartData
						.map((product) => `${product.product}: ${product.line2[i].value}`)
						.join('\n');
					setTooltip({
						show: true,
						text: isAll
							? `Financial Year: ${updatedChartData[0].line2[i].fy}\n${tooltipText}`
							: `${updatedChartData[0].line2[i].fy}: ${updatedChartData[0].line2[i].value}`,
						x: dotX,
						y: dotY2 - 10,
					});
					return;
				}
			}

			setTooltip({ show: false, text: '', x: 0, y: 0 });
		};

		if (isMobile) {
			canvas.addEventListener('touchstart', handleInteraction);
		} else {
			canvas.addEventListener('mousemove', handleInteraction);
		}

		return (): void => {
			if (isMobile) {
				canvas.removeEventListener('touchstart', handleInteraction);
			} else {
				canvas.removeEventListener('mousemove', handleInteraction);
			}
		};
	}, [allChartData, chartData, containerSize, isAll, isMobile, labels]);

	return (
		<div ref={containerRef} className="chart-inner-wrapper">
			<canvas ref={canvasRef} id="chartCanvas"></canvas>
			{tooltip.show && (
				<div
					className="tooltip-container"
					style={{
						left: `${tooltip.x}px`,
						top: `${tooltip.y}px`,
					}}
				>
					{tooltip.text}
				</div>
			)}
		</div>
	);
};

export default Chart;
