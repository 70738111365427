import { LeadUserObject } from '@components/members/components/leadList';
import { APICONFIG } from '@config/api.config';
import { getDatabase } from '@rxdb/config/databaseConfig';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export const useLeadUserQuery = (): IQueryResponse<Array<LeadUserObject>> => {
	const [shouldFetch, setShouldFetch] = useState(true);
	const [isDbChecked, setIsDbChecked] = useState(false);

	const fetchLeadUserData = async (): Promise<Array<LeadUserObject>> => {
		const response = await APIService.getData<Array<LeadUserObject>>(`${APICONFIG.LEAD_USER_API}`, {
			limit: '0',
		});
		if (response) {
			const db = await getDatabase();
			const allEndUser = await db.new_leads.find().exec();
			const primaryId = allEndUser.map((doc: { primary: string }) => doc.primary);
			await db.new_leads.bulkRemove(primaryId);
			await db.new_leads.bulkInsert(
				response.map((item) => {
					return { ...item, id: `${item.id.toString()}` };
				})
			);
		}
		return response;
	};

	const checkDb = async (): Promise<boolean> => {
		const db = await getDatabase();
		const result = await db.new_leads.find().exec();
		return result.length > 0;
	};

	useEffect(() => {
		const checkDatabase = async (): Promise<void> => {
			const exists = await checkDb();
			setShouldFetch(!exists);
			setIsDbChecked(true);
		};
		checkDatabase();
	}, []);

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['Lead User'],
		queryFn: fetchLeadUserData,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
