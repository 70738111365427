import { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ISidebarConfig } from '@config/sidebar.config';
import { useComponentLevelAccessCheck } from '../../../authGuard/hooks/useComponentLevelAccessCheck';

export interface IMenuItemProps {
	menu: ISidebarConfig;
	toggleMenu: () => void;
}

export const MenuItem = (props: IMenuItemProps): ReactElement => {
	const location = useLocation();
	const { menu, toggleMenu } = props;
	const accessCheck = useComponentLevelAccessCheck(menu.route, true);

	const isActiveRoute = (route: string): boolean => {
		if (route === '/') {
			return location.pathname === '/';
		}
		return location.pathname.startsWith(route);
	};

	if (!accessCheck) return <></>;

	return (
		<li key={menu.ariaLabel}>
			<NavLink
				to={menu.route}
				className={isActiveRoute(menu.route) ? 'active' : ''}
				end
				aria-label={menu.ariaLabel}
				onClick={toggleMenu}
			>
				<span className={menu.icon} /> {menu.ariaLabel}
				<span className="icon-arrow-right-circle"></span>
			</NavLink>
		</li>
	);
};
