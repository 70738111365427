import { useCallback } from 'react';
import { ToastType, useTosterStore } from '@store/toster';

type ImagePayload = {
	folder: string;
	filename: string;
};

type HandleAddImageReturn =
	| {
			payload: ImagePayload[];
			updatedImages: File[];
	  }
	| undefined;

type HandleAddImageFunction = (
	event: React.ChangeEvent<HTMLInputElement>,
	currentImages: File[],
	maxFileSize: number,
	maxImages: number,
	folder: string
) => HandleAddImageReturn;

type UseImageUploadReturn = {
	handleAddImage: HandleAddImageFunction;
};

export const useImageUpload = (): UseImageUploadReturn => {
	const { setToaster } = useTosterStore();

	const handleAddImage: HandleAddImageFunction = useCallback(
		(
			event: React.ChangeEvent<HTMLInputElement>,
			currentImages: File[],
			maxFileSize: number,
			maxImages: number,
			folder: string
		): HandleAddImageReturn => {
			const maxFileSizeMB = maxFileSize / (1024 * 1024);
			const files = event.target.files ? Array.from(event.target.files) : [];
			if (files.length === 0) return;

			const nonImageFiles = files.filter((file) => !file.type.startsWith('image/'));
			if (nonImageFiles.length > 0) {
				setToaster(true, ToastType.error, 'Please upload image files only.');
				event.target.value = '';
				return;
			}

			const oversizedFiles = files.filter((file) => file.size > maxFileSize);
			if (oversizedFiles.length > 0) {
				if (files.length > 1) {
					setToaster(
						true,
						ToastType.error,
						`One or more files exceed the ${maxFileSizeMB}MB limit. Please choose smaller files.`
					);
					return;
				}
				setToaster(true, ToastType.error, `File size exceeds ${maxFileSizeMB}MB limit. Please choose a smaller file.`);
				return;
			}

			const totalImages = currentImages?.length + files.length;

			if (totalImages > maxImages) {
				setToaster(true, ToastType.error, `Maximum ${maxImages} images allowed.`);
				return;
			}

			const updatedImages = [...currentImages, ...files].slice(0, maxImages);

			const payload: ImagePayload[] = updatedImages.map((img) => ({
				folder: folder,
				filename: img.name,
			}));

			return { payload, updatedImages };
		},
		[setToaster]
	);

	return { handleAddImage };
};
