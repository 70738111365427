import { ChangeEvent, ReactElement } from 'react';
import {
	CompetitionConversionHeader,
	ICompetitionConversionBreadCrumb,
} from '@components/competition-conversion/components/compeetitionHeader';
import { useCompetitionConversionStore } from '@store/competitionConversion';
import { useFetchGrades } from '@services/hooks/competition-conversion/useFetchGrades';
import { CompetitionNextActionButton } from '@components/competition-conversion/components/competitionNextActionButton';
import { GradeIconMap } from '@components/competition-conversion/components/competitionConversionGrade/index';
import { TypeOfContractor } from '@components/competition-conversion/components/competitionConversionContract';
import { Wrapper } from '@components/common/Wrapper/wrapper';

export const CompetitionConversionGrade = (): ReactElement => {
	const { data: gradeList, error, isPending } = useFetchGrades();
	const { grade, type_of_contract, reason, setGrade } = useCompetitionConversionStore();
	const breadCrumb: ICompetitionConversionBreadCrumb[] = [
		{
			name: reason,
			navLink: '/competition-conversion',
		},
		{
			name: TypeOfContractor.find((el) => el.value === type_of_contract)?.name ?? '',
			navLink: '/competition-conversion/type-of-contractor',
		},
		{
			name: 'Grade of Competition',
			navLink: '/competition-conversion/grade-of-competition',
		},
	];
	const handleGrade = (e: ChangeEvent<HTMLInputElement>): void => {
		setGrade(e.target.value);
	};

	return (
		<>
			<CompetitionConversionHeader title="Please select the grade of competition." breadCrumb={breadCrumb} />
			<div className="competition-contract-wrapper">
				<div className="competition-contract-form">
					<Wrapper isLoaderVisible={isPending} isError={error}>
						<ul className="row contract-card-list">
							{gradeList?.map((item) => {
								return (
									<li key={item.name} className="competition-contract-card d-col d-col-2">
										<div className="cmn-radio card-details invisible-input-focus">
											<input
												type="radio"
												id={item.name}
												value={item.name}
												checked={grade === item.name}
												onChange={handleGrade}
												name="cc_grade"
											/>
											<label htmlFor={item.name}>
												<span className="hide">hide</span>
											</label>
											<div className="about-details">
												<span className={GradeIconMap[item.name] || 'icon-sh'}>
													<i className="path1"></i>
													<i className="path2"></i>
													<i className="path3"></i>
												</span>
												<p className="h4">{item.name}</p>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</Wrapper>
				</div>

				<CompetitionNextActionButton buttonDisable={!grade} nextUrl="/competition-conversion/products-recommended" />
			</div>
		</>
	);
};
