export const siteSummaryListSchema = {
	title: 'Site Summary',
	description: 'Site summary listing schema',
	version: 3,
	primaryKey: 'sfid',
	type: 'object',
	properties: {
		id: {
			type: 'number',
		},
		sfid: {
			type: 'string',
			maxLength: 250,
		},
		name: {
			type: 'string',
		},
		contract_name: {
			type: 'string',
		},
		contract_phone: {
			type: 'string',
		},
		status_c: {
			type: ['string', 'null'],
			default: null,
		},
		createddate: {
			type: ['string', 'null'],
			default: null,
		},
		sitenumber_c: {
			type: 'string',
		},
		competition_products_at_site_c: {
			type: ['string', 'null'],
			default: null,
		},
		lastactivitydate: {
			type: ['string', 'null'],
			default: null,
		},
		assigned_cmdi_c: {
			type: ['string', 'null'],
			default: null,
		},
		current_stage_of_site_c: {
			type: 'string',
		},
		site_status_c: {
			type: ['string', 'null'],
			default: null,
		},
		site_location_c: {
			type: 'string',
		},
		site_state_c: {
			type: ['string', 'null'],
			default: null,
		},
		sitecode_c: {
			type: ['string', 'null'],
			default: null,
		},
		type_of_record_c: {
			type: 'string',
		},
		membership_no: {
			type: 'string',
		},
		adopted_site: {
			type: 'boolean',
		},
		competition_site: {
			type: 'boolean',
		},
		site_lead_status: {
			type: ['string', 'null'],
			default: null,
		},
	},

	required: [
		'id',
		'sfid',
		'name',
		'contract_name',
		'sitenumber_c',
		'current_stage_of_site_c',
		'site_location_c',
		'type_of_record_c',
	],
};
