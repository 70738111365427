import { ReactElement, useMemo } from 'react';
import { useGetRxDb } from '@hooks/getRxdbData';
import { Dealers } from '@services/hooks/enduser-details';
import { DealersList } from '@components/endUserDetails/components/moreDetailsForm';
import { Dropdown, TextInput } from '@components/common';
import { IStakeholderDetailsProps } from '@components/siteSummaryDetails/components';
import { IInitialFormikSiteDetails } from '@components/siteSummaryDetails';
import { AccordianDetailList } from '@components/endUserDetails/components/moreDetailsForm/accordianDetailList';

export const StakeholderDetails = (props: IStakeholderDetailsProps<IInitialFormikSiteDetails>): ReactElement => {
	const { formik } = props;
	const { result: dealerList } = useGetRxDb<Dealers>('dealer');
	const dealersList = useMemo((): DealersList[] => {
		if (dealerList?.length) {
			return dealerList.map((d, index) => ({
				id: index,
				value: `${d._data?.dealercode_c} -  ${d._data?.dealername_c}`,
				name: d._data?.dealername_c,
			}));
		}
		return [];
	}, [dealerList]);
	return (
		<ul className="row">
			<li className="form-control d-col d-col-2">
				<Dropdown
					id="dealer_name"
					name="dealer_name"
					label="Dealer Name"
					onBlur={formik.handleBlur}
					title={formik.values.dealer_name ?? ''}
					error={formik.touched.dealer_name && formik.errors.dealer_name ? formik.errors.dealer_name : ''}
					options={dealersList}
					setFieldValue={formik.setFieldValue}
					required
				/>
			</li>
			<li className="d-col">
				<div className="multi-input-box">
					<div className="inputs-wrap">
						<div className="form-control">
							<TextInput
								id="contract_name"
								name="contract_name"
								onBlur={formik.handleBlur}
								type="text"
								value={formik.values.contract_name ?? ''}
								setFieldValue={formik.setFieldValue}
								label="Contractor Name"
								error={formik.touched.contract_name && formik.errors.contract_name ? formik.errors.contract_name : null}
								disabled
								required
							/>
						</div>
					</div>
					<div className="inputs-wrap">
						<div className="form-control">
							<TextInput
								id="contract_phone"
								name="contract_phone"
								onBlur={formik.handleBlur}
								type="text"
								value={formik.values.contract_phone ?? ''}
								setFieldValue={formik.setFieldValue}
								label="Contractor Number"
								error={
									formik.touched.contract_phone && formik.errors.contract_phone ? formik.errors.contract_phone : null
								}
								disabled
								required
							/>
						</div>
					</div>
				</div>
			</li>
			{formik.values.architect_details.map((architect, index) => (
				<AccordianDetailList
					key={architect.id}
					title="Architect Details"
					accordianName="architect_details"
					accordianListIndex={index}
					formik={formik}
					data={architect}
					isEditing={false}
				/>
			))}
			{formik.values.owner_details.map((owner, index) => (
				<AccordianDetailList
					key={owner.id}
					title="Owner Details"
					accordianName="owner_details"
					accordianListIndex={index}
					formik={formik}
					data={owner}
					isEditing={false}
				/>
			))}
		</ul>
	);
};
