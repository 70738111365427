import TextInput from '@components/common/formControl/textInput/textInput';
import { ReactElement, useCallback, useState } from 'react';
import mpowerLogin from '../../assets/images/mpower-logo.png';
import { useGetOtpMutation } from '@services/hooks/login/useGetOtpMutation';
import { useFormik } from 'formik';
import { defaultOtpDetails } from '.';
import { ToastType, useTosterStore } from '@store/toster';
import { ConfirmationModal } from '@components/conductedMeetings/confirmationModal/confirmationModal';
import { Modal } from '@components/common/modal/modal';
import { useGetTokenMutation } from '@services/hooks/login/useGetTokenMutation';
import { keyDownEvent } from '@helper/utils';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { encryptData } from '@helper/encryptDecrypt';
import { authCookiesOption } from '../../authGuard';

export const LoginFormPage = (): ReactElement => {
	const { mutate, isPending: isGetOtpPending } = useGetOtpMutation();
	const { setToaster } = useTosterStore();
	const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
	const [conformedPhoneNumber, setConformedPhoneNumber] = useState<string | null>(null);
	const { mutate: tokenMutation, isPending: tokenPending } = useGetTokenMutation();
	const navigate = useNavigate();
	const [cmdiCode, setCmdiCode] = useState<string>('');

	const formik = useFormik({
		initialValues: defaultOtpDetails,
		onSubmit: () => {
			handleSubmitForm();
		},
	});

	const getOtpHandler = (): void => {
		const payload = {
			cmdi_code: formik.values.bdeCode?.toUpperCase(),
		};
		mutate(payload, {
			onSuccess: (data) => {
				setCmdiCode(formik.values.bdeCode);
				if (data?.phone_number) {
					setConfirmationModal(true);
					setConformedPhoneNumber(data.phone_number);
				}
				if (typeof data.message === 'object' && data.message.cmdi_code) {
					formik.setFieldError('bdeCode', data.message.cmdi_code);
					formik.setFieldTouched('bdeCode', true, false);
				}
				if (typeof data.message === 'object' && data?.message?.message) {
					formik.setFieldError('bdeCode', data?.message?.message);
					formik.setFieldTouched('bdeCode', true, false);
				}
			},
			onError: (error: Error) => {
				setToaster(true, ToastType.error, error.message);
			},
		});
	};

	const handleSubmitForm = (): void => {
		const payload = {
			cmdi_code: formik.values.bdeCode.toUpperCase(),
			otp: formik.values.otp,
		};
		tokenMutation(payload, {
			onSuccess: async (data) => {
				if (typeof data.message === 'object' && data.message.cmdi_code) {
					formik.setFieldError('otp', data.message.cmdi_code);
					formik.setFieldTouched('otp', true, false);
				}
				if (data.user_type_id) {
					if (cmdiCode.length === 0) {
						formik.setFieldError('otp', 'Something went to wrong please try in after some time.');
						formik.setFieldTouched('otp', true, false);
						return;
					}
					const cookie = new Cookies();
					const encryptRole = await encryptData('BDE');
					cookie.set('role', encryptRole, { ...authCookiesOption() });
					navigate('/');
				}
			},
			onError: (error: Error) => {
				setToaster(true, ToastType.error, error.message);
			},
		});
	};

	const handleModalClose = useCallback(() => {
		setConfirmationModal(false);
	}, []);

	return (
		<>
			<section className="login-form-section">
				<div className="login-bg"></div>

				<div className="login-form-wrapper">
					<div className="mpower-logo">
						<img src={mpowerLogin} alt="logo" title="logo" width="400" height="174" />
					</div>

					<form onSubmit={formik.handleSubmit}>
						<ul className="row">
							<li className="form-control d-col">
								<TextInput
									id="bdeCode"
									name="bdeCode"
									type="text"
									onBlur={formik.handleBlur}
									value={formik.values.bdeCode.toUpperCase().trim() ?? ''}
									error={formik.touched.bdeCode && formik.errors.bdeCode ? formik.errors.bdeCode : null}
									setFieldValue={formik.setFieldValue}
									placeholder="BDE Code"
								/>
							</li>
							<li className="form-control d-col">
								<TextInput
									id="otp"
									name="otp"
									type="password"
									onBlur={formik.handleBlur}
									value={formik.values.otp ?? ''}
									error={formik.touched.otp && formik.errors.otp ? formik.errors.otp : null}
									setFieldValue={formik.setFieldValue}
									placeholder="OTP"
									onKeyDown={keyDownEvent}
									maxLength={4}
								/>
							</li>

							<div className="form-btns">
								<button
									type="submit"
									aria-label="submit button"
									className="btn btn-primary btn-medium"
									disabled={formik.values.bdeCode.length === 0 || formik.values.otp.length === 0 || tokenPending}
								>
									Sign In
								</button>

								<button
									type="button"
									aria-label="generate otp"
									className={`otp-btn ${
										formik.values.bdeCode.length === 0 || formik.values.otp.length !== 0 || isGetOtpPending
											? 'disable-btn'
											: ''
									}`}
									onClick={getOtpHandler}
								>
									GENERATE OTP
								</button>

								<p className="version">V 0.1.1</p>
							</div>
						</ul>
					</form>
				</div>
			</section>
			<Modal
				modalId="message"
				className=" dialog-sm opt-confirm pt-25"
				modalOpen={confirmationModal}
				onModalClose={handleModalClose}
			>
				<ConfirmationModal
					onClose={handleModalClose}
					onSubmit={handleModalClose}
					message="You have successfully generated OTP"
					question={`Please check your registered`}
					secondLineQuestion={`mobile number - ${conformedPhoneNumber ?? ''}`}
					hideCancelButton
					buttonLabel="Ok"
					className="login-alert"
				/>
			</Modal>
		</>
	);
};
