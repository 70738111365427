import { UserCard } from '../userCard/userCard';
import { ReactElement, useEffect, useState } from 'react';
import {
	AdoptedContractorFilter,
	AdoptedContractorObject,
	AdoptedContractorObjectResultType,
	AdoptedSearchFilter,
} from '.';
import { useFilterStore, initialFilterObject } from '@store/index';
import { EndUserFilterStore, FilterObject } from '@store/memberList';
import { useInfiniteScroll } from '@components/common';
import { useRxData } from 'rxdb-hooks';
import { getFilter } from '@hooks/useFilterData';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { escapeRegExp } from '@helper/utils';
import { useAdoptedQuery } from '@services/hooks/endusers/adoptedQuery';

export const AdoptedContractorList = (): ReactElement => {
	const [searchElement, setSearchElement] = useState<string>('');
	const { isFetching: queryFetch, error } = useAdoptedQuery();
	const {
		sortValue,
		updateSortObject,
		updateFilterObject,
		filterObject,
		search,
		updateSearch,
		updateSortValue,
		updateSortActiveStatus,
	} = useFilterStore((state) => state);
	const { adoptedContractorModuleFilter, updateAdoptedContractorModuleFilter } = EndUserFilterStore((state) => state);

	const getDataFromIndexDb = (
		filterObject: FilterObject,
		searchQuery: string
	): { selector: AdoptedContractorFilter & AdoptedSearchFilter } => {
		const { selectorData } = getFilter(filterObject);
		const selector: AdoptedContractorFilter & AdoptedSearchFilter = selectorData;
		const searchData = searchQuery ? escapeRegExp(searchQuery) : '';
		if (searchQuery) {
			selector.$or = [
				{ name: { $regex: `.*${searchData}.*`, $options: 'i' } },
				{ phone: { $regex: `.*${searchData}.*`, $options: 'i' } },
				{ membershipno: { $regex: `.*${searchData}.*`, $options: 'i' } },
			];
		}

		return { selector };
	};

	const { result, fetchMore, isFetching }: AdoptedContractorObjectResultType = useRxData<AdoptedContractorObject>(
		'adopted_contractor',
		(collection) => {
			const { selector } = getDataFromIndexDb(filterObject, searchElement);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			let query = collection.find({ selector });

			if (sortValue) {
				query = query
					.sort({
						[sortValue]: sortValue === 'pwg_banking_qtd' || sortValue === 'glue_consumption_per_month' ? 'desc' : 'asc',
					})
					.sort({ createddate: 'desc' });
			} else {
				query = query.sort({ createddate: 'desc' });
			}
			return query;
		},
		{ pageSize: 20, pagination: 'Infinite' }
	);

	useEffect(() => {
		updateSortObject([
			{ value: 'priority', name: 'Priority Order' },
			{ value: 'glue_consumption_per_month', name: 'Glue Potential' },
			{ value: 'pwg_banking_qtd', name: 'PWG Banking in Quarter' },
		]);
		updateSortValue('');
		updateSortActiveStatus(false);
		updateSearch('');

		if (!adoptedContractorModuleFilter) {
			updateFilterObject({
				...initialFilterObject,
				dateOfVisit: { flagged: true, startDate: null, endDate: null },
				customToggle: { flagged: true, data: [{ value: 'OB' }, { value: 'ZBC' }], toggleData: [] },
				visitStatus: { flagged: true, visitStatus: '' },
				checkSecondary: [
					{
						flagged: true,
						name: 'Contractor Class',
						data: [{ value: 'XLC' }, { value: 'VLC' }, { value: 'LC' }, { value: 'RC' }, { value: 'SC' }],
						secondaryData: [],
					},
					{
						flagged: true,
						name: 'Contractor Competition Class',
						data: [{ value: 'XLC-C' }, { value: 'VLC-C' }, { value: 'LC-C' }, { value: 'RC-C' }, { value: 'SC-C' }],
						secondaryData: [],
					},
				],
				kycStatus: { flagged: true, kycStatus: '' },
				customSelect: { flagged: true, customData: [] },
			});
			return;
		}
		updateFilterObject(adoptedContractorModuleFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		updateAdoptedContractorModuleFilter(filterObject);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterObject]);

	useEffect(() => {
		const getData = setTimeout(() => {
			setSearchElement(search);
		}, 500);
		return (): void => clearTimeout(getData);
	}, [search]);

	useInfiniteScroll(() => {
		fetchMore();
	});

	return (
		<Wrapper isLoaderVisible={queryFetch || (isFetching && result.length === 0)} isError={error}>
			<div className="user-card-list-wrapper">
				<ul className="user-card-list row">
					{result.length > 0 ? (
						result.map((endUser, index) => (
							<UserCard key={endUser?._data?.id} userObject={endUser?._data} index={index} state={'/end-users'} />
						))
					) : (
						<li className="d-col d-col-2">
							<p className="h3">No Data Found!</p>
						</li>
					)}
				</ul>
			</div>
		</Wrapper>
	);
};
