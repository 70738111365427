import { ReactElement, useEffect, useMemo, useState } from 'react';
import '@assets/styles/pages/redemption-history.scss';
import { useLocation } from 'react-router-dom';
import { useRedemptionHistoryQuery } from '@services/hooks/contractor360/useRedemptionHistoryQuery';
import { formatDateDDMMYY, getQuarterStartEndDate } from '@helper/utils';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { FilterObject, RedemptionObject } from '.';
import { RedemptionHeader } from './redemptionHeader';
import { RedemptionFilter } from './redemptionFilter';
import { RedemptionData } from './redemptionTable';
import { useInfiniteScroll } from '@components/common';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import { useGetRedemptionHistoryHeaderQuery } from '@services/hooks/contractor360/useGetRedemptionHistoryHeaderQuery';

export const RedemptionHistory = (): ReactElement => {
	const { state } = useLocation();
	const { start, end } = getQuarterStartEndDate(new Date());
	const [redemptionData, setRedemptionData] = useState<RedemptionObject[]>([]);
	const [date, setDate] = useState<Value>([start, end]);
	const navigation = useLocation();
	const [filter, setFilter] = useState<FilterObject>({
		limit: 50,
		offset: 0,
		search: '',
	});
	const startDate = useMemo(() => (Array.isArray(date) ? date[0] : start), [date, start]);
	const endDate = useMemo(() => (Array.isArray(date) ? date[1] : end), [date, end]);

	const { data, isFetching, error } = useRedemptionHistoryQuery({
		startDate: startDate,
		endDate: endDate,
		limit: 50,
		mobile: navigation.state.number,
		search: filter.search,
		offset: (filter.offset + 1) * 50 - 50,
	});

	const {
		data: redemData,
		isFetching: redemLoader,
		error: redemError,
	} = useGetRedemptionHistoryHeaderQuery(state.membershipNumber);

	useInfiniteScroll(() => {
		if (data && filter.limit < +data?.response?.customer?.count) {
			setFilter((prevFilter) => ({
				...prevFilter,
				limit: prevFilter.limit + 50,
				offset: prevFilter.offset + 1,
			}));
		}
	});

	const handleSearch = (value: string): void => {
		setFilter({
			limit: 50,
			offset: 0,
			search: value,
		});
		setRedemptionData([]);
	};

	const handleDateChange = (date: Value): void => {
		setDate(date);
		setRedemptionData([]);
	};

	const ProcessRedemptionData = (): void => {
		const tempRedemptionData: RedemptionObject[] = [];

		data?.response?.customer?.transactions?.transaction?.forEach((transaction) => {
			const isRedemptionHistory = transaction?.custom_fields?.field?.some((field) => field?.name === 'order_status');

			if (isRedemptionHistory) {
				const { id, billing_time, auto_update_time, delivery_status, line_items } = transaction;
				const orderDate = formatDateDDMMYY(new Date(billing_time));
				const billingDate = formatDateDDMMYY(new Date(auto_update_time));
				line_items.line_item?.forEach((item) => {
					let name = item?.description || '-';

					if (!item?.description && item?.attributes?.attribute) {
						const productDescriptionAttribute = item?.attributes?.attribute?.find(
							(attr) => attr?.name === 'ProductDescription'
						);
						if (productDescriptionAttribute) {
							name = productDescriptionAttribute?.value;
						}
					}

					tempRedemptionData?.push({
						id,
						quantity: item?.qty,
						orderDate,
						amount: item?.amount || '-',
						name,
						billingDate,
						deliveryStatus: delivery_status,
					});
				});
			}
		});
		setRedemptionData([...redemptionData, ...tempRedemptionData]);
	};

	useEffect(() => {
		ProcessRedemptionData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.response?.customer?.transactions?.transaction]);

	return (
		<>
			<Wrapper isLoaderVisible={redemLoader} isError={redemError}>
				<>
					<RedemptionHeader data={data} redemptionData={redemptionData} redemptionHeaderData={redemData} />
					<RedemptionFilter setSearch={handleSearch} date={date} setDate={handleDateChange} />

					<section className="redemption-card-section">
						<div className="container">
							<Wrapper isLoaderVisible={isFetching && redemptionData.length == 0} isError={error}>
								<RedemptionData redemptionData={redemptionData} />
							</Wrapper>
							<Wrapper isLoaderVisible={isFetching && redemptionData.length !== 0} isError={null}>
								<div></div>
							</Wrapper>
						</div>
					</section>
				</>
			</Wrapper>
		</>
	);
};
