export * from './additionalDetail';

export interface ProductsReturnType {
	rows: IProductRow[];
	totalKg: number;
	totalPoints: number;
}

export interface IProductRow {
	id: string;
	product_name?: string;
	quantity?: string;
	totalPoints?: string | number;
	pointsPerKg?: string;
}

export const productsHeader = [
	{ id: 0, name: 'Product Name' },
	{ id: 1, name: 'Qty Estimated' },
	{ id: 2, name: 'Total Product Points' },
	{ id: 3, name: 'Pts per KG/L/Pc' },
];
