import { ReactElement, useMemo } from 'react';
import { useFilterStore } from '@store/index';
import { handlePreventDefault } from '@helper/utils';
import { useLocation } from 'react-router-dom';
import { useMeetingFilterStore } from '@store/meetingFilterStore';
import { IMeetingSearch } from '.';

export const MeetingModuleTab = (props: IMeetingSearch): ReactElement => {
	const { setFilterOpen } = props;
	const { pathname } = useLocation();
	const { search, updateSearch } = useFilterStore();
	const { allMeetingFilter, scheduledMeetingFilter, todayFilter } = useMeetingFilterStore();

	const { filterData } = useMemo(() => {
		switch (pathname) {
			case '/meetings':
				return { filterData: scheduledMeetingFilter };
			case '/meetings/todays-meetings':
				return { filterData: todayFilter };
			case '/meetings/all-meetings':
				return { filterData: allMeetingFilter };
			default:
				return { filterData: null, filterKey: '' };
		}
	}, [pathname, scheduledMeetingFilter, todayFilter, allMeetingFilter]);

	// TODO: Pavan replace this method with checkDataIsEmpty function in util.
	const isFilterValuePresent = (value: string | [] | [null, null]): boolean => {
		if (Array.isArray(value)) {
			if (value.length === 2 && value[0] === null && value[1] === null) {
				return false;
			}
			return value.length > 0;
		}
		return typeof value === 'string' ? value?.length > 0 : Boolean(value);
	};

	const checkDataPresent = (): boolean => {
		if (filterData) {
			return Object.values(filterData).some(isFilterValuePresent);
		}
		return false;
	};

	return (
		<div className="filter-section">
			<div className="search-filter-wrapper">
				<div className="search-with-filter">
					<div className="search">
						<form onSubmit={handlePreventDefault}>
							<div className="search-row">
								<div className="input-col">
									<label htmlFor="search-top" className="hide">
										search
									</label>
									<input
										value={search}
										onChange={(e) => updateSearch(e.target.value)}
										type="text"
										name="search"
										id="search-top"
										placeholder="Search Meetings"
										className="search-bar"
										autoComplete="off"
									/>
								</div>

								<button className="search-button" aria-label="search user" onClick={handlePreventDefault}>
									<span className="icon-search"></span>
								</button>
							</div>
						</form>
					</div>

					<div className="filters">
						<button
							className={`filter-button ${checkDataPresent() && 'active'}`}
							aria-label="filter"
							id="filter-contractor"
							onClick={setFilterOpen}
						>
							<span className="icon-filter"></span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
