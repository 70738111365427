import { create } from 'zustand';
import { initialFilterObject } from '.';
import { FilterObject } from './memberList';

interface FilterStore {
	search: string;
	sortValue: string;
	sortObject: { value: string; name: string }[];
	filterObject: FilterObject;
	sortActiveStatus: boolean;
	updateSortValue: (sortValue: string) => void;
	updateSortObject: (sortObject: { value: string; name: string }[]) => void;
	updateFilterObject: (filterObject: FilterObject) => void;
	updateSearch: (search: string) => void;
	updateSortActiveStatus: (search: boolean) => void;
}

const initialState = {
	search: '',
	sortValue: '',
	sortObject: [
		{ value: 'Priority Order', name: 'Priority Order' },
		{ value: 'Glue Potential', name: 'Glue Potential' },
		{ value: 'PWG Banking in Quarter', name: 'PWG Banking in Quarter' },
	],
	filterObject: initialFilterObject,
	sortActiveStatus: false,
};

export const useFilterStore = create<FilterStore>((set) => ({
	...initialState,
	updateSortValue: (sortValue): void => set(() => ({ sortValue: sortValue })),
	updateSortObject: (sortObject): void => set(() => ({ sortObject: sortObject })),
	updateFilterObject: (newUserData): void =>
		set(() => ({
			filterObject: { ...newUserData },
		})),
	updateSearch: (search): void => set(() => ({ search: search })),
	updateSortActiveStatus: (status): void => set(() => ({ sortActiveStatus: status })),
}));
