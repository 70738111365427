import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { UpdateSiteVisitData } from '.';
import { APPCONFIG } from '@config/app.config';
import useSiteLeadStore from '@store/siteLeadStore';

interface IUpdateSiteVisitData {
	mutate: UseMutateFunction<UpdateSiteVisitData, Error, UpdateSiteVisitData, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useUpdateSiteVisitMutation = (): IUpdateSiteVisitData => {
	const { siteVisitId } = useSiteLeadStore();

	const patchSiteVisitData = async (siteLeadFormData: UpdateSiteVisitData): Promise<UpdateSiteVisitData> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/site-leads/wwa/visit/${siteVisitId}/update_site_visit/`;
		return await APIService.patchData<UpdateSiteVisitData>(url, siteLeadFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: patchSiteVisitData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
