import { ReactElement } from 'react';

interface IPurchaseButton {
	label?: string;
	icon?: string;
	handleClick?: () => void;
	isActive?: boolean;
	disabled?: boolean;
}

export const PurchaseButton = (props: IPurchaseButton): ReactElement => {
	const { label, icon, handleClick, isActive, disabled } = props;
	return (
		<button
			type="button"
			aria-label="purchased url"
			className={`purchas-btn ${isActive ? 'active' : ''} ${disabled && 'disable-btn'}`}
			onClick={handleClick}
			disabled={disabled}
		>
			<i className={`${icon} icon`}></i>
			<span>{label}</span>
		</button>
	);
};
