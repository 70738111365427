import { ReactElement } from 'react';
import { ICloseButtonProps } from '.';

export const CloseButton = (props: ICloseButtonProps): ReactElement => {
	const { onClose } = props;
	return (
		<div className="close-icon-btn-main">
			<button type="button" aria-label="close button" className="close-icon-btn" onClick={onClose}>
				<span className="icon-x-circle"></span>
			</button>
		</div>
	);
};
