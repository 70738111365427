import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { KycClubDetails } from '.';
import { useCheckRxdb } from '@hooks/checkRxdb';

export const useClubDetailsQuery = (): IQueryResponse<KycClubDetails> => {
	const { shouldFetch, isDbChecked } = useCheckRxdb('club');

	const fetchClubKYCDetailsData = async (): Promise<KycClubDetails> => {
		return await APIService.getData<KycClubDetails>(APICONFIG.CLUB_KYC_DETAILS);
	};

	const { data, error, isLoading, isPending } = useQuery({
		queryKey: ['kycClubDetails'],
		queryFn: fetchClubKYCDetailsData,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
	};
};
