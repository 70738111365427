import TextInput from '@components/common/formControl/textInput/textInput';
import { useFormik } from 'formik';
import { ReactElement, useState } from 'react';
import { initialExpenseValues } from '.';
import Checkbox from '@components/common/formControl/checkbox/checkbox';
import { useAddExpenseMutation } from '@services/hooks/meetings/useAddExpensesObject';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { keyDownEvent } from '@helper/utils';
import { useMeetingsListQuery } from '@services/hooks/meetings/useMeetingsListQuery';
import { ToastType, useTosterStore } from '@store/toster';
import { useUpdateMeetingStatus } from '@services/hooks/meetings/useUpdateMeetingStatus';
import { useValidation } from '@hooks/validations';

export const ExpensiveDetails = (): ReactElement => {
	const { state } = useLocation();
	const { mutate, isPending: meetingExpensePending } = useAddExpenseMutation(state?.id);
	const navigate = useNavigate();
	const { refetch: meetingsRefetch } = useMeetingsListQuery();
	const { setToaster } = useTosterStore();
	const { mutate: meetingStatusUpdate, isPending: meetingsUpdatePending } = useUpdateMeetingStatus(state?.id);
	const { ExpenseValidationSchema } = useValidation();

	const [checkbox, setCheckBox] = useState(false);

	const formik = useFormik({
		initialValues: initialExpenseValues,
		onSubmit: () => {
			handleSubmit();
		},
		validationSchema: ExpenseValidationSchema,
	});

	const handleSubmit = (): void => {
		const payload = {
			meetingexpense_c: formik.values.meetingExpense,
			gift_cost_c: formik.values.giftCost,
			other_cost_c: formik.values.otherCost,
			food_cost_c: formik.values.foodCost,
			venue_cost_c: formik.values.venueCost,
		};
		mutate(payload, {
			onSuccess: () => {
				meetingStatusUpdate(
					{ status: 'Completed Meeting' },
					{
						onSuccess: () => {
							navigate('/meetings-conducted/completed-record', { state: state?.id });
							meetingsRefetch && meetingsRefetch();
							formik.resetForm();
						},
						onError: (error) => {
							setToaster(true, ToastType.error, error.message);
						},
					}
				);
			},
			onError: (error) => {
				setToaster(true, ToastType.error, error.message);
			},
		});
	};

	const totalCost =
		Number(formik.values.giftCost) +
		Number(formik.values.otherCost) +
		Number(formik.values.foodCost) +
		Number(formik.values.venueCost);

	return (
		<section className="meeting-conducted-section">
			<div className="container">
				<div className="breadcrumb-wrapper">
					<ul className="breadcrumb-list">
						<li>
							<NavLink to={'/meetings-conducted'} className="" state={{ id: state?.id }} end>
								Complete Attendance
							</NavLink>
						</li>
						<li>
							<a
								aria-label="Send expensive details"
								className="active"
								href="/meetings-conducted/expense-details"
								onClick={(e) => e.preventDefault()}
							>
								Send Expense Details
							</a>
						</li>
					</ul>
				</div>

				<div className="expense-details">
					<ul className="row">
						<li className="form-control d-col d-col-2">
							<TextInput
								value={formik.values.meetingExpense}
								label="Meeting Expenses"
								id="meetingExpense"
								name="meetingExpense"
								setFieldValue={formik.setFieldValue}
								onKeyDown={keyDownEvent}
								onBlur={formik.handleBlur}
								error={
									formik.touched.meetingExpense && formik.errors.meetingExpense ? formik.errors.meetingExpense : null
								}
								required
							/>
							<span className="value">₹</span>
						</li>
						<li className="form-control d-col d-col-2">
							<TextInput
								label="Food"
								value={formik.values.foodCost}
								id="foodCost"
								name="foodCost"
								setFieldValue={formik.setFieldValue}
								onBlur={formik.handleBlur}
								onKeyDown={keyDownEvent}
								error={formik.touched.foodCost && formik.errors.foodCost ? formik.errors.foodCost : null}
								required
							/>
							<span className="value">₹</span>
						</li>

						<li className="form-control d-col d-col-2">
							<TextInput
								label="Venue"
								value={formik.values.venueCost}
								id="venueCost"
								name="venueCost"
								setFieldValue={formik.setFieldValue}
								onBlur={formik.handleBlur}
								onKeyDown={keyDownEvent}
								error={formik.touched.venueCost && formik.errors.venueCost ? formik.errors.venueCost : null}
								required
							/>
							<span className="value">₹</span>
						</li>
						<li className="form-control d-col d-col-2">
							<TextInput
								label="Gifts"
								value={formik.values.giftCost}
								id="giftCost"
								name="giftCost"
								setFieldValue={formik.setFieldValue}
								onKeyDown={keyDownEvent}
								onBlur={formik.handleBlur}
								error={formik.touched.giftCost && formik.errors.giftCost ? formik.errors.giftCost : null}
								required
							/>
							<span className="value">₹</span>
						</li>
						<li className="form-control d-col d-col-2">
							<TextInput
								label="Others (Projectors etc..)"
								value={formik.values.otherCost}
								id="otherCost"
								name="otherCost"
								setFieldValue={formik.setFieldValue}
								onKeyDown={keyDownEvent}
								onBlur={formik.handleBlur}
								error={formik.touched.otherCost && formik.errors.otherCost ? formik.errors.otherCost : null}
								required
							/>
							<span className="value">₹</span>
						</li>
					</ul>
				</div>

				<div className="expense-result-details">
					<ul className="expense-result-list">
						<li>
							<div className="result-list">
								<span className="expense-name">Per head budget:</span>
								<span className="expense-number">0</span>
							</div>
						</li>

						<li>
							<div className="result-list">
								<span className="expense-name">Meeting Budget:</span>
								<span className="expense-number">0</span>
							</div>
						</li>

						<li>
							<div className="result-list">
								<span className="expense-name">Meeting Expenses Budget:</span>
								<span className="expense-number">{totalCost}</span>
							</div>
						</li>

						<li>
							<div className="result-list">
								<span className="expense-name">Per head Cost:</span>
								{state?.membersCount === 0 ? (
									<span className="expense-number">0</span>
								) : (
									<span className="expense-number">{(totalCost / state?.membersCount).toFixed(2)}</span>
								)}
							</div>
						</li>
					</ul>
				</div>

				<div className="submit-btn">
					<div className="check-popup">
						<Checkbox
							id="terms_and_conditions"
							name="terms_and_conditions"
							checked={checkbox}
							onChange={() => setCheckBox(!checkbox)}
							type="checkbox"
							label="I have successfully completed the FCC 2.0 checklist"
						/>
					</div>

					<button
						type="button"
						className="btn btn-medium btn-primary"
						onClick={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
						disabled={meetingsUpdatePending || meetingExpensePending || !formik.isValid || !formik.dirty}
					>
						Submit
					</button>
				</div>
			</div>
		</section>
	);
};
