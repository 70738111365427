import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { APPCONFIG } from '@config/app.config';
import { CompetitionBrandsList } from '.';
import { getDatabase } from '@rxdb/config/databaseConfig';
import { useEffect, useState } from 'react';

export const useCompetitionBrandsQuery = (): IQueryResponse<CompetitionBrandsList[]> => {
	const [shouldFetch, setShouldFetch] = useState(true);
	const [isDbChecked, setIsDbChecked] = useState(false);

	//TODO :: Ankit move the URL at common level
	const fetchCompetitionData = async (): Promise<CompetitionBrandsList[]> => {
		const url = `${APPCONFIG.baseUrl}/apis/v.2.0/members/fff/pil-brand-name/`;
		const response = await APIService.getData<CompetitionBrandsList[]>(url, {
			limit: '0',
		});

		if (response) {
			const db = await getDatabase();
			const competitionBrandsCollection = await db.competition_brand.find().exec();
			const primaryId = competitionBrandsCollection.map((doc: { primary: string }) => doc.primary);
			await db.competition_brand.bulkRemove(primaryId);
			await db.competition_brand.bulkInsert(response.filter((item) => item.prdgroup3_c !== null));
		}
		return response;
	};

	const checkDb = async (): Promise<boolean> => {
		const db = await getDatabase();
		const result = await db.competition_brand.find().exec();
		return result.length > 0;
	};

	useEffect(() => {
		const checkDatabase = async (): Promise<void> => {
			const exists = await checkDb();
			setShouldFetch(!exists);
			setIsDbChecked(true);
		};
		checkDatabase();
	}, []);

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['competitionBrandsList'],
		queryFn: fetchCompetitionData,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
