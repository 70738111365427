import { ReactElement } from 'react';
import { BasicDetailsCommonFormSectionProps } from '.';
import { AddressFields, Checkbox } from '@components/common';

export const MiddleFormSection = (props: BasicDetailsCommonFormSectionProps): ReactElement => {
	const { formik, isEditing, townsList } = props;

	return (
		<>
			<AddressFields
				mainFieldName="permanent"
				formik={formik}
				townName="permanent_town"
				addressName="permanent_address"
				zipcodeName="permanent_zipcode"
				cityName="permanent_city"
				streetName="permanent_street"
				stateName="permanent_state"
				addressLabel="Current Address"
				townsList={townsList || []}
				isEditing={isEditing}
			/>

			<li className="d-col">
				<Checkbox
					id="local_address_chekbox"
					name="local_address_chekbox"
					type="checkbox"
					checked={formik.values.local_address_chekbox}
					onChange={formik.handleChange}
					label="Hometown Address same as above"
					disabled={isEditing}
				/>
			</li>

			{!formik.values.local_address_chekbox && (
				<AddressFields
					mainFieldName="local"
					formik={formik}
					townName="local_town"
					addressName="local_address"
					zipcodeName="local_zipcode"
					cityName="local_city"
					streetName="local_street"
					stateName="local_state"
					addressLabel="Hometown Address"
					townsList={townsList || []}
					isEditing={isEditing}
				/>
			)}

			<li className="d-col">
				<Checkbox
					id="work_address_chekbox"
					name="work_address_chekbox"
					checked={formik.values.work_address_chekbox}
					onChange={formik.handleChange}
					type="checkbox"
					label="Office Address same as above"
					disabled={isEditing}
				/>
			</li>

			{!formik.values.work_address_chekbox && (
				<AddressFields
					mainFieldName="work"
					formik={formik}
					townName="work_town"
					addressName="work_address"
					zipcodeName="work_zipcode"
					cityName="work_city"
					streetName="work_street"
					stateName="work_state"
					addressLabel="Office Address"
					townsList={townsList || []}
					isEditing={isEditing}
				/>
			)}
		</>
	);
};
