import '@assets/styles/pages/meeting-conducted.scss';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

export const ConductedMeeting = (): ReactElement => {
	return (
		<div>
			<Outlet />
		</div>
	);
};
