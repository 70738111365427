import { DropdownOptions } from '@components/common/formControl/dropdown';
import { create, StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';

interface ISiteLeadStore {
	grade: string | null;
	reason: string | null;
	type_of_contract: string | null;
	glue: string | null;
	siteName: string | null;
	siteLocation: string | null;
	siteId: number | null;
	siteVisitId: number | null;
	isFeedbackModalOpen: boolean;
	productIndex: number;
	feedbackResponseValue: string;
	dealerName: string;
	ownerDetails: IownerDetails;
	sfid: string | null;
	productId: number | null;
	brandUsed: string | null;
	addNewSiteLead: boolean;
	substrates: DropdownOptions[];
	siteDetails: {
		name: string;
		number: string;
		sfid: string;
		membershipno: string;
	} | null;
	setSiteVisitId: (siteVisitId?: number | null) => void;
	setSiteLeadId: (siteLeadId?: number | null) => void;
	setFeedbackModal: (val: boolean) => void;
	setFeedbackResponseValue: (val: string) => void;
	setProductIndex: (val: number) => void;
	clearSiteLeadPersistedState: () => void;
	setDealerName: (val: string) => void;
	setOwnerDetails: (ownerName: string, ownerNumber: string | null) => void;
	setSiteSfid: (val: string | null) => void;
	setProductId: (val: number | null) => void;
	setKeyBaseValue: <T>(key: string, value: T) => void;
}

interface IownerDetails {
	ownerName: string;
	ownerNumber: string | null;
}

const initialState = {
	grade: '',
	reason: '',
	type_of_contract: '',
	glue: '',
	siteName: '',
	siteLocation: '',
	siteId: null,
	siteVisitId: null,
	isFeedbackModalOpen: false,
	feedbackResponseValue: '',
	productIndex: 0,
	dealerName: '',
	ownerDetails: {
		ownerName: '',
		ownerNumber: null,
	},
	sfid: '',
	productId: null,
	brandUsed: '',
	addNewSiteLead: false,
	substrates: [],
	siteDetails: {
		name: '',
		number: '',
		sfid: '',
		membershipno: '',
	},
};

type SiteLeadPersistOptions = PersistOptions<ISiteLeadStore, Partial<ISiteLeadStore>>;

export const useSiteLeadStore = create<ISiteLeadStore>(
	(
		persist as unknown as (
			config: StateCreator<ISiteLeadStore>,
			options: SiteLeadPersistOptions
		) => StateCreator<ISiteLeadStore>
	)(
		(set) => ({
			...initialState,
			setSiteLeadId: (siteId?: number | null): void =>
				set((state) => ({
					...state,
					siteId: siteId,
				})),
			setSiteVisitId: (visitId?: number | null): void =>
				set((state) => ({
					...state,
					siteVisitId: visitId,
				})),
			setFeedbackModal: (val: boolean): void =>
				set((state) => ({
					...state,
					isFeedbackModalOpen: val,
				})),
			setFeedbackResponseValue: (val: string): void =>
				set((state) => ({
					...state,
					feedbackResponseValue: val,
				})),
			setProductIndex: (val: number): void =>
				set((state) => ({
					...state,
					productIndex: val,
				})),
			setDealerName: (val: string): void =>
				set((state) => ({
					...state,
					dealerName: val,
				})),
			setOwnerDetails: (ownerName: string, ownerNumber: string | null): void =>
				set((state) => ({
					...state,
					ownerDetails: {
						ownerName: ownerName,
						ownerNumber: ownerNumber,
					},
				})),
			setSiteSfid: (id: string | null): void =>
				set((state) => ({
					...state,
					sfid: id,
				})),
			setProductId: (id: number | null): void =>
				set((state) => ({
					...state,
					productId: id,
				})),
			setKeyBaseValue: <T,>(key: string, value: T): void =>
				set((state) => ({
					...state,
					[key]: value,
				})),
			clearSiteLeadPersistedState: (): void => {
				set(initialState);
				localStorage.removeItem('site-lead-storage');
			},
		}),
		{
			name: 'site-lead-storage',
			partialize: (state) => ({
				siteId: state.siteId,
				siteVisitId: state.siteVisitId,
				sfid: state.sfid,
				productId: state.productId,
				siteDetails: state.siteDetails,
			}),
		} as SiteLeadPersistOptions
	)
);

export default useSiteLeadStore;
