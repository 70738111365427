import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { UserCode } from '.';
import { setRxdb } from '@helper/utils';
import { useCheckRxdb } from '@hooks/checkRxdb';

type UserQuery = {
	data: UserCode[];
	error: Error | null;
	isLoading: boolean;
	isPending: boolean;
};

export const useUserQuery = (): IQueryResponse<Array<UserQuery>> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('user_list');

	const fetchEndUserCodeData = async (): Promise<Array<UserQuery>> => {
		const response = await APIService.getData<Array<UserQuery>>(APICONFIG.USER_LIST + `?limit=${0}&version=v2`);
		if (response) {
			setRxdb('user_list', response);
		}
		return response;
	};

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['EndUserList'],
		queryFn: fetchEndUserCodeData,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
