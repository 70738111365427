import { SyntheticEvent } from 'react';
import BannerImage from '@assets/images/home-banner.png';
import profileImage from '@assets/images/profile.png';
import { BasicDetails, LeadAddressFields, MoreDetails, TownList } from '@services/hooks/enduser-details';
import { AdditionEndUser, ChildDetail, TownInfo } from '@components/endUserDetails/components/basicDetailsForm';
import {
	AccordianDetail,
	CompetitionDetail,
	DealerDetail,
	DealerDetailConfig,
} from '@components/endUserDetails/components/moreDetailsForm';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { getDatabase } from '@rxdb/config/databaseConfig';

export interface FormateDate {
	start: Date;
	end: Date;
}

export const convertTimeTo12HourFormat = (timeString: string): string => {
	const [hour] = timeString.split(':').map(Number);
	const period = hour >= 12 ? 'pm' : 'am';
	const hour12 = hour % 12 || 12;
	return `${hour12} ${period}`;
};

export const convertPathNameToTitle = (title: string): string => {
	const pathSegments = title.split('/').filter((segment) => segment !== '');

	const lastSegment = pathSegments[pathSegments.length - 1];
	return lastSegment
		?.split('-')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export const onImageError = (e: SyntheticEvent<HTMLImageElement, Event>): void => {
	e.currentTarget.onerror = null;
	e.currentTarget.src = BannerImage;
};
export const onProfileImageError = (e: SyntheticEvent<HTMLImageElement, Event>): void => {
	e.currentTarget.src = profileImage;
};

export const keyDownEvent = (e: React.KeyboardEvent<HTMLInputElement>): boolean => {
	const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Delete', 'Home', 'Tab', 'End'];
	const key = e.key;

	if (/^\d$/.test(key)) return true;

	if (allowedKeys.includes(key)) return true;

	if (e.ctrlKey && (key === 'c' || key === 'v' || key === 'a' || key === 'x' || key === 'z')) return true;

	e.preventDefault();
	return false;
};

export const convertISODateTODDMMYY = (DateTimeString: string | Date | null): string => {
	if (!DateTimeString) return '-';
	const date = new Date(DateTimeString);
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString().slice(2);

	return `${day}-${month}-${year}`;
};

export const getQuarterStartDate = (): string => {
	const today = new Date();
	const currentMonth = today.getMonth();
	const quarterStartMonth = Math.floor(currentMonth / 3) * 3;
	const startDate = new Date(today.getFullYear(), quarterStartMonth, 1);

	return startDate
		.toLocaleDateString('en-GB', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		})
		.split('/')
		.reverse()
		.join('-');
};

export const formatDate = (date: Date): string => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
};

export const get18YearsAgo = (): Date => {
	const today = new Date();
	return new Date(today.setFullYear(today.getFullYear() - 18));
};

export const isAtLeast18YearsAgo = (date: Date): boolean => {
	const today = new Date();
	const eighteenYearsAgo = new Date(today.setFullYear(today.getFullYear() - 18));
	return date <= eighteenYearsAgo;
};

export const formatDateRangePicker = (isoString: Date): string => {
	const date = new Date(isoString);
	return date.toLocaleDateString('en-CA');
};

export const renderNullCase = (className: string): JSX.Element => {
	return <span className={className}>-</span>;
};

export const getTownInfo = (townList: TownList[] | undefined, townDescription?: string | null): TownInfo | null => {
	const town = townList?.find((t) => t.towndescription_c === townDescription?.split('-')[0].trim());
	if (!town) return null;
	return {
		towncode: town?.towncode_c,
		townname: town?.towndescription_c,
	};
};

export const generateChildrenArray = (formData: BasicDetails): ChildDetail[] => {
	const details = [
		{ id: 0, dobKey: 'child1_dob_c', genderKey: 'child1_gender_c' },
		{ id: 1, dobKey: 'child2_dob_c', genderKey: 'child2_gender_c' },
		{ id: 2, dobKey: 'child3_dob_c', genderKey: 'child3_gender_c' },
	];

	return details.reduce((arr: ChildDetail[], detail) => {
		const dob = formData.additionenduser[detail.dobKey as keyof AdditionEndUser];
		const gender = formData.additionenduser[detail.genderKey as keyof AdditionEndUser];
		if (dob && gender) {
			arr.push({
				id: detail.id,
				child_gender: gender,
				child_birthday: dob,
			});
		}
		return arr;
	}, [] as ChildDetail[]);
};

export const getDealerDetails = (formData: MoreDetails): DealerDetail[] => {
	const details: DealerDetailConfig[] = [
		{ id: 0, codeKey: 'primarydealercode', nameKey: 'primarydealername' },
		{ id: 1, codeKey: 'alternate1dealercode_c', nameKey: 'alternate1dealername_c' },
		{ id: 2, codeKey: 'alternate2dealercode_c', nameKey: 'alternate2dealername_c' },
	];

	return details.reduce((arr: DealerDetail[], detail: DealerDetailConfig) => {
		const code = formData[detail.codeKey];
		const name = formData[detail.nameKey];
		if (code && name) {
			arr.push({
				id: detail.id,
				dealer_code: code.toString(),
				dealer_name: name.toString(),
			});
		}
		return arr;
	}, []);
};
export const getCompetitionArray = (formData: MoreDetails): CompetitionDetail[] => {
	const details = [
		{ id: 0, brandKey: 'competition_brand_1_used_c', volumeKey: 'competition_brand_1_consumption_c' },
		{ id: 1, brandKey: 'competition_brand_2_used_c', volumeKey: 'competition_brand_2_consumption_c' },
		{ id: 2, brandKey: 'competition_brand_3_used_c', volumeKey: 'competition_brand_3_consumption_c' },
	];

	return details.reduce((arr: CompetitionDetail[], detail) => {
		const brand = formData[detail.brandKey as keyof typeof formData];
		const volume = formData[detail.volumeKey as keyof typeof formData];
		if (brand && volume) {
			arr.push({
				id: detail.id,
				competition_brands: brand as string,
				competition_volume: parseInt(volume) as number,
			});
		}
		return arr;
	}, [] as CompetitionDetail[]);
};

export const getArchitectDetails = (formData: MoreDetails): AccordianDetail[] => {
	const details = [
		{ id: 0, nameKey: 'architect_name1_c', numberKey: 'architect_phone1_c' },
		{ id: 1, nameKey: 'architect_name2_c', numberKey: 'architect_phone2_c' },
		{ id: 2, nameKey: 'architect_name3_c', numberKey: 'architect_phone3_c' },
	];

	return details.reduce((arr: AccordianDetail[], detail) => {
		const name = formData.additionenduser[detail.nameKey as keyof typeof formData.additionenduser];
		const number = formData.additionenduser[detail.numberKey as keyof typeof formData.additionenduser];
		if (name && number) {
			arr.push({
				id: detail.id,
				name: name as string,
				number: number === '0' ? '' : (number as string),
				isActive: true,
			});
		}
		return arr;
	}, [] as AccordianDetail[]);
};

export const getInfluencerDetails = (formData: MoreDetails): AccordianDetail[] => {
	const details = [
		{ id: 0, nameKey: 'influencer_name1_c', numberKey: 'influencer_phone1_c' },
		{ id: 1, nameKey: 'influencer_name2_c', numberKey: 'influencer_phone2_c' },
		{ id: 2, nameKey: 'influencer_name3_c', numberKey: 'influencer_phone3_c' },
	];

	return details.reduce((arr: AccordianDetail[], detail) => {
		const name = formData.additionenduser[detail.nameKey as keyof typeof formData.additionenduser];
		const number = formData.additionenduser[detail.numberKey as keyof typeof formData.additionenduser];
		if (name && number) {
			arr.push({
				id: detail.id,
				name: name as string,
				number: number === '0' ? '' : (number as string),
				isActive: true,
			});
		}
		return arr;
	}, [] as AccordianDetail[]);
};

export const escapeRegExp = (string: string): string => {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const handlePreventDefault = <T extends { preventDefault: () => void }>(e: T): void => {
	e.preventDefault();
};

export const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
	e.stopPropagation();
};

export const deepEqual = <T, K extends T>(obj1: T, obj2: K): boolean => {
	if (obj1 === obj2) return true;

	if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) return false;

	for (const key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
	}

	return true;
};

const returnPeramentAddress = (getBasicFormData: BasicDetails): LeadAddressFields => {
	return {
		address: getBasicFormData.permanent_address1_c,
		city: getBasicFormData.permanentcity_c,
		state: getBasicFormData.permanentstate_c,
		street: getBasicFormData.permanentstreet_c,
		zipcode: getBasicFormData.permanentzipcode_c,
	};
};

export const compareLocalAddresses = (getBasicFormData: BasicDetails): boolean => {
	const localAddress: LeadAddressFields = {
		address: getBasicFormData.permanent_address2_c,
		city: getBasicFormData.local_city_c,
		state: getBasicFormData.local_state_c,
		street: getBasicFormData.local_street_c,
		zipcode: getBasicFormData.local_pin_code_c,
	};

	return deepEqual(localAddress, returnPeramentAddress(getBasicFormData));
};

export const checkDataIsEmpty = <T,>(props: T): boolean => {
	if (!props) {
		return false;
	}
	return !Object?.values(props).some(
		(x) =>
			x !== null &&
			x !== '' &&
			x !== false &&
			x !== undefined &&
			!(Array.isArray(x) && x.length === 0) &&
			!(
				Array.isArray(x) &&
				x.every((y) => {
					return checkDataIsEmpty(y);
				})
			)
	);
};
export const compareWorkShopAddresses = (getBasicFormData: MoreDetails): boolean => {
	const workShopAddress: LeadAddressFields = {
		address: getBasicFormData.workshopaddress1_c,
		city: getBasicFormData.workshopcity_c,
		state: getBasicFormData.workshopstate_c,
		street: getBasicFormData.workshopaddress2_c,
		zipcode: getBasicFormData.workshoppincode_c,
	};

	return checkDataIsEmpty(workShopAddress);
};
export const compareFactoryAddresses = (getBasicFormData: MoreDetails): boolean => {
	const factoryAddress: LeadAddressFields = {
		address: getBasicFormData.furniture_address_c,
		city: getBasicFormData.furniture_city_c,
		state: getBasicFormData.furniture_state_c,
		zipcode: getBasicFormData.furniture_pincode_c,
	};

	return checkDataIsEmpty(factoryAddress);
};

export const compareWorkAddresses = (getBasicFormData: BasicDetails): boolean => {
	const workAddress: LeadAddressFields = {
		address: getBasicFormData.permanent_address3_c,
		city: getBasicFormData.work_city_c,
		state: getBasicFormData.work_state_c,
		street: getBasicFormData.work_street_c,
		zipcode: getBasicFormData.work_pin_code_c,
	};

	return deepEqual(workAddress, returnPeramentAddress(getBasicFormData));
};

export const formatDateTime = (dateStr: string, timeStr: string): string => {
	const dateTimeStr = `${dateStr}T${timeStr}`;
	const date = new Date(dateTimeStr);
	const utcYear = date.getUTCFullYear();
	const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0');
	const utcDay = String(date.getUTCDate()).padStart(2, '0');
	const utcHours = String(date.getUTCHours()).padStart(2, '0');
	const utcMinutes = String(date.getUTCMinutes()).padStart(2, '0');
	const utcSeconds = String(date.getUTCSeconds()).padStart(2, '0');
	const utcMilliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

	return `${utcYear}-${utcMonth}-${utcDay}T${utcHours}:${utcMinutes}:${utcSeconds}.${utcMilliseconds}Z`;
};

export const convertToStartOfDay = (date: Date): Date => {
	const newDate = new Date(date);
	newDate.setHours(0, 0, 0, 0);
	return newDate;
};

export const convertDateToTime = (dateAndTime: Date): string => {
	return dateAndTime?.toTimeString().substring(0, 5);
};

export const convertTo24Hour = (time: string): string => {
	const [timePart, modifier] = time.split(' ');
	const [hours, minutes] = timePart.split(':');

	let hoursNumber = parseInt(hours, 10);
	const minutesNumber = parseInt(minutes, 10);
	if (modifier === 'PM' && hoursNumber !== 12) {
		hoursNumber += 12;
	} else if (modifier === 'AM' && hoursNumber === 12) {
		hoursNumber = 0;
	}
	const formattedHours = hoursNumber.toString().padStart(2, '0');
	const formattedMinutes = minutesNumber.toString().padStart(2, '0');
	return `${formattedHours}:${formattedMinutes}`;
};

export const convertTo12Hour = (time: string): string => {
	const [hoursStr, minutesStr] = time.split(':');
	let hours = parseInt(hoursStr, 10);
	const minutes = parseInt(minutesStr, 10);

	const modifier = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12 || 12;
	const formattedHours = hours.toString().padStart(2, '0');
	const formattedMinutes = minutes.toString().padStart(2, '0');
	return `${formattedHours}:${formattedMinutes} ${modifier}`;
};

export const generateTimeOptions = (minTime?: string): DropdownOptions[] => {
	const times: DropdownOptions[] = [];
	let minHours = 0;
	let minMinutes = 0;

	if (minTime) {
		const [time, period] = minTime.split(' ');
		const [hoursStr, minutesStr] = time.split(':');
		let hours = parseInt(hoursStr, 10);
		const minutes = parseInt(minutesStr, 10);

		if (period.toUpperCase() === 'PM' && hours !== 12) {
			hours += 12;
		} else if (period.toUpperCase() === 'AM' && hours === 12) {
			hours = 0;
		}

		minHours = hours;
		minMinutes = minutes;
	}

	for (let hours = 0; hours < 24; hours++) {
		for (const minutes of [0, 15, 30, 45]) {
			if (minTime && (hours < minHours || (hours === minHours && minutes < minMinutes))) {
				continue;
			}

			const timeString24 = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
			const hours12 = (hours % 12 || 12).toString().padStart(2, '0');
			const ampm = hours < 12 ? 'AM' : 'PM';
			const timeString12 = `${hours12}:${String(minutes).padStart(2, '0')} ${ampm}`;
			times.push({ id: timeString24, value: timeString12 });
		}
	}
	return times;
};

export const getMonthStartAndEndDate = (monthString: string): { startDate: string; endDate: string } => {
	const [year, month] = monthString.split('-').map(Number);
	const startDate = formatDate(new Date(year, month - 1, 1));
	const endDate = formatDate(new Date(year, month, 0));
	return { startDate, endDate };
};

export const onDownload = (file: string | undefined): void => {
	if (!file) {
		return;
	}
	const currentTimestamp = new Date().getTime();
	const fileUrl = file;
	const downloadLink = document.createElement('a');
	downloadLink.href = fileUrl;
	downloadLink.download = currentTimestamp.toString();
	// Open the file in a new tab
	downloadLink.target = '_blank';
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};

export const setRxdb = async <T,>(name: string, response: Array<T>): Promise<void> => {
	const db = await getDatabase();
	await getDatabase();
	const dbData = await db[name].find().exec();
	const primaryId = dbData.map((doc: { primary: string }) => doc.primary);
	await db[name].bulkRemove(primaryId);
	await db[name].bulkInsert(response);
};

export const formatDateDDMMYY = (date: Date): string => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');

	return `${day}-${month}-${year}`;
};

export const getQuarterStartEndDate = (date: Date): FormateDate => {
	const year = date.getFullYear();
	const quarter = Math.floor(date.getMonth() / 3);

	let start: Date;
	let end: Date;

	switch (quarter) {
		case 0:
			start = new Date(year, 0, 1); // January 1
			end = new Date(year, 2, 31); // March 31
			break;
		case 1:
			start = new Date(year, 3, 1); // April 1
			end = new Date(year, 5, 30); // June 30
			break;
		case 2:
			start = new Date(year, 6, 1); // July 1
			end = new Date(year, 8, 30); // September 30
			break;
		case 3:
			start = new Date(year, 9, 1); // October 1
			end = new Date(year, 11, 31); // December 31
			break;
		default:
			throw new Error('Invalid month');
	}

	return { start, end };
};

export const getMonthNames = (): DropdownOptions[] => {
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	return monthNames.map((month, index) => ({
		id: index + 1,
		value: month,
	}));
};

export const getMonthNumber = (monthName: string): string | null => {
	const months = getMonthNames();
	const month = months.find((m) => m.value.toLowerCase() === monthName.toLowerCase());
	return month ? month.id.toString().padStart(2, '0') : null;
};

export const getCoordinatesFromTownName = async (
	townName: string
): Promise<{ latitude?: number; longitude?: number; error?: string }> => {
	const town = encodeURIComponent(townName);
	const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${town}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

	try {
		const response = await fetch(apiUrl);
		const data = await response.json();

		if (data.results.length > 0) {
			const location = data.results[0].geometry.location;
			const latitude = location.lat;
			const longitude = location.lng;
			return { latitude, longitude };
		} else {
			return { error: 'No results found for the given town name.' };
		}
	} catch (error) {
		return { error: 'Error fetching coordinates.' };
	}
};

export const getDateByRange = (date: Date, range: number): string => {
	const endDate = new Date(date);
	const fiveDaysAfter = new Date(endDate);
	fiveDaysAfter.setDate(endDate.getDate() + range);
	return fiveDaysAfter.toLocaleDateString();
};

export interface DataRow {
	[key: string]: string | number | boolean;
}

export const ArrayToCSV = (data: DataRow[]): void => {
	if (data.length === 0) {
		return;
	}

	const headers: string[] = Object.keys(data[0]);
	const csvRows: string[] = [];

	csvRows.push(headers.join(','));

	for (const row of data) {
		const values: string[] = headers.map((header) => {
			const escaped: string = ('' + row[header]).replace(/"/g, '\\"');
			return `"${escaped}"`;
		});
		csvRows.push(values.join(','));
	}

	const csv: string = csvRows.join('\n');
	const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `attendance_data_${new Date().toISOString()}.csv`);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	URL.revokeObjectURL(url);
};

export const roundToNearestQuarterHour = (date: Date): Date => {
	const newDate = new Date(date);
	const minutes = newDate.getMinutes();
	const seconds = newDate.getSeconds();

	let roundedMinutes: number;
	if (minutes % 15 === 0 && seconds === 0) {
		roundedMinutes = (minutes + 15) % 60;
	} else {
		roundedMinutes = Math.ceil((minutes + 1) / 15) * 15;
	}

	if (roundedMinutes === 60) {
		newDate.setHours(newDate.getHours() + 1);
		roundedMinutes = 0;
	}
	newDate.setMinutes(roundedMinutes);
	newDate.setSeconds(0);
	newDate.setMilliseconds(0);

	return newDate;
};
export const formatTime = (date: Date): string => {
	const hours = date.getHours() % 12 || 12;
	const minutes = date.getMinutes();
	const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
	return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${ampm}`;
};

export const calculateEndTimeAndDate = (startTime: string, startDate: Date): { endTime: string; endDate: Date } => {
	const [time, period] = startTime.split(' ');
	const [hoursStr, minutesStr] = time.split(':');
	let hours = parseInt(hoursStr, 10);
	const minutes = parseInt(minutesStr, 10);

	if (period?.toLowerCase() === 'pm' && hours !== 12) {
		hours += 12;
	} else if (period?.toLowerCase() === 'am' && hours === 12) {
		hours = 0;
	}

	const endDate = new Date(startDate);
	let endHours = hours + 1;

	if (endHours >= 24) {
		endHours -= 24;
		endDate.setDate(endDate.getDate() + 1);
	}

	const endPeriod = endHours >= 12 ? 'PM' : 'AM';
	endHours = endHours % 12 || 12;

	const endTime = `${String(endHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${endPeriod}`;
	return { endTime, endDate };
};

export const compareDatePartsOnly = (date1: Date, date2: Date): boolean => {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
};

export function isTimePast(currentTime: string, apiTime: string): boolean {
	// Convert 12-hour format to 24-hour format
	const convertTo24Hour = (time: string): number => {
		const [timeStr, period] = time.split(' ');
		// eslint-disable-next-line prefer-const
		let [hours, minutes] = timeStr.split(':').map(Number);

		if (period === 'PM' && hours !== 12) {
			hours += 12;
		} else if (period === 'AM' && hours === 12) {
			hours = 0;
		}

		return hours * 60 + minutes;
	};

	const currentMinutes = convertTo24Hour(currentTime);
	const apiMinutes = convertTo24Hour(apiTime);

	return apiMinutes < currentMinutes;
}

export function convertTime(input: string): string {
	// Parse the input time
	const [hours, minutes] = input.split(':').map(Number);

	// If minutes are 45 or less, round down to the nearest 15-minute interval
	if (minutes <= 45) {
		const roundedMinutes = Math.floor(minutes / 15) * 15;
		const formattedMinutes = roundedMinutes.toString().padStart(2, '0');
		const period = hours >= 12 ? 'PM' : 'AM';
		const displayHours = hours % 12 || 12; // Convert to 12-hour format

		return `${displayHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
	}
	// If minutes are greater than 45, round up to the next hour
	else {
		const nextHour = (hours + 1) % 24;
		const period = nextHour >= 12 ? 'PM' : 'AM';
		const displayHours = nextHour % 12 || 12; // Convert to 12-hour format

		return `${displayHours.toString().padStart(2, '0')}:00 ${period}`;
	}
}

export const isFilterValuePresent = <T,>(value: T): boolean => {
	if (Array.isArray(value)) {
		return value.length > 0 && !(value.length === 2 && value.every((v) => v === null));
	}
	return typeof value === 'string' ? !!value : Boolean(value);
};

export const formateTimeTo12HrFormate = (time24: string | undefined): string => {
	if (!time24) return 'N/A'; // Return a default value if input is undefined
	const [hoursStr, minutesStr] = time24.split(':');
	const hours = parseInt(hoursStr, 10);
	const minutes = parseInt(minutesStr, 10);
	if (isNaN(hours) || isNaN(minutes) || hours > 23 || minutes > 59) {
		return 'Invalid time';
	}
	const period: string = hours < 12 ? 'AM' : 'PM';
	const hours12: number = hours % 12 || 12;
	return `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
};

export const urlToFile = async (url: string, filename: string): Promise<File> => {
	const response = await fetch(url);
	const blob = await response.blob();
	return new File([blob], filename, { type: blob.type });
};

export const normalizeDate = (input: Date | string): string => {
	if (input instanceof Date) {
		return input.toISOString();
	}

	if (typeof input === 'string') {
		if (/^\d{2}\/\d{4}$/.test(input)) {
			const [month, year] = input.split('/');
			return new Date(parseInt(year), parseInt(month) - 1, 1).toISOString();
		}

		const parsedDate = new Date(input);
		if (!isNaN(parsedDate.getTime())) {
			return parsedDate.toISOString();
		}
	}

	throw new Error('Invalid date format');
};

export const extractLink = (htmlString: string): string | null => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, 'text/html');
	const anchor = doc.querySelector('a');
	return anchor ? anchor.getAttribute('href') : null;
};

export const getRelativeDate = (inputDate?: string | null): string => {
	if (!inputDate) return 'N/A';
	const currentDate = new Date();
	const inputDateTime = new Date(inputDate);

	if (isNaN(inputDateTime.getTime())) {
		return 'Invalid date';
	}

	const diffTime = currentDate.getTime() - inputDateTime.getTime();
	const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
	const diffMonths =
		(currentDate.getFullYear() - inputDateTime.getFullYear()) * 12 +
		(currentDate.getMonth() - inputDateTime.getMonth());
	const diffYears = currentDate.getFullYear() - inputDateTime.getFullYear();

	if (diffDays === 0) {
		return 'today';
	} else if (diffDays < 30) {
		return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
	} else if (diffMonths < 12) {
		return `${diffMonths} month${diffMonths !== 1 ? 's' : ''} ago`;
	} else {
		return `${diffYears} year${diffYears !== 1 ? 's' : ''} ago`;
	}
};

export const getPathData = (name: string | null): string => {
	switch (name) {
		case 'MAR(Today Meeting)':
			return 'today-meeting';
		case 'Scheduled Meeting':
		case null:
		case '':
			return 'scheduled-meeting';
		case 'Completed Meeting':
			return 'meeting-completed';
		case 'List Of Meeting Conducted':
			return 'meeting-conducted';
		default:
			return 'scheduled-meeting';
	}
};

export const getInvoice = async (): Promise<{
	data: { access_token?: string; expires_in?: string; token_type?: string };

	error?: string;
}> => {
	const apiUrl = `https://invoiceapi.pilportal.com:11688/token`;

	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

	const urlencoded = new URLSearchParams();
	urlencoded.append('UserName', 'Invoice_api');
	urlencoded.append('Password', "HpN9s'xff*A%7pS?*hemx$K}");
	urlencoded.append('grant_type', 'password');

	const requestOptions: RequestInit = {
		method: 'POST',
		headers: myHeaders,
		body: urlencoded,
		redirect: 'follow',
	};

	try {
		const response = await fetch(apiUrl, requestOptions);

		const responseData = await response.json();

		return { data: responseData };
	} catch (error) {
		return { data: {}, error: 'Error fetching invoice details.' };
	}
};

export const getInvoiceDetails = async (
	BatchNo: string | number,
	Material: string | undefined
): Promise<{ data: string[]; error?: string }> => {
	try {
		const token = await getInvoice();

		const apiUrl = `https://invoiceapi.pilportal.com:11688/api/InvoiceDetails`;

		const urlencoded = new URLSearchParams();
		urlencoded.append('BatchNo', `${BatchNo}`);
		urlencoded.append('Material', `${Material}`);
		const myHeaders = new Headers();
		myHeaders.append('Authorization', `Bearer ${token.data.access_token}`);
		myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

		const requestOptions: RequestInit = {
			method: 'POST',
			headers: myHeaders,
			body: urlencoded,
			redirect: 'follow',
		};

		const response = await fetch(apiUrl, requestOptions);

		const data = await response.json();

		if (data?.results && data.results.length > 0) {
			return { data: data.results };
		} else {
			return { data: [], error: 'No results found for the given batch number and material.' };
		}
	} catch (error) {
		return { data: [], error: 'Error fetching invoice details.' };
	}
};

export const getCurrentQuarterNumber = (text?: string): string => {
	const currentQuarter = Math.ceil(new Date().getMonth() / 3),
		quarter = currentQuarter - 1 === 0 ? 1 : currentQuarter - 1;

	return text ? `${text}${quarter}` : quarter.toString();
};

export const extractTime = (isoDateTime: string): string => {
	const date = new Date(isoDateTime);
	return date.toLocaleTimeString('en-US', {
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
		timeZone: 'UTC',
	});
};

export const formatDateForQuery = (date: Date): string => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const formatDateString = (dateString: string, includeTime: boolean = true): string => {
	const date = new Date(dateString.replace('Z', ''));
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const formatted = `${year}-${month}-${day}`;

	if (includeTime) {
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');
		return `${formatted}T${hours}:${minutes}:${seconds}`;
	}

	return formatted;
};
