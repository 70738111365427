import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { ErrorHandler } from '@helper/errorHandler';
import { IBankingDetailData } from '@components/contractor360/components/contractorNavigation';

export const useBankingDetailsQuery = (membershipNumber: string): IQueryResponse<IBankingDetailData> => {
	const fetchBankingDetailData = async (): Promise<IBankingDetailData> => {
		try {
			return await APIService.getData<IBankingDetailData>(APICONFIG.BANKING_DETAIL, {
				membershipno: membershipNumber,
			});
		} catch (error) {
			ErrorHandler(error);
			return { message: '', data: null };
		}
	};
	const { data, error, isLoading, isPending, isFetching, isSuccess } = useQuery({
		queryKey: ['bankingDetail', membershipNumber],
		queryFn: fetchBankingDetailData,
		enabled: membershipNumber.length > 0,
		refetchOnWindowFocus: false,
		retry: false,
	});
	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		isSuccess,
	};
};
