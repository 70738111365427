import { RouterProvider } from 'react-router-dom';
import { router } from './routes/routes';
import { ReactElement, useEffect, useState } from 'react';
import { Modal } from '@components/common/modal/modal';
import { SuccessModal, Toster } from '@components/common';
import { useSuccessTosterStore } from '@store/successToster';
import { Provider } from 'rxdb-hooks';
import { getDatabase } from '@rxdb/config/databaseConfig';
import { useLoadGoogleMapScript } from '@hooks/useLoadGoogleMapScript';
import { useTosterStore } from '@store/toster';
import { FullPageLoader } from '@components/common/fullPageLoader';

function App(): ReactElement {
	const { isOpen, setSuccessModalClose } = useSuccessTosterStore();
	const [db, setDb] = useState();
	const { isOpen: isTosterOpen } = useTosterStore();
	useLoadGoogleMapScript();

	useEffect(() => {
		getDatabase().then(setDb);
	}, []);

	return (
		<Provider db={db}>
			<FullPageLoader />
			<RouterProvider fallbackElement={<FullPageLoader />} router={router} />
			{isOpen && (
				<Modal modalId="successToster" className="dialog-sm" modalOpen={isOpen} onModalClose={setSuccessModalClose}>
					<SuccessModal onClose={setSuccessModalClose} />
				</Modal>
			)}
			{isTosterOpen && <Toster />}
		</Provider>
	);
}

export default App;
