import { Workbox } from 'workbox-window';

// TODO Need to remove console once code freezing is completed
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const promptForUpdate = () => {
	return new Promise((resolve) => {
		const confirmation = window.confirm('New update available. Do you want to update?');
		console.log('User response to update prompt:', confirmation);
		resolve(confirmation);
	});
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const checkForUpdates = () => {
	if (navigator?.serviceWorker?.controller) {
		navigator.serviceWorker.controller.postMessage({ type: 'CHECK_FOR_UPDATES' });
		console.log('Sent CHECK_FOR_UPDATES message to service worker');
	}
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const register = () => {
	if ('production' !== process.env.NODE_ENV) {
		return;
	}

	if ('serviceWorker' in navigator) {
		console.log('Service Worker is supported');
		const wb = new Workbox('/service-worker.js');

		let refreshing = false;

		navigator.serviceWorker.addEventListener('controllerchange', () => {
			console.log('Controller changed, reloading page');
			if (!refreshing) {
				console.log('Reloading page...');
				window.location.reload();
				refreshing = true;
			}
		});

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		const showSkipWaitingPrompt = async () => {
			const updateAccepted = await promptForUpdate();
			if (updateAccepted) {
				console.log('Update accepted, sending skipWaiting message');
				wb.messageSkipWaiting();
				setTimeout(() => {
					window.location.reload();
				}, 2000)
			} else {
				console.log('Update rejected by user');
			}
		};

		wb.addEventListener('waiting', async (event) => {
			console.log('New service worker is waiting @@', event);
		});

		wb.addEventListener('message', (event) => {
			if (event.data.type === 'CACHE_UPDATED') {
				const { updatedURL } = event.data.payload;

				console.log(`A newer version of ${updatedURL} is available!`);
			}
		});
		wb.addEventListener('installed', (event) => {
			if (!event.isUpdate) {
				// eslint-disable-next-line no-console
				console.log('Service worker installed for the first time');
			}
		});

		wb.addEventListener('activated', () => {
			// eslint-disable-next-line no-console
			console.log('Service worker activated');
		});

		wb.addEventListener('controlling', (event) => {
			console.log('Service worker is controlling the page', event);
			// window.location.reload();reload
		});

		wb.addEventListener('redundant', (event) => {
			console.log('Service worker became redundant', event);
		});

		wb.register().then((registration) => {
			console.log('Service Worker registered successfully:', registration);
			// Check for updates immediately after registration
			registration.update();
		}).catch((error) => {
			console.error('Service Worker registration failed:', error);
		});
	}
};

export { register };
