interface PCMSFromData {
	productDescription: string;
	productWeight: string;
	sku_names_c: string;
	ref_lot_no_c: string;
	manufactured_in_c: string;
	quantity_c: string | null;
	uom_c: string;

	customer_name_c: string;
	registered_mobile_number_c: string;
	type_of_complaint_c: string;
	dealer_membership_code_c: string;
	wss_names_c: string;
	wss_codes_c: string;
	address_c: string;
	site_under_warranty_c: string;
	what_is_the_warranty_certificate_number_c: string;

	nature_of_complaints_c: string;
	sub_category_defect_c: string;
	description_c: string;
	pictures_videos_c: File[] | [];
	complaint_sample_availability_c: string;
	sample_non_availability_reason: string | null;
	sample_non_availability_reason_others: string | null;

	courier_company_ref_id: string;
	attachment_courier_receipt_c: File[] | [];

	applicator_name: string | null;
	stage_of_application_during_complaint_c: string | null;
	quantity_action_c: string | null;
	application_surface_c: string | null;
	area_in_sqft_c: string | null;
	observation_c: string | null;
	material_code_c: string | null;
	batch_number_c: string | null;
	plant_code_c: string;
	plant_name_c: string;
	manufacturing_date_c: string | null;
}

export const pcmsInitialData: PCMSFromData = {
	productDescription: '',
	productWeight: '',
	sku_names_c: '',
	ref_lot_no_c: '',
	manufactured_in_c: '',
	customer_name_c: '',
	registered_mobile_number_c: '',
	wss_names_c: '',
	address_c: '',
	nature_of_complaints_c: '',
	sub_category_defect_c: '',
	description_c: '',
	uom_c: 'EA (Pieces)',
	pictures_videos_c: [],
	attachment_courier_receipt_c: [],
	complaint_sample_availability_c: '',
	sample_non_availability_reason: '',
	courier_company_ref_id: '',
	applicator_name: '',
	stage_of_application_during_complaint_c: '',
	sample_non_availability_reason_others: '',
	quantity_action_c: '',
	quantity_c: '',
	application_surface_c: '',
	area_in_sqft_c: '',
	observation_c: '',
	material_code_c: '',
	batch_number_c: '',
	plant_code_c: '',
	plant_name_c: '',
	manufacturing_date_c: '',
	dealer_membership_code_c: '',
	type_of_complaint_c: '',
	site_under_warranty_c: '',
	what_is_the_warranty_certificate_number_c: '',
	wss_codes_c: '',
};
import { Value } from '@components/common/date-picker';
import { FormikValues } from 'formik';

export type TFormik = {
	values: PCMSFromData;
	setFieldValue: (field: string, value: Value | string | string[] | boolean | number, shouldValidate?: boolean) => void;
	touched: FormikValues;
	errors: FormikValues;
	handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	setFieldError?: (field: string, value: string | undefined) => void;
};

export interface DealerWhoRaisedAComplaintProps {
	formik: TFormik;
	isEditing: boolean;
}

export type PcmsObject = {
	sku_names_c?: string;
	ref_lot_no_c?: string;
	manufactured_in_c?: string;
	customer_name_c?: string;
	registered_mobile_number_c?: string;
	wss_names_c?: string;
	address_c?: string;
	nature_of_complaints_c?: string;
	sub_category_defect_c?: string;
	description_c?: string;
	uom_c?: string;
	pictures_videos_c?: string | null;
	attachment_courier_receipt_c?: string | null;
	complaint_sample_availability_c?: string;
	sample_non_availability_reason?: string;
	courier_company_ref_id?: string;
	applicator_name?: string;
	stage_of_application_during_complaint_c?: string;
	sample_non_availability_reason_others?: string;
	quantity_action_c?: string;
	quantity_c?: string | number;
	application_surface_c?: string;
	area_in_sqft_c?: string | number;
	observation_c?: string;
	material_code_c?: string;
	batch_number_c?: string;
	plant_code_c?: string;
	plant_name_c?: string;
	manufacturing_date_c?: string;
	dealer_membership_code_c?: string;
	type_of_complaint_c?: string;
	product_code_c?: string;
	cluster_name_c?: string;
	site_under_warranty_c?: string;
	what_is_the_warranty_certificate_number_c?: string;
	wss_codes_c?: string;
};
