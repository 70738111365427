import { ReactElement, useEffect, useState } from 'react';
import { ProductCard } from './productCard';
import { TaskListProduct } from '@services/hooks/site-leads';
import { IFeedback, IProductDiscussionProps } from '.';
import useSiteLeadStore from '@store/siteLeadStore';
import { Modal } from '@components/common/modal/modal';
import { ProductFeedback } from './ProductFeedback';
import { useRxData } from 'rxdb-hooks';
import { Dealers } from '@services/hooks/enduser-details';
import { feedbackQuestions, masterFeedbackQuestions } from '@config/feedbackQuestions';
import { VideoPlayer } from '@components/common/videoPlayerModal/videoPlayer';
import { useVideoPlayerStore } from '@store/videoPlayerStore';

export const ProductDiscussion = (props: IProductDiscussionProps): ReactElement => {
	const { products, formik } = props;
	const { result: dealerList } = useRxData<Dealers>('dealer', (collection) => collection.find());
	const { isFeedbackModalOpen, setFeedbackModal, feedbackResponseValue, reason, glue, grade } = useSiteLeadStore();
	const [feedbackQuestionsNew, setFeedbackQuestionsNew] = useState(masterFeedbackQuestions);
	const { isVideoPlayerOpen, setVideoPlayerState } = useVideoPlayerStore();

	const commonFeedbackProps = {
		formik,
		products,
		action: feedbackResponseValue,
	};

	useEffect(() => {
		if (dealerList?.length) {
			const dealers = dealerList?.map((d, index) => {
				return {
					id: index,
					value: `${d._data?.dealercode_c} -  ${d._data?.dealername_c}`,
					name: d._data?.dealername_c,
				};
			});
			formik.setFieldValue('dealers', dealers);

			// TODO: Need to make it dynamic based on API

			setFeedbackQuestionsNew((prevQuestions) => {
				const updatedQuestions = { ...prevQuestions };
				updatedQuestions['Competition due to dealer push'][0].options = dealers;
				updatedQuestions['Homeowner bought cheaper adhesive'][2].options = dealers;
				updatedQuestions['Builder choses adhesive'][0].options = dealers;
				return updatedQuestions;
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dealerList]);

	useEffect(() => {
		products?.forEach((item, index) => {
			if (item?.recommended_from === 'master' && glue === 'F&B') {
				const fields = feedbackQuestionsNew;
				const masterfields = fields.fnb;
				formik.setFieldValue(
					`feedback[${index}]`,
					masterfields?.map((ele) => {
						return {
							id: ele.id,
							productId: item.product_code,
							feedbackQuestion: ele.question,
							feedbackOptions: ele.options,
							feedbackResponse: '',
							type: ele.type,
							placeHolder: ele.placeHolder,
							validation: ele.validation,
						};
					})
				);
			}
			if (item?.recommended_from === 'master' && glue === 'Competition' && reason) {
				const fields = feedbackQuestionsNew;
				const masterfields = fields[reason];
				const result = masterfields?.reduce<IFeedback[]>((acc, ele) => {
					if ((ele.type === 'checkbox' && products[index]?.visit_count < 2) || !ele.question.trim()) {
						return acc;
					}

					acc.push({
						id: ele.id,
						productId: item.product_code,
						feedbackQuestion: ele.question,
						feedbackOptions: ele.options,
						feedbackResponse: '',
						type: ele.type,
						placeHolder: ele.placeHolder,
						validation: ele.validation,
					});

					return acc;
				}, []);

				formik.setFieldValue(`feedback[${index}]`, result);
			}
			if (
				item?.recommended_from === 'master' &&
				glue === 'FV' &&
				grade &&
				(grade?.toLowerCase() === 'sh' || grade?.toLowerCase() === 'marine')
			) {
				formik.setFieldValue(`feedback[${index}]`, []);
			}
			if (item?.recommended_from === 'analytics') {
				const item = products?.[index];
				const fields = feedbackQuestions.analytics;
				const updatedQuestions =
					(item?.questions !== null &&
						fields &&
						item?.questions !== undefined &&
						item?.questions &&
						Object.entries(item?.questions || {}).map(([key, value]) => {
							return { ...fields[key][0], question: value };
						})) ||
					[];
				formik.setFieldValue(
					`feedback[${index}]`,
					updatedQuestions.map((ele) => {
						return {
							id: ele.id,
							productId: item.product_code,
							feedbackQuestion: ele.question,
							feedbackOptions: ele.options,
							feedbackResponse: '',
							type: 'dropdown',
							placeHolder: ele.placeHolder,
						};
					})
				);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [glue, products, reason, formik.values.dealers]);

	return (
		<>
			<div className="product-discussion-wrapper">
				<h1 className="list-title">
					<span className="title-wrapper">
						<span className="title-number">1</span>
						<span className="title-name">Product Discussion</span>
					</span>
				</h1>

				<ul className="product-discussion-list row">
					{products?.length ? (
						products?.map((product: TaskListProduct, index) => (
							<ProductCard
								key={`${product.product_name}-${product.id}`}
								product={product}
								productIndex={index}
								action={formik.values.productResponse[index]}
								formik={formik}
							/>
						))
					) : (
						<li>No Data</li>
					)}
				</ul>
			</div>
			<Modal
				modalId="productFeedback"
				className="dialog-lg dialog-bottom site-product-popup"
				modalOpen={isFeedbackModalOpen}
				onModalClose={() => setFeedbackModal(false)}
			>
				<ProductFeedback {...commonFeedbackProps} dealers={formik.values.dealers} />
			</Modal>
			<Modal
				modalId="videoPlayer"
				className="dialog-sm dialog-lg pt-4"
				modalOpen={isVideoPlayerOpen}
				onModalClose={() => setVideoPlayerState(false)}
			>
				<VideoPlayer />
			</Modal>
		</>
	);
};
