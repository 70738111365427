import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ToastType, useTosterStore } from '@store/toster';
import { useGetCustomerDetailsMutation } from '@services/hooks/contractor360/useGetCustomerQuery';
import '@assets/styles/pages/qr-validate-page.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Wrapper } from '@components/common/Wrapper/wrapper';

export const QRCodeCheck: React.FC = () => {
	const { state } = useLocation();
	const { setToaster } = useTosterStore();
	const [clusterCode, setClusterCode] = useState('');
	const { mutate, isPending, error } = useGetCustomerDetailsMutation();
	const navigate = useNavigate();
	const effectRan = useRef(false);

	useEffect(() => {
		if (effectRan.current) {
			effectRan.current = true;
			return;
		}

		mutate(state?.number, {
			onSuccess: (capillaryData) => {
				const clusterCode = capillaryData?.response?.customers?.customer?.[0]?.custom_fields?.field?.find(
					(name) => name.name === 'clustercode'
				)?.value;
				if (clusterCode) {
					setClusterCode(clusterCode);
				}
			},
			onError: () => {
				setToaster(true, ToastType.error, 'Cluster Code Not Found');
			},
		});
	}, [mutate, setToaster, state.code, state?.number]);

	const handleNavigate = useCallback((): void => {
		navigate('/contractor/qr-code-validate', {
			state: {
				number: state?.number,
				codes: state?.codes,
				clusterCode: clusterCode,
			},
		});
	}, [clusterCode, navigate, state]);

	return (
		<section className="qr-validate-wrapper">
			<div className="container">
				<Wrapper isLoaderVisible={isPending} isError={error}>
					<>
						<ul className="item-list">
							{state.codes.map((item: string, index: number) => (
								<li key={item}>
									<span className="item-number">{index + 1}.</span>
									<span className="item-code">{item}</span>
								</li>
							))}
						</ul>
						{clusterCode.length === 0 && !isPending && (
							<p className={`error-message text-center ${clusterCode.length === 0 ? 'show' : ''}`}>
								Cluster code not found
							</p>
						)}

						<div className="submit-btn">
							<button
								className={`btn btn-primary btn-medium`}
								disabled={clusterCode.length === 0}
								onClick={handleNavigate}
							>
								Submit
							</button>
						</div>
					</>
				</Wrapper>
			</div>
		</section>
	);
};
