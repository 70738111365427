import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { CurrentSlabData } from '@components/contractor360/components/currentSlab';
import { ErrorHandler } from '@helper/errorHandler';

export const useCurrentSlabQuery = (membershipNumber?: string): IQueryResponse<CurrentSlabData> => {
	const fetchCurrentSlabData = async (): Promise<CurrentSlabData> => {
		try {
			return await APIService.getData<CurrentSlabData>(APICONFIG.CURRENT_SLAB, {
				membershipno: membershipNumber!,
			});
		} catch (error) {
			ErrorHandler(error);
			return { message: '', data: null };
		}
	};
	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['currentSlab', membershipNumber],
		queryFn: fetchCurrentSlabData,
		enabled: !!membershipNumber,
		refetchOnWindowFocus: false,
		retry: false,
	});
	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
