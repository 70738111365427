import { ReactElement, useCallback } from 'react';
import { IActionButton } from '.';
import { NavLink } from 'react-router-dom';

const ActionButton = (props: IActionButton): ReactElement => {
	const { label, handlerFn, className = '', childIndex, disabled, action } = props;

	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();
			if (handlerFn) {
				handlerFn(e, childIndex);
			}
		},
		[handlerFn, childIndex]
	);

	return (
		<div className={`add-product-wrapper ${className}`}>
			<NavLink
				to="/"
				className={`add-product ${disabled ? 'disable-btn' : ''}`}
				aria-label="add product"
				onClick={handleClick}
			>
				<span className={`icon-${action === 'remove' ? 'divide-circle' : 'plus-circle'} icon`}></span>
				<span className="product-title">{label}</span>
			</NavLink>
		</div>
	);
};

export default ActionButton;
