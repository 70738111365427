import Dropdown from '@components/common/formControl/dropdown/dropdown';
import { memo, ReactElement, useMemo } from 'react';
import { IProductFieldsProps } from '.';
import { useAvailableProductsListQuery } from '@services/hooks/site-leads/useAvailableProductsListQuery';
import { useGetRxDb } from '@hooks/getRxdbData';
import { AvailableProductsList } from '@services/hooks/site-leads';
import { AvailableProducts } from '@components/siteLeads/components/siteAddress';

export const AddProductsFields = (props: IProductFieldsProps): ReactElement => {
	const { formik, primaryFocusProduct, secondaryFocusProduct, product1Discussed, product2Discussed, isEditing } = props;

	useAvailableProductsListQuery();

	const { result: availableProductsDataList } = useGetRxDb<AvailableProductsList>('products');
	const availableProductsList = useMemo((): AvailableProducts[] => {
		if (availableProductsDataList?.length) {
			return availableProductsDataList.map((product, index) => ({
				id: index,
				value: product?.productgroup3description_c,
			}));
		}
		return [];
	}, [availableProductsDataList]);

	return (
		<>
			<li className="d-col d-col-2  ">
				<Dropdown
					id={primaryFocusProduct}
					name={primaryFocusProduct}
					label="Primary Focus Products"
					title={formik.values[primaryFocusProduct]}
					error={
						formik.touched[primaryFocusProduct] && formik.errors[primaryFocusProduct]
							? formik.errors[primaryFocusProduct]
							: null
					}
					options={availableProductsList}
					onBlur={formik.handleBlur}
					setFieldValue={formik.setFieldValue}
					disabled={isEditing}
					required
				/>
			</li>
			<li className="d-col d-col-2  ">
				<Dropdown
					id={secondaryFocusProduct}
					name={secondaryFocusProduct}
					label="Secondary Focus Products"
					title={formik.values[secondaryFocusProduct]}
					error={
						formik.touched[secondaryFocusProduct] && formik.errors[secondaryFocusProduct]
							? formik.errors[secondaryFocusProduct]
							: null
					}
					options={availableProductsList}
					onBlur={formik.handleBlur}
					setFieldValue={formik.setFieldValue}
					disabled={isEditing}
				/>
			</li>
			<li className="d-col d-col-2  ">
				<Dropdown
					id={product1Discussed}
					name={product1Discussed}
					label="Product 1 Discussed"
					title={formik.values[product1Discussed]}
					error={
						formik.touched[product1Discussed] && formik.errors[product1Discussed]
							? formik.errors[product1Discussed]
							: null
					}
					options={availableProductsList}
					onBlur={formik.handleBlur}
					setFieldValue={formik.setFieldValue}
					disabled={isEditing}
					required
				/>
			</li>
			<li className="d-col d-col-2  ">
				<Dropdown
					id={product2Discussed}
					name={product2Discussed}
					label="Product 2 Discussed"
					title={formik.values[product2Discussed]}
					error={
						formik.touched[product2Discussed] && formik.errors[product2Discussed]
							? formik.errors[product2Discussed]
							: null
					}
					options={availableProductsList}
					onBlur={formik.handleBlur}
					setFieldValue={formik.setFieldValue}
					disabled={isEditing}
					required
				/>
			</li>
		</>
	);
};
export default memo(AddProductsFields);
