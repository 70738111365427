import { useEffect } from 'react';

export const useSetMainHeader = (header?: string): void => {
	useEffect(() => {
		const mainHeader = document.getElementById('main-header');
		if (mainHeader) {
			mainHeader.innerHTML = header ?? '';
		}
	}, [header]);
};
