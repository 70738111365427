import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { ISiteStatusModalProps } from '.';
import { Dropdown } from '../formControl';
import { useContractor360Store } from '@store/contractor360Store';
import { siteStatusOptions } from '@components/contractor360/components/contractorNavigation';
import { useAddSiteStatus, useUpdateSiteStatus } from '@services/hooks/contractor360';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { useSiteSummaryListing } from '@services/hooks/site-summary-listing';

export const SiteStatusModal = (props: ISiteStatusModalProps): ReactElement => {
	const { formik } = props;
	const { state } = useLocation();
	const queryClient = useQueryClient();
	const { setSiteStatusModal, status, siteId } = useContractor360Store();
	const { mutate: addSiteStatus, isPending: isAddSiteStatusPending } = useAddSiteStatus();
	const { mutate: updateSiteStatus, isPending: isUpdateSiteStatusPending } = useUpdateSiteStatus();
	const { refetch: refetchSiteSummaryListing } = useSiteSummaryListing();

	useEffect(() => {
		formik.setFieldValue('siteStatus', status ?? '');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const handleSubmit = useCallback((): void => {
		const payload = {
			site_lead: siteId,
			status: formik.values.siteStatus,
		};

		const onSuccess = (): void => {
			setSiteStatusModal(false, null, null);
			refetchSiteSummaryListing?.();
			queryClient.invalidateQueries({ queryKey: ['siteSummary', state?.sfid] });
			formik.setFieldValue('siteStatus', '');
		};

		const action = !status && siteId && formik.values.siteStatus ? addSiteStatus : updateSiteStatus;

		action(payload, { onSuccess });
	}, [
		addSiteStatus,
		formik,
		queryClient,
		refetchSiteSummaryListing,
		setSiteStatusModal,
		siteId,
		state?.sfid,
		status,
		updateSiteStatus,
	]);

	const isSubmitDisabled = useMemo(() => {
		return !siteStatusOptions.some((item) => item.value === formik.values.siteStatus);
	}, [formik.values.siteStatus]);

	return (
		<div>
			<Dropdown
				id="siteStatus"
				name="siteStatus"
				label="Status"
				title={formik.values.siteStatus ?? ''}
				error={formik.touched.siteStatus && formik.errors.siteStatus ? formik.errors.siteStatus : null}
				options={siteStatusOptions}
				onBlur={formik.handleBlur}
				setFieldValue={formik.setFieldValue}
			/>

			<div>
				<button
					type="submit"
					aria-label="submit button"
					className="btn btn-primary btn-medium"
					disabled={isSubmitDisabled || isAddSiteStatusPending || isUpdateSiteStatusPending}
					onClick={handleSubmit}
				>
					{isAddSiteStatusPending || isUpdateSiteStatusPending ? 'Submitting' : 'Submit'}
				</button>
			</div>
		</div>
	);
};
