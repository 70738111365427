import '@assets/styles/pages/dealer-visit.scss';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, SubmitButton } from '@components/common';
import { useFormik } from 'formik';
import {
	activityDiscussionProducts,
	convertUrlToFile,
	dealerVisitFormInitialValues,
	IdealerVisitFormInitialValues,
	isNullOrEmpty,
	parseMultiselectOptions,
	premiumProducts,
} from '.';
import { DealersList } from '@components/endUserDetails/components/moreDetailsForm';
import { useGetRxDb } from '@hooks/getRxdbData';
import MultiselectDropdown from '@components/common/formControl/multiselect-dropdown/multiselectDropdown';
import { IDealerDetails, IDealerVisitProducts } from '@components/delaerVisit';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { ProductImages } from '@components/siteLeads/components/siteProducts/productImages';
import { ToastType, useTosterStore } from '@store/toster';
import { maxFileSize } from '@config/constant';
import { useValidation } from '@hooks/validations';
import { useGeoLocationDetails } from '@hooks/useGeoLocationDetails';
import {
	useDealerVisitMutation,
	useDealerVisits,
	useMemberDetails,
	useRetrieveDealerVisitDetails,
} from '@services/hooks/dealerVisit';
import { useLocation, useNavigate } from 'react-router-dom';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { CustomTextInput } from './customTextInput';
import { usePresignedURLQuery } from '@services/hooks/enduser-details/usePresignedURLQuery';
import { useUploadSiteImagesMutation } from '@services/hooks/site-leads/useUploadSiteImagesMutation';
import { keyDownEvent } from '@helper/utils';
import { useDebounce } from '@hooks/useDebounce';
import { useDealerListRxDb } from '@services/hooks/enduser-details/rxdbHooks/useDealerListRxDb';

export const AddEditDealerVisit = (): ReactElement => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const { setToaster } = useTosterStore();
	const { DealerVisitValidationSchema } = useValidation();
	const { refetch: refetchDealerVisits } = useDealerVisits();
	const { result: visitProducts } = useGetRxDb<IDealerVisitProducts>('dealer_visit_products');
	const [dealerSearch, setDealerSearch] = useState<string>('');
	const { result: dealerList } = useDealerListRxDb({
		searchParams: [
			{ key: 'dealername_c', value: dealerSearch },
			{ key: 'dealercode_c', value: dealerSearch },
		],
		sortBy: 'dealername_c',
	});
	const { locationDetails } = useGeoLocationDetails();
	const {
		data: delaerDeatils,
		isFetching: isDealerFetching,
		error: dealerError,
	} = useRetrieveDealerVisitDetails(state?.dealerId);
	const { mutate: createAndUpdateDealerVisit, isPending: isDealerVisitPending } = useDealerVisitMutation(
		state?.dealerId
	);
	const { mutate: mutatePresignedURL, data: getSiteImagesPresignedUrl } = usePresignedURLQuery();
	const { mutate: putSiteImagesData, isPending: isPutSiteImagePending } =
		useUploadSiteImagesMutation(getSiteImagesPresignedUrl);
	const { mutate: fetchMemberDetails, isPending: isMemberDetailPending } = useMemberDetails();

	const formik = useFormik({
		initialValues: dealerVisitFormInitialValues,
		onSubmit: () => {},
		validationSchema: DealerVisitValidationSchema,
	});

	const dealerVisitProducts = useMemo((): DropdownOptions[] => {
		if (visitProducts?.length) {
			return [...visitProducts]
				.sort((a, b) => a.productgroup3description_c.localeCompare(b.productgroup3description_c))
				.map((d, index) => ({
					id: index,
					value: d.productgroup3description_c,
				}));
		}
		return [];
	}, [visitProducts]);

	const dealersList = useMemo((): DealersList[] => {
		if (dealerList?.length) {
			return dealerList.map((d, index) => ({
				id: index,
				value: `${d?.dealername_c} - ${d?.dealercode_c}`,
				name: d?.dealername_c,
			}));
		}
		return [];
	}, [dealerList]);

	const handleNumberChange = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length === 10) {
			fetchMemberDetails?.(e.target.value, {
				onSuccess: (data) => {
					const isSuccessful = data?.message.toLowerCase() === 'success';
					if (isSuccessful) {
						const fullName = [data?.data?.firstname, data?.data?.lastname].filter(Boolean).join(' ') || '';
						e.target.dataset?.name && formik.setFieldValue(e.target.dataset?.name, fullName);
						e.target.dataset?.field && formik.setFieldValue(e.target.dataset?.field, true);
					}
				},
			});
		}

		e.target.dataset?.name && formik.setFieldValue(e.target.dataset?.name, '');
		e.target.dataset?.field && formik.setFieldValue(e.target.dataset?.field, false);
	}, 100);

	const loadImages = async (
		dealer_display_image1_c?: string | null,
		dealer_display_image2_c?: string | null
	): Promise<void> => {
		const imageUrls = [];
		if (dealer_display_image1_c) imageUrls.push(dealer_display_image1_c);
		if (dealer_display_image2_c) imageUrls.push(dealer_display_image2_c);

		const promises = imageUrls.map((url) => convertUrlToFile(url));
		const files = await Promise.all(promises);
		if (files.length > 0) {
			formik.setFieldValue('productImages', files);
		}
	};

	const handleAddImage = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const files = event.target.files ? Array.from(event.target.files) : [];
			if (files.length === 0) return;

			const nonImageFiles = files.filter((file) => !file.type.startsWith('image/'));
			if (nonImageFiles.length > 0) {
				setToaster(true, ToastType.error, 'Please upload image files only.');
				event.target.value = '';
				return;
			}

			const oversizedFiles = files.filter((file) => file.size > maxFileSize);
			if (oversizedFiles.length > 0) {
				if (files.length > 1) {
					setToaster(true, ToastType.error, 'One or more files exceed the 5MB limit. Please choose smaller files.');
					return;
				}
				setToaster(true, ToastType.error, 'File size exceeds 5MB limit. Please choose a smaller file.');
				return;
			}

			const currentImages = formik.values.productImages;
			const totalImages = currentImages.length + files.length;

			if (totalImages > 2) {
				setToaster(true, ToastType.error, 'Maximum 2 images allowed.');
				return;
			}

			const updatedImages = [...currentImages, ...files].slice(0, 2);

			formik.setFieldValue('productImages', updatedImages);

			const payload = updatedImages.map((img) => {
				return {
					folder: 'dealer_visit',
					filename: img.name,
				};
			});

			mutatePresignedURL?.(payload);
		},
		[formik, mutatePresignedURL, setToaster]
	);

	const handleSubmit = useCallback((): void => {
		const {
			dealerName,
			siteLocation,
			address,
			state: stateLocation,
			city,
			contractorName1,
			contractorName2,
			contractorNumber1,
			contractorNumber2,
			feedback,
			siteAddress,
			premiumProducts,
			productCompetitionFighting,
			activityDiscussed,
			productRSI,
			orderTaken,
			orderTakenVolume,
			productImages,
		} = formik.values;

		const payload: IDealerDetails = {
			dealer_name_c: dealerName,
			location_c: siteLocation,
			address_c: address,
			state_c: isNullOrEmpty(stateLocation),
			city_c: isNullOrEmpty(city),
			premium_product_discussed_c: isNullOrEmpty(premiumProducts),
			product_discussed_for_rsi_c: isNullOrEmpty(productRSI),
			product_discussed_for_competition_fighti_c: isNullOrEmpty(productCompetitionFighting),
			activity_discussion_c: isNullOrEmpty(activityDiscussed),
			order_taken_c: isNullOrEmpty(orderTaken),
			order_taken_volumn_c: isNullOrEmpty(orderTakenVolume),
			contractor_lead_name_1_c: isNullOrEmpty(contractorName1),
			contractor_lead_name_2_c: isNullOrEmpty(contractorName2),
			contractor_lead_number_1_c: isNullOrEmpty(contractorNumber1),
			contractor_lead_number_2_c: isNullOrEmpty(contractorNumber2),
			site_lead_address_c: isNullOrEmpty(siteAddress),
			feedback_c: isNullOrEmpty(feedback),
			dealer_display_image1_c: productImages[0] ? getSiteImagesPresignedUrl?.[0]?.normal_url : null,
			dealer_display_image2_c: productImages[1] ? getSiteImagesPresignedUrl?.[1]?.normal_url : null,
		};

		putSiteImagesData?.(productImages, {
			onSuccess: () => {
				createAndUpdateDealerVisit?.(payload, {
					onSuccess: () => {
						setToaster(
							true,
							ToastType.success,
							`Dealer visit ${state?.dealerId ? 'updated' : 'created'} successfully.`
						);
						refetchDealerVisits?.();
						navigate('/dealer-visit-summary');
					},
				});
			},
		});
	}, [
		createAndUpdateDealerVisit,
		formik.values,
		getSiteImagesPresignedUrl,
		navigate,
		putSiteImagesData,
		refetchDealerVisits,
		setToaster,
		state?.dealerId,
	]);

	useEffect(() => {
		if (!state?.dealerId && locationDetails) {
			const { fullAddress, state, city } = locationDetails;
			const address = fullAddress.split(',').slice(0, -3).join(',').trim();
			formik.setFieldValue('siteLocation', fullAddress, true);
			formik.setFieldValue('address', address, true);
			formik.setFieldValue('state', state, true);
			formik.setFieldValue('city', city, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, locationDetails]);

	useEffect(() => {
		if (state?.dealerId && delaerDeatils && dealerVisitProducts) {
			const {
				dealer_name_c,
				city_c,
				location_c,
				address_c,
				state_c,
				dealer_display_image1_c,
				dealer_display_image2_c,
			} = delaerDeatils;

			loadImages(dealer_display_image1_c, dealer_display_image2_c);

			formik.setValues(
				{
					...formik.values,
					dealerName: dealer_name_c,
					siteLocation: location_c,
					address: address_c,
					city: city_c,
					state: state_c,
					premiumProducts: parseMultiselectOptions(delaerDeatils?.premium_product_discussed_c, premiumProducts),
					productRSI: parseMultiselectOptions(delaerDeatils?.product_discussed_for_rsi_c, dealerVisitProducts),
					productCompetitionFighting: parseMultiselectOptions(
						delaerDeatils?.product_discussed_for_competition_fighti_c,
						dealerVisitProducts
					),
					activityDiscussed: parseMultiselectOptions(delaerDeatils?.activity_discussion_c, activityDiscussionProducts),
					contractorName1: delaerDeatils?.contractor_lead_name_1_c,
					contractorName2: delaerDeatils?.contractor_lead_name_2_c,
					contractorNumber1: delaerDeatils?.contractor_lead_number_1_c,
					contractorNumber2: delaerDeatils?.contractor_lead_number_2_c,
					siteAddress: delaerDeatils?.site_lead_address_c,
					orderTaken: parseMultiselectOptions(delaerDeatils?.order_taken_c, dealerVisitProducts),
					orderTakenVolume:
						delaerDeatils?.order_taken_volumn_c && parseInt(delaerDeatils?.order_taken_volumn_c).toString(),
					feedback: delaerDeatils?.feedback_c,
					isContractorName1Disabled: !!delaerDeatils?.contractor_lead_name_1_c,
					isContractorName2Disabled: !!delaerDeatils?.contractor_lead_name_2_c,
				},
				true
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, delaerDeatils, dealerVisitProducts]);

	const onDropDownSearch = useDebounce((e: React.KeyboardEvent<HTMLInputElement>): void => {
		const element = e.target as HTMLInputElement;
		setDealerSearch(element.value);
	}, 300);

	return (
		<div className="form-popup show">
			<section className="add-dealer-visit-section new-site-desktop overflow desktop-popup">
				<Wrapper isLoaderVisible={isDealerFetching} isError={dealerError}>
					<div className="container">
						<form>
							<ul className="row">
								<li className="form-control d-col d-col-2">
									<Dropdown
										id="dealerName"
										name="dealerName"
										label="Dealer Name"
										onBlur={formik.handleBlur}
										title={formik.values.dealerName ?? ''}
										error={formik.touched.dealerName && formik.errors.dealerName ? formik.errors.dealerName : null}
										options={dealersList}
										setFieldValue={formik.setFieldValue}
										handelKeyDownProp={onDropDownSearch}
										required
										isAutoFocus
										blurValidate
									/>
								</li>

								<li className="form-control d-col d-col-2">
									<CustomTextInput<IdealerVisitFormInitialValues>
										id="siteLocation"
										label="Site Location"
										formik={formik}
										required
									/>
								</li>

								<li className="form-control d-col d-col-2">
									<CustomTextInput<IdealerVisitFormInitialValues>
										id="address"
										label="Address"
										formik={formik}
										required
									/>
								</li>

								<li className="form-control d-col d-col-2">
									<CustomTextInput<IdealerVisitFormInitialValues> id="state" label="State" formik={formik} />
								</li>

								<li className="form-control d-col d-col-2">
									<CustomTextInput<IdealerVisitFormInitialValues> id="city" label="City" formik={formik} />
								</li>

								<li className="d-col d-col-2">
									<MultiselectDropdown
										id="premiumProducts"
										name="premiumProducts"
										label="Premium Product Discussed"
										className="input-border"
										onBlur={formik.handleBlur}
										values={formik.values.premiumProducts}
										options={premiumProducts}
										setFieldValue={formik.setFieldValue}
									/>
								</li>

								<li className="d-col d-col-2">
									<MultiselectDropdown
										id="productRSI"
										name="productRSI"
										className="input-border"
										label="Product Discussed for RSI"
										onBlur={formik.handleBlur}
										values={formik.values.productRSI}
										options={dealerVisitProducts}
										setFieldValue={formik.setFieldValue}
										error={
											formik.touched.productRSI && formik.errors.productRSI ? formik.errors.productRSI.toString() : null
										}
										required
									/>
								</li>

								<li className="d-col d-col-2">
									<MultiselectDropdown
										id="productCompetitionFighting"
										name="productCompetitionFighting"
										className="input-border signle-drawwer-lable"
										label="Product Discussed for Competition Fighting"
										onBlur={formik.handleBlur}
										values={formik.values.productCompetitionFighting}
										options={dealerVisitProducts}
										setFieldValue={formik.setFieldValue}
									/>
								</li>

								<li className="d-col d-col-2">
									<MultiselectDropdown
										id="activityDiscussed"
										name="activityDiscussed"
										className="input-border"
										label="Activity Discussion"
										onBlur={formik.handleBlur}
										values={formik.values.activityDiscussed}
										options={activityDiscussionProducts}
										setFieldValue={formik.setFieldValue}
										error={
											formik.touched.activityDiscussed && formik.errors.activityDiscussed
												? formik.errors.activityDiscussed.toString()
												: null
										}
										required
									/>
								</li>

								<li className="form-control d-col d-col-2">
									<CustomTextInput<IdealerVisitFormInitialValues>
										id="contractorNumber1"
										label="Contractor Lead Number 1"
										formik={formik}
										maxLength={10}
										onKeyDown={keyDownEvent}
										handleChange={handleNumberChange}
										dataAttributes={{
											name: 'contractorName1',
											field: 'isContractorName1Disabled',
										}}
									/>
								</li>

								<li className="form-control d-col d-col-2">
									<CustomTextInput<IdealerVisitFormInitialValues>
										id="contractorName1"
										label="Contractor Lead Name 1"
										formik={formik}
										isDisabled={formik.values.isContractorName1Disabled}
										required={!!formik.values.contractorNumber1}
									/>
								</li>

								<li className="form-control d-col d-col-2">
									<CustomTextInput<IdealerVisitFormInitialValues>
										id="contractorNumber2"
										label="Contractor Lead Number 2"
										formik={formik}
										maxLength={10}
										onKeyDown={keyDownEvent}
										handleChange={handleNumberChange}
										dataAttributes={{
											name: 'contractorName2',
											field: 'isContractorName2Disabled',
										}}
									/>
								</li>

								<li className="form-control d-col d-col-2">
									<CustomTextInput<IdealerVisitFormInitialValues>
										id="contractorName2"
										label="Contractor Lead Name 2"
										formik={formik}
										isDisabled={formik.values.isContractorName2Disabled}
										required={!!formik.values.contractorNumber2}
									/>
								</li>

								<li className="form-control d-col d-col-2">
									<CustomTextInput<IdealerVisitFormInitialValues>
										id="siteAddress"
										label="Site Address"
										formik={formik}
									/>
								</li>

								<li className="d-col d-col-2">
									<MultiselectDropdown
										id="orderTaken"
										name="orderTaken"
										className="input-border"
										label="Order Taken"
										onBlur={formik.handleBlur}
										values={formik.values.orderTaken ?? []}
										options={dealerVisitProducts}
										setFieldValue={formik.setFieldValue}
									/>
								</li>

								{formik.values.orderTaken.length > 0 && (
									<li className="form-control d-col d-col-2">
										<CustomTextInput<IdealerVisitFormInitialValues>
											id="orderTakenVolume"
											label="Order Taken Volume"
											formik={formik}
										/>
									</li>
								)}

								<li className="d-col d-col-2">
									<ProductImages fieldName="productImages" formik={formik} handleAddImage={handleAddImage} />
								</li>

								<li className="form-control d-col d-col-2">
									<CustomTextInput<IdealerVisitFormInitialValues>
										id="feedback"
										label="Feedback"
										formik={formik}
										maxLength={50}
									/>
								</li>
							</ul>
						</form>

						<SubmitButton
							label="Submit"
							isDisabled={
								!formik.isValid ||
								!formik.dirty ||
								isDealerVisitPending ||
								isPutSiteImagePending ||
								isMemberDetailPending
							}
							isPending={isDealerVisitPending || isPutSiteImagePending}
							handleSubmitClick={handleSubmit}
						/>
					</div>
				</Wrapper>
			</section>
		</div>
	);
};
