import { create } from 'zustand';

interface IVideoPlayerStore {
	isVideoPlayerOpen: boolean;
	url: string;
	setVideoPlayerState: (val: boolean, url?: string) => void;
}

const initialState = {
	isVideoPlayerOpen: false,
	url: '',
};

export const useVideoPlayerStore = create<IVideoPlayerStore>((set) => ({
	...initialState,
	setVideoPlayerState: (val: boolean, url?: string): void =>
		set((state) => ({ ...state, isVideoPlayerOpen: val, url: url })),
}));
