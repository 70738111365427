import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { APPCONFIG } from '@config/app.config';
import useSiteLeadStore from '@store/siteLeadStore';
import { SiteSummaryDetails } from '../contractor360';

export const useSiteLeadQuery = (): IQueryResponse<SiteSummaryDetails> => {
	const { siteId, sfid } = useSiteLeadStore();

	const getSiteLeadData = async (): Promise<SiteSummaryDetails> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/site-leads/wwa/site-summary/${siteId}/`;
		return await APIService.getData<SiteSummaryDetails>(url);
	};
	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['siteLead', sfid],
		queryFn: getSiteLeadData,
		enabled: !!sfid,
		refetchOnWindowFocus: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
