import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { GetOtpDetails, GetTokenObject } from '@components/login';

interface IGetOtp {
	mutate: UseMutateFunction<GetTokenObject, Error, GetOtpDetails, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useGetTokenMutation = (): IGetOtp => {
	const getToken = async (getTokenFormData: GetOtpDetails): Promise<GetTokenObject> => {
		const url = `${APICONFIG.LOGIN}/token-access/`;
		return await APIService.postData<GetTokenObject>(url, getTokenFormData);
	};

	const { mutate, error, isError, isPending } = useMutation<GetTokenObject, Error, GetOtpDetails>({
		mutationFn: getToken,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
