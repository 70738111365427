import { ReactElement } from 'react';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { MeetingModuleCard } from '../meeting-card/meeting-card';
import { useMeetingsListQuery } from '@services/hooks/meetings/useMeetingsListQuery';
import { useInfiniteScroll } from '@components/common';
import { useFilterStore } from '@store/index';
import { GetMeetings } from '../getMeetings/get-meetings';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';
import { useMeetingFilterStore } from '@store/meetingFilterStore';

export const ScheduledMeetingList = (): ReactElement => {
	const { isFetching: queryFetch, error } = useMeetingsListQuery();
	const { search } = useFilterStore();
	const { scheduledMeetingFilter } = useMeetingFilterStore();
	const { fetchMore, isFetching, result } = GetMeetings(search, {
		...scheduledMeetingFilter,
		type: 'Scheduled Meeting',
	});

	useInfiniteScroll(fetchMore);

	return (
		<Wrapper isLoaderVisible={queryFetch || (isFetching && result.length === 0)} isError={error}>
			<ul className="meeting-card-wrapper row">
				{result.length > 0 ? (
					result.map((meeting) => {
						return <MeetingModuleCard key={meeting.id} data={meeting} />;
					})
				) : (
					<NoDataFound />
				)}
			</ul>
		</Wrapper>
	);
};
