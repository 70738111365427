import { ReactElement } from 'react';
import { IStepper } from '.';
import { useStepperStore } from '@store/stepperStore';

const getClassName = (index: number, currentStep: number): string => {
	if (index < currentStep) return 'progress-dot completed';
	if (index === currentStep) return 'progress-dot active';
	return 'progress-dot';
};

export const Stepper = (props: IStepper): ReactElement => {
	const { nextHandler, isNext, isPending, label = 'Next' } = props;
	const { currentStep } = useStepperStore();
	const totalSteps = 3;

	const renderStepDots = Array.from({ length: totalSteps }, (_, i) => (
		<span key={i} className={getClassName(i, currentStep)}></span>
	));

	return (
		<div className="progress-bar-wrapper">
			<div className="progress-bar-main-wrapper">
				<div className="progress-bar">{renderStepDots}</div>
				<div>
					<button
						type="submit"
						className="btn btn-primary btn-small"
						onClick={nextHandler}
						disabled={isNext || isPending}
					>
						{isPending ? 'Submitting' : label}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Stepper;
