import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { ErrorHandler } from '@helper/errorHandler';
import { IProductUsageData } from '@components/contractor360/components/productUsage';

export const useProductUsageQuery = (membershipNumber: string): IQueryResponse<IProductUsageData[]> => {
	const fetchProducUsageData = async (): Promise<IProductUsageData[]> => {
		try {
			return await APIService.getData<IProductUsageData[]>(APICONFIG.PRODUCT_USAGE, {
				membershipno: membershipNumber,
			});
		} catch (error) {
			ErrorHandler(error);
			return [];
		}
	};
	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['productUsage', membershipNumber],
		queryFn: fetchProducUsageData,
		enabled: !!membershipNumber,
		refetchOnWindowFocus: false,
		retry: false,
	});
	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
