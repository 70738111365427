import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { PresignedURLDetails } from '.';
import { APICONFIG } from '@config/api.config';

interface PresignedURL {
	mutate: UseMutateFunction<PresignedURLDetails[], Error, PresignedURLData[], unknown>;
	data?: PresignedURLDetails[];
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}
interface PresignedURLData {
	folder: string;
	filename: string;
}

const postPresignedURLData = async (data: PresignedURLData[]): Promise<PresignedURLDetails[]> => {
	return await APIService.postData<PresignedURLDetails[]>(APICONFIG.PRESIGNED_URL, data);
};

export const usePresignedURLQuery = (): PresignedURL => {
	const { mutate, data, error, isError, isPending } = useMutation({
		mutationFn: postPresignedURLData,
	});

	return {
		mutate,
		data,
		error,
		isError,
		isPending,
	};
};
