import { calendarHeader } from '@config/constant';
import { preventDefault } from '@fullcalendar/core/internal';
import { ReactElement, useEffect, useRef } from 'react';
import { useFormTabNavigation } from '@hooks/useFormTabNavigation';

interface ICalendarTab {
	setActiveIndex: (index: number) => void;
	activeIndex: number;
	handleHeader: (data: string) => void;
}
export const DayToggle = (props: ICalendarTab): ReactElement => {
	const { activeIndex, setActiveIndex, handleHeader } = props;
	const listRef = useRef<HTMLUListElement | null>(null);
	useFormTabNavigation({ listRef: listRef, currentActiveTab: activeIndex });
	useEffect(() => {
		setActiveIndex(1);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="slide-tab-wrapper">
			<ul className="tabs" ref={listRef}>
				{calendarHeader.map((navData) => (
					<li
						key={navData.id}
						className={`${navData.id === activeIndex ? 'active' : ''}`}
						onMouseDownCapture={() => {
							handleHeader(navData.value);
							setActiveIndex(navData.id);
						}}
					>
						<a href="/" className="tab" onClick={preventDefault}>
							<span className="tabs-icon icon-sun" />
							{navData.name}
						</a>
					</li>
				))}
				<li className="glider" style={{ transform: 'translateX(0px)' }}></li>
			</ul>
		</div>
	);
};
