export const competitionBrandSchema = {
	title: 'Competiton Brand Schema',
	description: 'create a Competiton Brand schema',
	version: 0,
	primaryKey: 'prdgroup3_c',
	type: 'object',
	properties: {
		prdgroup3_c: { type: 'string', primary: true, maxLength: 200 },
		productgroup3description_c: { type: 'string' },
	},
	required: ['prdgroup3_c', 'productgroup3description_c'],
};
