import { ClubRecommendationResponse } from '@components/meetingModule/components/clubRecommendations';
import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';

interface ClubRecommendationParams {
	club_id: string;
	start_date: string;
}

interface IClubRecommendationsResponse {
	mutate: UseMutateFunction<ClubRecommendationResponse, Error, ClubRecommendationParams, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useGetClubRecommendationsMutation = (): IClubRecommendationsResponse => {
	const fetchClubRecommendationList = async (params: ClubRecommendationParams): Promise<ClubRecommendationResponse> => {
		return APIService.getData<ClubRecommendationResponse>(`${APICONFIG.CLUB_RECOMMENDATION}`, {
			club_id: params.club_id,
			start_date: params.start_date,
		});
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: fetchClubRecommendationList,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
