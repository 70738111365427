import { ReactElement } from 'react';
import {
	CompetitionConversionHeader,
	ICompetitionConversionBreadCrumb,
} from '@components/competition-conversion/components/compeetitionHeader';
import { useCompetitionConversionStore } from '@store/competitionConversion';
import { NavLink } from 'react-router-dom';
import { useFetchProductsRecommended } from '@services/hooks/competition-conversion/useFetchProductsRecommended';
import { TypeOfContractor } from '@components/competition-conversion/components/competitionConversionContract';
import { ProductCard } from '@components/competition-conversion/components/competitionConversionProduct/productCard';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { Modal } from '@components/common/modal/modal';
import { useVideoPlayerStore } from '@store/videoPlayerStore';
import { VideoPlayer } from '@components/common/videoPlayerModal/videoPlayer';

export const CompetitionConversionProduct = (): ReactElement => {
	const { grade, type_of_contract, reason, reset } = useCompetitionConversionStore();
	const { isVideoPlayerOpen, setVideoPlayerState } = useVideoPlayerStore();

	const breadCrumb: ICompetitionConversionBreadCrumb[] = [
		{
			name: reason,
			navLink: '/competition-conversion',
		},
		{
			name: TypeOfContractor.find((el) => el.value === type_of_contract)?.name ?? '',
			navLink: '/competition-conversion/type-of-contractor',
		},
		{
			name: grade,
			navLink: '/competition-conversion/grade-of-competition',
		},
	];
	const {
		data: recommendedProducts,
		isPending,
		error,
	} = useFetchProductsRecommended({ grade: grade, reason: reason, type_of_contract: type_of_contract });

	return (
		<>
			<CompetitionConversionHeader title="Products Recommended" breadCrumb={breadCrumb} />
			<div className="competition-conversion-products">
				<Wrapper isLoaderVisible={isPending} isError={error}>
					<ul className="products-recommended-list row">
						<ProductCard recommendedProducts={recommendedProducts} />
					</ul>
				</Wrapper>

				<div className="home-btn">
					<NavLink to={'/'} aria-label="home page link" onClick={reset}>
						Go to Home
					</NavLink>
				</div>
			</div>
			<Modal
				modalId="videoPlayer"
				className="dialog-sm dialog-lg pt-4"
				modalOpen={isVideoPlayerOpen}
				onModalClose={() => setVideoPlayerState(false)}
			>
				<VideoPlayer />
			</Modal>
		</>
	);
};
