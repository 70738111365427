import { formatDateDDMMYY } from '@helper/utils';
import { RedemptionHistoryDetails } from '@services/hooks/contractor360';
import { ReactElement } from 'react';
import { RedemptionObject } from '.';
import { IRedemptionHistoryHeaderQuery } from '@services/hooks/contractor360/useGetRedemptionHistoryHeaderQuery';

// TODO:: Redemption code cleaning Pavan
export interface IRedemptionHeaderQuery {
	data: RedemptionHistoryDetails | undefined;
	redemptionData: RedemptionObject[];
	redemptionHeaderData?: IRedemptionHistoryHeaderQuery;
}
export const RedemptionHeader = (props: IRedemptionHeaderQuery): ReactElement => {
	const { data, redemptionHeaderData } = props;

	return (
		<section className="point-overview-section">
			<div className="container">
				<h1 className="h3">Points Overview</h1>

				<div className="point-overview-wrapper">
					<span className="balance-point">
						Balance Points : {data?.response?.customer?.total_available_points ?? '--'}
					</span>
					<ul className="point-overview-list">
						<li>
							<span className="icon-star-bag overview-icon"> </span>

							<p>Points Redeemed in Quarter</p>

							<span className="h4">
								{redemptionHeaderData?.redeem_pts_qtd ? `${redemptionHeaderData?.redeem_pts_qtd} Pts` : '-'}{' '}
							</span>
						</li>

						<li>
							<span className="icon-star-gift overview-icon"> </span>
							<p>Highest Value Gift Redeemed</p>
							<span className="h4 text-center">
								{redemptionHeaderData?.highest_value_gift_redeemed
									? `${redemptionHeaderData?.highest_value_gift_redeemed}`
									: '-'}
							</span>
						</li>
						<li>
							<span className="icon-star-calander overview-icon"> </span>
							<p>Last Redeemed Date</p>
							{redemptionHeaderData?.last_redeem_date ? (
								<span className="h4">{formatDateDDMMYY(new Date(redemptionHeaderData.last_redeem_date))}</span>
							) : (
								<span className="h4"> - </span>
							)}
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};
