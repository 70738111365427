import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { QuickAddAllUser } from '.';

interface IMeetingDetails {
	mutate: UseMutateFunction<QuickAddAllUser, Error, QuickAddAllUser, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useQuickAddAllUserMutation = (id: string): IMeetingDetails => {
	const postMembersData = async (meetingFormData: QuickAddAllUser): Promise<QuickAddAllUser> => {
		const url = `${APICONFIG.MEETINGS_DETAILS}/${id}/add-new-member/?membershipno=${meetingFormData.membershipno}`;
		return await APIService.postData<QuickAddAllUser>(url, { contactid: meetingFormData.contactid });
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: postMembersData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
