import { ReactElement, useState } from 'react';
import { GoogleMap } from './googleMap';

interface IGoogleMapModal {
	handleConfirm: () => void;
	handleClose: () => void;
}

export const GoogleMapModal = (props: IGoogleMapModal): ReactElement => {
	const { handleConfirm, handleClose } = props;
	const [address, setAddress] = useState<string>('');
	return (
		<>
			<GoogleMap setAddress={setAddress} />

			<div className="map-address">
				<span className="location-label">Location</span>
				{address && <div className="address">{address}</div>}
			</div>

			<div className="location-btn-wrapper">
				<button className=" close-btn btn btn-primary" onClick={handleClose}>
					Close
				</button>
				<button className=" btn btn-primary" onClick={handleConfirm}>
					Confirm
				</button>
			</div>
		</>
	);
};
