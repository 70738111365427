import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { BasicDetails } from '.';
import { APPCONFIG } from '@config/app.config';

interface IBasicDetails {
	mutate: UseMutateFunction<BasicDetails, Error, BasicDetails, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useBasicDetailsMutation = (id: number | null, isEdit?: boolean): IBasicDetails => {
	const postBasicDetailsData = async (basicFormData: BasicDetails): Promise<BasicDetails> => {
		return await APIService.postData<BasicDetails>(APICONFIG.BASIC_DETAILS, basicFormData);
	};
	const updateEditBasicDetailsData = async (basicFormData: BasicDetails): Promise<BasicDetails> => {
		const url = isEdit
			? `${APICONFIG.EDIT_BASIC_DETAILS}${id}/`
			: `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/wwa/lead-user/${id}/basic_detail/`;
		return await APIService.putData<BasicDetails>(url, basicFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: id ? updateEditBasicDetailsData : postBasicDetailsData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
