import { useEffect } from 'react';

export const useLoadGoogleMapScript = (): void => {
	useEffect(() => {
		if (document.getElementById('google-script')) {
			return;
		}
		const script = document.createElement('script');
		script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&loading=async&libraries=marker,geometry,drawing,places`;
		script.async = true;
		script.defer = true;
		script.id = 'google-script';
		document.head.appendChild(script);
	}, []);
};
