import { ReactElement } from 'react';
import { ToastType, useTosterStore } from '@store/toster';
import { useSiderToggleMenu } from '@store/useSiderToggleMenu';
import { cleanupDatabase } from '@rxdb/config/databaseConfig';
import { useLogoutMutation } from '@services/hooks/login';
import Cookies from 'universal-cookie';

// TODO:: Need to remove console.
export const LogoutButton = (): ReactElement => {
	const { setToaster } = useTosterStore();
	const { setFullPageLoader } = useSiderToggleMenu();
	const { mutate: logout } = useLogoutMutation();

	const handleClearDatabase = async (): Promise<void> => {
		setFullPageLoader(true);
		try {
			console.log('Starting logout process...');
			await cleanupDatabase();
			console.log('Database cleanup completed');

			// await clearIndexedDBDatabases();
			console.log('IndexedDB databases cleared');

			console.log('Storage cleared started');
			const cookie = new Cookies();
			cookie.remove('role');
			cookie.remove('token');
			console.log('Storage cleared completed');

			const clearCacheData = (): void => {
				caches.keys().then((names) => {
					names.forEach((name) => {
						caches.delete(name);
					});
				});
			};
			clearCacheData();
			console.log('clear cache storage end');

			// Use a small delay before redirecting to ensure all cleanup is complete
			setTimeout(() => {
				window.location.href = '/login';
			}, 50);
		} catch {
			setToaster(true, ToastType.error, 'Error during logout');
		} finally {
			setFullPageLoader(false);
		}
	};

	const handleLogout = async (): Promise<void> => {
		try {
			const cookie = new Cookies();
			const token = localStorage.getItem('token') ?? cookie.get('token');
			handleClearDatabase();
			return;
			// if (!token) handleClearDatabase();

			// token &&
			// 	logout(token, {
			// 		onSuccess: () => {
			// 			localStorage.clear();
			// 			sessionStorage.clear();
			// 			handleClearDatabase();
			// 		},
			// 		onError: (error) => {
			// 			setToaster(true, ToastType.error, `Error during logout please try again. ${error}`);
			// 		},
			// 	});
		} catch {
			setToaster(true, ToastType.error, 'Error during logout');
		}
	};

	return (
		<div className="logout-btn">
			<button
				type="button"
				aria-label="logout-button"
				className="btn btn-primary navbar-logout-btn"
				value="logout-button"
				onClick={handleLogout}
			>
				Logout <span className="icon-logout" />
			</button>
		</div>
	);
};
