import { useAdoptedQuery } from '@services/hooks/endusers/adoptedQuery';
import { useLeadUserQuery } from '@services/hooks/endusers/leadUserQuery';
import { useAllEndUserQuery } from '@services/hooks/endusers/allEndUserQuery';
import { useClubDropDownQuery } from '@services/hooks/endusers/clubListQuery';
import { useDealersQuery } from '@services/hooks/enduser-details/useDealerQuery';
import { useCompetitionBrandsQuery } from '@services/hooks/enduser-details/useCompetitionBrandsQuery';
import { useIMRCodeListQuery } from '@services/hooks/enduser-details/useIMRCodeListQuery';
import { useTownDetailsQuery } from '@services/hooks/enduser-details/useTownDetailsQuery';
import { useAvailableProductsListQuery } from '@services/hooks/site-leads/useAvailableProductsListQuery';
import { useMeetingsListQuery } from '@services/hooks/meetings/useMeetingsListQuery';
import { useMeetingsTypesQuery } from '@services/hooks/meetings/useMeetingTypesQuery';
import { useProfileQuery } from '@services/hooks/profile/useProfileQuery';
import { useSiteSummaryListing } from '@services/hooks/site-summary-listing';
import { useDealerVisitProducts, useDealerVisits } from '@services/hooks/dealerVisit';
import { usePcmsListQuery } from '@services/hooks/pcms-registration/useGetPcmsRegistrationList';
import {
	useDealersListQuery,
	useEndUserCategoryList,
	useEndUserListingQuery,
	useGetOneToOneMeetingsList,
	useWssTerritoryListQuery,
} from '@services/hooks/oneToOneMeetings';

export const UseSyncData = (): void => {
	useAdoptedQuery();
	useLeadUserQuery();
	useAllEndUserQuery();
	useClubDropDownQuery();
	useDealersQuery();
	useCompetitionBrandsQuery();
	useSiteSummaryListing();
};

interface IRefetchAllData {
	refetchAllData?: () => Promise<void>;
}

export const useRefetchAllData = (): IRefetchAllData => {
	const { refetch: refetchAdopted } = useAdoptedQuery();
	const { refetch: refetchLeadUser } = useLeadUserQuery();
	const { refetch: refetchAllEndUser } = useAllEndUserQuery();
	const { refetch: refetchClubDropDown } = useClubDropDownQuery();
	const { refetch: refetchCompetitonBrandsDropDown } = useCompetitionBrandsQuery();
	const { refetch: refetchDealer } = useDealersQuery();
	const { refetch: refetchImrCodeList } = useIMRCodeListQuery();
	const { refetch: refetchTowns } = useTownDetailsQuery();
	const { refetch: refetchProducts } = useAvailableProductsListQuery();
	const { refetch: refetchTypes } = useMeetingsTypesQuery();
	const { refetch: refetchMeetings } = useMeetingsListQuery();
	const { refetch: refetchSiteSummaryListing } = useSiteSummaryListing();
	const { refetch: refetchProfile } = useProfileQuery();
	const { refetch: refetchDealerVisits } = useDealerVisits();
	const { refetch: refetchDealerVisitProducts } = useDealerVisitProducts();
	const { refetch: refetchPcmsList } = usePcmsListQuery();
	const { refetch: refetchOneToOneMeetingList } = useGetOneToOneMeetingsList();
	const { refetch: refetchEndUserList } = useEndUserListingQuery();
	const { refetch: refetchWssTerritoryList } = useWssTerritoryListQuery();
	const { refetch: refetchPrimaryDealersList } = useDealersListQuery();
	const { refetch: refetchEndUserCategoryList } = useEndUserCategoryList();

	const refetchAllData = async (): Promise<void> => {
		await Promise.all([
			refetchAdopted?.(),
			refetchLeadUser?.(),
			refetchAllEndUser?.(),
			refetchClubDropDown?.(),
			refetchDealer?.(),
			refetchCompetitonBrandsDropDown?.(),
			refetchImrCodeList?.(),
			refetchTowns?.(),
			refetchProducts?.(),
			refetchMeetings?.(),
			refetchTypes?.(),
			refetchSiteSummaryListing?.(),
			refetchProfile?.(),
			refetchDealerVisits?.(),
			refetchDealerVisitProducts?.(),
			refetchPcmsList?.(),
			refetchOneToOneMeetingList?.(),
			refetchEndUserList?.(),
			refetchWssTerritoryList?.(),
			refetchPrimaryDealersList?.(),
			refetchEndUserCategoryList?.(),
		]);
	};

	return { refetchAllData };
};
