export const ImrCodeSchema = {
	title: 'Imr schema',
	description: 'create a IMR schema',
	version: 0,
	primaryKey: 'imr_code_c',
	type: 'object',
	properties: {
		id: {
			type: 'number',
		},
		imr_code_c: { type: 'string', primary: true, maxLength: 200 },
		assigned_imr_c: { type: 'string' },
	},
	required: ['id', 'imr_code_c', 'assigned_imr_c'],
};
