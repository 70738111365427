import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { UpdateSiteLeadData } from '.';
import { APPCONFIG } from '@config/app.config';
import useSiteLeadStore from '@store/siteLeadStore';

interface IUpdateSiteLeadData {
	mutate: UseMutateFunction<UpdateSiteLeadData, Error, UpdateSiteLeadData, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useUpdateSiteLeadMutation = (): IUpdateSiteLeadData => {
	const { siteId } = useSiteLeadStore();

	const patchSiteLeadData = async (siteLeadFormData: UpdateSiteLeadData): Promise<UpdateSiteLeadData> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/site-leads/wwa/site-lead/${siteId}/update_site_lead/`;
		return await APIService.patchData<UpdateSiteLeadData>(url, siteLeadFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: patchSiteLeadData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
