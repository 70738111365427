import { useEffect } from 'react';
import { getMobileOperatingSystem } from '@helper/detechUserAgent';

export interface IFileUploadDeviceCaptureProps {
	id: string;
	dependencyList?: string[];
}

export const FileUploadDeviceCapture = (props: IFileUploadDeviceCaptureProps): void => {
	const { dependencyList, id } = props;

	useEffect(() => {
		const fileInput: HTMLInputElement | null = document.getElementById(`${id}`) as HTMLInputElement;
		if (getMobileOperatingSystem() === 'iOS' && fileInput) {
			fileInput.removeAttribute('capture');
		}
	}, [id, dependencyList]);
};
