import { memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { IMultiselectDropdownProps } from '.';
import { DropdownOptions } from '../dropdown';
import { useOutsideClickMultiSelect } from '@hooks/useOutsideClickMultiSelect';
import { useScrollIntoView } from '@hooks/usescrollIntoView';

const MultiselectDropdown = (props: IMultiselectDropdownProps): ReactElement => {
	const {
		values,
		setFieldValue,
		options,
		name,
		onBlur,
		error,
		label,
		required,
		disabled,
		placeholder,
		icon,
		className = '',
		maxLimit,
		onChange,
	} = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const { handleFocus } = useScrollIntoView(inputRef);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [allOptions, setAllOptions] = useState<DropdownOptions[]>(JSON.parse(JSON.stringify(options)));
	const [filterOptions, setFilterOptions] = useState<DropdownOptions[]>(JSON.parse(JSON.stringify(options)));
	const [inputValue, setInputValue] = useState<string>('');
	const { isOpen, setIsOpen } = useOutsideClickMultiSelect({ dropdownRef, emptyValue: () => resetFilter() });

	useEffect(() => {
		setAllOptions(JSON.parse(JSON.stringify(options)));
		setFilterOptions(JSON.parse(JSON.stringify(options)));
	}, [options]);

	const resetFilter = useCallback(() => {
		setInputValue('');
		setFilterOptions(JSON.parse(JSON.stringify(allOptions)));
	}, [allOptions]);

	const handleInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>): void => {
			e.preventDefault();
			setInputValue(e.target.value);
			setFilterOptions(
				options.filter((option) =>
					(option.name ? option.name : option.value)?.toLowerCase().includes(e.target.value.toLowerCase())
				)
			);
		},
		[options]
	);

	const handleCrossIcon = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: DropdownOptions): void => {
		e.preventDefault();
		handleCheckboxChange(item);
	};

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
		if (onBlur) {
			onBlur(e);
		}
	};

	const handleDropDownClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault();
		setIsOpen(!isOpen);
		if (isOpen) {
			handleFocus();
		}
	};

	const handleInputFocus = (): void => {
		setIsOpen(true);
		handleFocus();
	};

	useEffect(() => {
		if (isOpen) {
			handleFocus();
		}
	}, [handleFocus, isOpen]);

	const handleCheckboxChange = useCallback(
		(event: DropdownOptions): void => {
			const index = values.findIndex((item) => item.id === event.id);
			if (!setFieldValue) {
				return;
			}
			if (index !== -1) {
				const updatedMultiSelect = [...values.slice(0, index), ...values.slice(index + 1)];
				setFieldValue(name, updatedMultiSelect);
			} else if (maxLimit === undefined || values.length < maxLimit) {
				const updatedMultiSelect = [...values, event];
				setFieldValue(name, updatedMultiSelect);
			}
			onChange?.(event);
		},
		[values, setFieldValue, maxLimit, onChange, name]
	);

	const isItemDisabled = useCallback(
		(item: DropdownOptions): boolean => {
			return maxLimit !== undefined && values.length >= maxLimit && !values.some((data) => item.id === data.id);
		},
		[values, maxLimit]
	);

	return (
		<>
			<div className={`drop-down-wrapper ${disabled && 'disabled-drawer'}`} ref={dropdownRef}>
				<div className={`custom-select ${label && 'label-dropdown'} ${className ?? ''}`}>
					<div className="custom-select-item">
						{values?.length > 0 && (
							<div className="filter-tags-list">
								<ul className="tag-list">
									{values.map((item, index) => (
										<li key={item.id + '_' + index}>
											<span className="tag-text">{item.name ?? item.value}</span>
											<a
												href="/"
												aria-label="disable tag"
												onClick={(e) => {
													handleCrossIcon(e, item);
												}}
											>
												<span className="icon-cross"></span>
											</a>
										</li>
									))}
								</ul>
							</div>
						)}
						<label htmlFor="multi-select" className="hide">
							select
						</label>
						<input
							ref={inputRef}
							type="text"
							name={name}
							id={name}
							className={`multi-select-bar ${className} ${!isOpen && values?.length !== 0 ? 'hide' : ''}`}
							onChange={handleInputChange}
							onFocus={handleInputFocus}
							onBlur={handleBlur}
							autoComplete="off"
							placeholder={placeholder}
							value={inputValue}
							disabled={disabled}
						/>
						<span className={`custom-label ${required ? 'required' : ''}`}>{label}</span>
					</div>
					<div>
						<button className="select-button" aria-label="select club" onClick={(e) => handleDropDownClick(e)}>
							<span className={icon ?? 'icon-bottom-arrow'}></span>
						</button>
					</div>
				</div>
				{isOpen && !disabled && (
					<div className="select-list-items-wrapper">
						<ul className="select-list-items">
							{filterOptions?.length > 0 ? (
								filterOptions?.map((item, index) => (
									<li className="cmn-checkbox" key={item.id + '_' + index}>
										<input
											type="checkbox"
											onChange={() => {
												handleCheckboxChange(item);
											}}
											checked={values?.some((data) => item.id === data.id)}
											name={item.value}
											value={item.value}
											id={item.id.toString()}
											disabled={isItemDisabled(item)}
										/>
										<label htmlFor={`${item.id}`}>{item.name ?? item.value}</label>
									</li>
								))
							) : (
								<li className="">
									<label htmlFor="noData">No Data</label>
								</li>
							)}
						</ul>
					</div>
				)}
			</div>
			{error && <p className={`error-message ${error && 'show'}`}>{error}</p>}
		</>
	);
};

export default memo(MultiselectDropdown);
