export * from './siteDetailsTab';

export interface ISiteDetailsTab {
	key: number;
	label: string;
}

export const SiteAllDetailsTabHeading: ISiteDetailsTab[] = [
	{
		label: 'Project Details',
		key: 0,
	},
	{
		label: 'Stakeholder Details',
		key: 1,
	},
	{
		label: 'Visits',
		key: 2,
	},
];
