import { useActivityModuleQuery } from '@services/hooks/site-leads';
import { ReactElement, useEffect, useRef } from 'react';

export const ActivityModule = (): ReactElement => {
	const { data: activityData } = useActivityModuleQuery();
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (activityData && containerRef.current) {
			containerRef.current.innerHTML = activityData?.activity;
		}
	}, [activityData]);

	return (
		<div className="container">
			<div ref={containerRef}></div>
		</div>
	);
};
