import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { Feedback } from '.';

interface IMeetingDetails {
	mutate: UseMutateFunction<Feedback, Error, Feedback, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useAddFeedbackMutation = (id: string): IMeetingDetails => {
	const putFeedBackDetails = async (meetingFormData: Feedback): Promise<Feedback> => {
		const url = `${APICONFIG.MEETINGS_DETAILS}/${id}/feedback/`;
		return await APIService.putData<Feedback>(url, meetingFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: putFeedBackDetails,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
