import { TextInput } from '@components/common';
import { ReactElement, useCallback, useEffect } from 'react';
import { DealerWhoRaisedAComplaintProps } from '.';
import { PcmsImageVideoUpload } from '../pcmsProductImage/pcmsImageVideoUpload';
import { ToastType, useTosterStore } from '@store/toster';
import { usePresignedURLQuery } from '@services/hooks/enduser-details/usePresignedURLQuery';
import { PresignedURLDetails } from '@services/hooks/enduser-details';

interface CourierReceiptProps extends DealerWhoRaisedAComplaintProps {
	onCourierReceiptUpload: (data: PresignedURLDetails[]) => void;
	onCourierSubmit: () => void;
}

export const CourierDetails = (props: CourierReceiptProps): ReactElement => {
	const { formik, onCourierReceiptUpload, isEditing, onCourierSubmit } = props;
	const { setToaster } = useTosterStore();
	const { mutate: mutatePresignedURL, data: getCourierReceipt } = usePresignedURLQuery();

	const handleAddMedia = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const files = event.target.files ? Array.from(event.target.files) : [];
			if (files.length === 0) return;

			const nonMediaFiles = files.filter((file) => !file.type.startsWith('image/') && !file.type.startsWith('video/'));
			if (nonMediaFiles.length > 0) {
				setToaster(true, ToastType.error, 'Please upload only image or video files.');
				event.target.value = '';
				return;
			}

			const updatedMedia = [...files].slice(0, 1);

			formik.setFieldValue('attachment_courier_receipt_c', updatedMedia);

			const payload = updatedMedia.map((media) => {
				return {
					folder: 'pcms',
					filename: media.name,
					fileType: media.type.startsWith('video/') ? 'video' : 'image',
				};
			});

			mutatePresignedURL && mutatePresignedURL(payload);
		},
		[formik, mutatePresignedURL, setToaster]
	);

	useEffect(() => {
		if (getCourierReceipt) {
			onCourierReceiptUpload(getCourierReceipt);
		}
	}, [getCourierReceipt, onCourierReceiptUpload]);

	const handleCourier = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault();
		onCourierSubmit();
	};
	return (
		<>
			<li className="form-control d-col ">
				<TextInput
					id="courier_company_ref_id"
					name="courier_company_ref_id"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.courier_company_ref_id ?? ''}
					label="Courier Company/Ref Id"
					placeholder="Courier Company/Ref Id"
					error={
						formik.touched.courier_company_ref_id && formik.errors.courier_company_ref_id
							? formik.errors.courier_company_ref_id
							: null
					}
					setFieldValue={formik.setFieldValue}
				/>
			</li>
			<li className="d-col d-col-2 ">
				<span className="video-title">Courier Receipt</span>
				<PcmsImageVideoUpload
					fieldName="attachment_courier_receipt_c"
					formik={formik}
					handleAddImage={handleAddMedia}
					label="Courier Receipt"
					limit={true}
				/>
			</li>
			{isEditing && (
				<li className="d-col pcms-submit">
					<button
						type="submit"
						aria-label="submit button"
						className="btn btn-primary btn-medium"
						onClick={handleCourier}
					>
						Edit Courier
					</button>
				</li>
			)}
		</>
	);
};
