import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { MeetingDetails } from '.';
import { APICONFIG } from '@config/api.config';

interface IMeetingDetails {
	mutate: UseMutateFunction<MeetingDetails, Error, MeetingDetails, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useMeetingMutation = (id?: number | null): IMeetingDetails => {
	const postMeetingsDetailsData = async (meetingFormData: MeetingDetails): Promise<MeetingDetails> => {
		const url = `${APICONFIG.MEETINGS_DETAILS}/`;
		return await APIService.postData<MeetingDetails>(url, meetingFormData);
	};
	const putMeetingsDetails = async (meetingFormData: MeetingDetails): Promise<MeetingDetails> => {
		const url = `${APICONFIG.MEETINGS_DETAILS}/${id}/`;
		return await APIService.putData<MeetingDetails>(url, meetingFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: id ? putMeetingsDetails : postMeetingsDetailsData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
