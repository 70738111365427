import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { BarcodePayload } from '.';
import { BarcodeValidate } from '@components/contractor360/components/qrScanner/components/qrCodeValidate';

interface IBarcodeDetails {
	mutate: UseMutateFunction<BarcodeValidate, Error, BarcodePayload, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useBarcodeValidateMutation = (): IBarcodeDetails => {
	const postBasicDetailsData = async (basicFormData: BarcodePayload): Promise<BarcodeValidate> => {
		return await APIService.postData<BarcodeValidate>(`${APICONFIG.VALIDATE_BARCODE}/`, basicFormData);
	};

	const { mutate, error, isError, isPending } = useMutation<BarcodeValidate, Error, BarcodePayload>({
		mutationFn: postBasicDetailsData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
