import { AdoptedContractorData } from '@components/members/components/adoptedContractorList';
import { APICONFIG } from '@config/api.config';
import { getDatabase } from '@rxdb/config/databaseConfig';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

type AdoptedQuery = {
	data: AdoptedContractorData[];
	error: Error | null;
	isLoading: boolean;
	isPending: boolean;
};

export const useAdoptedQuery = (): IQueryResponse<Array<AdoptedQuery>> => {
	const [shouldFetch, setShouldFetch] = useState(true);
	const [isDbChecked, setIsDbChecked] = useState(false);

	const fetchAdoptedContractorData = async (): Promise<Array<AdoptedQuery>> => {
		const response = await APIService.getData<Array<AdoptedQuery>>(APICONFIG.ADOPTED_CONTRACTOR_API, {
			limit: '0',
		});
		if (response) {
			const db = await getDatabase();
			const adoptedContractor = await db.adopted_contractor.find().exec();
			const primaryId = adoptedContractor.map((doc: { primary: string }) => doc.primary);
			await db.adopted_contractor.bulkRemove(primaryId);
			await db.adopted_contractor.bulkInsert(response);
		}
		return response;
	};

	const checkDb = async (): Promise<boolean> => {
		const db = await getDatabase();
		const result = await db.adopted_contractor.find().exec();
		return result.length > 0;
	};

	useEffect(() => {
		const checkDatabase = async (): Promise<void> => {
			const exists = await checkDb();
			setShouldFetch(!exists);
			setIsDbChecked(true);
		};
		checkDatabase();
	}, []);

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['adoptedContractor'],
		queryFn: fetchAdoptedContractorData,
		enabled: isDbChecked && shouldFetch,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
