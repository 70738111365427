import { RxCollectionCreator } from 'rxdb';

export const dealersListSchema: RxCollectionCreator = {
	schema: {
		title: 'dealers list schema',
		description: 'Dealers List Schema',
		version: 0,
		primaryKey: 'sfid',
		type: 'object',
		properties: {
			sfid: { type: 'string', maxLength: 250 },
			dealername_c: { type: 'string' },
			dealercode_c: { type: 'string' },
			hierarchylevel1wssterritory_c: { type: 'string' },
			divisioncode_c: { type: 'string' },
			dealermobile_c: { type: 'string' },
			dealerlatitude_c: { type: 'string' },
			dealerlongitude_c: { type: 'string' },
			citycode_c: { type: 'string' },
			descriptionofhierarchylevel1_c: { type: 'string' },
			towndescription_c: { type: 'string' },
			tsicode_c: { type: 'string' },
			tsiname_c: { type: 'string' },
		},
		required: ['sfid'],
	},
};
