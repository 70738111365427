import { DropdownOptions } from '@components/common/formControl/dropdown';
import { formatTime, roundToNearestQuarterHour } from '@helper/utils';
import { Member } from '@services/hooks/meetings';

export interface IDefaultAddMeetingFormInitialValues {
	meetingType: string;
	mdi: string;
	name: string;
	contact: number | string;
	town: string;
	startdate: string;
	enddate: string;
	start_time_c: string;
	meetingTheme: string;
	end_time_c: string;
	club: DropdownOptions[];
	fccClub: string;
	meetingVenue: string;
	primaryFocusProduct: string;
	secondaryFocusProduct: string;
	product1Discussed: string;
	product2Discussed: string;
	meetFormat: string;
	participants: Member[];
	status: string;
}
const now = new Date();
const startDate = roundToNearestQuarterHour(now);
const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);

export const defaultAddMeetingInitialValues: IDefaultAddMeetingFormInitialValues = {
	meetingType: '',
	mdi: '',
	name: '',
	contact: '',
	town: '',
	startdate: new Date().toISOString(),
	enddate: endDate.toISOString(),
	start_time_c: formatTime(startDate),
	end_time_c: formatTime(endDate),
	club: [],
	fccClub: '',
	meetingVenue: '',
	primaryFocusProduct: '',
	secondaryFocusProduct: '',
	product1Discussed: '',
	product2Discussed: '',
	meetFormat: 'Physical',
	participants: [],
	meetingTheme: '',
	status: '',
};
