import { useRef, useEffect } from 'react';

type Timer = ReturnType<typeof setTimeout>;
type SomeFunction<T extends unknown[], R> = (...args: T) => R;

export function useDebounce<T extends unknown[], R>(func: SomeFunction<T, R>, delay = 1000): SomeFunction<T, void> {
	const timer = useRef<Timer>();

	useEffect((): (() => void) => {
		return (): void => {
			if (timer.current) {
				clearTimeout(timer.current);
			}
		};
	}, []);

	const debouncedFunction = (...args: T): void => {
		if (timer.current) {
			clearTimeout(timer.current);
		}
		timer.current = setTimeout(() => {
			func(...args);
		}, delay);
	};

	return debouncedFunction;
}
