export const leadUserSchema = {
	title: 'Lead user schema',
	description: 'create a Lead schema',
	version: 2,
	primaryKey: 'id',
	type: 'object',
	properties: {
		id: {
			type: 'string',
			maxLength: 250,
		},
		name: {
			type: 'string',
		},
		phone: {
			type: 'string',
			maxLength: 250,
		},
		createddate: {
			type: 'string',
			format: 'date-time',
		},
		assigned_cmdi: {
			type: 'string',
		},
		lead_closure_date: {
			type: 'string',
			format: 'date',
		},
		source: {
			type: ['string', 'null'],
		},
		primarydealercode: {
			type: 'string',
		},
		primarydealername: {
			type: 'string',
		},
		status: {
			type: 'string',
		},
	},
	required: [
		'id',
		'name',
		'phone',
		'createddate',
		'assigned_cmdi',
		'lead_closure_date',
		'primarydealercode',
		'primarydealername',
	],
};
