import { ReactElement, useEffect, useRef } from 'react';
import { IModalProps } from '@components/common/modal/index';
import { useOutsideDialogClose } from '@hooks/useOutsideDialogClose';
import { CloseButton } from '../closeButton';

export const Modal = (props: IModalProps): ReactElement => {
	const { children, className, modalOpen, modalId, onModalClose } = props;
	const dialogRef = useRef<HTMLDialogElement | null>(null);
	useOutsideDialogClose({
		dialogRef,
		callBackFunction: () => props.onModalClose && props.onModalClose(),
	});

	const escapeEvent = (): void => {
		document.addEventListener('keyup', function (event) {
			if (event.key === 'Escape') {
				document.body.classList.remove('overflow-hidden');
				onModalClose && onModalClose();
			}
		});
	};

	useEffect(() => {
		const modal = document.querySelector(`#${modalId}`) as HTMLDialogElement;
		const overlay = document.getElementById('overlay') as HTMLElement;

		if (!modalOpen) {
			modal && modal?.close();
			document.body.classList.remove('overflow-hidden');
			overlay?.classList?.remove('show');
			return;
		}

		overlay?.classList?.add('show');
		modal?.showModal();
		document.body.classList.add('overflow-hidden');

		escapeEvent();

		return (): void => {
			escapeEvent();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalId, modalOpen]);

	return (
		<dialog id={modalId} className={`dialog ${className}`} ref={dialogRef}>
			<CloseButton onClose={() => onModalClose && onModalClose()} />
			{children}
		</dialog>
	);
};
