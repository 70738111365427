const iv = new Uint8Array([188, 185, 57, 146, 246, 194, 114, 34, 12, 80, 198, 77]);
const importKey = (): Promise<CryptoKey> => {
	return crypto.subtle.importKey('raw', new Uint8Array(16), 'AES-GCM', true, ['encrypt', 'decrypt']);
};

const encrypt = (data: Uint8Array, key: CryptoKey): Promise<ArrayBuffer> => {
	return window.crypto.subtle.encrypt(
		{
			name: 'AES-GCM',
			iv: iv,
		},
		key,
		data
	);
};

const decrypt = (data: BufferSource, key: CryptoKey): Promise<ArrayBuffer> => {
	return window.crypto.subtle.decrypt(
		{
			name: 'AES-GCM',
			iv: iv,
		},
		key,
		data
	);
};
const ab2str = (buffer: ArrayBuffer): string => {
	return String.fromCharCode(...new Uint8Array(buffer));
};

const str2ab = (contentString: string): BufferSource => {
	const buf = new ArrayBuffer(contentString.length);
	const bufView = new Uint8Array(buf);
	for (let i = 0, strLen = contentString.length; i < strLen; i++) {
		bufView[i] = contentString.charCodeAt(i);
	}
	return bufView;
};
export const encryptData = async (data: string): Promise<string> => {
	const enc = new TextEncoder();
	const content = await encrypt(enc.encode(data), await importKey());
	return ab2str(content);
};

export const decryptData = async (encryptedData: string): Promise<string> => {
	if (!encryptedData) return '';
	const content = await decrypt(str2ab(encryptedData), await importKey());
	const enc = new TextDecoder('utf-8');
	return enc.decode(content);
};
