import { useRxData } from 'rxdb-hooks';
import { RxCollection, RxDocument } from 'rxdb';
import { useCallback } from 'react';

export type IUseDataReturn<T> = {
	result: RxDocument<T>[];
	isFetching: boolean;
	fetchMore: () => void;
};

export const useGetRxDb = <T,>(name = ''): IUseDataReturn<T> => {
	const queryConstructor = useCallback((collection: RxCollection<T>) => collection.find(), []);

	const { result, isFetching, fetchMore } = useRxData<T>(name, queryConstructor);

	return {
		result,
		isFetching,
		fetchMore,
	};
};
