import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { TaskListProductsData } from '.';
import useSiteLeadStore from '@store/siteLeadStore';

export const useTaskListProductsQuery = (membershipno?: string): IQueryResponse<TaskListProductsData> => {
	const { grade, reason, type_of_contract, glue } = useSiteLeadStore();

	const fetchTaskListProductsData = async (): Promise<TaskListProductsData> => {
		const url = `${APICONFIG.TASKLIST_PRODUCTS}`;
		return await APIService.getData<TaskListProductsData>(url, {
			grade: grade || '',
			reason: reason || '',
			type_of_contract: type_of_contract || '',
			glue: glue?.toLowerCase() || '',
			membershipno: membershipno || '',
		});
	};

	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['taskListProducts'],
		queryFn: fetchTaskListProductsData,
		refetchOnWindowFocus: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
