import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { ProjectDetails, SiteDetailsTab, StakeholderDetails } from '@components/siteSummaryDetails/components';
import { useSiteAllDetailsStore } from '@store/siteAllDetailsStore';
import { useFormik } from 'formik';
import { initialFormikSiteDetails } from '@components/siteSummaryDetails/index';
import { useGetAllSiteDetails } from '@services/hooks/all-site-details';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { typeOfContractor } from '@config/constant';
import { SiteVisits } from '@components/siteSummaryDetails/components/siteVisits';
import useSiteLeadStore from '@store/siteLeadStore';
import useStepperStore from '@store/stepperStore';
import { useNavigate } from 'react-router-dom';
import { SiteLeadOverStateProps } from '@components/SiteLeadOverview';
import { parseMultiselectOptions } from '@components/delaerVisit/components/addEditDealerVisit';
import { substratesOptions } from '@components/siteLeads/components/addNewSite';
import { useUpdateSiteLeadMutation } from '@services/hooks/site-leads/useUpdateSiteLeadMutation';
import { UpdateSiteLeadData } from '@services/hooks/site-leads';
import { ToastType, useTosterStore } from '@store/toster';

export const SiteSummaryPageDetails = (): ReactElement => {
	const { currentActiveTab, siteLeadId } = useSiteAllDetailsStore();
	const navigate = useNavigate();
	const { setCurrentStep } = useStepperStore();
	const { setSiteSfid, setKeyBaseValue } = useSiteLeadStore();
	const { data: siteDetail, isFetching, error } = useGetAllSiteDetails(siteLeadId);
	const { mutate: updateSiteLead, isPending: isSiteLeadPending } = useUpdateSiteLeadMutation();
	const { setToaster } = useTosterStore();
	const formik = useFormik({
		initialValues: initialFormikSiteDetails,
		onSubmit: () => {},
	});

	useEffect(() => {
		if (siteDetail) {
			formik.setValues({
				name: siteDetail.name,
				site_location_c: siteDetail.site_location_c,
				grade_glue_used_at_the_site: siteDetail.grade_glue_used_at_the_site,
				brand_of_glue_used_at_site: siteDetail.brand_of_glue_used_at_site ?? '',
				contract_name: siteDetail.contract_name,
				contract_phone: siteDetail.contract_phone,
				type_of_contract: typeOfContractor.find((item) => item.name === siteDetail.type_of_contract)?.value ?? '',
				reason_of_competition: siteDetail.reason_of_competition,
				glue_used_at_the_site: siteDetail.glue_used_at_the_site,
				site_type: siteDetail.site_type,
				dealer_code: siteDetail.dealer_code,
				dealer_name: `${siteDetail.dealer_code} - ${siteDetail.dealer_name}`,
				glue_potential: siteDetail.glue_potential,
				architect_name: siteDetail.architect_name,
				architect_mobile_no: siteDetail.architect_mobile_no,
				owner_name: siteDetail.owner_name,
				owner_mobile_no: siteDetail.owner_mobile_no,
				owner_details: [{ id: 0, isActive: true, name: siteDetail.owner_name, number: siteDetail.owner_mobile_no }],
				architect_details: [
					{ id: 0, isActive: true, name: siteDetail.architect_name, number: siteDetail.architect_mobile_no },
				],
				substratesCombinations: parseMultiselectOptions(siteDetail.roff_substrate_c, substratesOptions),
				veneer: siteDetail.epoxy_adhesive_used_c,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [siteDetail]);

	const contractorDetails = useMemo((): SiteLeadOverStateProps => {
		return {
			name: siteDetail?.contract_name ?? '',
			phone: Number(siteDetail?.contract_phone) || null,
			number: Number(siteDetail?.contract_phone) || null,
			sfid: siteDetail?.end_user_c ?? '',
			membershipno: siteDetail?.membership_no ?? '',
			siteId: siteDetail?.id ?? null,
		};
	}, [siteDetail]);
	const handleAddNewVisitClick = (): void => {
		setCurrentStep(0);
		setKeyBaseValue('addNewSiteLead', false);
		setSiteSfid(siteDetail?.sfid ?? '');
		setKeyBaseValue('siteDetails', {
			name: siteDetail?.contract_name ?? '',
			number: Number(siteDetail?.contract_phone) || null,
			sfid: siteDetail?.end_user_c ?? '',
			membershipno: siteDetail?.membership_no ?? '',
		});
		navigate('/add-new-site', {
			state: {
				siteId: siteDetail?.id ?? null,
			},
		});
	};

	const activeTabElement = useMemo((): ReactElement => {
		switch (currentActiveTab) {
			case 0:
				return <ProjectDetails formik={formik} />;
			case 1:
				return <StakeholderDetails formik={formik} />;
			case 2:
				return (
					<SiteVisits visits={siteDetail?.visits} siteLeadId={siteDetail?.id} contractorDetails={contractorDetails} />
				);
			default:
				return <></>;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contractorDetails, currentActiveTab, formik, siteDetail?.visits]);

	const handleSubmit = useCallback((): void => {
		const payload: UpdateSiteLeadData = {
			site_type: formik.values.site_type,
			dealer_name: formik.values.dealer_name.split('-')[1].trim(),
			dealer_code: formik.values.dealer_name.split('-')[0].trim(),
			glue_potential: formik.values.glue_potential,
			architect_name: formik.values.architect_details[0].name,
			architect_mobile_no: formik.values.architect_details[0].number,
			owner_name: formik.values.owner_details[0].name,
			owner_mobile_no: formik.values.owner_details[0].number,
			epoxy_adhesive_used_c: formik.values.veneer,
		};
		updateSiteLead?.(payload, {
			onSuccess: () => {
				setToaster(true, ToastType.success, 'Stackholder details updated successfully.');
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values, updateSiteLead]);

	const submitButton = (): ReactElement | null => {
		if (currentActiveTab === 2) {
			return (
				<div className="submit-btn">
					<button
						type="button"
						className="btn btn-medium btn-primary"
						id="add-new-visit-btn"
						onClick={handleAddNewVisitClick}
					>
						Add New Visit
					</button>
				</div>
			);
		}
		if (currentActiveTab === 1) {
			return (
				<div className="submit-btn">
					<button
						type="button"
						disabled={isSiteLeadPending}
						onClick={handleSubmit}
						className="btn btn-medium btn-primary"
						id="edit-btn"
					>
						Edit
					</button>
				</div>
			);
		}

		return null;
	};

	return (
		<section className="site-summary-all-details">
			<div className="container">
				<SiteDetailsTab />
				<Wrapper isLoaderVisible={isFetching} isError={error}>
					<div className="summary-details-form">
						<form>{activeTabElement}</form>
						{submitButton()}
					</div>
				</Wrapper>
			</div>
		</section>
	);
};
