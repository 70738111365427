import { ReactElement } from 'react';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { IDatePickerProps } from '.';
import { DateRangePicker } from '@wojtekmaj/react-daterange-picker';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';

export const MultiDateRangePicker = (props: IDatePickerProps): ReactElement => {
	const { formik, required, error, id, name, label, disabled, maxDate, value, onChange } = props;

	const handleDateChange = (value: Value): void => {
		formik?.setFieldValue(name, value);
		if (onChange) {
			onChange(value);
		}
	};

	return (
		<div className="date-input custom-date-range-picker">
			<label htmlFor={id} className={`custom-label ${required ? 'required' : ''}`}>
				{label}
			</label>
			<DateRangePicker
				id={id}
				name={name}
				value={value}
				onChange={handleDateChange}
				disabled={disabled}
				maxDate={maxDate}
			/>
			{error && <p className={`error-message ${error && 'show'}`}>{error}</p>}
		</div>
	);
};
