import { DropdownOptions } from '@components/common/formControl/dropdown';

export * from './addEditDealerVisit';

export interface IdealerVisitFormInitialValues {
	dealerName: string | null;
	siteLocation: string | null;
	address: string | null;
	siteAddress: string | null;
	state: string | null;
	city: string | null;
	premiumProducts: DropdownOptions[];
	productRSI: DropdownOptions[];
	productCompetitionFighting: DropdownOptions[];
	activityDiscussed: DropdownOptions[];
	orderTaken: DropdownOptions[];
	orderTakenVolume?: string | null;
	productImages: File[];
	contractorName1: string | null;
	contractorName2: string | null;
	contractorNumber1: string | null;
	contractorNumber2: string | null;
	feedback: string | null;
	isContractorName1Disabled: boolean;
	isContractorName2Disabled: boolean;
}

export const dealerVisitFormInitialValues: IdealerVisitFormInitialValues = {
	dealerName: '',
	siteLocation: '',
	address: '',
	siteAddress: '',
	state: '',
	city: '',
	premiumProducts: [],
	productRSI: [],
	productCompetitionFighting: [],
	activityDiscussed: [],
	productImages: [],
	orderTaken: [],
	orderTakenVolume: '',
	contractorName1: '',
	contractorName2: '',
	contractorNumber1: '',
	contractorNumber2: '',
	feedback: '',
	isContractorName1Disabled: false,
	isContractorName2Disabled: false,
};

export const premiumProducts: DropdownOptions[] = [
	{ id: 1, value: 'Marine' },
	{ id: 2, value: 'Hiper' },
	{ id: 3, value: 'Heatx' },
];
export const activityDiscussionProducts: DropdownOptions[] = [
	{ id: 1, value: 'FCC Meet' },
	{ id: 2, value: 'FCCR Points' },
	{ id: 3, value: 'Campaigns' },
	{ id: 4, value: 'Redemption Drive' },
	{ id: 5, value: 'Canopy Drive' },
	{ id: 6, value: 'Competition Discussion' },
];

export const parseMultiselectOptions = (
	selectedValues: string | undefined | null,
	availableOptions: Array<{ id: string | number; value: string }>
): DropdownOptions[] => {
	if (!selectedValues) return [];

	return selectedValues
		.split(',')
		.map((selectedValue) => {
			const matchingOption = availableOptions.find((option) => option.value === selectedValue);
			return matchingOption ? { id: matchingOption.id, value: selectedValue } : null;
		})
		.filter((option): option is DropdownOptions => option !== null);
};

export const convertUrlToFile = async (url?: string | null): Promise<File | null> => {
	if (!url) return null;
	const response = await fetch(url);
	const blob = await response.blob();
	const filename = url.split('/').pop() ?? 'unknown';
	const file = new File([blob], filename, {
		type: response.headers.get('Content-Type') ?? undefined,
	});
	return file;
};

export const isNullOrEmpty = (value?: string | null | DropdownOptions[]): string | null => {
	if (!value) return null;

	if (Array.isArray(value)) {
		return value.length > 0 ? value.map((item) => item.value).toString() : null;
	}

	return typeof value === 'string' && value.length > 0 ? value : null;
};
