import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { ICalculatorProductDetails } from '.';
import { APICONFIG } from '@config/api.config';
// import { ErrorHandler } from '@helper/errorHandler';

// TODO:: need to optimise code
export const useCalculatorProductList = (membershipNumber: string): IQueryResponse<ICalculatorProductDetails> => {
	const fetchCalculatorProductList = async (): Promise<ICalculatorProductDetails> => {
		// try {
		const response = await APIService.getData<ICalculatorProductDetails>(
			`${APICONFIG.CALCULATOR_PRODUCT_LIST}${membershipNumber}/data/`
		);
		return response;
		// } catch (error) {
		// 	ErrorHandler(error);
		// 	return null;
		// }
	};
	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['calculatorProductList'],
		queryFn: fetchCalculatorProductList,
		refetchOnWindowFocus: false,
	});
	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
