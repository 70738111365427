import { RxCollectionCreator } from 'rxdb';

export const wssTerritoryListSchema: RxCollectionCreator = {
	schema: {
		title: 'wss territory list schema',
		description: 'Wss Territory List Schema',
		version: 0,
		primaryKey: 'wssterritoryname_c',
		type: 'object',
		properties: {
			wssterritoryname_c: { type: 'string', maxLength: 250 },
			wssterritorycode_c: { type: 'string' },
		},
		required: ['wssterritoryname_c'],
	},
};
