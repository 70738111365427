import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { setRxdb } from '@helper/utils';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { PCMSObject } from '@components/pcms';

export const usePcmsListQuery = (): IQueryResponse<Array<PCMSObject>> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('pcms_registration_list');

	const fetchPcmsListData = async (): Promise<Array<PCMSObject>> => {
		const response = await APIService.getData<Array<PCMSObject>>(APICONFIG.PCMS_REGISTRATION, {
			limit: '0',
		});
		if (response) {
			const data = response.map((item) => {
				return { ...item, id: item.id.toString() };
			});
			await setRxdb('pcms_registration_list', data);
		}
		return response;
	};

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['PCMSList'],
		queryFn: fetchPcmsListData,
		enabled: isDbChecked && shouldFetch,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
