import { SingleDatePicker, TextInput } from '@components/common';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { DealerWhoRaisedAComplaintProps } from '.';
import Dropdown from '@components/common/formControl/dropdown/dropdown';
import { useProductDescriptionListQuery } from '@services/hooks/pcms-registration/useGetProductDescription';
import { useGetRxDb } from '@hooks/getRxdbData';
import { ProductDescriptionObject } from '@components/pcms';
import { UOM } from '@config/constant';
import { getInvoiceDetails, keyDownEvent } from '@helper/utils';
import { useGetProductWeightMutation } from '@services/hooks/pcms-registration/useGetProductWeight';
import { useGetProductNameMutation } from '@services/hooks/pcms-registration/useGetProductNameQuery';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { ToastType, useTosterStore } from '@store/toster';
import { useLocation } from 'react-router-dom';

interface ProductDetailsProps extends DealerWhoRaisedAComplaintProps {
	onShowConfirmationPopUp: (setAddress: boolean) => void;
}

export const ProductDetailsForm = (props: ProductDetailsProps): ReactElement => {
	const { formik, isEditing, onShowConfirmationPopUp } = props;
	const [productWeight, setProductWeight] = useState<DropdownOptions[]>([]);
	const [skuName, setSkuName] = useState<DropdownOptions[]>([]);
	const { setToaster } = useTosterStore();
	const { state } = useLocation();

	const [invoiceData, setInvoiceData] = useState<DropdownOptions[] | null>(null);

	const handleInvoiceDetails = async (value: string): Promise<void> => {
		if (invoiceData === null) {
			const { data } = await getInvoiceDetails(value, formik.values.sku_names_c?.split('-')?.pop() ?? '');
			if (data.length === 0) {
				onShowConfirmationPopUp(true);
			}
			setInvoiceData(
				data.length
					? data.map((item) => {
							return {
								id: item,
								value: item,
							};
						})
					: []
			);
		}
	};

	useProductDescriptionListQuery();
	const { result: productDescriptionList } = useGetRxDb<ProductDescriptionObject>('product_description');
	const productDescriptionDescription = useMemo(
		() =>
			productDescriptionList.map((item) => ({
				id: item.prdgroup3_c,
				value: `${item.prdgroup3_c} - ${item.productgroup3description_c}` ?? '',
			})),
		[productDescriptionList]
	);
	const { mutate: productWeightMutation } = useGetProductWeightMutation();
	const { mutate: productNameMutation } = useGetProductNameMutation();

	const handleProductDescription = useCallback(
		(product_code: string | number): void => {
			formik.setFieldValue('productWeight', '');
			formik.setFieldValue('sku_names_c', '');

			productWeightMutation(
				{ product_code: `${product_code}` },
				{
					onSuccess: (data) => {
						setProductWeight(data.map((item) => ({ id: item.id, value: item.productgroup5description_c })));
					},
					onError: (error) => {
						setToaster(true, ToastType.error, error.message);
					},
				}
			);
			productNameMutation(
				{ product_code: `${product_code}` },
				{
					onSuccess: (data) => {
						setSkuName(data.map((item) => ({ id: item.name, value: `${item.name} - ${item.materialcode_c ?? ''}` })));
					},
					onError: (error) => {
						setToaster(true, ToastType.error, error.message);
					},
				}
			);
		},
		[formik, productNameMutation, productWeightMutation, setToaster]
	);

	const handleRefLotNoChange = useCallback(
		(e: string) => {
			if (e.length === 5) {
				formik.setFieldValue('ref_lot_no_c', e);
				handleInvoiceDetails(e);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formik]
	);

	return (
		<>
			{!state?.id && (
				<>
					<li className="form-control d-col d-col-2 ">
						<Dropdown
							id="productDescription"
							name="productDescription"
							label="Product Description"
							placeholder="Product Description"
							onBlur={formik.handleBlur}
							title={formik.values.productDescription ?? ''}
							options={productDescriptionDescription}
							setFieldValue={formik.setFieldValue}
							onChange={(e) => handleProductDescription(e.id)}
							disabled={isEditing}
						/>
					</li>
					<li className="form-control d-col d-col-2 ">
						<Dropdown
							id="productWeight"
							name="productWeight"
							label="Product Weight"
							placeholder="Product Weight"
							onBlur={formik.handleBlur}
							title={formik.values.productWeight ?? ''}
							options={productWeight}
							setFieldValue={formik.setFieldValue}
							disabled={isEditing}
							onChange={() => {
								formik.setFieldValue('sku_names_c', '');
							}}
						/>
					</li>
				</>
			)}
			<li className="form-control d-col">
				<Dropdown
					id="sku_names_c"
					name="sku_names_c"
					label="SKU Name"
					placeholder="SKU Name"
					onBlur={formik.handleBlur}
					title={formik.values.sku_names_c ?? ''}
					options={skuName}
					setFieldValue={formik.setFieldValue}
					required
					error={formik.touched.sku_names_c && formik.errors.sku_names_c ? formik.errors.sku_names_c : null}
					disabled={isEditing}
					onChange={() => {
						formik.setFieldValue('ref_lot_no_c', '');
						setInvoiceData(null);
					}}
				/>
			</li>
			<li className="form-control d-col d-col-2 ">
				{invoiceData?.length !== 0 || invoiceData === null ? (
					<Dropdown
						id="ref_lot_no_c"
						name="ref_lot_no_c"
						label="Ref/Lot No"
						placeholder="Ref/Lot No"
						onBlur={formik.handleBlur}
						title={formik.values.ref_lot_no_c ?? ''}
						setFieldValue={formik.setFieldValue}
						required
						error={formik.touched.ref_lot_no_c && formik.errors.ref_lot_no_c ? formik.errors.ref_lot_no_c : null}
						disabled={isEditing}
						options={invoiceData ?? []}
						onInputChange={handleRefLotNoChange}
					/>
				) : (
					<TextInput
						id="ref_lot_no_c"
						name="ref_lot_no_c"
						label="Ref/Lot No"
						placeholder="Ref/Lot No"
						onBlur={formik.handleBlur}
						value={formik.values.ref_lot_no_c ?? ''}
						setFieldValue={formik.setFieldValue}
						required={formik.values.sub_category_defect_c !== 'Post Application'}
						error={formik.touched.ref_lot_no_c && formik.errors.ref_lot_no_c ? formik.errors.ref_lot_no_c : null}
						disabled={isEditing}
					/>
				)}
			</li>

			<li className=" d-col d-col-2 ">
				<SingleDatePicker
					className="single-date-picker"
					label="Manufactured In"
					name="manufactured_in_c"
					id="manufactured_in_c"
					key="manufactured_in_c"
					value={formik.values.manufactured_in_c ?? ''}
					error={
						formik.touched.manufactured_in_c && formik.errors.manufactured_in_c ? formik.errors.manufactured_in_c : null
					}
					required
					formik={formik}
					disabled={isEditing}
					formate="MMM-yy"
				/>
			</li>

			<li className="form-control d-col d-col-2 ">
				<TextInput
					id="quantity_c"
					name="quantity_c"
					label="Quantity In Pieces"
					placeholder="Quantity In Pieces"
					onBlur={formik.handleBlur}
					setFieldValue={formik.setFieldValue}
					required
					value={formik.values.quantity_c ?? ''}
					error={formik.touched.quantity_c && formik.errors.quantity_c ? formik.errors.quantity_c : null}
					onKeyDown={keyDownEvent}
					disabled={isEditing}
				/>
			</li>
			<li className="form-control d-col d-col-2 ">
				<Dropdown
					id="uom_c"
					name="uom_c"
					label="Unit of Measurement"
					placeholder="Unit of Measurement"
					onBlur={formik.handleBlur}
					title={formik.values.uom_c ?? ''}
					options={UOM}
					disabled
					setFieldValue={formik.setFieldValue}
				/>
			</li>
		</>
	);
};
