import { ReactElement, useEffect } from 'react';
import { ToastType, useTosterStore } from '@store/toster';

export const Toster = (): ReactElement => {
	const { message, setToaster, className, toasterTime = 3000 } = useTosterStore();

	useEffect(() => {
		const showToast = (): NodeJS.Timeout => {
			const toast = document.getElementById('toast');
			toast?.classList.remove('hidden');
			const toastTimeout = setTimeout(function () {
				toast?.classList.add('hidden');
				setToaster(false);
			}, toasterTime);
			return toastTimeout;
		};

		const toastTimeout = showToast();

		return (): void => clearTimeout(toastTimeout);
	}, [setToaster, toasterTime]);

	return (
		<div id="toast" className={`toast-message ${className}`}>
			<div className="toast-content-wrapper">
				<div className="toast-icon">
					<span className={`icon ${ToastType.error === className ? 'icon-alert-triangle' : 'icon-thumbs-up'}`} />
				</div>
				<div className="toast-content">{message}</div>
				<button aria-label="close toast" type="button" className="cross-icon" onClick={() => setToaster(false)}>
					<span className="icon-x-circle" />
				</button>
			</div>
		</div>
	);
};
