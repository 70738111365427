import { TextInput } from '@components/common';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { DealerWhoRaisedAComplaintProps } from '.';
import Dropdown from '@components/common/formControl/dropdown/dropdown';
import { NatureOfComplaints, YesNo, SampleNonAvailabilityReason } from '@config/constant';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { usePresignedURLQuery } from '@services/hooks/enduser-details/usePresignedURLQuery';
import { ToastType, useTosterStore } from '@store/toster';
import { PcmsImageVideoUpload } from '../pcmsProductImage/pcmsImageVideoUpload';
import { PresignedURLDetails } from '@services/hooks/enduser-details';

interface DealerDetailsProps extends DealerWhoRaisedAComplaintProps {
	onProductImageUpload: (data: PresignedURLDetails[]) => void;
}

export const ComplaintDetailsForm = (props: DealerDetailsProps): ReactElement => {
	const { formik, isEditing, onProductImageUpload } = props;
	const { setToaster } = useTosterStore();

	const getSubCategory = useMemo(() => {
		let defectsArray: DropdownOptions[] = [];

		switch (formik.values.nature_of_complaints_c) {
			case 'Product Application Complaint':
				defectsArray = [
					{ id: '0', value: 'During Application' },
					{ id: '1', value: 'Post Application' },
				];
				break;
			case 'Packaging Complaint':
				defectsArray = [
					{ id: '0', value: 'Packaging Damage/ Leakage' },
					{ id: '1', value: 'Wrong Label or Packaging/ Dispatch Mistake' },
					{ id: '2', value: 'Barcode/ Token/ Sticker/ Coupon/ Gift Related' },
					{ id: '3', value: 'Printing quality issue on packaging/ wrong printing on packaging' },
				];
				break;
			case 'Product Quality Complaint':
				defectsArray = [
					{ id: '0', value: 'Performance Related' },
					{ id: '1', value: 'Appearance & Texture' },
					{ id: '2', value: 'Smell/Contamination' },
					{ id: '3', value: 'Others' },
				];
				break;
			case 'Expired Product':
				defectsArray = [
					{ id: '0', value: 'During Application' },
					{ id: '1', value: 'Post Application' },
					{ id: '2', value: 'Packaging Damage/ Leakage' },
					{ id: '3', value: 'Wrong Label or Packaging/ Dispatch Mistake' },
					{ id: '4', value: 'Barcode/ Token/ Sticker/ Coupon/ Gift Related' },
					{ id: '5', value: 'Printing quality issue on packaging/ wrong printing on packaging' },
				];
				break;
			default:
				defectsArray = [];
		}

		return defectsArray;
	}, [formik.values.nature_of_complaints_c]);

	const { mutate: mutatePresignedURL, data: getPictureVideo } = usePresignedURLQuery();

	useEffect(() => {
		if (getPictureVideo) {
			onProductImageUpload(getPictureVideo);
		}
	}, [getPictureVideo, onProductImageUpload]);

	const handleAddMedia = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const files = event.target.files ? Array.from(event.target.files) : [];
			if (files.length === 0) return;

			const nonMediaFiles = files.filter((file) => !file.type.startsWith('image/') && !file.type.startsWith('video/'));
			if (nonMediaFiles.length > 0) {
				setToaster(true, ToastType.error, 'Please upload only image or video files.');
				event.target.value = '';
				return;
			}

			const currentMedia = formik.values.pictures_videos_c;

			const updatedMedia = [...currentMedia, ...files];

			formik.setFieldValue('pictures_videos_c', updatedMedia);

			const payload = updatedMedia.map((media) => {
				return {
					folder: 'pcms',
					filename: media.name,
					fileType: media.type.startsWith('video/') ? 'video' : 'image',
				};
			});

			mutatePresignedURL && mutatePresignedURL(payload);
		},
		[formik, mutatePresignedURL, setToaster]
	);

	return (
		<>
			<li className="form-control d-col d-col-2">
				<Dropdown
					id="nature_of_complaints_c"
					name="nature_of_complaints_c"
					label="Nature of Complaint"
					placeholder="Nature of Complaint"
					onBlur={formik.handleBlur}
					title={formik.values.nature_of_complaints_c ?? ''}
					error={
						formik.touched.nature_of_complaints_c && formik.errors.nature_of_complaints_c
							? formik.errors.nature_of_complaints_c
							: null
					}
					options={NatureOfComplaints}
					setFieldValue={formik.setFieldValue}
					required
					onChange={() => formik.setFieldValue('sub_category_defect_c', '')}
					disabled={isEditing}
				/>
			</li>
			<li className="form-control d-col d-col-2">
				<Dropdown
					id="sub_category_defect_c"
					name="sub_category_defect_c"
					label="Sub Category/Defect"
					onBlur={formik.handleBlur}
					title={formik.values.sub_category_defect_c ?? ''}
					placeholder="Sub Category/Defect"
					error={
						formik.touched.sub_category_defect_c && formik.errors.sub_category_defect_c
							? formik.errors.sub_category_defect_c
							: null
					}
					options={getSubCategory}
					setFieldValue={formik.setFieldValue}
					required={formik.values.nature_of_complaints_c !== 'Expired Product'}
					disabled={isEditing}
				/>
			</li>
			<li className="form-control d-col ">
				<TextInput
					id="description_c"
					name="description_c"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.description_c ?? ''}
					label="Complaint Description"
					placeholder="Complaint Description"
					error={formik.touched.description_c && formik.errors.description_c ? formik.errors.description_c : null}
					setFieldValue={formik.setFieldValue}
					required
					disabled={isEditing}
				/>
			</li>
			<li className="d-col">
				<span className="video-title required">Picture Video</span>
				<PcmsImageVideoUpload
					fieldName="pictures_videos_c"
					formik={formik}
					handleAddImage={handleAddMedia}
					label="Picture Video"
					isEditing={isEditing}
				/>
			</li>
			<li className="form-control d-col">
				<Dropdown
					id="complaint_sample_availability_c"
					name="complaint_sample_availability_c"
					label="Complaint Sample Availability"
					onBlur={formik.handleBlur}
					title={formik.values.complaint_sample_availability_c ?? ''}
					placeholder="Complaint Sample Availability"
					error={
						formik.touched.complaint_sample_availability_c && formik.errors.complaint_sample_availability_c
							? formik.errors.complaint_sample_availability_c
							: null
					}
					options={YesNo}
					setFieldValue={formik.setFieldValue}
					required
					disabled={isEditing}
				/>
			</li>
			{formik.values.complaint_sample_availability_c === 'No' && (
				<li className="form-control d-col">
					<Dropdown
						id="sample_non_availability_reason"
						name="sample_non_availability_reason"
						label="Reason for unavailability of Sample"
						placeholder="Reason for unavailability of Sample"
						onBlur={formik.handleBlur}
						title={formik.values.sample_non_availability_reason ?? ''}
						error={
							formik.touched.sample_non_availability_reason && formik.errors.sample_non_availability_reason
								? formik.errors.sample_non_availability_reason
								: null
						}
						options={SampleNonAvailabilityReason}
						setFieldValue={formik.setFieldValue}
						required
						disabled={isEditing}
					/>
				</li>
			)}
			{formik.values.sample_non_availability_reason === 'Others' && (
				<li className="form-control d-col ">
					<TextInput
						id="sample_non_availability_reason_others"
						name="sample_non_availability_reason_others"
						type="text"
						onBlur={formik.handleBlur}
						value={formik.values.sample_non_availability_reason_others ?? ''}
						label="If Others Please Specify"
						placeholder="If Others Please Specify"
						error={
							formik.touched.sample_non_availability_reason_others &&
							formik.errors.sample_non_availability_reason_others
								? formik.errors.sample_non_availability_reason_others
								: null
						}
						setFieldValue={formik.setFieldValue}
						required
						disabled={isEditing}
					/>
				</li>
			)}
		</>
	);
};
