import { create } from 'zustand';

export enum ToastType {
	error = 'error',
	success = 'success',
}

interface ITosterStore {
	message: string;
	isOpen: boolean;
	className: ToastType;
	toasterTime?: number;
	setToaster: (isOpen: boolean, className?: ToastType, msg?: string, duration?: number) => void;
}

const initialState = {
	message: '',
	isOpen: false,
	className: ToastType.success,
	toasterTime: 3000,
};

export const useTosterStore = create<ITosterStore>((set) => ({
	...initialState,
	setToaster: (isOpen: boolean, className?: ToastType, msg?: string, duration?: number): void =>
		set((state) => ({ ...state, isOpen: isOpen, message: msg, className: className, toasterTime: duration })),
}));
