import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { IMemberDetails } from '@components/delaerVisit';

interface IMemberDetailData {
	mutate: UseMutateFunction<IMemberDetails, Error, string, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useMemberDetails = (): IMemberDetailData => {
	const getMemberDeatils = async (mobileNo: string): Promise<IMemberDetails> => {
		return await APIService.getData<IMemberDetails>(`${APICONFIG.MEMBER_NAME_DETAILS}`, {
			mobile: mobileNo,
		});
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: getMemberDeatils,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
