import { usePermission } from './usePermission';
import Cookies from 'universal-cookie';
import { ReactElement } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export interface IRouteHandlerReturn {
	isAuthenticated: () => boolean;
	permissionAndSessionCheck: () => ReactElement;
}

export const useRouteHandler = (): IRouteHandlerReturn => {
	const { pathname } = useLocation();
	const routes = usePermission();

	const isAuthenticated = (): boolean => {
		const cookie = new Cookies();
		const token = localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? cookie.get('token');
		return !!token;
	};

	const permissionAndSessionCheck = (): ReactElement => {
		if (!isAuthenticated()) {
			return <Navigate to="/login" replace />;
		}
		const checkIsRouteAllowed = routes?.find((route) => route.route === pathname) ?? false;
		if (!checkIsRouteAllowed) {
			return <Navigate to="/" replace />;
		}
		return <Outlet />;
	};

	return {
		isAuthenticated,
		permissionAndSessionCheck,
	};
};
