import { useEffect, useCallback, useState } from 'react';

interface UseOutsideClickProps {
	dropdownRef: React.RefObject<HTMLElement>;
	emptyValue?: () => void;
}
export interface UseOutsideClickReturnType {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

const useOutsideClickMultiSelect = ({ dropdownRef, emptyValue }: UseOutsideClickProps): UseOutsideClickReturnType => {
	const [isOpen, setIsOpen] = useState(false);

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false);
				emptyValue && emptyValue();
			}
		},
		[dropdownRef, emptyValue]
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return (): void => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	return { isOpen, setIsOpen };
};

export { useOutsideClickMultiSelect };
