export const ProductsListSchema = {
	title: 'Products schema',
	description: 'create Products List schema',
	version: 0,
	primaryKey: 'prdgroup3_c',
	type: 'object',
	properties: {
		id: {
			type: 'number',
		},
		prdgroup3_c: { type: 'string', primary: true, maxLength: 200 },
		productgroup3description_c: { type: 'string' },
		averagerateeach_c: { type: 'string' },
		materialdescription_c: { type: ['string', 'null'] },
		sellingprice_c: { type: 'number' },
		name: { type: 'string' },
	},
	required: [
		'id',
		'prdgroup3_c',
		'productgroup3description_c',
		'averagerateeach_c',
		'materialdescription_c',
		'sellingprice_c',
		'name',
	],
};
