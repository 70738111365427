import { LoginFormPage } from '@components/login/loginFormPage';
import { ReactElement } from 'react';
import '@assets/styles/pages/login-page.scss';
import { Navigate } from 'react-router-dom';

export const LoginPage = (): ReactElement => {
	return localStorage.getItem('token') || sessionStorage.getItem('token') ? (
		<Navigate to="/" replace />
	) : (
		<>
			<LoginFormPage />
			<div id="overlay" className="overlay"></div>
		</>
	);
};
