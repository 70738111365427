/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem(): string {
	const userAgent = navigator.userAgent;

	if (/windows phone/i.test(userAgent)) {
		return 'Windows';
	}

	if (/android/i.test(userAgent)) {
		return 'Android';
	}

	if (/iPad|iPhone|iPod/.test(userAgent)) {
		return 'iOS';
	}

	return 'unknown';
}
