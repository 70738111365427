import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { ICompetitionConversionUsage } from '@components/competition-conversion/components/competitionConversionUsage';
import { IQueryResponse } from '@services/api';

export const useCompetitionConversionUsageQuery = (): IQueryResponse<Array<ICompetitionConversionUsage>> => {
	const fetchCompetitionConversionUsageData = async (): Promise<Array<ICompetitionConversionUsage>> => {
		return await APIService.getData<Array<ICompetitionConversionUsage>>(APICONFIG.COMPETITION_CONVERSION_USAGE, {
			limit: '0',
		});
	};

	const { data, error, isLoading, isPending } = useQuery({
		queryKey: ['competitionConversionUsage'],
		queryFn: fetchCompetitionConversionUsageData,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
	};
};
