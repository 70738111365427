import { IMeetingDetails } from '@components/meeting-details';
import { formatDateDDMMYY } from '@helper/utils';
import { ReactElement } from 'react';

export const MeetingTitleInfo = (props: IMeetingDetails): ReactElement => {
	const { data } = props;
	return (
		<div className="meeting-about-details-wrapper">
			<div className="meeting-about">
				<span className="meeting-id">{data?.name}</span>
				{/* {data?.status !== 'Completed Meeting' && (
					<a href="/" aria-label="edit meeting" className="edit-btn">
						<span className="icon-edit-tag" />
						<span className="title">Edit</span>
					</a>
				)} */}
			</div>

			<p className="club">{data?.club_name_c}</p>

			<span className="meeting-badge">Meet Type: {data?.meeting_type_c ?? '-'}</span>
			<span className="meeting-date h4 hide">{data?.startdate && formatDateDDMMYY(new Date(data?.startdate))}</span>
		</div>
	);
};
