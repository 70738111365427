import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { MeetingStatus } from '.';

interface IMeetingDetails {
	mutate: UseMutateFunction<MeetingStatus, Error, MeetingStatus, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useUpdateMeetingStatus = (id: string): IMeetingDetails => {
	const putMeetingStatus = async (meetingFormData: MeetingStatus): Promise<MeetingStatus> => {
		const url = `${APICONFIG.MEETINGS_DETAILS}/${id}/change-status/`;
		return await APIService.putData<MeetingStatus>(url, meetingFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: putMeetingStatus,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
