import * as Sentry from '@sentry/react';
import '/sentry';
import '@assets/styles/pages/preload-page.scss';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// cacheTime: 1000 * 60 * 60 * 24, // 24 hours
			staleTime: 2000,
			retry: 0,
		},
	},
});

const root = createRoot(document.getElementById('root') as HTMLElement, {
	onRecoverableError: Sentry.reactErrorHandler(),
});
root.render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<App />
		</QueryClientProvider>
	</StrictMode>
);

serviceWorker.register();
