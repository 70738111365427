import { create, StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { ISiteLeadVisits } from '@components/SiteLeadOverview';

export enum keysSiteDetailsStore {
	currentActiveTab = 'currentActiveTab',
}

interface ISiteAllDetailsStore {
	siteLeadId: number;
	currentActiveTab: number;
	visit: ISiteLeadVisits | null;
	setKeyBaseValue: <T>(key: string, value: T) => void;
}

const initialState = {
	siteLeadId: 0,
	currentActiveTab: 0,
	visit: null,
};

type SiteAllDetailsPersistOptions = PersistOptions<ISiteAllDetailsStore, Partial<ISiteAllDetailsStore>>;

export const useSiteAllDetailsStore = create<ISiteAllDetailsStore>(
	(
		persist as unknown as (
			config: StateCreator<ISiteAllDetailsStore>,
			options: SiteAllDetailsPersistOptions
		) => StateCreator<ISiteAllDetailsStore>
	)(
		(set) => ({
			...initialState,
			setKeyBaseValue: <T,>(key: string, value: T): void =>
				set((state) => ({
					...state,
					[key]: value,
				})),
		}),
		{
			name: 'site-all-details-storage',
			partialize: (state) => ({
				siteLeadId: state.siteLeadId,
				currentActiveTab: state.currentActiveTab,
			}),
		} as SiteAllDetailsPersistOptions
	)
);
