import { ReactElement } from 'react';
import { IPcmsImageProps } from '.';

export const PcmsImageVideoUpload = (props: IPcmsImageProps): ReactElement => {
	const { fieldName, formik, handleAddImage, isEditing, error, limit } = props;
	const handleDownload = (e: React.MouseEvent<HTMLButtonElement>, file: File): void => {
		e.preventDefault();
		const url = URL.createObjectURL(file);
		const a = document.createElement('a');
		a.href = url;
		a.download = file.name;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	};

	const handleDelete = (index: number): void => {
		const updatedFiles = formik.values[fieldName].filter((_: File, idx: number) => idx !== index);
		formik.setFieldValue(fieldName, updatedFiles);
	};

	return (
		<div className={`file-upload video-upload`}>
			<label htmlFor={`media-upload-${fieldName}`} className={`file-upload-label ${isEditing ? 'disabled-btn' : ''}`}>
				{/* {label} */}
				<div className="file-upload-wrapper">
					<div className={`media-upload-wrapper ${isEditing && 'disable-btn'}`}>
						<span className="file-upload-title">
							<span className="file-name">Choose File</span>
						</span>
						<input
							type="file"
							name={`media-${fieldName}`}
							id={`media-upload-${fieldName}`}
							accept="image/*, video/*"
							multiple={!limit}
							onChange={handleAddImage}
							disabled={isEditing}
						/>
					</div>
				</div>
			</label>
			<ul className="row">
				{formik.values[fieldName]?.map((media: File, idx: number) => (
					<li className="d-col d-col-2" key={`${media.lastModified}_${idx}`}>
						<div className={`file-upload `}>
							<div className={`file-upload-wrapper ${isEditing && 'disable-btn'}`}>
								<span className="file-upload-title">
									<span className="file-name">{media?.name}</span>
									<button
										aria-label="remove file"
										type="button"
										className="icon-x-circle"
										onClick={() => handleDelete(idx)}
										disabled={isEditing}
									></button>
								</span>
							</div>

							<button
								type="button"
								aria-label={`Download ${media.name}`}
								className="download-btn"
								onClick={(e) => handleDownload(e, media)}
							>
								Download
							</button>
						</div>
					</li>
				))}
			</ul>
			{error && <p className={`error-message ${error ? 'show' : ''}`}>{error}</p>}
		</div>
	);
};
