// ExpensesObject
import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { ExpensesObject } from '.';

interface IMeetingDetails {
	mutate: UseMutateFunction<ExpensesObject, Error, ExpensesObject, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useAddExpenseMutation = (id: string): IMeetingDetails => {
	const postExpenseMutation = async (expenseFormData: ExpensesObject): Promise<ExpensesObject> => {
		const url = `${APICONFIG.MEETINGS_DETAILS}/${id}/expense-details/`;
		return await APIService.putData<ExpensesObject>(url, expenseFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: postExpenseMutation,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
