import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';
import { ErrorHandler } from '@helper/errorHandler';

export interface SchemeData {
	name: string;
	scheme_url: string;
	scheme_type: string;
}

export const useSchemeDetails = (): IQueryResponse<SchemeData[]> => {
	const fetchSchemeData = async (): Promise<SchemeData[]> => {
		try {
			return await APIService.getData<SchemeData[]>(APICONFIG.SCHEME_DETAILS);
		} catch (error) {
			ErrorHandler(error);
			return [];
		}
	};

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['scheme'],
		queryFn: fetchSchemeData,
		enabled: false,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
