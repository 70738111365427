import {
	TCompetitionDetails,
	TPrimaryDetails,
	TSupervisorDetails,
} from '@components/endUserDetails/components/moreDetailsForm';
import { FormikValues } from 'formik';
import { useCallback } from 'react';

interface IAddRemoveProps {
	formik: {
		values: FormikValues;
		setFieldValue: (field: string, value: string[], shouldValidate?: boolean) => void;
	};
	addNewDeatils:
		| TNewCompetitionDetails
		| TNewPrimaryDetails
		| TNewSupervisorDetails
		| TChildDeatils
		| TDemoedProductDetails;
	fieldName: string;
}

interface IAddRemove {
	handleAddDetails: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
	handleRemoveDetails: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, childIndex: number | undefined) => void;
}

type TNewPrimaryDetails = {
	id: number;
	dealer_code: string | null;
	dealer_name: string | null;
};
type TNewCompetitionDetails = {
	id: number;
	competition_brands: string | null;
	competition_volume: string | null;
};
type TNewSupervisorDetails = {
	id: number;
	supervisor_name: string;
	supervisor_number: string;
};

type TChildDeatils = {
	id: number;
	child_gender: string;
	child_birthday: Date;
};

type TDemoedProductDetails = {
	id: number;
	product: string;
};

export const useAddRemoveAction = ({ formik, addNewDeatils, fieldName }: IAddRemoveProps): IAddRemove => {
	const handleAddDetails = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			e.preventDefault();
			formik.setFieldValue(fieldName, [...formik.values[fieldName], addNewDeatils]);
		},
		[formik, fieldName, addNewDeatils]
	);
	const handleRemoveDetails = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, childIndex: number | undefined) => {
			e.preventDefault();
			if (formik.values[fieldName].length > 1) {
				const updatedDetails = formik.values[fieldName].filter(
					(_: TCompetitionDetails | TPrimaryDetails | TSupervisorDetails | TChildDeatils, i: number) => i !== childIndex
				);
				formik.setFieldValue(fieldName, updatedDetails);
			}
		},
		[fieldName, formik]
	);

	return {
		handleAddDetails,
		handleRemoveDetails,
	};
};
