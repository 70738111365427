import { RxCollectionCreator } from 'rxdb';

export const dealerVisitsSchema: RxCollectionCreator = {
	schema: {
		title: 'dealer visits schema',
		description: 'Dealer Visits Schema',
		version: 0,
		primaryKey: 'id',
		type: 'object',
		properties: {
			sfid: { type: 'string' },
			id: { type: 'string', maxLength: 250 },
			dealer_name_c: { type: ['string', 'null'] },
			createddate: { type: ['string', 'null'] },
			activity_discussion_c: { type: ['string', 'null'] },
			city_c: { type: 'string' },
		},
		required: ['id'],
	},
};
