import { ReactElement } from 'react';

interface IConfirmationModal {
	onClose: () => void;
	onSubmit: () => void;
	message: string;
	question?: string;
	secondLineQuestion?: string;
	buttonLabel?: string;
	hideCancelButton?: boolean;
	className?: string;
}

export const ConfirmationModal = (props: IConfirmationModal): ReactElement => {
	const {
		onClose,
		onSubmit,
		message,
		question,
		secondLineQuestion,
		buttonLabel,
		className,
		hideCancelButton: hideCancelButton,
	} = props;

	return (
		<div className={`conform-pop ${className}`}>
			<span className="h4">{message}</span>
			{question && (
				<p className="question">
					{question} {secondLineQuestion && <span className="second-question">{secondLineQuestion}</span>}
				</p>
			)}

			<div className="button-component">
				{!hideCancelButton && (
					<button className="btn btn-primary btn-primary-2" type="button" onClick={onClose}>
						Cancel
					</button>
				)}

				<button className="btn btn-primary" type="button" onClick={onSubmit}>
					{buttonLabel ?? 'Submit'}
				</button>
			</div>
		</div>
	);
};
