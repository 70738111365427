import { ReactElement, useCallback } from 'react';
import { enrollFormRedirection, IUserCard, toEnrollFormSection } from '.';
import { NavLink, useNavigate } from 'react-router-dom';
import { convertISODateTODDMMYY } from '@helper/utils';
import { useCloseLeadsPopup } from '@store/closeLeadsPopupStore';
import { closeLeadsDropDown } from '@config/constant';
import { useEndUserStore } from '@store/enduser';

export const UserCard = (props: IUserCard): ReactElement => {
	const { index, state, userObject } = props;
	const priorityClassName =
		userObject.priority && userObject.priority >= 1 && userObject.priority <= 4
			? `priority-tag-${userObject.priority}`
			: 'priority-tag-4';
	const { setModal, setDropDownData } = useCloseLeadsPopup((state) => state);
	const navigate = useNavigate();
	const { setActiveNavTab, setIsFormEdit, setFormId } = useEndUserStore();

	const handleNavigate = useCallback(() => {
		navigate('/contractor', {
			state: {
				userData: userObject,
				path: state,
			},
		});
	}, [navigate, state, userObject]);

	const handleEnrollClick = useCallback((): void => {
		if (userObject?.id && userObject?.status) {
			setFormId(userObject.id);
			setIsFormEdit(false);

			const section = toEnrollFormSection(userObject?.status);
			if (section && enrollFormRedirection[section]) {
				navigate(enrollFormRedirection[section].path);
				setActiveNavTab(enrollFormRedirection[section].activeTabs);
			}
		}
	}, [navigate, setActiveNavTab, setFormId, setIsFormEdit, userObject?.id, userObject?.status]);
	return (
		<li key={index} className="d-col d-col-2">
			{state === '/new-leads' ? (
				<fieldset>
					<legend title={userObject.name}>{userObject.name}</legend>

					<div className="user-about-details leads-card">
						<div className="card-upper-details">
							<NavLink to={`tel:+91${userObject.phone}`} aria-label="call user link" className="call-icon">
								<span className="icon-call"></span>
							</NavLink>
						</div>

						<div className="user-bottom-details row">
							<div className="left-content d-col d-col-6">
								<p className="h5" title={convertISODateTODDMMYY(userObject.createddate)}>
									Creation: <span>{convertISODateTODDMMYY(userObject.createddate)}</span>
								</p>
								<p className="h5" title={`${userObject.source}`}>
									Source: <span>{userObject.source}</span>
								</p>
								{userObject.primarydealercode && (
									<p className="h5" title={`${userObject.primarydealercode}`}>
										Dealer Code: <span>{userObject.primarydealercode}</span>
									</p>
								)}
							</div>

							<div className="right-content d-col d-col-6">
								<NavLink
									to="#"
									className="h5"
									aria-label="close lead url"
									onClick={(e) => {
										e.preventDefault();
										setModal(true);
										setDropDownData(
											{ id: userObject.id, name: userObject.name, number: userObject.phone },
											closeLeadsDropDown
										);
									}}
								>
									Close Lead
								</NavLink>
								<NavLink to="" onClick={handleEnrollClick} className="h5" aria-label="contractor page url">
									Enroll
								</NavLink>
							</div>
						</div>
					</div>
				</fieldset>
			) : (
				<div role="button" tabIndex={0} onClick={handleNavigate} className="user-card-inner-container">
					<fieldset>
						<legend title={userObject.name}>{userObject.name}</legend>
						{(state === '/all' || state === '/end-users') && (
							<span className={`h5 user-id`}>ID: {userObject.membershipno}</span>
						)}
						<div className="user-about-details adopted-list">
							<div className="card-upper-details">
								<span className="club-name" title={userObject.clubname}>
									Club: {userObject.clubname}
								</span>

								<NavLink
									to={`tel:+91${userObject.phone}`}
									aria-label="call user link"
									className="call-icon"
									onClick={(e) => e.stopPropagation()}
								>
									<span className="icon-call"></span>
								</NavLink>
							</div>

							<div className="user-bottom-details row">
								{state === '/end-users' && (
									<div className="left-content d-col d-col-6">
										{'task_list' in userObject ? (
											userObject?.task_list?.slice(0, 2)?.map((task: string, index: number) => (
												<p key={task + '_' + index} className="h5" title={task}>
													{task}
												</p>
											))
										) : (
											<></>
										)}
									</div>
								)}

								{state === '/all' && (
									<div className="left-content d-col d-col-6">
										<p className="h5" title={`${userObject.glue_consumption_per_month}`}>
											Glue Potential: {userObject.glue_consumption_per_month}
										</p>
										<p className="h5" title={`${userObject.totalbankingQTD}`}>
											Total Banking QTD: {userObject.totalbankingQTD}
										</p>
									</div>
								)}

								<div className="right-content d-col d-col-6">
									{state === '/all' && (
										<span className="h5">Added: {convertISODateTODDMMYY(userObject.createddate)}</span>
									)}
									{userObject.contractorclass && state === '/end-users' && (
										<div className="h5 card-bottom-status">
											{userObject.contractorclass} {userObject.contractorconsumption}
										</div>
									)}
									{state === '/end-users' && (
										<div className="user-priority">
											{userObject?.visitstatus !== null && (
												<div
													className={`priority-activation ${userObject.visitstatus?.toLowerCase() === 'site visited' ? 'bg-success' : 'bg-danger'}`}
												>
													<span></span>
												</div>
											)}
											<span className={`priority ${priorityClassName} `}>Priority {userObject.priority}</span>
										</div>
									)}
								</div>
							</div>
						</div>
					</fieldset>
				</div>
			)}
		</li>
	);
};
