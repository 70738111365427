import { create } from 'zustand';

export interface AllSiteSummaryFilterObject {
	current_stage_of_site_c: string;
	competition_site: boolean;
	adopted_site: boolean;
	lastactivitydate: [Date | null, Date | null];
}

export interface SiteSummaryListingStore {
	allSiteSummaryFilter: AllSiteSummaryFilterObject;
	updateFilter: (filterObject: AllSiteSummaryFilterObject) => void;
	resetSiteSummaryFilter: () => void;
}

const initialState: { allSiteSummaryFilter: AllSiteSummaryFilterObject } = {
	allSiteSummaryFilter: {
		current_stage_of_site_c: '',
		competition_site: false,
		adopted_site: false,
		lastactivitydate: [null, null],
	},
};

export const useSiteSummaryListingStore = create<SiteSummaryListingStore>((set) => ({
	...initialState,
	updateFilter: (filterObject): void => {
		set((state) => ({
			...state,
			allSiteSummaryFilter: { ...state.allSiteSummaryFilter, ...filterObject },
		}));
	},
	resetSiteSummaryFilter: (): void => {
		set((state) => ({
			...state,
			allSiteSummaryFilter: {
				current_stage_of_site_c: '',
				competition_site: false,
				adopted_site: false,
				lastactivitydate: [null, null],
			},
		}));
	},
}));
