import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';
import { ErrorHandler } from '@helper/errorHandler';

export interface IRedemptionHistoryHeaderQuery {
	redeem_pts_qtd: number;
	last_redeem_date: Date;
	highest_value_gift_redeemed: string | null;
}

export const useGetRedemptionHistoryHeaderQuery = (
	membershipNumber: string
): IQueryResponse<IRedemptionHistoryHeaderQuery> => {
	const fetchGetRedemptionHistoryHeaderQuery = async (): Promise<IRedemptionHistoryHeaderQuery> => {
		try {
			return await APIService.getData<IRedemptionHistoryHeaderQuery>(
				APICONFIG.GET_CONTRACTOR_REDEMPTION_HISTORY + `${membershipNumber}`
			);
		} catch (error) {
			ErrorHandler(error);
			throw error;
		}
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['getRedemptionHistoryHeaderQuery'],
		queryFn: fetchGetRedemptionHistoryHeaderQuery,
		networkMode: 'always',
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
