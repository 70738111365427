import { ReactElement, useEffect, useMemo } from 'react';
import { handlePreventDefault, isFilterValuePresent } from '@helper/utils';
import { useFilterStore } from '@store/filter';
import { ISiteSummarySearchProps } from '@components/siteSummary';
import { useSiteSummaryListingStore } from '@store/siteSummaryListingFilterStore';

export const SiteSummarySearch = (props: ISiteSummarySearchProps): ReactElement => {
	const { setSortCallback, setFilterCallback } = props;
	const { search, sortActiveStatus, updateSearch, updateSortObject, updateSortValue, updateSortActiveStatus } =
		useFilterStore();
	const { allSiteSummaryFilter } = useSiteSummaryListingStore();

	useEffect(() => {
		updateSortObject([{ value: 'lastactivitydate', name: 'Last visited date' }]);
		updateSortValue('');
		updateSortActiveStatus(false);
		// eslint-disable-next-line
	}, []);

	const isFilterActive: boolean = useMemo(() => {
		return Object.values(allSiteSummaryFilter).some(isFilterValuePresent);
	}, [allSiteSummaryFilter]);

	return (
		<>
			<div className="filter-section">
				<div className="search-filter-wrapper">
					<div className="search-with-filter">
						<div className="search">
							<form onSubmit={handlePreventDefault}>
								<div className="search-row">
									<div className="input-col">
										<label htmlFor="search-top" className="hide">
											search
										</label>
										<input
											value={search}
											onChange={(e) => updateSearch(e.target.value)}
											type="text"
											name="search"
											id="search-top"
											placeholder="Search site name/contractor name/site number"
											className="search-bar"
										/>
									</div>

									<button className="search-button" aria-label="search user" onClick={handlePreventDefault}>
										<span className="icon-search"></span>
									</button>
								</div>
							</form>
						</div>

						<div className="filters">
							<button
								className={`filter-button ${sortActiveStatus && 'active'}`}
								aria-label="filter for user"
								id="sort-lead-btn"
								onClick={setSortCallback}
							>
								<span className="icon-slider"></span>
							</button>

							<button
								className={`filter-button ${isFilterActive && 'active'}`}
								aria-label="filter"
								id="filter-contractor"
								onClick={setFilterCallback}
							>
								<span className="icon-filter"></span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
