export type ISidebarConfig = {
	route: string;
	ariaLabel: string;
	icon: string;
};

export const SidebarConfig: ISidebarConfig[] = [
	{
		route: '/end-users',
		ariaLabel: 'Members',
		icon: 'icon-users',
	},
	{
		route: '/meetings',
		ariaLabel: 'Meetings',
		icon: 'icon-coffee',
	},
	{
		route: '/site-summary',
		ariaLabel: 'Site Leads',
		icon: 'icon-tool',
	},
	{
		route: '/dealer-visit-summary',
		ariaLabel: 'Dealer Visit',
		icon: 'icon-package',
	},
	{
		route: '/one-to-one-meeting-summary',
		ariaLabel: 'One to One Meetings',
		icon: 'icon-star-calander',
	},
	{
		route: '/gallery',
		ariaLabel: 'Gallery',
		icon: 'icon-gallery',
	},
	// TODO: Need to confirm with client for the FM Content module
	// {
	// 	route: '/field-marketing-content',
	// 	ariaLabel: 'Field Marketing Content',
	// 	icon: 'icon-gallery',
	// },
	{
		route: '/structured-questionnaires',
		ariaLabel: 'Structured Questionnaires',
		icon: 'icon-structured-questionnaire',
	},
	{
		route: '/pcms',
		ariaLabel: 'PCMS',
		icon: 'icon-tv',
	},
	{
		route: '/help-and-support',
		ariaLabel: 'Help & Support',
		icon: 'icon-help-circle',
	},
];
