import { ReactElement, useCallback } from 'react';
import { IRemoveImageProps } from '.';

export const RemoveImageAction = (props: IRemoveImageProps): ReactElement => {
	const { fieldName, formik, imgIndex } = props;

	const handleRemoveImage = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();
			const updatedImages = formik.values[fieldName]?.filter((_: File, index: number) => index !== imgIndex);
			const el = document.getElementById('image-upload') as HTMLInputElement;
			if (el) el.value = '';
			formik.setFieldValue(fieldName, updatedImages);
		},
		[fieldName, formik, imgIndex]
	);

	return <a href="/" aria-label="remove image" className="icon-cross remove-image" onClick={handleRemoveImage}></a>;
};
