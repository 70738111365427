import { overlayRemoveFadeAction } from '@components/common/sort';
import { create } from 'zustand';

interface ISuccessTosterStore {
	isOpen: boolean;
	navigateUrl: string;
	message: string;
	setSuccessModalClose: () => void;
	setSuccessModalOpen: (url: string, msg: string) => void;
}

const initialState = {
	isOpen: false,
	navigateUrl: '',
	message: '',
};

export const useSuccessTosterStore = create<ISuccessTosterStore>((set) => ({
	...initialState,
	setSuccessModalClose: (): void =>
		set((state) => {
			overlayRemoveFadeAction(() => {});
			return { ...state, isOpen: false };
		}),
	setSuccessModalOpen: (url: string, msg: string): void =>
		set((state) => ({ ...state, isOpen: true, message: msg, navigateUrl: url })),
}));
