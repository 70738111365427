import { useState, useEffect } from 'react';

export const useDebounceSearch = <T,>(value: T, delay = 500): T => {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return (): void => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};
