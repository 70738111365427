import { DropdownOptions } from '@components/common/formControl/dropdown';
import { AdoptedContractorDetails, SiteSummaryDetails } from '@services/hooks/contractor360';

export interface IContractorNavigation {
	data: AdoptedContractorDetails | undefined;
	isGetContractorDetailFetching?: boolean;
	error: Error | null;
	membershipNumber: string;
}

export interface ISiteSummaryCard {
	site: SiteSummaryDetails;
}

export interface ProductGraphData {
	product: string;
	line1: GraphDataPoint[];
	line2: GraphDataPoint[];
}

export interface GraphDataPoint {
	qt: string;
	fy: string;
	value: number;
}

export interface TableData {
	bank_data: BankData;
	non_bank_data: BankData;
}

export interface BankData {
	header: string[];
	data: (string | number)[][];
}

export interface IBankingDetailData {
	message: string;
	data: {
		tertiary_current_month: number | null;
		tertiary_current_quarter: number | null;
		graph_data: ProductGraphData[];
		table_data: TableData;
		quarter_order: string[];
	} | null;
}
export interface IBankingTertiaryDetails {
	bankingDetails?: IBankingDetailData;
	isSuccess?: boolean;
}
export interface IBankingCategoryProps {
	bankingDetails?: IBankingDetailData;
	isSuccess?: boolean;
}
export interface ISiteStatusData {
	id?: number;
	status: string;
	site_lead?: number | null;
}

export const siteStatusOptions: DropdownOptions[] = [
	{ id: 0, value: 'Site Not Converted' },
	{ id: 1, value: 'Site Converted Using FV Marine' },
	{ id: 2, value: 'Site Converted Using FV Hi Per' },
	{ id: 3, value: 'Site Converted Using FV Hi Per Star' },
	{ id: 4, value: 'Site Converted Using FV SH' },
	{ id: 5, value: 'Site Converted Using Heatx' },
];
