import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { setRxdb } from '@helper/utils';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { MeetingsObject } from '@components/meetingModule/components';

export const useMeetingsListQuery = (): IQueryResponse<Array<MeetingsObject>> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('meeting_list');

	const fetchMeetingListData = async (): Promise<Array<MeetingsObject>> => {
		const response = await APIService.getData<Array<MeetingsObject>>(APICONFIG.MEETINGS_DETAILS, {
			limit: '0',
		});
		if (response) {
			const data = response.map((item) => {
				return { ...item, id: item.id.toString() };
			});
			await setRxdb('meeting_list', data);
		}
		return response;
	};

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['MeetingList'],
		queryFn: fetchMeetingListData,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
