import { ReactElement, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Header, Loader } from '@components/common';
import { convertPathNameToTitle } from '@helper/utils';
import { CheckServiceWorkerUpdates } from './checkServiceWorkerUpdates';
import { useRouteHandler } from '../authGuard';

export const SubRoutingLayout = (): ReactElement => {
	const { pathname: path } = useLocation();
	const { isAuthenticated, permissionAndSessionCheck } = useRouteHandler();

	if (!isAuthenticated() && path !== '/login') {
		return (
			<Suspense fallback={<Loader />}>
				<Navigate to="/login" replace />
			</Suspense>
		);
	}
	return (
		<>
			{isAuthenticated() && <Header subRoute={true} menuHeading={convertPathNameToTitle(path)} />}
			<main>
				<Suspense fallback={<Loader />}>{permissionAndSessionCheck()}</Suspense>
			</main>
			<div id="overlay" className="overlay"></div>
			<CheckServiceWorkerUpdates />
		</>
	);
};
