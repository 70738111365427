import { FormikValues } from 'formik';

type UseFormErrorFunction<T> = (field: keyof T) => string | null;

export const useFormError = <T extends FormikValues>(formik: FormikValues): UseFormErrorFunction<T> => {
	const getErrorMessage = (field: keyof T): string | null => {
		return formik?.touched?.[field] && formik?.errors?.[field] ? (formik.errors[field] as string) : null;
	};

	return getErrorMessage;
};
