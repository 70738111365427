import { DayHeaderContentArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import { ReactElement } from 'react';

export const RenderCustomDayHeader = (
	info: DayHeaderContentArg,
	calendarRef: React.RefObject<FullCalendar>,
	setActive: (number: number) => void
): ReactElement => {
	const date = info.date;
	const formattedDate = date.toLocaleString('en-us', { weekday: 'short', day: 'numeric' });
	const formattedWeek = date.toLocaleString('en-us', { weekday: 'short' });

	const handleDateClick = (date: Date): void => {
		const calendarApi = calendarRef?.current?.getApi();
		calendarApi?.changeView('timeGridDay', date);
		setActive(0);
	};
	const isClickable = info.view.type !== 'dayGridMonth';

	return (
		<div className="fc-scrollgrid-sync-inner">
			{isClickable ? (
				<a
					aria-label={formattedDate}
					href="/"
					className="fc-col-header-cell-cushion"
					onClick={(e) => {
						e.preventDefault();
						handleDateClick(date);
					}}
				>
					{formattedDate}
				</a>
			) : (
				<span className="fc-col-header-cell-cushion">{formattedWeek}</span>
			)}
		</div>
	);
};
