import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { IQuickAddEndUserDetails } from '@components/onetoonemeetings/components/quickAddEndUser';

interface IMeetingsData {
	mutate: UseMutateFunction<IQuickAddEndUserDetails, Error, IQuickAddEndUserDetails, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useAddQuickEndUser = (): IMeetingsData => {
	const addQuickEndUser = async (userData: IQuickAddEndUserDetails): Promise<IQuickAddEndUserDetails> => {
		return await APIService.postData<IQuickAddEndUserDetails>(APICONFIG.ADD_QUICK_ENDUSER, userData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: addQuickEndUser,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
