import { Dropdown, GoogleMapModal, MultiselectDropdown, RadioButton, Stepper, TextInput } from '@components/common';
import { useStepperStore } from '@store/stepperStore';
import { useFormik } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { defaultAddSiteLeadFormInitialValues, substratesOptions } from '.';
import { brandUsedList, glueUsedRadioTypeList, typeOfContractor } from '@config/constant';
import { Modal } from '@components/common/modal/modal';
import { useGoogleMapStore } from '@store/googleMapStore';
import { useValidation } from '@hooks/validations';
import { useFetchGrades } from '@services/hooks/competition-conversion/useFetchGrades';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useCompetitionConversionUsageQuery } from '@services/hooks/competition-conversion/useCompetitionConversionUsageQuery';
import { useSiteLeadStore } from '@store/siteLeadStore';
import { useSetMainHeader } from '@hooks/useSetMainHeader';
import { useSiteLeadQuery, useUpdatedNewSiteLead } from '@services/hooks/site-leads';
import { useGetRxDb } from '@hooks/getRxdbData';
import { AllEndUserObject } from '@components/members/components/allEndUserList';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { isNullOrEmpty, parseMultiselectOptions } from '@components/delaerVisit/components/addEditDealerVisit';

export const AddNewSite = (): ReactElement => {
	const [addFields, setAddFields] = useState({});
	useSetMainHeader('Add New Site');
	const { setCurrentStep } = useStepperStore();
	const { currentAddress } = useGoogleMapStore();
	const { AddSiteLeadValidationSchema } = useValidation();
	const [locationModal, setLocationModal] = useState<boolean>(false);
	const { data: gradeList, error: gradeListError, isPending: isGradeListPending } = useFetchGrades();
	const { sfid, addNewSiteLead, setKeyBaseValue, siteDetails, siteVisitId } = useSiteLeadStore();
	const {
		data: competitionList,
		error: competitionListError,
		isPending: iscompetitionListPending,
	} = useCompetitionConversionUsageQuery();
	const { data: siteData, isFetching: isSiteDataFetching, error: siteDataError } = useSiteLeadQuery();
	const { result: allEndUser } = useGetRxDb<AllEndUserObject>('all');
	const { mutate: updateSiteLead } = useUpdatedNewSiteLead();

	const formik = useFormik({
		initialValues: defaultAddSiteLeadFormInitialValues,
		onSubmit: () => {},
		validationSchema: AddSiteLeadValidationSchema,
	});
	const [maxLimit, setMaxLimit] = useState<number>(3);

	const updateSiteLeadData = useCallback((): void => {
		const payload = {
			name: formik.values.siteName,
			site_location_c: formik.values.siteLocation,
			end_user_c: siteDetails?.sfid,
			glue_used_at_the_site: formik.values.glueUsedAtSite,
			grade_glue_used_at_the_site: formik.values.gradeUsed,
			reason_of_competition: formik.values.glueUsedAtSite === 'Competition' ? formik.values.competitionReason : null,
			type_of_contract:
				typeOfContractor.find((contractor) => contractor.value === formik.values.contractType)?.name ?? '',
			brand_of_glue_used_at_site: formik.values.brandUsed,
			market: 'BV',
			contract_phone: formik.values?.contractorName,
			contract_name: formik.values?.contractorNumber,
			roff_substrate_c: isNullOrEmpty(formik.values.substratesCombinations),
		};
		updateSiteLead?.(payload);
	}, [formik.values, siteDetails?.sfid, updateSiteLead]);

	const handleNext = useCallback((): void => {
		const {
			gradeUsed,
			contractType,
			competitionReason,
			glueUsedAtSite,
			siteName,
			siteLocation,
			brandUsed,
			substratesCombinations,
		} = formik.values;
		const contractorTypeValue = typeOfContractor.find((contractor) => contractor.value === contractType)?.name ?? '';
		const reasonForCompetition = glueUsedAtSite?.toLowerCase() === 'competition' ? competitionReason : '';
		const keyBaseValues = {
			grade: gradeUsed,
			brandUsed,
			type_of_contract: contractorTypeValue,
			reason: reasonForCompetition,
			glue: glueUsedAtSite,
			siteName,
			siteLocation,
			substrates: substratesCombinations,
		};

		Object.entries(keyBaseValues).forEach(([key, value]) => {
			setKeyBaseValue(key, value);
		});

		if (!siteData?.glue_used_at_the_site && sfid) {
			updateSiteLeadData();
		}

		const stepNavigate =
			siteData?.visits?.find((visit) => visit.id === siteVisitId)?.site_visit_status?.toLowerCase() === 'draft' ? 2 : 1;
		setCurrentStep(stepNavigate);

		if (addNewSiteLead) {
			setKeyBaseValue('siteDetails', addFields);
		}
	}, [
		sfid,
		formik.values,
		siteData,
		setCurrentStep,
		addNewSiteLead,
		setKeyBaseValue,
		updateSiteLeadData,
		siteVisitId,
		addFields,
	]);

	const handleLocationSubmit = useCallback((): void => {
		formik.setFieldValue('siteLocation', currentAddress);
		setLocationModal(false);
	}, [currentAddress, formik]);

	const handleOpenSiteLocationModal = (): void => {
		setLocationModal((prevState) => !prevState);
	};

	const handleCloseSiteLocationModal = (): void => {
		setLocationModal(false);
	};

	const gradeOptions = useMemo(() => {
		if (!gradeList?.length) return [];
		return gradeList?.map((grade, index) => {
			return {
				id: index,
				value: grade.name,
			};
		});
	}, [gradeList]);

	const competitionOptions = useMemo(() => {
		if (!competitionList?.length) return [];
		return competitionList.map((competition, index) => {
			return {
				id: index,
				value: competition.name,
			};
		});
	}, [competitionList]);

	const handleMultiSelectChange = (e: DropdownOptions): void => {
		const hasNA = formik.values.substratesCombinations.some((v) => v.value === 'NA');
		const isNASelected = e.value === 'NA';

		setMaxLimit(isNASelected && !hasNA ? 1 : 3);
		if (isNASelected) {
			formik.setFieldValue('substratesCombinations', hasNA ? [] : [{ id: 1, value: 'NA' }]);
		}
	};

	useEffect(() => {
		if (siteDetails) {
			formik.setFieldValue('contractorName', siteDetails?.name);
			formik.setFieldValue('contractorNumber', siteDetails?.number);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [siteDetails]);

	useEffect(() => {
		if (siteData) {
			formik.setValues(
				{
					siteName: siteData.name,
					contractorName: siteData.contract_name,
					contractorNumber: siteData.contract_phone,
					contractType: typeOfContractor.find((item) => item.name === siteData.type_of_contract)?.value,
					gradeUsed: siteData.grade_glue_used_at_the_site,
					brandUsed: siteData.brand_of_glue_used_at_site,
					siteLocation: siteData.site_location_c,
					competitionReason: siteData.reason_of_competition,
					glueUsedAtSite: siteData.glue_used_at_the_site,
					substratesCombinations: parseMultiselectOptions(siteData.roff_substrate_c, substratesOptions),
				},
				true
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [siteData]);

	return (
		<section className="new-site-section">
			<Wrapper
				isLoaderVisible={isGradeListPending || iscompetitionListPending || isSiteDataFetching}
				isError={gradeListError || competitionListError || siteDataError}
			>
				<div className="container">
					<form onSubmit={formik.handleSubmit}>
						<ul className="new-site-wrapper row">
							<li className="form-control d-col d-col-2">
								<TextInput
									id="siteName"
									name="siteName"
									type="text"
									onBlur={formik.handleBlur}
									value={formik.values.siteName ?? ''}
									setFieldValue={formik.setFieldValue}
									label="Site Name"
									error={formik.touched.siteName && formik.errors.siteName ? formik.errors.siteName : null}
									disabled={!!(sfid && siteData?.name)}
									isAutoFocus
									required
								/>
							</li>

							<li className="form-control d-col d-col-2  location">
								<TextInput
									id="siteLocation"
									name="siteLocation"
									onBlur={formik.handleBlur}
									type="text"
									value={formik.values.siteLocation ?? ''}
									setFieldValue={formik.setFieldValue}
									label="Site Location"
									error={formik.touched.siteLocation && formik.errors.siteLocation ? formik.errors.siteLocation : null}
									disabled={!!(sfid && siteData?.site_location_c)}
									required
								/>
								<button
									type="button"
									aria-label="map link"
									className="icon-location map"
									onClick={handleOpenSiteLocationModal}
									disabled={!!(sfid && siteData?.site_location_c)}
								/>
							</li>

							<li className="d-col d-col-2  form-radio">
								<RadioButton
									name="glueUsedAtSite"
									title="Glue Used at Site"
									checked={formik.values.glueUsedAtSite ?? ''}
									radioOptions={glueUsedRadioTypeList}
									onChange={formik.handleChange}
									isDisabled={!!(sfid && siteData?.glue_used_at_the_site)}
									required
								/>
							</li>

							<li className="d-col d-col-2 ">
								<Dropdown
									id="gradeUsed"
									name="gradeUsed"
									label="Grade of Glue used at Site"
									onBlur={formik.handleBlur}
									title={formik.values.gradeUsed ?? ''}
									error={formik.touched.gradeUsed && formik.errors.gradeUsed ? formik.errors.gradeUsed : null}
									options={gradeOptions}
									setFieldValue={formik.setFieldValue}
									disabled={!!(sfid && siteData?.grade_glue_used_at_the_site)}
									required
								/>
							</li>

							{formik.values.glueUsedAtSite === 'Competition' && (
								<li className="d-col d-col-2 ">
									<Dropdown
										id="brandUsed"
										name="brandUsed"
										label="Brand of Glue used at Site"
										title={formik.values.brandUsed ?? ''}
										options={brandUsedList}
										setFieldValue={formik.setFieldValue}
										disabled={!!(sfid && siteData?.brand_of_glue_used_at_site)}
										onBlur={formik.handleBlur}
									/>
								</li>
							)}

							<li className="d-col d-col-2 ">
								<Dropdown
									id="contractType"
									name="contractType"
									label="Type of Contract"
									onBlur={formik.handleBlur}
									title={formik.values.contractType ?? ''}
									error={formik.touched.contractType && formik.errors.contractType ? formik.errors.contractType : null}
									options={typeOfContractor}
									setFieldValue={formik.setFieldValue}
									disabled={!!(sfid && siteData?.type_of_contract)}
									required
								/>
							</li>

							<li className="d-col d-col-2 single-line-multiselect">
								<MultiselectDropdown
									id="substratesCombinations"
									name="substratesCombinations"
									label="Are any of the below substrates combinations being used at site?"
									className="input-border single-lable-multi-dropdown"
									onBlur={formik.handleBlur}
									values={formik.values.substratesCombinations}
									options={substratesOptions}
									setFieldValue={formik.setFieldValue}
									maxLimit={maxLimit}
									onChange={handleMultiSelectChange}
									required
									disabled={!!(sfid && siteData?.roff_substrate_c)}
									error={
										formik.touched.substratesCombinations && formik.errors.substratesCombinations
											? formik.errors.substratesCombinations.toString()
											: null
									}
								/>
							</li>

							{formik.values.glueUsedAtSite?.toLowerCase() === 'competition' && (
								<li className="d-col d-col-2 ">
									<Dropdown
										id="competitionReason"
										name="competitionReason"
										label="Reason For Competition"
										onBlur={formik.handleBlur}
										title={formik.values.competitionReason ?? ''}
										error={
											formik.touched.competitionReason && formik.errors.competitionReason
												? formik.errors.competitionReason
												: null
										}
										options={competitionOptions}
										setFieldValue={formik.setFieldValue}
										disabled={!!(sfid && siteData?.reason_of_competition)}
										required
									/>
								</li>
							)}

							<li className="d-col d-col-2 ">
								<Dropdown
									id="contractorName"
									name="contractorName"
									label="Contractor Name"
									onBlur={(e) => {
										if (!e.target.value) {
											formik.setFieldValue('contractorNumber', '');
										}
										formik.handleBlur(e);
									}}
									title={formik.values.contractorName ?? ''}
									error={
										formik.touched.contractorName && formik.errors.contractorName ? formik.errors.contractorName : null
									}
									options={allEndUser.map(
										(user): DropdownOptions => ({
											id: user.sfid,
											name: user.sfid,
											value: user.name,
										})
									)}
									setFieldValue={formik.setFieldValue}
									disabled={!!formik.values.contractorName && !addNewSiteLead}
									onChange={(e) => {
										const user = allEndUser.find((user) => user.sfid === e.id);
										formik.setFieldValue('contractorNumber', user?.phone);
										setAddFields({
											name: user?.name,
											number: user?.phone,
											sfid: user?.sfid,
											membershipno: user?.membershipno,
										});
									}}
									required
								/>
							</li>

							<li className="d-col d-col-2 ">
								<Dropdown
									id="contractorNumber"
									name="contractorNumber"
									label="Contractor Number"
									onBlur={formik.handleBlur}
									title={formik.values.contractorNumber ?? ''}
									error={
										formik.touched.contractorNumber && formik.errors.contractorNumber
											? formik.errors.contractorNumber
											: null
									}
									options={allEndUser.map(
										(user): DropdownOptions => ({
											id: user.sfid,
											name: user.sfid,
											value: user.phone,
										})
									)}
									setFieldValue={formik.setFieldValue}
									disabled
									required
								/>
							</li>
						</ul>
					</form>

					<Modal
						modalId="googleMapModal"
						className="map-popup dialog-sm md-large pt-4"
						modalOpen={locationModal}
						onModalClose={handleCloseSiteLocationModal}
					>
						<GoogleMapModal handleConfirm={handleLocationSubmit} handleClose={handleCloseSiteLocationModal} />
					</Modal>
					<Stepper nextHandler={handleNext} isNext={!formik.isValid || !formik.dirty} />
				</div>
			</Wrapper>
		</section>
	);
};
