import { ReactElement, useRef } from 'react';
import { BottomDrawerModel, MultiDateRangePicker } from '@components/common';
import { ISiteSummaryFilterInitialValue, ISiteSummaryFiltersProps } from '@components/siteSummary/components';
import { useFormik } from 'formik';
import { useOutsideDialogClose } from '@hooks/useOutsideDialogClose';
import { siteStatusLists } from '@config/constant';
import { FilterInputLabelCheckboxField } from '@components/common/filterFormControls/filterInputLabelCheckboxField';
import { useSiteSummaryListingStore } from '@store/siteSummaryListingFilterStore';

export const SiteSummaryFilters = (props: ISiteSummaryFiltersProps): ReactElement => {
	const { openFilters, closeFilters } = props;
	const dialogRef = useRef<HTMLDivElement | null>(null);
	useOutsideDialogClose({ dialogRef, callBackFunction: closeFilters });
	const { updateFilter, resetSiteSummaryFilter, allSiteSummaryFilter } = useSiteSummaryListingStore();

	const formik = useFormik({
		initialValues: allSiteSummaryFilter,
		onSubmit: (values: ISiteSummaryFilterInitialValue): void => {
			updateFilter(values);
			closeFilters();
		},
	});

	const handleSubmit = (): void => {
		formik.handleSubmit();
	};

	const clearAll = (): void => {
		resetSiteSummaryFilter();
		closeFilters();
	};

	const isFilterChecked =
		formik.values.current_stage_of_site_c !== '' ||
		formik.values.lastactivitydate.every((item) => item?.toString().length) ||
		formik.values.competition_site ||
		formik.values.adopted_site;

	const handleCheckboxChangeEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (!e || !e.target) {
			return;
		}
		const name = e.target.name;
		const value = e?.target?.checked;
		formik.setFieldValue(name, value);
	};

	return (
		<BottomDrawerModel openModelStatus={openFilters} modelId="filter-main-wrapper">
			<div className="container">
				<button
					type="button"
					aria-label="filter popup"
					className="filter-sort-icon icon-filter"
					onClick={closeFilters}
				></button>
				<div ref={dialogRef} className="filter-drawer-inner-wrapper filter">
					<span className="filter-title">
						<p className="h2">Filter</p>
						<button
							className="clear-all"
							type="button"
							hidden={!isFilterChecked}
							onClick={clearAll}
							aria-label="Clear All"
						>
							Clear All
						</button>
					</span>
					<form>
						<div className="form-fieldset row">
							<div className="d-col">
								<span className="form-title h4">
									<span className="icon-hammer"></span>Visit Status (FTQ)
								</span>
								<ul className="custom-check primary">
									{siteStatusLists.map((status) => (
										<li key={status.label} className="custom-check-item">
											<FilterInputLabelCheckboxField<ISiteSummaryFilterInitialValue>
												id={status.label}
												name={status.name}
												formik={formik}
												formikValue={formik.values.current_stage_of_site_c}
												value={status.value}
												label={status.label}
											/>
										</li>
									))}
								</ul>
							</div>

							<div className="d-col">
								<span className="form-title h4">
									<span className="icon-date"></span>Date of Visit
								</span>
								<MultiDateRangePicker
									id="lastactivitydate"
									name="lastactivitydate"
									value={formik.values.lastactivitydate}
									formik={formik}
									maxDate={new Date()}
								/>
							</div>

							<div className="d-col">
								<div className="sites-label">
									<span className="form-title h4">
										<span className="icon-user"> </span>Competition Sites
									</span>

									<ul className="custom-toggle">
										<li className="custom-toggle-item">
											<input
												type="checkbox"
												checked={formik.values.competition_site}
												name="competition_site"
												id="competition_site"
												onChange={handleCheckboxChangeEvent}
											/>
											<label htmlFor="competition_site">
												<span className="visually-hidden">competition_site</span>
											</label>
										</li>
									</ul>
								</div>
							</div>

							<div className="d-col">
								<div className="sites-label">
									<span className="form-title h4">
										<span className="icon-user"> </span>Adopted User Sites
									</span>

									<ul className="custom-toggle">
										<li className="custom-toggle-item">
											<input
												type="checkbox"
												id="adopted_user_sites"
												name="adopted_site"
												checked={formik.values.adopted_site}
												onChange={handleCheckboxChangeEvent}
											/>
											<label htmlFor="adopted_user_sites">
												<span className="visually-hidden">Adopted User Sites</span>
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</form>
					<div className="d-col submit">
						<button
							type="submit"
							disabled={!isFilterChecked}
							onClick={handleSubmit}
							className="btn btn-primary btn-medium"
						>
							Submit
						</button>
					</div>
				</div>
			</div>
		</BottomDrawerModel>
	);
};
