import { RxCollectionCreator } from 'rxdb';

export const endUserListSchema: RxCollectionCreator = {
	schema: {
		title: 'end user list schema',
		description: 'End User List Schema',
		version: 0,
		primaryKey: 'id',
		type: 'object',
		properties: {
			id: { type: 'string', maxLength: 250 },
			name: { type: ['string', 'null'] },
			email_c: { type: ['string', 'null'] },
			phone: { type: ['string', 'null'] },
			sfid: { type: 'string' },
			mdicode: { type: ['string', 'null'] },
			wss_terri_code_c: { type: ['string', 'null'] },
			app_id_c: { type: ['string', 'null'] },
			producthierarchycode_c: { type: ['string', 'null'] },
			endusercategory_c: { type: ['string', 'null'] },
			personcontactid: { type: ['string', 'null'] },
			membershipno: { type: ['string', 'null'] },
		},
		required: ['id'],
	},
};
