import { RxCollectionCreator } from 'rxdb';

export const meetingTypeSchema: RxCollectionCreator = {
	schema: {
		title: 'Meeting type  schema',
		description: 'create a Meeting Type  schema',
		version: 0,
		primaryKey: 'sfid',
		type: 'object',
		properties: {
			id: {
				type: 'number',
			},
			sfid: { type: 'string', maxLength: 200 },
			name: { type: 'string' },
		},
		required: ['id', 'name', 'sfid'],
	},
};
