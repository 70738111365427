import { ReactElement, useEffect, useState } from 'react';
import { IMeetingModalProps } from '.';
import QRCode from 'react-qr-code';
import WhereToScan from '@assets/images/where-scan.png';

export const MeetingQrModal = (props: IMeetingModalProps): ReactElement => {
	const { dataObject } = props;
	const [qrValue, setQrValue] = useState<string>(dataObject?.sfid + '_' + Date.now());

	useEffect(() => {
		const interval = setInterval((): void => {
			return setQrValue(dataObject?.sfid + '_' + Date.now());
		}, 5000);
		return (): void => clearInterval(interval);
	}, [dataObject?.sfid]);

	return (
		<div className="about-pop-content">
			<div className="top-content">
				<div className="qr-code-image">
					{dataObject?.sfid && <QRCode value={qrValue} size={200} style={{ height: '200px' }} />}
				</div>

				<span className="h3 fcc-title">{dataObject?.name}</span>

				<p className="date-time">{dataObject?.startdate}</p>

				<div className="divider"></div>
			</div>

			<div className="bottom-content">
				<span className="qs-scan">whare to scan?</span>

				<div className="whare-to-scan">
					<img src={WhereToScan} alt="whare to scan" title="whare to scan" height="210" width="190" />
				</div>
			</div>
		</div>
	);
};
