import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { formatDate } from '@helper/utils';
import { IRedemptionQuery, QueryParams, RedemptionHistoryDetails } from '.';

export const useRedemptionHistoryQuery = (parameters: QueryParams): IQueryResponse<RedemptionHistoryDetails> => {
	const fetchRedemptionHistoryData = async (): Promise<RedemptionHistoryDetails> => {
		const queryParams: IRedemptionQuery = {
			mobile: parameters.mobile,
			format: 'json',
			limit: parameters.limit.toString(),
			offset: parameters.offset.toString(),
			number: parameters.search,
			points_breakup: 'true',
		};

		if (parameters.startDate && parameters.endDate) {
			queryParams.start_date = formatDate(parameters.startDate);
			queryParams.end_date = formatDate(parameters.endDate);
		}

		return await APIService.getData<RedemptionHistoryDetails>(APICONFIG.REDEMPTION_HISTORY, queryParams);
	};

	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['redemptionDetails', parameters],
		queryFn: fetchRedemptionHistoryData,
		enabled: !!parameters.mobile,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
