import { memo, ReactElement } from 'react';
import { ICheckbox } from '.';

const CheckBox = (props: ICheckbox): ReactElement => {
	const { id, type, name, onChange, checked, label, disabled, className = '' } = props;

	return (
		<div className={`cmn-checkbox form-check ${className}`}>
			<input type={type} name={name} checked={checked} onChange={onChange} id={id} disabled={disabled} />
			<label htmlFor={id}>{label}</label>
		</div>
	);
};

export default memo(CheckBox);
