import { ChangeEvent, ReactElement } from 'react';
import {
	CompetitionConversionHeader,
	ICompetitionConversionBreadCrumb,
} from '@components/competition-conversion/components/compeetitionHeader';
import { useCompetitionConversionStore } from '@store/competitionConversion';
import { TypeOfContractor } from '@components/competition-conversion/components/competitionConversionContract/index';
import { CompetitionNextActionButton } from '@components/competition-conversion/components/competitionNextActionButton';

export const CompetitionConversionContractor = (): ReactElement => {
	const { setTypeOfContract, type_of_contract, reason } = useCompetitionConversionStore();
	const breadCrumb: ICompetitionConversionBreadCrumb[] = [
		{
			name: reason,
			navLink: '/competition-conversion',
		},
		{
			name: 'Type of contract',
			navLink: '/competition-conversion/type-of-contractor',
		},
	];

	const handleContractor = (e: ChangeEvent<HTMLInputElement>): void => {
		setTypeOfContract(e.target.value);
	};

	return (
		<>
			<CompetitionConversionHeader title="Please select the type of contract." breadCrumb={breadCrumb} />
			<div className="competition-contract-wrapper">
				<div className="competition-contract-form">
					<ul className="row contract-card-list">
						{TypeOfContractor.map((item) => (
							<li key={item.name} className="competition-contract-card d-col d-col-2">
								<div className="cmn-radio card-details invisible-input-focus">
									<input
										type="radio"
										id={item.name}
										value={item.value}
										checked={type_of_contract === item.value}
										name="contractor"
										onChange={handleContractor}
										tabIndex={0}
									/>
									<label htmlFor={item.name}>
										<span className="hide">hide</span>
									</label>
									<div className="about-details">
										<span className={item.icon}></span>
										<p className="h4">{item.name}</p>
									</div>
								</div>
							</li>
						))}
					</ul>
				</div>

				<CompetitionNextActionButton
					buttonDisable={!type_of_contract}
					nextUrl="/competition-conversion/grade-of-competition"
				/>
			</div>
		</>
	);
};
