import { useCallback } from 'react';

export interface ScrollIntoView {
	handleFocus: () => void;
}

export const useScrollIntoView = (inputRef: React.RefObject<HTMLInputElement>): ScrollIntoView => {
	const handleFocus = useCallback((): void => {
		if (inputRef?.current) {
			inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [inputRef]);

	return { handleFocus };
};
