import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IMRCode } from '.';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { setRxdb } from '@helper/utils';

export const useIMRCodeListQuery = (): IQueryResponse<Array<IMRCode>> => {
	const { shouldFetch, isDbChecked } = useCheckRxdb('imr_code_list');

	const fetchIMRCodeData = async (): Promise<Array<IMRCode>> => {
		const response = await APIService.getData<Array<IMRCode>>(
			APICONFIG.IMR_CODE_LIST_DETAILS + `?limit=${0}&version=v2`
		);
		if (response) {
			await setRxdb('imr_code_list', response);
		}

		return response;
	};

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['IMRList'],
		queryFn: fetchIMRCodeData,
		enabled: isDbChecked && shouldFetch,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
