import { useGetRxDb } from '@hooks/getRxdbData';
import { ProfileData } from '@services/hooks/profile';
import { useProfileQuery } from '@services/hooks/profile/useProfileQuery';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

export const PcmsContentCard = (): ReactElement => {
	const { state } = useLocation();
	useProfileQuery();
	const { result: profile } = useGetRxDb<ProfileData>('profile');
	return (
		<div className="pcms-content-card">
			<div className="pcms-top-content row">
				<div className={`pcms-card-inner-wrapper d-col   ${state?.complaintNumber && 'd-col-6'}`}>
					<span className="pcms-card-heading">Complaint Owner</span>
					<span className="pcms-content">{profile?.[0]?.name}</span>
				</div>

				{state?.complaintNumber && (
					<div className="pcms-card-inner-wrapper d-col  d-col-6">
						<span className="pcms-card-heading">Complaint Number</span>
						<span className="pcms-content">{state?.complaintNumber}</span>
					</div>
				)}
			</div>

			<div className="pcms-bottom-content row">
				<div className="pcms-card-inner-wrapper d-col d-col-6">
					<span className="pcms-card-heading">Cluster</span>
					<span className="pcms-content">{profile?.[0]?.cluster_c}</span>
				</div>

				<div className="pcms-card-inner-wrapper d-col d-col-6">
					<span className="pcms-card-heading">Division</span>
					<span className="pcms-content">{profile?.[0]?.division_c ?? '--'}</span>
				</div>
			</div>
		</div>
	);
};
