export const dashboardBannerSchema = {
	title: 'Dashboard banner schema',
	description: 'Dashboard banner schema',
	version: 0,
	primaryKey: 'id',
	type: 'object',
	properties: {
		id: {
			type: 'string',
			maxLength: 250,
			primary: true,
		},
		name: {
			type: 'string',
		},
		banner_url_c: {
			type: 'string',
		},
		recordtypeid: {
			type: 'string',
		},
		redirect_link_c: { type: ['string', 'null'] },
		banner_type_c: { type: ['string', 'null'] },
	},
	required: ['id'],
};
