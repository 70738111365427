import { useGoogleMapStore } from '@store/googleMapStore';
import { ReactElement, useEffect, useRef, useState } from 'react';

declare global {
	interface Window {
		google: typeof google;
		initMap: () => void;
	}
}
interface IGoogleMap {
	setAddress: (val: string) => void;
}

export const GoogleMap = (props: IGoogleMap): ReactElement => {
	const { setAddress } = props;
	const mapDivRef = useRef<HTMLDivElement | null>(null);
	const { setCurrentAddress } = useGoogleMapStore();
	const lastZoomRef = useRef<number>(10);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const initMap = (): void => {
			if (mapDivRef.current) {
				const map = new window.google.maps.Map(mapDivRef.current, {
					center: { lat: -34.397, lng: 150.644 },
					zoom: 15,
					mapId: 'SITE_LEAD_MAP',
				});

				const marker = new google.maps.marker.AdvancedMarkerElement({
					map: map,
					gmpDraggable: true,
					content: new google.maps.marker.PinElement({
						scale: 0.8,
						background: '#EA4335',
						borderColor: '#B31412',
					}).element,
				});

				const getGeocode = (position: google.maps.LatLng | google.maps.LatLngLiteral): void => {
					const geocoder = new window.google.maps.Geocoder();
					geocoder.geocode({ location: position }, (results, status) => {
						if (status === 'OK' && results?.[0]) {
							setAddress(results[0].formatted_address);
							setCurrentAddress(results[0].formatted_address);
						} else {
							setError('Failed to get address. Please try again.');
						}
					});
				};

				const setPositionAndGeocode = (position: google.maps.LatLng | google.maps.LatLngLiteral): void => {
					marker.position = position;
					map.setCenter(position);
					getGeocode(position);
				};

				const getUserLocation = (): void => {
					if (navigator.geolocation) {
						const requestGeolocation = (): void => {
							navigator.geolocation.getCurrentPosition(
								(position) => {
									const pos = {
										lat: position.coords.latitude,
										lng: position.coords.longitude,
									};
									setPositionAndGeocode(pos);
									setError(null);
								},
								(error) => {
									if (error.code === error.PERMISSION_DENIED) {
										setError('Location access was denied. You can click on the map to set a location.');
									} else {
										setError('Failed to get your location. You can click on the map to set a location.');
									}
								},
								{ timeout: 10000, maximumAge: 60000 }
							);
						};
						requestGeolocation();

						navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
							if (permissionStatus.state === 'prompt') {
								setError('Please allow location access when prompted to auto-capture your position.');
							}

							// Listen for changes to the permission state
							permissionStatus.onchange = (): void => {
								if (permissionStatus.state === 'granted') {
									requestGeolocation();
								} else if (permissionStatus.state === 'denied') {
									setError('Location access was denied. You can click on the map to set a location.');
								}
							};
						});
					} else {
						setError('Geolocation is not supported by this browser. You can click on the map to set a location.');
					}
				};

				getUserLocation();

				map.addListener('click', (event: google.maps.MapMouseEvent) => {
					const clickedPos = event.latLng;
					if (clickedPos) {
						setPositionAndGeocode(clickedPos);
					}
				});

				marker.addListener('dragend', () => {
					const newPos = marker.position;
					if (newPos) {
						setPositionAndGeocode(newPos);
					}
				});

				map.addListener('zoom_changed', () => {
					const currentZoom = map.getZoom() ?? 0;
					const zoomDiff = currentZoom - lastZoomRef.current;

					if (Math.abs(zoomDiff) === 1) {
						map.setZoom(lastZoomRef.current + zoomDiff * 2);
					} else {
						lastZoomRef.current = currentZoom;
					}
				});
			}
		};

		if (window?.google?.maps) {
			initMap();
		} else {
			window.initMap = initMap;
		}
	}, [setAddress, setCurrentAddress]);

	return (
		<div>
			<div ref={mapDivRef} style={{ height: '40vh', width: '100%' }}></div>
			{error && <p className={`error-message ${error && 'show'}`}>{error}</p>}
		</div>
	);
};

export default GoogleMap;
