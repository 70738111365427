import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

Sentry.init({
	dsn: process.env.SENTRY_DNS_URL,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.replayIntegration(),
	],
	transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
	transportOptions: {
		dbName: 'mpowerwwa',
	},
	enabled: process.env.NODE_ENV === 'production',
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});
