import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';
import { ISiteSummaryList } from '@components/siteSummary';
import { ErrorHandler } from '@helper/errorHandler';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { setRxdb } from '@helper/utils';

export const useSiteSummaryListing = (): IQueryResponse<Array<ISiteSummaryList>> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('site_summary_list');
	const fetchSiteSummaryListing = async (): Promise<Array<ISiteSummaryList>> => {
		try {
			const response = await APIService.getData<Array<ISiteSummaryList>>(APICONFIG.SITE_SUMMARY_LISTING, {
				limit: '0',
			});
			if (response) {
				await setRxdb('site_summary_list', response);
			}
			return response;
		} catch (error) {
			ErrorHandler(error);
			return [];
		}
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['siteSummaryListing'],
		queryFn: fetchSiteSummaryListing,
		enabled: isDbChecked && shouldFetch,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
