import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';
import { setRxdb } from '@helper/utils';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { IDealerVisitProducts } from '@components/delaerVisit';

export const useDealerVisitProducts = (): IQueryResponse<IDealerVisitProducts[]> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('dealer_visit_products');
	const fetchDealerVisitProducts = async (): Promise<IDealerVisitProducts[]> => {
		const response = await APIService.getData<IDealerVisitProducts[]>(
			APICONFIG.DEALER_VISIT_PRODUCTS + `?limit=${0}&version=v2`
		);
		if (response) {
			await setRxdb('dealer_visit_products', response);
		}
		return response;
	};

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['dealerVisitProducts'],
		queryFn: fetchDealerVisitProducts,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
