import { create } from 'zustand';

interface DropdownOptions {
	id: number;
	value: string;
	name: string;
}
interface ICloseModal {
	isOpen: boolean;
	setModal: (state: boolean) => void;
	data: DropdownOptions[];
	setDropDownData: (
		data: {
			id: number;
			name: string;
			number: string;
		},
		state: DropdownOptions[]
	) => void;
	closeLeadObject: {
		id: number;
		name: string;
		number: string;
	};
}
const initialState = {
	closeLeadObject: {
		id: 0,
		name: '',
		number: '',
	},
	isOpen: false,
	data: [{ id: 0, value: '', name: '' }],
};

export const useCloseLeadsPopup = create<ICloseModal>((set) => ({
	...initialState,
	setModal: (item: boolean): void => set((state) => ({ ...state, isOpen: item })),
	setDropDownData: (data, item): void =>
		set((state) => ({ ...state, closeLeadObject: { id: data.id, name: data.name, number: data.number }, data: item })),
}));
