export const VideoGallerySchema = {
	title: 'Video Gallery Schema',
	description: 'create a video gallery schema',
	version: 0,
	primaryKey: 'sfid',
	type: 'object',
	properties: {
		id: {
			type: 'number',
		},
		sfid: { type: 'string', primary: true, maxLength: 200 },
		type_c: { type: ['string', 'null'] },
		category_c: { type: 'string' },
		name: { type: 'string' },
		view_count_c: { type: ['number', 'null'] },
		url_c: { type: 'string' },
		createddate: { type: 'string' },
		pidilite_content_name_c: { type: 'string' },
	},
	required: ['sfid'],
};
