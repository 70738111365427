import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { GetOtpDetails, GetOtpObject } from '@components/login';

interface IGetOtp {
	mutate: UseMutateFunction<GetOtpObject, Error, GetOtpDetails, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useGetOtpMutation = (): IGetOtp => {
	const getOtp = async (getOtpFormData: GetOtpDetails): Promise<GetOtpObject> => {
		const url = `${APICONFIG.LOGIN}/get-otp/`;
		return await APIService.postData<GetOtpObject>(url, getOtpFormData);
	};

	const { mutate, error, isError, isPending } = useMutation<GetOtpObject, Error, GetOtpDetails>({
		mutationFn: getOtp,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
