import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { CustomerObject, ICustomerQuery } from '@components/contractor360/components/redemptionHistory';

interface ICustomerData {
	mutate: UseMutateFunction<CustomerObject, Error, string>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useGetCustomerDetailsMutation = (): ICustomerData => {
	const fetchCustomerDetails = async (phoneNumber: string): Promise<CustomerObject> => {
		const queryParams: ICustomerQuery = {
			mobile: phoneNumber,
			format: 'json',
			points_summary: 'true',
			promotion_points: 'true',
		};
		return await APIService.getData<CustomerObject>(APICONFIG.CUSTOMER_HISTORY, queryParams);
	};

	const { mutate, error, isError, isPending } = useMutation<CustomerObject, Error, string>({
		mutationFn: fetchCustomerDetails,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
