import { useState, useEffect } from 'react';

export type IReturnWindowWidth = {
	width: number;
};

const useWindowWidth = (): IReturnWindowWidth => {
	const [windowWidth, setWindowWidth] = useState<number>(0);

	const handleResize = (): void => {
		setWindowWidth(window.innerWidth);
	};
	useEffect(() => {
		setWindowWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return (): void => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return {
		width: windowWidth,
	};
};

export default useWindowWidth;
