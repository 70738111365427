export const userCodeSchema = {
	title: 'End user Code schema',
	description: 'End user code Schema',
	version: 0,
	primaryKey: 'sfid',
	type: 'object',
	properties: {
		sfid: {
			type: 'string',
			maxLength: 250,
		},
		phone_number_c: {
			type: 'string',
		},
		name: {
			type: 'string',
		},
		mdicode_c: {
			type: 'string',
		},
		recordtypeid: {
			type: 'string',
		},
		recordtypename_c: {
			type: 'string',
		},
		usertype_c: {
			type: 'number',
		},
	},
	required: ['sfid', 'mdicode_c'],
};
