import { getDatabase } from '@rxdb/config/databaseConfig';
import { useEffect, useState } from 'react';

export interface UseRxdbReturnType {
	shouldFetch: boolean;
	isDbChecked: boolean;
}

const useCheckRxdb = (name: string): UseRxdbReturnType => {
	const [shouldFetch, setShouldFetch] = useState(true);
	const [isDbChecked, setIsDbChecked] = useState(false);

	const checkDb = async (): Promise<boolean> => {
		const db = await getDatabase();
		const result = await db[name].find().exec();
		return result.length > 0;
	};

	useEffect(() => {
		const checkDatabase = async (): Promise<void> => {
			const exists = await checkDb();
			setShouldFetch(!exists);
			setIsDbChecked(true);
		};
		checkDatabase();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { shouldFetch, isDbChecked };
};

export { useCheckRxdb };
