import { formatTime, roundToNearestQuarterHour } from '@helper/utils';
import { CalendarDetails, CalendarQuery } from '@services/hooks/calendar';
import { UseMutateFunction } from '@tanstack/react-query';

export interface CalenderPopUp {
	dateObject: { id: string; title: string; start: Date; end: Date; venu: string } | null;
	onClose: () => void;
	refetch: () => void;
	setData: (date: { startDate: string; endDate: string }) => void;
}
export interface InitialCalender {
	title: string;
	meeting_venue_c: string;
	startdate: Date | string;
	enddate: Date | string;
	start_time_c: string;
	end_time_c: string;
	id?: number;
}

const now = new Date();
const startDate = roundToNearestQuarterHour(now);
const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);

export const defaultCalendarValues: InitialCalender = {
	title: '',
	meeting_venue_c: '',
	startdate: new Date().toISOString(),
	enddate: endDate.toISOString(),
	start_time_c: formatTime(startDate),
	end_time_c: formatTime(endDate),
};

export interface ModalData {
	id: string;
	title: string;
	start: Date;
	end: Date;
	venu: string;
}

export interface ICalendarDetails {
	mutate: UseMutateFunction<CalendarDetails, unknown, CalendarQuery, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}
