import { Chart } from '@components/common';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BankingCategoryTab } from './bankingCategoryTab';
import { BankingProductItem } from './bankingProductItem';
import { BankData, IBankingCategoryProps, ProductGraphData } from '.';

export const BankingCategory = (props: IBankingCategoryProps): ReactElement => {
	const { bankingDetails, isSuccess } = props;
	const [isAll, setIsAll] = useState<boolean>(false);
	const [activeCategoryTab, setActiveCategoryTab] = useState('Banked');
	const [selectedProduct, setSelectedProduct] = useState<string | number>('');
	const [bankData, setBankData] = useState<BankData>();
	const productListRef = useRef<HTMLTableCellElement>(null);
	const productDataRef = useRef<HTMLDivElement>(null);
	const productCategories = useMemo(() => bankData?.data?.map((item) => item[0]), [bankData?.data]);
	const bankingGraphData = useMemo(
		() => bankingDetails?.data?.graph_data.filter((item) => item.product === selectedProduct),
		[bankingDetails?.data?.graph_data, selectedProduct]
	);

	const formatValue = (value?: string | number, unit?: string): string => {
		if (value === undefined || value === null || value === '') return '';

		const numValue = typeof value === 'string' ? parseFloat(value) : value;

		if (isNaN(numValue)) return '';

		if (Number.isInteger(numValue)) return `${numValue.toString()} ${unit}`;

		return `${numValue.toFixed(2)} ${unit}`;
	};

	const calculateQuarterlyTotals = useMemo((): ProductGraphData[] => {
		const line1Totals = [
			{ qt: '', fy: '', value: 0 },
			{ qt: '', fy: '', value: 0 },
			{ qt: '', fy: '', value: 0 },
			{ qt: '', fy: '', value: 0 },
		];
		const line2Totals = [
			{ qt: '', fy: '', value: 0 },
			{ qt: '', fy: '', value: 0 },
			{ qt: '', fy: '', value: 0 },
			{ qt: '', fy: '', value: 0 },
		];

		bankingDetails?.data?.graph_data.forEach((product) => {
			product.line1.forEach((item, index) => {
				line1Totals[index].value += item.value;
			});

			product.line2.forEach((item, index) => {
				line2Totals[index].value += item.value;
			});
		});

		return [
			{
				product: 'All',
				line1: line1Totals,
				line2: line2Totals,
			},
		];
	}, [bankingDetails?.data?.graph_data]);

	useEffect(() => {
		const productList = productListRef.current;
		const productData = productDataRef.current;

		if (productList && productData) {
			const handleScroll = (event: Event): void => {
				const target = event.target as HTMLElement;
				if (target === productList) {
					productData.scrollTop = target.scrollTop;
				}
				if (target === productData) {
					productList.scrollTop = target.scrollTop;
				}
			};

			productList.addEventListener('scroll', handleScroll);
			productData.addEventListener('scroll', handleScroll);

			return (): void => {
				productList.removeEventListener('scroll', handleScroll);
				productData.removeEventListener('scroll', handleScroll);
			};
		}
	}, []);

	useEffect(() => {
		if (isSuccess) {
			setBankData(bankingDetails?.data?.table_data.bank_data);
		}
	}, [bankingDetails, isSuccess]);

	useEffect(() => {
		if (bankData?.data?.length) {
			setSelectedProduct(bankData?.data[0][0]);
		}
	}, [activeCategoryTab, bankData]);

	const handleTabClick = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
			e.preventDefault();
			const tabName = e.currentTarget.getAttribute('data-tab')!;
			if (tabName === 'Banked') {
				setBankData(bankingDetails?.data?.table_data.bank_data);
			}
			if (tabName === 'Not-Banked') {
				setBankData(bankingDetails?.data?.table_data.non_bank_data);
			}
			setActiveCategoryTab(tabName);
		},
		[bankingDetails]
	);

	const handleProductItem = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
		e.preventDefault();
		const product = e.currentTarget.getAttribute('data-tab')!;
		setSelectedProduct(product);
		setIsAll(false);
	}, []);

	const handleAll = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>): void => {
			bankData && setSelectedProduct(e.target.checked ? '' : bankData?.data?.[0]?.[0]);
			setIsAll(e.target.checked);
		},
		[bankData]
	);

	return (
		<section className="category-product-details-section">
			<div className="container">
				<div className="category-product">
					<ul className="category-product-nav">
						<BankingCategoryTab label="Banked" activeCategoryTab={activeCategoryTab} handleTabClick={handleTabClick} />
						<BankingCategoryTab
							label="Not-Banked"
							activeCategoryTab={activeCategoryTab}
							handleTabClick={handleTabClick}
						/>
					</ul>
				</div>
			</div>

			<div className="product-list-table">
				<div className="table-container">
					<div className="product-data-table" ref={productDataRef}>
						<table aria-label="product details">
							<thead>
								<tr>
									<th>Category/ Product</th>
									{bankData?.header?.map((item, index) => <th key={`${item}-${index}`}>{item}</th>)}
								</tr>
							</thead>
							<tbody>
								{bankData?.data.map((data, rowIndex) => (
									<tr key={data[0]} className={selectedProduct === data[0] ? 'active' : ''}>
										{data.slice(1).map((item, index) => (
											<>
												{index === 0 && (
													<td ref={productListRef}>
														{productCategories && (
															<BankingProductItem
																key={`${productCategories[rowIndex]}-${index}`}
																product={productCategories[rowIndex]}
																selectedProduct={selectedProduct}
																handleProductItem={handleProductItem}
															/>
														)}
													</td>
												)}

												<td key={`${item}-${index}`}>
													<div className="inner-table-data">
														{formatValue(item, bankData?.header[index] === 'Growth' ? '%' : 'Kg')}
													</div>
												</td>
											</>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="banking-trade-graph">
					<span className="chart-label">
						<input type="checkbox" id="all" checked={isAll} onChange={handleAll} />
						<label htmlFor="all">All</label>
					</span>
					<h1 className="h3">Banking Trend YTD</h1>
					<h1 className="h3">({isAll ? 'All' : selectedProduct})</h1>
					<div className="chart-wrapper">
						<Chart
							chartData={isAll ? calculateQuarterlyTotals : bankingGraphData}
							allChartData={bankingDetails?.data?.graph_data}
							isAll={isAll}
							labels={bankingDetails?.data?.quarter_order}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};
