import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ISiteLead } from '.';
import useSiteLeadStore from '@store/siteLeadStore';
import { APICONFIG } from '@config/api.config';

interface IUpdateSiteLeadData {
	mutate: UseMutateFunction<ISiteLead, Error, ISiteLead, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useUpdatedNewSiteLead = (): IUpdateSiteLeadData => {
	const { siteId } = useSiteLeadStore();

	const updateSiteLeadData = async (siteLeadFormData: ISiteLead): Promise<ISiteLead> => {
		const url = `${APICONFIG.UPDATE_SITE_LEAD}${siteId}/`;
		return await APIService.putData<ISiteLead>(url, siteLeadFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: updateSiteLeadData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
