import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { IAddNewDataButton } from '@components/common';

export const AddNewDataButton = (props: IAddNewDataButton): ReactElement => {
	const { redirectUrl, labelName, handleClick } = props;

	const backRouteNavigation = (): void => {
		handleClick && handleClick();
	};

	return (
		<div className="container floting-component-wrapper">
			{redirectUrl ? (
				<NavLink to={redirectUrl} aria-label={labelName} className="floating-btn" onClick={backRouteNavigation}>
					<span className="icon-plus"></span>
				</NavLink>
			) : (
				<button aria-label={labelName} className="floating-btn" onClick={backRouteNavigation}>
					<span className="icon-plus"></span>
				</button>
			)}
		</div>
	);
};
