import { ReactElement, useMemo } from 'react';
import { MultiselectDropdown, TextInput } from '@components/common';
import { useSiteAllDetailsStore } from '@store/siteAllDetailsStore';
import { onImageError } from '@helper/utils';
import { useGetRxDb } from '@hooks/getRxdbData';
import { AvailableProductsList } from '@services/hooks/site-leads';
import { DropdownOptions } from '@components/common/formControl/dropdown';

export const SiteVisit = (): ReactElement => {
	const { visit } = useSiteAllDetailsStore();
	const { result: availableProductsDataList } = useGetRxDb<AvailableProductsList>('products');

	const availableProductsList = useMemo((): DropdownOptions[] => {
		if (availableProductsDataList?.length) {
			return availableProductsDataList.map((product, index) => ({
				id: index,
				name: product?.productgroup3description_c,
				value: product?.prdgroup3_c ?? '',
			}));
		}
		return [];
	}, [availableProductsDataList]);

	const availableProductsAtSite = useMemo(() => {
		const idArray = visit?.product_available_at_site?.split(',');
		return availableProductsList.filter((product) => idArray?.includes(product.value));
	}, [availableProductsList, visit?.product_available_at_site]);

	const images = useMemo(
		(): string[] =>
			Array.from({ length: 5 }, (_, i) => i + 1)
				.map((i) => visit?.[`visit_image${i}`])
				.filter(Boolean),
		[visit]
	);

	return (
		<section className="new-site-section">
			<div className="container">
				<form>
					<ul className="new-site-wrapper row">
						<li className="form-control d-col d-col-2">
							<TextInput
								id="demoedProducts"
								name="demoedProducts"
								type="text"
								value={visit?.product1_demoed_name ?? ''}
								label="Product Demoed"
								isAutoFocus
								disabled
							/>
						</li>
						<li className="form-control d-col d-col-2">
							<TextInput
								id="discussedProducts"
								name="discussedProducts"
								type="text"
								value={visit?.product1_discussed?.split('-')[1] ?? ''}
								label="Product Discussed"
								disabled
							/>
						</li>

						<li className="d-col d-col-2 site-product-images-wrapper">
							<div className="upload-images-wrapper">
								{images?.map((image: string) => (
									<div key={`${image}`} className="upload-image">
										<div className="wrapper-upload-images">
											<img
												role="presentation"
												src={image}
												alt={image}
												title="site"
												width="56"
												height="56"
												onError={onImageError}
											/>
										</div>
									</div>
								))}
							</div>
						</li>
						<li className="d-col d-col-2">
							<MultiselectDropdown
								id="availableProducts"
								name="availableProducts"
								label="Product Available at Site"
								className="input-border single-lable-multi-dropdown"
								values={availableProductsAtSite}
								options={availableProductsList}
								required
								disabled
							/>
						</li>
					</ul>
				</form>
			</div>
		</section>
	);
};
