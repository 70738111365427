import { ReactElement } from 'react';
import { IProductCard } from '@components/competition-conversion/components/competitionConversionProduct/index';
import { onImageError } from '@helper/utils';
import { PlayButton } from '@components/common';

export const ProductCard = (props: IProductCard): ReactElement => {
	const { recommendedProducts } = props;

	if (recommendedProducts?.data?.length === 0) {
		return (
			<li className="d-col d-col-2">
				<div className="product-details">
					<p className="h3">No Data Found!</p>
				</div>
			</li>
		);
	}

	return (
		<>
			{recommendedProducts?.data?.length &&
				recommendedProducts?.data[0]?.recommended_products?.length &&
				recommendedProducts?.data[0]?.recommended_products
					?.filter((value) => Object.keys(value).length !== 0)
					?.map((product, index: number) => {
						return (
							<li key={product?.product_name + index} className="d-col d-col-2">
								<div className="product-details">
									<div className="product-image">
										<picture>
											<img
												src={product?.image}
												onError={onImageError}
												alt={product?.product_name}
												title={product?.product_name}
												width="56"
												height="56"
											/>
										</picture>

										<PlayButton url={product.av_link} />
									</div>
									<p className="h3">{product?.product_name}</p>
									<span className="priority">Priority {index + 1}</span>
								</div>
							</li>
						);
					})}
		</>
	);
};
