import { BDEPermissions } from './BDEPermissions';
import { IMRPermission } from './IMRPermissions';
import { TSIPermission } from './TSIPermissions';

export type Role = 'BDE' | 'IMR' | 'TSI';

export interface IPermissionModel {
	name: string;
	route: string;
	components: string[];
}

export const RBAC_MODEL: Record<Role, IPermissionModel[]> = {
	BDE: BDEPermissions,
	IMR: IMRPermission,
	TSI: TSIPermission,
};
