import { ErrorMessageConfig } from '@config/error-message.config';
import { IQueryOption } from '@services/api/index';
import Cookies from 'universal-cookie';
import { authCookiesOption } from '../../authGuard';

const fetchWithInterceptors = async <T>(url: string, options: RequestInit, isTokenRequired: boolean): Promise<T> => {
	const cookie = new Cookies();
	// Get token from wherever you store it
	const token: string = localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? cookie.get('token');
	const headers: Record<string, string> = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};

	if (token && isTokenRequired) {
		headers.Authorization = `Bearer ${token}`;
	}

	const fetchOptions = {
		...options,
		headers: {
			...options.headers,
			...headers,
		},
	};

	try {
		const response = await fetch(url, fetchOptions);
		const accessToken = response?.headers?.get('x-access-token') ?? '';
		if (accessToken) {
			const cookie = new Cookies();
			cookie.set('token', accessToken, { ...authCookiesOption() });
		}

		const responseText = await response.text();

		if (!response.ok && response.status === 401) {
			localStorage.clear();
			sessionStorage.clear();
			const cookie = new Cookies();
			cookie.remove('role');
			cookie.remove('token');
			window.location.replace('/login');
		}

		if (!response.ok && response.status !== 404) {
			throw new Error(ErrorMessageConfig.NETWORK_ERROR);
		}

		if (!responseText) {
			throw new Error('Empty response received from the server.');
		}

		const result = JSON.parse(responseText);

		if (!isTokenRequired) {
			return result;
		}
		return await result;
	} catch (error) {
		if (error instanceof Error) {
			throw new Error(`Fetch error: ${error.message}`);
		} else {
			throw new Error(ErrorMessageConfig.NETWORK_ERROR);
		}
	}
};

export const APIService = {
	// Get data api call service
	getData: <T>(url: string, queryParams?: IQueryOption, signal?: AbortSignal): Promise<T> => {
		const options = signal ? { signal } : {};
		let apiURL = url;

		if (queryParams) {
			const params = new URLSearchParams();
			for (const [key, value] of Object.entries(queryParams)) {
				if (value !== undefined) {
					params.append(key, value.toString());
				}
			}
			apiURL += '?' + params.toString();
		}

		return fetchWithInterceptors(apiURL, options, true);
	},

	// Post data api call service
	postData: <T>(url: string, data: object, option?: Record<string, string>): Promise<T> => {
		const options: RequestInit = {
			method: 'POST',
			body: JSON.stringify(data),
		};
		if (option) {
			options.headers = option;
		}
		return fetchWithInterceptors(url, options, true);
	},

	// Put data api call service
	putData: <T>(url: string, data?: object | string | ArrayBuffer, headers?: Record<string, string>): Promise<T> => {
		let isRequiredToken = true;
		const options: RequestInit = {
			method: 'PUT',
			body: JSON.stringify(data),
		};
		if (headers) {
			options.headers = headers;
			isRequiredToken = false;
		}
		return fetchWithInterceptors(url, options, isRequiredToken);
	},

	// Patch data api call service
	patchData: <T>(url: string, data: object | string | ArrayBuffer, headers?: Record<string, string>): Promise<T> => {
		let isRequiredToken = true;
		const options: RequestInit = {
			method: 'PATCH',
			body: JSON.stringify(data),
		};
		if (headers) {
			options.headers = headers;
			isRequiredToken = false;
		}
		return fetchWithInterceptors(url, options, isRequiredToken);
	},

	// Delete data api call service
	deleteData: <T>(url: string): Promise<T> => {
		const options = {
			method: 'DELETE',
		};
		return fetchWithInterceptors(url, options, true);
	},
};
