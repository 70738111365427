import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { APPCONFIG } from '@config/app.config';
import { KycDetails } from '.';

export const useKYCDetailsQuery = (id: number | null, isEdit: boolean): IQueryResponse<KycDetails> => {
	const fetchKYCDetailsData = async (): Promise<KycDetails> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/wwa/lead-user/${id}/kyc_detail/`;
		return await APIService.getData<KycDetails>(url);
	};

	const fetchEditKYCDetailsData = async (): Promise<KycDetails> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/wwa/account/${id}/kyc_detail/`;
		return await APIService.getData<KycDetails>(url);
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['kycDetails'],
		queryFn: isEdit ? fetchEditKYCDetailsData : fetchKYCDetailsData,
		enabled: !!id,
		refetchOnWindowFocus: false,
	});

	return {
		data,
		error,
		isLoading,
		isFetching,
		isPending,
		refetch,
	};
};
