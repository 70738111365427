export type IKYCDetailsInitialValueProps = {
	document: string | null;
	document_number: string | null;
	IMR_Code: string | null;
	club?: string | null;
	document_url: string;
	profile_url: string;
	document_image: string | null;
	kyc_file_name?: string;
};

export const defaultKYCDetailFormInitialValues: IKYCDetailsInitialValueProps = {
	document: '',
	document_number: '',
	document_url: '',
	profile_url: '',
	IMR_Code: '',
	club: '',
	document_image: null,
	kyc_file_name: '',
};

export type TDocumentImage = {
	url?: string;
	imageName?: string;
};

export type TClubDetails = {
	id: number;
	value: string;
};
export type TIMRCodeDetails = {
	id: number;
	value: string;
};
