export const clubDropdownSchema = {
	title: 'Club Dropdown schema',
	description: 'create a Clubdropdown schema',
	version: 0,
	primaryKey: 'sfid',
	type: 'object',
	properties: {
		id: {
			type: 'number',
		},
		sfid: { type: 'string', primary: true, maxLength: 200 },
		name: { type: 'string' },
		clublocation: { type: 'string' },
		clubid: { type: 'string' },
	},
	required: ['id', 'name', 'sfid', 'clublocation', 'clubid'],
};
