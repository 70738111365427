import { IMeetingDetails } from '@components/meeting-details';
import { ReactElement } from 'react';

export const MeetingVenueThemeDetails = (props: IMeetingDetails): ReactElement => {
	const { data } = props;
	return (
		<div className="meeting-about-details">
			<ul className="about-list">
				<li className="row">
					<span className="d-col d-col-6 about-heading h4">Meeting Venue</span>
					<span className="d-col d-col-6 about-info" title={data?.meeting_venue_c}>
						{data?.meeting_venue_c}
					</span>
				</li>

				<li className="row">
					<span className="d-col d-col-6 about-heading h4">Meeting Type</span>
					<span className="d-col d-col-6 about-info" title={data?.meeting_type_c}>
						{data?.meeting_type_c}
					</span>
				</li>

				{data?.meeting_theme_c && (
					<li className="row">
						<span className="d-col d-col-6 about-heading h4">Meeting Theme</span>
						<span className="d-col d-col-6 about-info">
							<a href="/" aria-label="meeting-theme" className="theme-info" onClick={(e) => e.preventDefault()}>
								<span className="theme-title">{data?.meeting_theme_c}</span>
								<span className="icon-arrow-right-circle" />
							</a>
						</span>
					</li>
				)}
			</ul>
		</div>
	);
};
