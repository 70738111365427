import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { uploadCompressFile } from '@helper/fileUpload';

interface KYCFileData {
	mutate: UseMutateFunction<
		unknown,
		Error,
		{
			kycData: {
				url?: string;
				kycFileData?: File | null;
			};
			profileData: {
				url?: string;
				profileImageData?: File | null;
			};
		},
		unknown
	>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

interface IVariables {
	kycData: {
		url?: string;
		kycFileData?: File | null;
	};
	profileData: {
		url?: string;
		profileImageData?: File | null;
	};
}

export const useKYCFileUploadMutation = (): KYCFileData => {
	const postKYCFileData = async (variables: IVariables): Promise<unknown> => {
		const { kycData, profileData } = variables;

		const promises: Promise<unknown>[] = [];

		if (kycData.url && kycData.kycFileData) {
			promises.push(uploadCompressFile(kycData.kycFileData, kycData.url));
		}

		if (profileData.url && profileData.profileImageData) {
			promises.push(uploadCompressFile(profileData.profileImageData, profileData.url));
		}

		return Promise.all(promises);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: postKYCFileData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
