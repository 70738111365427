import { Value } from '@components/common/date-picker';
import { IOneToOneMeetingsList } from '@components/onetoonemeetings';
import { formatDateForQuery } from '@helper/utils';
import { useCallback, useMemo } from 'react';
import { MangoQuery, RxCollection, RxDocument } from 'rxdb';
import { useRxCollection, useRxData, useRxQuery } from 'rxdb-hooks';

export type IOneToOneMeetinsReturn = {
	result: RxDocument<IOneToOneMeetingsList>[];
	isFetching: boolean;
	fetchMore: () => void;
	totalCount: number;
};

export interface IOneToOneMeetingFilterProps {
	name: string;
	meetingRange?: Value;
}

export const useGetOneToOneMeetingRxdb = (props: IOneToOneMeetingFilterProps): IOneToOneMeetinsReturn => {
	const { name = '', meetingRange } = props;
	const collection = useRxCollection<IOneToOneMeetingsList>('one_to_one_meetings');

	const queryConstructor = useCallback(
		(collection: RxCollection<IOneToOneMeetingsList>) => {
			const querySelector: MangoQuery<IOneToOneMeetingsList> = {
				selector: name.trim() !== '' ? { user_name_c: { $regex: `.*${name}.*`, $options: 'i' } } : {},
				sort: [{ startdatetime: 'desc', id: 'desc' }],
			};

			if (querySelector.selector && Array.isArray(meetingRange) && meetingRange.length > 0) {
				const [startDate, endDate] = meetingRange;
				if (startDate && endDate) {
					querySelector.selector.startdatetime = {
						$gte: formatDateForQuery(startDate),
						$lte: formatDateForQuery(endDate),
					};
				}
			}

			return collection.find(querySelector);
		},
		[meetingRange, name]
	);

	const { result, isFetching, fetchMore } = useRxData<IOneToOneMeetingsList>('one_to_one_meetings', queryConstructor, {
		pageSize: 20,
		pagination: 'Infinite',
	});

	// Create the count query
	const countQuery = useMemo(() => {
		if (!collection) return undefined;
		return queryConstructor(collection).limit(0);
	}, [collection, queryConstructor]);

	// Use the count query
	const { result: countResult } = useRxQuery(countQuery);

	// Calculate the total count
	const totalCount = useMemo(() => {
		if (!countResult) return 0;
		return countResult.length;
	}, [countResult]);

	return {
		result,
		isFetching,
		fetchMore,
		totalCount,
	};
};
