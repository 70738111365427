/* eslint-disable jsx-a11y/media-has-caption */
import { useVideoPlayerStore } from '@store/videoPlayerStore';
import { ReactElement } from 'react';

export const VideoPlayer = (): ReactElement => {
	const { setVideoPlayerState, url } = useVideoPlayerStore();

	const handleClose = (): void => {
		setVideoPlayerState(false);
	};

	return (
		<div className="video-player-wrapper">
			<iframe className="video-frame" title="video" width="420" height="345" src={url}></iframe>

			<button className="close" onClick={handleClose}>
				Close
			</button>
		</div>
	);
};
