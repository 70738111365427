export interface ITabToggle {
	toggleTab: () => void;
}

export const useFooterToggle = (): ITabToggle => {
	const moveIndicator = (): void => {
		setTimeout(() => {
			const indicator = document?.querySelector('.bottombar-indicator') as HTMLElement;
			const tabsContainer = document?.querySelector('.mobile-tab') as HTMLElement;
			const activeTab = document?.querySelector('.bottombar.active') as HTMLElement;

			const tabsContainerRect = tabsContainer?.getBoundingClientRect();
			const bottomBarActiveTabRect = activeTab?.getBoundingClientRect();

			const translateX = bottomBarActiveTabRect?.left - tabsContainerRect?.left;

			if (!indicator || translateX === undefined) return;

			indicator.style.width = `${bottomBarActiveTabRect?.width}px`;
			indicator.style.transform = `translateX(${translateX}px)`;
		}, 100);
	};

	return {
		toggleTab: moveIndicator,
	};
};
