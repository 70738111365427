import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { MoreDetails } from '.';
import { APPCONFIG } from '@config/app.config';

interface IMoreDetails {
	mutate: UseMutateFunction<MoreDetails, Error, MoreDetails, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useMoreDetailsMutation = (id: number | null, isEdit?: boolean): IMoreDetails => {
	const postMoreDetailsData = async (moreDetailFormData: MoreDetails): Promise<MoreDetails> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/wwa/lead-user/${id}/business_detail/`;
		return await APIService.putData<MoreDetails>(url, moreDetailFormData);
	};
	const updateMoreDetailsData = async (basicFormData: MoreDetails): Promise<MoreDetails> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/wwa/account/${id}/business_detail/`;
		return await APIService.putData<MoreDetails>(url, basicFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: isEdit ? updateMoreDetailsData : postMoreDetailsData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
