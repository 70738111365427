import { IDealerVisits } from '@components/delaerVisit';
import { MangoQuery, RxCollection, RxDocument } from 'rxdb';
import { useRxData } from 'rxdb-hooks';

export type IDealerVisitReturn = {
	result: RxDocument<IDealerVisits>[];
	isFetching: boolean;
	fetchMore: () => void;
};

export const useDealerVisitRxdb = (): IDealerVisitReturn => {
	const { result, isFetching, fetchMore } = useRxData<IDealerVisits>(
		'dealer_visits',
		(collection: RxCollection<IDealerVisits>) => {
			const querySelector: MangoQuery<IDealerVisits> = {
				selector: {},
				sort: [{ createddate: 'desc' }],
			};

			return collection.find(querySelector);
		},
		{
			pageSize: 20,
			pagination: 'Infinite',
		}
	);

	return {
		result,
		isFetching,
		fetchMore,
	};
};
