import { ReactElement } from 'react';
import { useSiderToggleMenu } from '@store/useSiderToggleMenu';

export const FullPageLoader = (): ReactElement => {
	const { isFullPageLoader } = useSiderToggleMenu();
	if (!isFullPageLoader) return <></>;
	return (
		<div className="loading main-center">
			<span className="loading-dot"></span>
			<span className="loading-dot"></span>
			<span className="loading-dot"></span>
		</div>
	);
};
