import { ReactElement } from 'react';
import { NoDataFoundObject } from '.';

export const NoDataFound = (props: NoDataFoundObject): ReactElement => {
	const { message = 'No Data Found', id = 'no-data', className = 'd-col d-col-2' } = props;
	return (
		<li className={`${className}`} key={id}>
			<p className="h3">{message}</p>
		</li>
	);
};
