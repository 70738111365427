import { AllParticipantsObject } from '../attendedMembers';

export interface MeetingsPopUp {
	dateObject: { id: string };
	onClose: () => void;
	refetch: () => void;
	details?: QuickUserMemberObject;
	isForQuickUser?: boolean;
	participants?: AllParticipantsObject[];
}
export interface InitialQuickUser {
	firstName: string;
	lastName: string;
	primary_number: string;
	secondary_number: string;
	productHierarchyCode: string;
	catagories: string;
	designation: string;
	natureOfWork: string;
	userToBeLinked: string;
}
export const defaultQuickUser: InitialQuickUser = {
	firstName: '',
	lastName: '',
	primary_number: '',
	secondary_number: '',
	productHierarchyCode: '11',
	catagories: '',
	designation: '',
	natureOfWork: '',
	userToBeLinked: '',
};

export interface ModalData {
	id: string;
	title: string;
	start: Date;
	end: Date;
	venu: string;
}

export interface QuickUserMemberObject {
	firstName: string;
	lastName: string;
	primary_number: string;
	secondary_number: string | null;
	productHierarchyCode: string;
	leadId: string;
}
