import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { APPCONFIG } from '@config/app.config';
import { IDealerDetails } from '@components/delaerVisit';

export const useRetrieveDealerVisitDetails = (id: number): IQueryResponse<IDealerDetails> => {
	const fetchDealerVisitDetails = async (): Promise<IDealerDetails> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/dealer-visit/wwa/crud/${id}/`;
		return await APIService.getData<IDealerDetails>(url);
	};

	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['dealerDetails', id],
		queryFn: fetchDealerVisitDetails,
		enabled: !!id,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
