import { useCallback, useEffect, useState } from 'react';

export interface IClikedOutside {
	dropdownRef: React.RefObject<HTMLInputElement>;
	callback?: () => void;
}

export interface UseOutsideClickReturnType {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

export const useOutsideClick = ({ dropdownRef, callback }: IClikedOutside): UseOutsideClickReturnType => {
	const [isOpen, setIsOpen] = useState(false);

	const handleDropDownClick = useCallback(
		(e: MouseEvent): void => {
			if (dropdownRef.current?.contains(e.target as Node)) {
				setIsOpen(!isOpen);
			}
			callback && callback();
		},
		[callback, dropdownRef, isOpen]
	);
	useEffect(() => {
		document.addEventListener('mousedown', handleDropDownClick);
		return (): void => {
			document.removeEventListener('mousedown', handleDropDownClick);
		};
	}, [handleDropDownClick]);

	return { isOpen, setIsOpen };
};
