import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { ISiteStatusData } from '@components/contractor360/components/contractorNavigation';

interface ISiteStatusDetails {
	mutate: UseMutateFunction<ISiteStatusData, Error, ISiteStatusData, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useAddSiteStatus = (): ISiteStatusDetails => {
	const postSiteStatusData = async (siteData: ISiteStatusData): Promise<ISiteStatusData> => {
		return await APIService.postData<ISiteStatusData>(APICONFIG.ADD_SITE_STATUS, siteData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: postSiteStatusData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
