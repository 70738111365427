import { ReactElement, useEffect } from 'react';
import { checkForUpdates } from '../serviceWorkerRegistration';
import { useLocation } from 'react-router-dom';

export const CheckServiceWorkerUpdates = (): ReactElement => {
	const location = useLocation();
	useEffect(() => {
		checkForUpdates();
	}, [location]);

	return <></>;
};
