import { ReactElement, useCallback } from 'react';
import { IViewMore } from '.';
import { useGetMembershipNumber } from '@services/hooks/contractor360';
import { TransactionDetails } from '../qrCodeValidate';

export const ViewMore = (props: IViewMore): ReactElement => {
	const { item, setCouponCodeValidate, index } = props;
	const { mutate: fetchMembershipNumber, isPending } = useGetMembershipNumber();

	const handleViewMoreClick = useCallback(() => {
		fetchMembershipNumber?.(item.barCode, {
			onSuccess: (data: TransactionDetails) => {
				if (data?.response?.transactions?.transaction?.length > 0) {
					const responseObject = data?.response?.transactions?.transaction?.[0];

					setCouponCodeValidate((prevState) => {
						if (!prevState) return prevState;
						const updatedBarCodes = [...prevState.barCodes];
						if (updatedBarCodes[index].barCode === responseObject?.number) {
							updatedBarCodes[index] = {
								...updatedBarCodes[index],
								viewMore: true,
								membershipNumber: responseObject?.customer?.external_id,
							};
							return { ...prevState, barCodes: updatedBarCodes };
						}
					});
				}
			},
		});
	}, [fetchMembershipNumber, index, item.barCode, setCouponCodeValidate]);

	return (
		<>
			{((item.code === 700 && item.status === 'REDEMPTION_SUCCESSFUL') ||
				(item.code === 711 && item.status === 'BARCODE_HAS_BEEN_USED')) &&
				!item.success &&
				!item.viewMore && (
					<div className="view-more-btn">
						<button type="button" disabled={isPending} aria-label="view more" onClick={handleViewMoreClick}>
							<span className="view-more-content">View More </span> <span className="icon-right-arrow" />
						</button>
					</div>
				)}

			{item.viewMore && item?.membershipNumber && (
				<div className="status-info">
					<span className="status-name">Note</span>
					<span className="status-code">This code has been used by user of {item.membershipNumber} membership no</span>
				</div>
			)}
		</>
	);
};
