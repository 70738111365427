import { ReactElement } from 'react';
import '@assets/styles/pages/banking-details.scss';
import { BankingTertiaryDetails } from './bankingTertiaryDetails';
import { BankingCategory } from './bankingCategory';
import { useBankingDetailsQuery } from '@services/hooks/contractor360';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useLocation } from 'react-router-dom';

export const BankingDetails = (): ReactElement => {
	const { state } = useLocation();
	const { data: bankingDetails, isSuccess, isFetching, error } = useBankingDetailsQuery(state?.membershipNumber);

	const commonBankingDetailsProps = {
		bankingDetails,
		isSuccess,
	};

	return (
		<Wrapper isLoaderVisible={isFetching} isError={error}>
			<>
				<BankingTertiaryDetails {...commonBankingDetailsProps} />
				<BankingCategory {...commonBankingDetailsProps} />
			</>
		</Wrapper>
	);
};
