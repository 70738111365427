import { ReactElement } from 'react';
import TextInput from '@components/common/formControl/textInput/textInput';
import { IProjectDetailsProps } from '@components/siteSummaryDetails/components';
import { IInitialFormikSiteDetails } from '@components/siteSummaryDetails';
import { MultiselectDropdown } from '@components/common';
import { substratesOptions } from '@components/siteLeads/components/addNewSite';

export const ProjectDetails = (props: IProjectDetailsProps<IInitialFormikSiteDetails>): ReactElement => {
	const { formik } = props;
	return (
		<ul className="row">
			<li className="form-control d-col d-col-2">
				<TextInput
					id="name"
					name="name"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.name ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Site Name"
					error={formik.touched.name && formik.errors.name ? formik.errors.name : null}
					isAutoFocus
					disabled
					required
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<TextInput
					id="site_location_c"
					name="site_location_c"
					onBlur={formik.handleBlur}
					type="text"
					value={formik.values.site_location_c ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Site Location"
					error={formik.touched.site_location_c && formik.errors.site_location_c ? formik.errors.site_location_c : null}
					disabled
					required
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<TextInput
					id="glue_used_at_the_site"
					name="glue_used_at_the_site"
					onBlur={formik.handleBlur}
					type="text"
					value={formik.values.glue_used_at_the_site ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Glue Used at Site"
					error={
						formik.touched.glue_used_at_the_site && formik.errors.glue_used_at_the_site
							? formik.errors.glue_used_at_the_site
							: null
					}
					disabled
					required
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<TextInput
					id="grade_glue_used_at_the_site"
					name="grade_glue_used_at_the_site"
					onBlur={formik.handleBlur}
					type="text"
					value={formik.values.grade_glue_used_at_the_site ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Grade of Glue used at Site"
					error={
						formik.touched.grade_glue_used_at_the_site && formik.errors.grade_glue_used_at_the_site
							? formik.errors.grade_glue_used_at_the_site
							: null
					}
					disabled
					required
				/>
			</li>

			{formik.values.glue_used_at_the_site?.toLowerCase() === 'competition' && (
				<>
					<li className="form-control d-col d-col-2">
						<TextInput
							id="brand_of_glue_used_at_site"
							name="brand_of_glue_used_at_site"
							onBlur={formik.handleBlur}
							type="text"
							value={formik.values.brand_of_glue_used_at_site ?? ''}
							setFieldValue={formik.setFieldValue}
							label="Brand of Glue used at Site"
							error={
								formik.touched.brand_of_glue_used_at_site && formik.errors.brand_of_glue_used_at_site
									? formik.errors.brand_of_glue_used_at_site
									: null
							}
							disabled
							required
						/>
					</li>
					<li className="form-control d-col d-col-2">
						<TextInput
							id="reason_of_competition"
							name="reason_of_competition"
							onBlur={formik.handleBlur}
							type="text"
							value={formik.values.reason_of_competition ?? ''}
							setFieldValue={formik.setFieldValue}
							label="Reason for Competition"
							error={
								formik.touched.reason_of_competition && formik.errors.reason_of_competition
									? formik.errors.reason_of_competition
									: null
							}
							disabled
							required
						/>
					</li>
				</>
			)}

			<li className="form-control d-col d-col-2">
				<TextInput
					id="type_of_contract"
					name="type_of_contract"
					onBlur={formik.handleBlur}
					type="text"
					value={formik.values.type_of_contract ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Type of Contractor"
					error={
						formik.touched.type_of_contract && formik.errors.type_of_contract ? formik.errors.type_of_contract : null
					}
					disabled
					required
				/>
			</li>
			<li className="d-col d-col-2 single-line-multiselect">
				<MultiselectDropdown
					id="substratesCombinations"
					name="substratesCombinations"
					label="Are any of the below substrates combinations being used at site?"
					className="input-border single-lable-multi-dropdown"
					onBlur={formik.handleBlur}
					values={formik.values.substratesCombinations}
					options={substratesOptions}
					setFieldValue={formik.setFieldValue}
					required
					disabled
				/>
			</li>
			<li className="form-control d-col d-col-2">
				<TextInput
					id="veneer"
					name="veneer"
					onBlur={formik.handleBlur}
					type="text"
					value={formik.values.veneer ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Is MDF/Veneer being used at site?"
					error={formik.touched.veneer && formik.errors.veneer ? formik.errors.veneer : null}
					disabled
					required
				/>
			</li>
			<li className="form-control d-col d-col-2">
				<TextInput
					id="glue_potential"
					name="glue_potential"
					type="text"
					value={formik.values.glue_potential ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Glue Potential of Site"
					disabled
					required
				/>
			</li>
			<li className="form-control d-col d-col-2">
				<TextInput
					id="site_type"
					name="site_type"
					type="text"
					value={formik.values.site_type ?? ''}
					setFieldValue={formik.setFieldValue}
					label="Site Type"
					disabled
					required
				/>
			</li>
		</ul>
	);
};
