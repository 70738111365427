import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { AllParticipantsObject } from '../attendedMembers';

export interface ISummaryCard {
	value: number | null;
	label: string;
	path: string;
	cardKey: number;
	participants: AllParticipantsObject[] | [];
	id: string;
	placeHolder: string;
}
export const SummaryBoxCard = (props: ISummaryCard): ReactElement => {
	const { value, label, path, cardKey, placeHolder, participants, id } = props;

	const getIconColor = (index: number): string => {
		switch (index) {
			case 1:
				return 'not-invited';
			case 2:
				return 'invited';
			default:
				return '';
		}
	};
	return (
		<div className="summary-main-box">
			<div className="summary-card-wrapper">
				<NavLink
					aria-label="summary card link"
					className=""
					to={path}
					state={{ cardKey: cardKey, participants: participants, id: id }}
				>
					<div className="summary-number-details">
						<span className={`icon-user user-icon ${getIconColor(cardKey)}`}></span>
						<p className="summary-number h1">{value}</p>
						<p className="h4">
							{label}
							{placeHolder && <div className="light-text">({placeHolder})</div>}
						</p>
					</div>
					<div className="summary-about-details">
						<p className="h4">
							{label}
							{placeHolder && <div className="light-text">({placeHolder})</div>}
						</p>
						<p className="summary-number h1">{value}</p>
						<span className="icon-arrow-right-circle"></span>
					</div>
				</NavLink>
			</div>
		</div>
	);
};
