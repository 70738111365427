import { ReactElement } from 'react';

interface IBankingCategoryTab {
	activeCategoryTab: string;
	label: string;
	handleTabClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const BankingCategoryTab = (props: IBankingCategoryTab): ReactElement => {
	const { label, handleTabClick, activeCategoryTab } = props;
	return (
		<li className={activeCategoryTab === label ? 'active' : ''}>
			<a href="/" data-tab={label} aria-label="product banked tab" onClick={handleTabClick}>
				{label}
			</a>
		</li>
	);
};
