export interface ICompetitionConversionUsage {
	name: string;
	is_active: string;
	remarks: string;
}

export const UsageIconMap = {
	'Instant Gratification': 'icon-smiley',
	'Dealer Push': 'icon-dealer-push',
	'Cheaper Adhesive brought by Homeowner': 'icon-percentage',
	'Disgruntled user': 'icon-angry',
	'Better Competition Quality': 'icon-builder',
	'Builder choses adhesive': 'icon-builder',
	'Homeowner bought cheaper adhesive': 'icon-percentage',
	'Competition due to dealer push': 'icon-dealer-push',
	'FV Disgruntled': 'icon-angry',
	'Better Schemes of Competition Product': 'icon-smiley',
	'Lower price of competition product': 'icon-lower-price',
	'Superior quality of competition': 'icon-star-tag',
};

export * from './competitionConversionUsage';
