import { useFooterToggle } from '@hooks/footerDashboardToogle';
import { useSchemeDetails } from '@services/hooks/scheme';
import { ReactElement, useCallback, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ToastType, useTosterStore } from '@store/toster';
import { useSiderToggleMenu } from '@store/useSiderToggleMenu';
import { useComponentLevelAccessCheck } from '../../../authGuard/hooks/useComponentLevelAccessCheck';

export const Footer = (): ReactElement => {
	const { pathname } = useLocation();
	const { toggleTab } = useFooterToggle();
	const { refetch } = useSchemeDetails();
	const { setToaster } = useTosterStore();
	const { setFullPageLoader } = useSiderToggleMenu();
	const shouldShowIndicator =
		pathname === '/' ||
		pathname.substring(1).split('/').includes('end-users') ||
		pathname === '/site-summary' ||
		pathname === '/scheme';

	const checkMemberModulePermission = useComponentLevelAccessCheck('/end-users', true);
	const checkSiteSummaryModulePermission = useComponentLevelAccessCheck('/site-summary', true);

	useEffect(() => {
		toggleTab();
		window.addEventListener('resize', toggleTab);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const handleScheme = useCallback((): void => {
		setFullPageLoader(true);
		refetch?.()
			.then((result) => {
				setFullPageLoader(false);
				if (!result.data || result.data.length === 0) {
					setToaster(true, ToastType.error, 'No schemes Found!');
				} else {
					result.data.forEach((item) => {
						window.open(item.scheme_url, '_blank')?.focus();
					});
				}
			})
			.catch((error) => {
				setFullPageLoader(false);
				setToaster(true, ToastType.error, 'No schemes Found!');
				console.error('Error fetching schemes:', error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refetch]);

	return (
		<footer>
			<div className="container">
				<ul className="mobile-tab">
					<li className={`bottombar ${pathname === '/' ? 'active' : ''} `}>
						<NavLink to="/" aria-label="Dashboard">
							<span className="icon-home tab-icon"></span>
							<span className="footer-smalltext">Dashboard</span>
						</NavLink>
					</li>
					{checkMemberModulePermission && (
						<li className={`bottombar ${pathname.substring(1).split('/').includes('end-users') ? 'active' : ''} `}>
							<NavLink to="end-users" aria-label="end users">
								{pathname.substring(1).split('/').includes('end-users') ? (
									<span className="icon-user-filled tab-icon"></span>
								) : (
									<span className="icon-user tab-icon"></span>
								)}

								<span className="footer-smalltext">End Users</span>
							</NavLink>
						</li>
					)}
					{checkSiteSummaryModulePermission && (
						<li className={`bottombar ${pathname === '/site-summary' ? 'active' : ''} `}>
							<NavLink to="site-summary" aria-label="site leads">
								{pathname === '/site-summary' ? (
									<span className="icon-tool-filled tab-icon"></span>
								) : (
									<span className="icon-tool tab-icon"></span>
								)}

								<span className="footer-smalltext">Site Leads</span>
							</NavLink>
						</li>
					)}
					<li className={`bottombar ${pathname === '/scheme' ? 'active' : ''} `}>
						<button onClick={handleScheme}>
							{pathname === '/scheme' ? (
								<span className="icon-star-filled tab-icon"></span>
							) : (
								<span className="icon-star  tab-icon"></span>
							)}

							<span className="footer-smalltext">Scheme</span>
						</button>
					</li>
					{shouldShowIndicator && <li className="bottombar-indicator"></li>}
				</ul>
			</div>
		</footer>
	);
};
