import { ReactElement } from 'react';
import { IFilterInputLabelCheckboxFieldProps } from '@components/common/filterFormControls/filterInputLabelCheckboxField/index';

export const FilterInputLabelCheckboxField = <T,>(props: IFilterInputLabelCheckboxFieldProps<T>): ReactElement => {
	const { label, name, className, value, id, formikValue, formik } = props;

	const setFormikValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (!e || !e.target) {
			return;
		}
		const name = e.target.name;
		const value = e?.target?.value || '';
		formik.setFieldValue(name, value);
	};

	return (
		<label>
			<input
				id={id}
				className={className}
				type="checkbox"
				checked={formikValue === value}
				name={name}
				value={value}
				onChange={setFormikValue}
			/>
			{label && <span className="h4">{label}</span>}
		</label>
	);
};
