import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { APPCONFIG } from '@config/app.config';
import { MoreDetails } from '.';

export const useMoreDetailsQuery = (id: number | null, isEdit: boolean): IQueryResponse<MoreDetails> => {
	const fetchMoreDetailsData = async (): Promise<MoreDetails> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/wwa/lead-user/${id}/business_detail/`;
		return await APIService.getData<MoreDetails>(url);
	};

	const fetchEditMoreDetailsData = async (): Promise<MoreDetails> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/wwa/account/${id}/business_detail/`;
		return await APIService.getData<MoreDetails>(url);
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['moreDetails'],
		queryFn: isEdit ? fetchEditMoreDetailsData : fetchMoreDetailsData,
		enabled: !!id,
		refetchOnWindowFocus: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
