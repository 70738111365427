import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { setRxdb } from '@helper/utils';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { MeetingType } from '.';

export const useMeetingsTypesQuery = (): IQueryResponse<Array<MeetingType>> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('meeting_types');

	const fetchMeetingListData = async (): Promise<Array<MeetingType>> => {
		const response = await APIService.getData<Array<MeetingType>>(APICONFIG.MEETING_TYPE, {
			limit: '0',
		});
		if (response) {
			await setRxdb('meeting_types', response);
		}
		return response;
	};

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['MeetingType'],
		queryFn: fetchMeetingListData,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
