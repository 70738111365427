import { ReactElement } from 'react';
import '@assets/styles/pages/site-summary.scss';
import { useSiteSummaryQuery } from '@services/hooks/contractor360/useSiteSummaryQuery';
import { SiteSummaryCard } from './siteSummaryCard';
import { useLocation } from 'react-router-dom';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { Modal } from '@components/common/modal/modal';
import { useContractor360Store } from '@store/contractor360Store';
import { SiteStatusModal } from '@components/common';
import { useFormik } from 'formik';

export const SiteSummary = (): ReactElement => {
	const { state } = useLocation();
	const { isSiteStatusModalOpen, setSiteStatusModal } = useContractor360Store();
	const {
		data: siteSummmaryData,
		isFetching: isSiteSummaryFetching,
		error: siteSummaryError,
	} = useSiteSummaryQuery(state?.siteId);

	const formik = useFormik({
		initialValues: {
			siteStatus: '',
		},
		onSubmit: () => {},
	});
	return (
		<section className="site-summary-wrapper">
			<Wrapper isLoaderVisible={isSiteSummaryFetching} isError={siteSummaryError}>
				<div className="container">
					<ul className="site-summary-card row">
						{siteSummmaryData?.data?.length ? (
							siteSummmaryData?.data?.map((site) => {
								return <SiteSummaryCard key={site.id} site={site} />;
							})
						) : (
							<li className="d-col d-col-2">
								<div className="summary-info-main">
									<p className="h3">No Data Found!</p>
								</div>
							</li>
						)}
					</ul>
				</div>
			</Wrapper>
			<Modal
				modalId="siteStatus"
				className="dialog dialog-sm site-summary-status-popup close-site-popup pt-4"
				modalOpen={isSiteStatusModalOpen}
				onModalClose={() => setSiteStatusModal(false)}
			>
				<SiteStatusModal formik={formik} />
			</Modal>
		</section>
	);
};
