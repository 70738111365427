import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { BasicDetails } from '.';
import { APPCONFIG } from '@config/app.config';

export const useBasicDetailsQuery = (id: number | null, isEdit?: boolean): IQueryResponse<BasicDetails> => {
	const fetchBasicDetailsData = async (): Promise<BasicDetails> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/wwa/lead-user/${id}/basic_detail/`;
		return await APIService.getData<BasicDetails>(url);
	};
	const fetchEditBasicDetailsData = async (): Promise<BasicDetails> => {
		const url = `${APICONFIG.EDIT_BASIC_DETAILS}${id}`;
		return await APIService.getData<BasicDetails>(url);
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['basicDetails'],
		queryFn: isEdit ? fetchEditBasicDetailsData : fetchBasicDetailsData,
		enabled: !!id,
		refetchOnWindowFocus: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
