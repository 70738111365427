import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { DropdownListProps } from '.';

export const DropdownList = (props: DropdownListProps): ReactElement => {
	const { id, onChange, value, name, setFieldValue, title, options, setDropdownTitle, isFocused } = props;
	const listItemsRef = useRef<HTMLLIElement>(null);

	const handleChange = useCallback(
		(e: MouseEvent): void => {
			if (
				listItemsRef.current?.contains(e.target as Node) &&
				setFieldValue &&
				setDropdownTitle &&
				name &&
				options.length > 0
			) {
				setDropdownTitle(value);
				setFieldValue(name, value);
				onChange && onChange({ id: id, name: name, value: value });
			}
		},
		[id, name, onChange, options.length, setDropdownTitle, setFieldValue, value]
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleChange);
		return (): void => {
			document.removeEventListener('mousedown', handleChange);
		};
	}, [handleChange]);

	useEffect(() => {
		if (isFocused && listItemsRef.current) {
			listItemsRef.current.scrollIntoView({ block: 'nearest', inline: 'nearest' });
		}
	}, [isFocused]);

	return (
		<li
			key={id}
			className={`dropdown-item ${title === value ? 'active' : ''} ${isFocused ? 'dropdown-focus' : ''} `}
			ref={listItemsRef}
		>
			<span className="dropdown-item-content">{options.length > 0 ? value : 'No Data'}</span>
		</li>
	);
};
