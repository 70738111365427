import { ITabList } from '@components/common/navigator-tab';
export const endUserPaths = ['/end-users', '/end-users/new-leads', '/end-users/all'];
export const MemberTabList: ITabList[] = [
	{
		key: 1,
		name: 'Adopted Contractor',
		pathUrl: '/end-users',
		tabIcon: 'icon-user',
	},
	{
		key: 2,
		name: 'New Leads',
		pathUrl: '/end-users/new-leads',
		tabIcon: 'icon-user',
	},
	{
		key: 3,
		name: 'All',
		pathUrl: '/end-users/all',
		tabIcon: 'icon-user',
	},
];

export * from './endUserList';
