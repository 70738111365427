import { ICalculatorProductDetails, ISkuSlabCombination } from '@services/hooks/calculator';
import { useCallback, useState } from 'react';

export type UseSlabCombinationCalculatorReturn = {
	calculateSlabCombinations: () => (ISkuSlabCombination & { kgValueSum: number })[] | null;
};

const additionPoint = (
	item: ISkuSlabCombination & { kgValueSum: number },
	type: 'add' | 'subtract'
): { bonus: number; pointsPerKgLTR: number } => {
	const { kgValueSum, slab_from, slab_to, bonus_point, bonus_type, max_earnable_points } = item;
	let bonus = 0;
	let pointsPerKgLTR = 0;
	let earnedPoints = 0;

	if (kgValueSum >= slab_from && kgValueSum <= slab_to) {
		switch (bonus_type) {
			case 'Flat Bonus':
				bonus = bonus_point;
				break;
			case 'Points per KG/LTR':
				earnedPoints = kgValueSum * bonus_point;
				if (max_earnable_points !== null) {
					pointsPerKgLTR = Math.min(earnedPoints, max_earnable_points);
				} else {
					pointsPerKgLTR = earnedPoints;
				}
				break;
		}
	}

	return {
		bonus: type === 'add' ? bonus : -bonus,
		pointsPerKgLTR: type === 'add' ? pointsPerKgLTR : -pointsPerKgLTR,
	};
};

export const useSlabCombinationCalculator = (
	data?: ICalculatorProductDetails,
	setTotalPoints?: React.Dispatch<React.SetStateAction<number>>
): UseSlabCombinationCalculatorReturn => {
	const [prevProductData, setPrevProductData] = useState<(ISkuSlabCombination & { kgValueSum: number })[]>([]);

	const calculateSlabCombinations = useCallback(() => {
		if (!data?.sku_slab_combination.length) return null;

		const skuCodesInCombination = new Set(data.sku_slab_combination.flatMap((item) => item.sku_code));

		const skuToKgValueMap = new Map();
		const sortedAddedCode = new Set<string>();
		let totalBonus = 0;
		let totalPointsPerKgLTR = 0;

		data?.product_data.forEach((product) => {
			product.product_sku.forEach((sku) => {
				if ((sku.quantity ?? 0) > 0 && sku.sku_code && skuCodesInCombination.has(sku.sku_code)) {
					skuToKgValueMap.set(sku.sku_code, (skuToKgValueMap.get(sku.sku_code) || 0) + sku.quantity);
					sortedAddedCode.add(sku.sku_code);
				}
			});
		});

		prevProductData.forEach((code) => {
			const { bonus, pointsPerKgLTR } = additionPoint(code, 'subtract');
			totalBonus += bonus;
			totalPointsPerKgLTR += pointsPerKgLTR;
		});

		const result = data.sku_slab_combination
			.filter((item) => item.sku_code.some((code) => sortedAddedCode.has(code)))
			.map((item) => ({
				...item,
				kgValueSum: item.sku_code.reduce((sum, code) => sum + (skuToKgValueMap.get(code) || 0), 0),
			}));

		setPrevProductData(result);

		result.forEach((item) => {
			const { bonus, pointsPerKgLTR } = additionPoint(item, 'add');
			totalBonus += bonus;
			totalPointsPerKgLTR += pointsPerKgLTR;
		});

		setTotalPoints?.((prev: number) => prev + totalBonus + totalPointsPerKgLTR);
		return result;
	}, [data, setTotalPoints, prevProductData]);

	return { calculateSlabCombinations };
};
