import { formatDateDDMMYY, handlePreventDefault } from '@helper/utils';
import { useDebounce } from '@hooks/useDebounce';
import { ReactElement } from 'react';
import { IRedemptionFilter } from '.';
import { MultiDateRangePicker } from '@components/common';
import { NavLink } from 'react-router-dom';

export const RedemptionFilter = (props: IRedemptionFilter): ReactElement => {
	const { setSearch, date, setDate } = props;
	const handleSearch = useDebounce((e: React.ChangeEvent<HTMLInputElement>): void => {
		e.preventDefault();
		setSearch(e?.target?.value);
	}, 1000);

	const dateRangeText =
		Array.isArray(date) && date.length === 2 && date[0] instanceof Date && date[1] instanceof Date
			? `${formatDateDDMMYY(date[0])} to ${formatDateDDMMYY(date[1])}`
			: '';

	return (
		<section className="redemption-card-filter redemption-filter-section filter-section">
			<div className="container">
				<h2 className="h3">All Redemptions</h2>

				<div className="search-filter-wrapper">
					<div className="search-with-filter">
						<div className="search">
							<form onSubmit={handlePreventDefault}>
								<div className="search-row">
									<div className="input-col">
										<label htmlFor="search-top" className="hide">
											search
										</label>
										<input
											type="text"
											name="search"
											id="search-top"
											placeholder="Search Coupon"
											className="search-bar"
											autoComplete="off"
											onChange={handleSearch}
										/>
									</div>

									<button className="search-button" aria-label="search user">
										<span className="icon-search"></span>
									</button>
								</div>
							</form>
						</div>

						<div className="filters">
							<button className="filter-button filter-daterange-picker" aria-label="filter for user">
								<MultiDateRangePicker name="date" value={date} onChange={setDate} />
							</button>
						</div>
					</div>

					{dateRangeText && (
						<div className="filter-tags show">
							<ul className="tag-list">
								<li>
									{dateRangeText}
									<NavLink
										to="#"
										aria-label="disable tag"
										onClick={(e) => {
											e.preventDefault();
											setDate([null, null]);
										}}
									>
										<span className="icon-cross"></span>
									</NavLink>
								</li>
							</ul>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
