import { useCallback, useRef, useState, useEffect, ReactElement } from 'react';
import QrScanner from 'qr-scanner';
import { ToastType, useTosterStore } from '@store/toster';
import { TextInput } from '@components/common';
import '@assets/styles/pages/qr-code-scanner.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export const QRCodeScanner = (): ReactElement => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const scannerRef = useRef<QrScanner | null>(null);
	const { setToaster } = useTosterStore();
	const [scannedCode, setScannedCode] = useState<string[]>([]);
	const [coupon, setCoupon] = useState('');
	const { state } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const setupQrScanner = async (): Promise<void> => {
			if (videoRef.current) {
				scannerRef.current = new QrScanner(
					videoRef.current,
					(result: QrScanner.ScanResult) => {
						setScannedCode((prev) => {
							if (prev.includes(result.data)) {
								setToaster(true, ToastType.error, 'QR code already scanned');

								return prev;
							}
							return [...prev, result.data];
						});
					},

					{
						highlightScanRegion: true,
						maxScansPerSecond: 1,
						highlightCodeOutline: true,
						returnDetailedScanResult: true,
					}
				);

				try {
					await scannerRef.current.start();
				} catch (err) {
					setToaster(true, ToastType.error, 'Unable to start Camera');
				}
			}
		};

		setupQrScanner();

		return (): void => {
			if (scannerRef.current) {
				scannerRef.current.stop();
				scannerRef.current.destroy();
				scannerRef.current = null;
			}
		};
	}, [setToaster]);

	const handleMore = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.preventDefault();
			if (coupon.trim().length > 0) {
				setScannedCode((prev) => {
					if (prev.includes(coupon)) {
						setToaster(true, ToastType.error, 'QR code already added');
						return prev;
					}
					setCoupon('');
					return [...prev, coupon.trim()];
				});
			}
		},
		[coupon, setToaster]
	);

	const handleNavigate = useCallback((): void => {
		if (scannerRef.current) {
			scannerRef.current.stop();
		}
		navigate('/contractor/qr-code-check', {
			state: {
				number: state?.number,
				codes: scannedCode,
			},
		});
	}, [navigate, scannedCode, state?.number]);

	return (
		<section className="qr-scanner-section">
			<div className="container">
				<div className="qr-scanner-wrapper">
					<div className="video-wrapper">
						<video ref={videoRef} style={{ width: '100%' }} autoPlay muted />
					</div>

					<span className="h4 or">OR</span>

					<form>
						<div className="popup-content">
							<div className="input-container">
								<div className="form-control">
									<TextInput
										name="coupon"
										placeholder="Enter Coupon code"
										value={coupon}
										onChange={(e) => {
											setCoupon(e.target.value);
										}}
									/>
								</div>
							</div>
							<div className="add-btn">
								<button type="button" className="add-button" onClick={(e) => handleMore(e)}>
									+
								</button>
							</div>
						</div>
					</form>
					<ul className="item-list">
						{scannedCode.map((item, index) => (
							<li key={item}>
								<span className="item-number">{index + 1}.</span>
								<span className="item-code">{item}</span>
							</li>
						))}
					</ul>
					<div className="submit-btn">
						<button
							className="btn  btn-primary btn-medium"
							onClick={handleNavigate}
							disabled={scannedCode?.length === 0}
						>
							Submit
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};
