import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { IOneToOneMeetingsList } from '@components/onetoonemeetings';
import { ErrorHandler } from '@helper/errorHandler';

interface IMeetingsData {
	mutate: UseMutateFunction<IOneToOneMeetingsList, Error, IOneToOneMeetingsList, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useOneToOneMeetingMutation = (meetingId: number): IMeetingsData => {
	const createOneToOneMeeting = async (meetingData: IOneToOneMeetingsList): Promise<IOneToOneMeetingsList> => {
		try {
			return await APIService.postData<IOneToOneMeetingsList>(`${APICONFIG.ONE_TO_ONE_MEETINGS_LIST}/`, meetingData);
		} catch (error) {
			ErrorHandler(error);
			return {};
		}
	};

	const updateOneToOneMeeting = async (meetingData: IOneToOneMeetingsList): Promise<IOneToOneMeetingsList> => {
		try {
			return await APIService.putData<IOneToOneMeetingsList>(
				`${APICONFIG.ONE_TO_ONE_MEETINGS_LIST}/${meetingId}/`,
				meetingData
			);
		} catch (error) {
			ErrorHandler(error);
			return {};
		}
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: meetingId ? updateOneToOneMeeting : createOneToOneMeeting,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
