import { formatDateDDMMYY } from '@helper/utils';
import { ReactElement, useCallback } from 'react';
import { MeetingsObject } from '..';
import { NavLink, useLocation } from 'react-router-dom';
import { useNonAttendedMembersStore } from '@store/nonAttendedMembersStore';
import { useGetRxDb } from '@hooks/getRxdbData';
import { ProfileData } from '@services/hooks/profile';

export const MeetingModuleCard = (props: { data: MeetingsObject }): ReactElement => {
	const { club_name_c, meeting_type_c, name, startdate, status, id, meeting_theme_c } = props.data;
	const { result: profile } = useGetRxDb<ProfileData>('profile');
	const { pathname } = useLocation();
	const { resetMembersStore } = useNonAttendedMembersStore();

	const getPathData = useCallback((name: string | null) => {
		switch (name) {
			case 'MAR(Today Meeting)':
				return 'today-meeting';
			case 'Scheduled Meeting':
			case null:
			case '':
				return 'scheduled-meeting';
			case 'Completed Meeting':
				return 'meeting-completed';
			default:
				return 'meeting-conducted';
		}
	}, []);

	return (
		<li className="d-col d-col-2">
			<NavLink
				to={
					getPathData(status) === 'today-meeting'
						? '/today-meetings'
						: getPathData(status) === 'meeting-conducted'
							? '/meetings-conducted'
							: '/meeting-details'
				}
				aria-label="add meeting"
				className="meeting-module-card"
				state={{ id: id, status: getPathData(status) }}
				onClick={() => resetMembersStore()}
			>
				<div className="meeting-upper-details">
					<div className="left-content">
						<span className="meeting-id">{name ?? `FV-${profile[0]?.mdicode_c}`}</span>
						{meeting_theme_c && (
							<span className="theme" title={meeting_theme_c ?? '-'}>
								Theme:{meeting_theme_c ?? '--'}
							</span>
						)}
					</div>

					<div className="date-details">
						<span className="date">{formatDateDDMMYY(new Date(startdate))}</span>
					</div>
				</div>

				<p className="club">{club_name_c}</p>
				<div className="meeting-bottom-wrapper">
					{meeting_type_c && (
						<div className="meeting-bottom-details top-content">
							<span className="meeting-about h4">{meeting_type_c ?? '-'}</span>
						</div>
					)}
					{pathname === '/meetings/all-meetings' && status && (
						<div className="meeting-bottom-details">
							<span className={`meeting-status ${getPathData(status)}`}>{status ?? '-'}</span>
						</div>
					)}
					<span className="icon-arrow-right-circle" />
				</div>
			</NavLink>
		</li>
	);
};
