import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';

import { APICONFIG } from '@config/api.config';
import { PcmsObject } from '@components/pcms/components/addEditPcmsRegistrationForm';

interface IPcmsDetails {
	mutate: UseMutateFunction<PcmsObject, Error, PcmsObject, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const usePcmsMutation = (id?: number | null): IPcmsDetails => {
	const postPcmsDetailsData = async (pcmsFormData: PcmsObject): Promise<PcmsObject> => {
		const url = `${APICONFIG.PCMS}/details/`;
		return await APIService.postData<PcmsObject>(url, pcmsFormData);
	};
	const putPcmsDetails = async (pcmsFormData: PcmsObject): Promise<PcmsObject> => {
		const url = `${APICONFIG.PCMS}/details/${id}/`;
		return await APIService.putData<PcmsObject>(url, pcmsFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: id ? putPcmsDetails : postPcmsDetailsData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
