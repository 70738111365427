import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { GluePotentialData } from '.';

export const useGluePotentialQuery = (
	sites_worked?: string,
	sites_competition?: string,
	average_glue?: string,
	membershipNumber?: string
): IQueryResponse<GluePotentialData> => {
	const fetchGluePotentialData = async (): Promise<GluePotentialData> => {
		return await APIService.getData<GluePotentialData>(APICONFIG.GLUE_POTENTIAL, {
			visited_sites: sites_worked || '',
			competition_used: sites_competition || '',
			glue_consumption: average_glue || '',
			membership_no: membershipNumber || '',
		});
	};
	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['gluePotential', sites_worked, sites_competition, average_glue, membershipNumber],
		queryFn: fetchGluePotentialData,
		enabled: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
