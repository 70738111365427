import { useFormik } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { defaultKYCDetailFormInitialValues, TClubDetails, TIMRCodeDetails } from '.';
import { documents, maxFileSize } from '@config/constant';
import { Dropdown, SubmitButton, TextInput } from '@components/common';
import { useValidation } from '@hooks/validations';
import profileImage from '@assets/images/profile.png';
import { useKYCDetailsQuery } from '@services/hooks/enduser-details/useKYCDetailsQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIMRCodeListQuery } from '@services/hooks/enduser-details/useIMRCodeListQuery';
import { useActiveGliderClick } from '@hooks/useActiveGlider';
import { useEndUserStore } from '@store/enduser';
import { useKYCDetailsMutation } from '@services/hooks/enduser-details/useKYCDetailMutation';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useKYCFileUploadMutation } from '@services/hooks/enduser-details/useKYCFileUploadMutation';
import { onDownload } from '@helper/utils';
import { ToastType, useTosterStore } from '@store/toster';
import { useGetKycDocumentPresignedUrl } from '@services/hooks/enduser-details/useGetKycDocumentPresignedUrl';
import { useGetKycProfilePresignedUrl } from '@services/hooks/enduser-details/useGetKycProfilePresignedUrl';
import { useGetRxDb } from '@hooks/getRxdbData';
import { ClubDetails, IMRCode } from '@services/hooks/enduser-details';
import { useClubDetailsQuery } from '@services/hooks/enduser-details/useClubDetailsQuery';

export const KYCDetailsForm = (): ReactElement => {
	const nav = useNavigate();
	const { state } = useLocation();
	const { setToaster } = useTosterStore();
	useIMRCodeListQuery();
	useClubDetailsQuery();
	const profileImageRef = useRef<HTMLImageElement>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const { setActiveNavTab, isEditing, setIsEditing, isFormEdit, formId } = useEndUserStore();
	const {
		data: getKYCFormData,
		isFetching: isGetKycFormFetching,
		error: isGetKycFormError,
	} = useKYCDetailsQuery(formId, isFormEdit);
	const { mutate, isPending: isKYCFormPending } = useKYCDetailsMutation(formId, isFormEdit);
	const { result: clubDetails } = useGetRxDb<ClubDetails>('club');
	const { result: IMRCodeList } = useGetRxDb<IMRCode>('imr_code_list');
	const { KYCDetailsValidationSchema } = useValidation();
	const { handleTabClick } = useActiveGliderClick();
	const [kycFileData, setkycFileData] = useState<File | null>();
	const [profileImageData, setProfileImageData] = useState<File | null>();
	const {
		mutate: mutateDocumentPresignedURL,
		data: kycDocumentPresignedURL,
		isPending: isKycDocumentUploadPending,
	} = useGetKycDocumentPresignedUrl();
	const {
		mutate: mutateProfilePresignedURL,
		data: kycProfilePresignedURL,
		isPending: isKycProfileUploadPending,
	} = useGetKycProfilePresignedUrl();
	const { mutate: putKycFileUpload, isPending: isKycFileUploadPending } = useKYCFileUploadMutation();

	const formik = useFormik({
		initialValues: defaultKYCDetailFormInitialValues,
		onSubmit: () => {
			handleNext();
		},
		validationSchema: KYCDetailsValidationSchema,
	});

	const IMRCodeListData = useMemo((): TIMRCodeDetails[] => {
		if (IMRCodeList?.length) {
			return IMRCodeList?.map((imrCode) => ({
				id: imrCode.id,
				value: imrCode.imr_code_c,
			}));
		}
		return [];
	}, [IMRCodeList]);

	const clubList = useMemo((): TClubDetails[] => {
		if (clubDetails?.length > 0) {
			return clubDetails?.map((item) => ({
				id: item.id,
				value: item.name,
			}));
		}
		return [];
	}, [clubDetails]);

	useEffect(() => {
		if (!formId && getKYCFormData?.status !== 'Basic Details') {
			nav('/add-member-details/basic-details');
		}
	}, [formId, nav, getKYCFormData]);

	const handleNext = useCallback(() => {
		const clubId = clubList?.find((c) => c.value === formik.values.club)?.id;
		const payload = {
			idproof: formik.values.document,
			idproofnumber: formik.values.document_number,
			documenturl: kycDocumentPresignedURL?.[0]?.normal_url || formik.values.document_url,
			club_mapping: clubId || null,
			imrcode_c: formik.values.IMR_Code?.length ? formik.values.IMR_Code : null,
			profile_image_url_c: kycProfilePresignedURL?.[0]?.normal_url || formik.values.profile_url,
			status: 'More details',
		};
		const variables = {
			kycData: {
				url: kycDocumentPresignedURL?.[0].pre_signed_url,
				kycFileData: kycFileData,
			},
			profileData: {
				url: kycProfilePresignedURL?.[0].pre_signed_url,
				profileImageData: profileImageData,
			},
		};
		if (formId) {
			putKycFileUpload(variables, {
				onSuccess: () => {
					mutate(payload, {
						onSuccess: (data) => {
							if (data.id) {
								const navigateUrl = `/${isFormEdit ? 'edit' : 'add'}-member-details/more-details`;
								setActiveNavTab([0, 1, 2]);
								nav(navigateUrl, {
									state: {
										membershipNumber: state?.membershipNumber,
									},
								});
								handleTabClick(2);
							}
						},
					});
				},
			});
		}
	}, [
		clubList,
		formik.values,
		kycDocumentPresignedURL,
		kycProfilePresignedURL,
		formId,
		putKycFileUpload,
		kycFileData,
		profileImageData,
		mutate,
		isFormEdit,
		setActiveNavTab,
		nav,
		state?.membershipNumber,
		handleTabClick,
	]);

	// TODO:: Need to create common function to upload file Ankit.
	const handleFileUpload = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, folder: string, setFileData?: (file: File) => void): void => {
			const file = event.target.files?.[0] as File;
			if (!file) return;

			if (!file.type.startsWith('image/')) {
				setToaster && setToaster(true, ToastType.error, `Please upload an image file.`);
				event.target.value = '';
				return;
			}

			if (maxFileSize && file.size > maxFileSize) {
				setToaster && setToaster(true, ToastType.error, `File size exceeds 5MB limit. Please choose a smaller file.`);
				event.target.value = '';
				return;
			}

			setFileData && setFileData(file);

			const payload = [
				{
					folder,
					filename: file.name,
				},
			];

			if (folder === 'profile') {
				mutateProfilePresignedURL && mutateProfilePresignedURL(payload);
				formik.setFieldValue('document_image', URL.createObjectURL(file));
			}

			if (folder === 'kyc') {
				mutateDocumentPresignedURL &&
					mutateDocumentPresignedURL(payload, {
						onSuccess: (data) => {
							if (data.length) {
								formik.setFieldValue('kyc_file_name', data[0]?.normal_url?.split('-').pop());
							}
						},
					});
			}
		},
		[setToaster, mutateProfilePresignedURL, formik, mutateDocumentPresignedURL]
	);

	const handleImageChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>): void => {
			handleFileUpload(event, 'profile', setProfileImageData);
		},
		[handleFileUpload]
	);

	const handleFileChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>): void => {
			handleFileUpload(event, 'kyc', setkycFileData);
		},
		[handleFileUpload]
	);

	useEffect(() => {
		if (formId && getKYCFormData && Object.keys(getKYCFormData).length > 0) {
			formik.setValues({
				...formik.values,
				document: getKYCFormData.idproof,
				document_number: getKYCFormData.idproofnumber,
				club: getKYCFormData?.clubname_c,
				IMR_Code: getKYCFormData.imrcode_c,
				document_url: getKYCFormData.documenturl,
				profile_url: getKYCFormData.profile_image_url_c,
				kyc_file_name: getKYCFormData.documenturl?.split('-').pop(),
				document_image: getKYCFormData.profile_image_url_c,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getKYCFormData, formId]);

	const handleSubmitClick = (): void => {
		formik.handleSubmit();
	};

	const handleRemoveKycProof = useCallback((): void => {
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
		formik.setFieldValue('kyc_file_name', '');
	}, [formik]);

	const handleDownloadFile = useCallback((): void => {
		onDownload(formik.values.document_url);
	}, [formik.values.document_url]);

	return (
		<div className="kyc-details-wrapper">
			<Wrapper isLoaderVisible={isGetKycFormFetching} isError={isGetKycFormError}>
				<form onSubmit={formik.handleSubmit}>
					<ul className="kyc-details-list row">
						<li className="d-col">
							<div className="profile-image">
								<div className="user-image">
									<img
										ref={profileImageRef}
										src={formik.values.document_image ? formik.values.document_image : profileImage}
										alt="profile img"
										title="profile img"
										width="120"
										height="120"
									/>
								</div>

								<div className={`upload-image ${isEditing && 'disable-btn'}`}>
									<label htmlFor="image-upload">
										<span className="icon-edit-2"></span>
										<span className="hide">image upload</span>
									</label>
									<input
										type="file"
										name="profile-image"
										id="image-upload"
										accept="image/*,application/pdf"
										disabled={isEditing || isKycProfileUploadPending}
										onChange={handleImageChange}
									/>
								</div>
							</div>
							{formik.touched.document_image && formik.errors.document_image ? (
								<p className="error-message text-center show">{formik.errors.document_image}</p>
							) : null}
						</li>

						<li className="d-col d-col-2">
							<Dropdown
								id="document"
								name="document"
								label="Select Document"
								title={formik.values.document ?? ''}
								onBlur={formik.handleBlur}
								error={formik.touched.document && formik.errors.document ? formik.errors.document : null}
								options={documents}
								setFieldValue={formik.setFieldValue}
								disabled={isEditing}
								required
							/>
						</li>

						<li className="d-col d-col-2">
							<div className={`file-upload ${isEditing && 'disable-btn'}`}>
								<div className="file-upload-wrapper">
									{!formik.values.kyc_file_name && (
										<label htmlFor="kyc-file" className="file-upload-lable">
											{isKycDocumentUploadPending ? 'Uploading...' : 'Choose File'}
										</label>
									)}

									{formik.values.kyc_file_name && (
										<span className="file-upload-title">
											<span className="file-name">{formik.values.kyc_file_name}</span>
											<button
												aria-label="remove file"
												type="button"
												className="icon-x-circle"
												onClick={handleRemoveKycProof}
												disabled={isEditing}
											></button>
										</span>
									)}

									<input
										ref={fileInputRef}
										type="file"
										name="kyc-file"
										id="kyc-file"
										accept=".png, .jpg"
										disabled={isEditing || isKycDocumentUploadPending}
										onChange={handleFileChange}
									/>
								</div>

								{formik.values.kyc_file_name && (
									<button
										type="button"
										aria-label="download file"
										className="download-btn"
										onClick={handleDownloadFile}
										disabled={isEditing}
									>
										Download
									</button>
								)}
							</div>
						</li>

						<li className="form-control d-col d-col-2">
							<TextInput
								id="document_number"
								name="document_number"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.document_number ?? ''}
								setFieldValue={formik.setFieldValue}
								label="Document No"
								error={
									formik.touched.document_number && formik.errors.document_number ? formik.errors.document_number : null
								}
								disabled={isEditing}
								required={true}
							/>
						</li>

						<li className="d-col d-col-2">
							<Dropdown
								id="IMR_Code"
								name="IMR_Code"
								label="IMR Code"
								title={formik.values.IMR_Code ?? ''}
								error={formik.touched.IMR_Code && formik.errors.IMR_Code ? formik.errors.IMR_Code : null}
								options={IMRCodeListData}
								setFieldValue={formik.setFieldValue}
								disabled={isEditing}
							/>
						</li>

						<li className="d-col d-col-2">
							<Dropdown
								id="club"
								name="club"
								label="Select Club"
								title={formik.values.club ?? ''}
								onBlur={formik.handleBlur}
								error={formik.touched.club && formik.errors.club ? formik.errors.club : null}
								options={clubList}
								setFieldValue={formik.setFieldValue}
								disabled={isEditing}
								required
							/>
						</li>
					</ul>

					<SubmitButton
						isEditing={isEditing}
						isPending={isKYCFormPending || isKycFileUploadPending}
						isDisabled={!formik.isValid || !formik.dirty || isKYCFormPending || isKycFileUploadPending}
						setIsEditing={setIsEditing}
						handleSubmitClick={handleSubmitClick}
					/>
				</form>
			</Wrapper>
		</div>
	);
};
