export const meetingsSchema = {
	title: 'meeting schema',
	description: 'Meeting Schema',
	version: 1,
	primaryKey: 'id',
	type: 'object',
	properties: {
		sfid: {
			type: 'string',
			maxLength: 100,
		},
		id: {
			type: 'string',
			maxLength: 100,
		},
		name: {
			type: ['string', 'null'],
		},
		status: {
			type: ['string', 'null'],
		},

		startdate: {
			type: ['string', 'null'],
		},
		meeting_type_c: {
			type: ['string', 'null'],
		},
		club_name_c: {
			type: ['string', 'null'],
		},
		enddate: {
			type: ['string', 'null'],
		},
		start_time_c: {
			type: ['string', 'null'],
		},
		end_time_c: {
			type: ['string', 'null'],
		},
		meeting_venue_c: {
			type: ['string', 'null'],
		},

		type: {
			type: ['string', 'null'],
		},
		createddate: {
			type: ['string', 'null'],
		},
		focus_product_1: {
			type: ['string', 'null'],
		},
		focus_product_2: {
			type: ['string', 'null'],
		},
		focus_product_4: {
			type: ['string', 'null'],
		},
		focus_product_5: {
			type: ['string', 'null'],
		},
		town_venue_c: {
			type: ['string', 'null'],
		},
		meetformatid_c: {
			type: 'integer',
		},
		publish_and_send_invitation_c: {
			type: ['string', 'null'],
		},
		app_id_c: {
			type: ['string', 'null'],
			maxLength: 100,
		},
		club_name_id_c: {
			type: ['string', 'null'],
		},
		town_city_c: {
			type: ['string', 'null'],
		},
		missedcallnumber_c: {
			type: ['string', 'null'],
		},
		meeting_theme_c: {
			type: ['string', 'null'],
		},
	},
	required: ['id'],
};
