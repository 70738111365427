import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { PublishMeetingsObject } from '@components/meeting-details';

interface IPublishMember {
	mutate: UseMutateFunction<PublishMeetingsObject, Error, void, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const usePublishMeetingMutation = (id: string): IPublishMember => {
	const publishMeetingData = async (): Promise<PublishMeetingsObject> => {
		return await APIService.putData(`${APICONFIG.MEETINGS_DETAILS}/${id}/send-invitation/`);
	};

	const { mutate, error, isError, isPending } = useMutation<PublishMeetingsObject, Error, void, unknown>({
		mutationFn: publishMeetingData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
