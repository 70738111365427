import { DropdownOptions } from '@components/common/formControl/dropdown';
import { CurrentSlabData } from '@components/contractor360/components/currentSlab';
import { DealersList } from '@components/endUserDetails/components/moreDetailsForm';
import { FeedbackExceptions, TaskListProduct } from '@services/hooks/site-leads';
import { FormikValues } from 'formik';

export interface IDefaultTaskListFormInitialValues {
	redemptionFeedback: string;
	dealers: DealersList[];
	feedback: IFeedback[] | [];
	exception: IFeedback[] | [];
	productResponse: string[];
	slab: string;
}
export const defaultTaskListFormInitialValues: IDefaultTaskListFormInitialValues = {
	redemptionFeedback: '',
	dealers: [],
	feedback: [],
	exception: [],
	productResponse: [],
	slab: '',
};

export interface IFCPDetails {
	message: string;
	data: {
		pwg_ytd: number;
		balance: number;
		track: boolean;
	};
}

export interface IFeedbackExpectation {
	formik: TFormik;
	exception?: FeedbackExceptions[];
}
export interface ISchemeDiscussion {
	formik: TFormik;
	slabData?: CurrentSlabData;
	fpcDetails?: IFCPDetails;
}

export interface IFeedback {
	id: number | null;
	feedbackQuestion: string;
	feedbackResponse: string;
	feedbackOptions: DropdownOptions[];
	type: string;
	placeHolder: string;
	productId: string;
	validation?: string;
}

export type TFormik = {
	values: FormikValues;
	setFieldValue: (
		field: string,
		value: string | string[] | boolean | DealersList[] | IFeedback[],
		shouldValidate?: boolean
	) => void;
	touched: FormikValues;
	errors: FormikValues;
	handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	isValid?: boolean;
	dirty?: boolean;
};

export interface IProductCardProps {
	product: TaskListProduct;
	productIndex: number;
	action: string;
	formik: TFormik;
}

export interface IProductDiscussionProps {
	products?: TaskListProduct[];
	formik: TFormik;
}

export interface IProductFeedbackProps {
	formik: TFormik;
	dealers?: DealersList[];
	products?: TaskListProduct[];
}

export const FeedbackOnException = [
	{
		id: 1,
		name: 'The contractor is accumulating points for a big redemption',
		value: 'The contractor is accumulating points for a big redemption',
	},
	{
		id: 2,
		name: 'Stock unavailable for the gifts he wants to redeem',
		value: 'Stock unavailable for the gifts he wants to redeem',
	},
	{
		id: 3,
		name: 'Other',
		value: 'Other',
	},
];

export const ProductFeedbackList = [
	{
		id: 1,
		name: 'Competition has a better alternative to the demoed product',
		value: 'Competition has a better alternative to the demoed product',
	},
	{
		id: 2,
		name: 'The price point is very high',
		value: 'The price point is very high',
	},
	{
		id: 3,
		name: 'I am satisfied with my current product usage for these application areas',
		value: 'I am satisfied with my current product usage for these application areas',
	},
	{
		id: 4,
		name: 'Not relevant for me since I do not use these substrates',
		value: 'Not relevant for me since I do not use these substrates',
	},
	{
		id: 5,
		name: 'Other',
		value: 'Other',
	},
];
