import { FormikValues, useFormik } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { defaultBasicDetailFormInitialValues } from '.';
import { SubmitButton } from '@components/common';
import { useValidation } from '@hooks/validations';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBasicDetailsMutation } from '@services/hooks/enduser-details/useBasicDetailsMutation';
import { useActiveGliderClick } from '@hooks/useActiveGlider';
import { useBasicDetailsQuery } from '@services/hooks/enduser-details/useBasicDeailsQuery';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { BasicDetails, TownList } from '@services/hooks/enduser-details';
import { useEndUserStore } from '@store/enduser';
import { UpperFormSection } from './upperFormSection';
import { MiddleFormSection } from './middleFormSection';
import { BottomFormSection } from './bottomFormSection';
import { compareLocalAddresses, compareWorkAddresses, formatDate, get18YearsAgo, getTownInfo } from '@helper/utils';
import { usePhoneNumberValidateQuery } from '@services/hooks/enduser-details/usePhoneNumberValidateQuery';
import { useTownDetailsQuery } from '@services/hooks/enduser-details/useTownDetailsQuery';
import { useGetRxDb } from '@hooks/getRxdbData';
import { useLeadUserQuery } from '@services/hooks/endusers/leadUserQuery';

export const BasicDetailsForm = (): ReactElement => {
	const nav = useNavigate();
	const { state } = useLocation();
	const { handleTabClick } = useActiveGliderClick();
	useTownDetailsQuery();
	const { result: towns } = useGetRxDb<TownList>('towns');
	const { basicDetailsValidationSchema } = useValidation();
	const { isEditing, setIsEditing, setActiveNavTab, isFormEdit, setFormId, formId } = useEndUserStore();
	const { mutate, isPending: isBasicFormPending } = useBasicDetailsMutation(formId, isFormEdit);
	const {
		data: getBasicFormData,
		isFetching: isGetBasicFormFetching,
		error: isGetBasicFormError,
	} = useBasicDetailsQuery(formId, isFormEdit);
	const { refetch: leadUserRefetch } = useLeadUserQuery();

	const formik = useFormik({
		initialValues: defaultBasicDetailFormInitialValues,
		onSubmit: () => {
			handleNext();
		},
		validationSchema: basicDetailsValidationSchema,
		validate: async () => {
			const errors: Partial<FormikValues> = {};
			if (phoneValidationData?.data?.exists && phoneValidationData?.data?.message) {
				const phoneNumberError = phoneValidationData?.data?.message;
				errors.primary_number = phoneNumberError;
			}

			if (secondaryNumberVlidationData?.data?.exists && secondaryNumberVlidationData?.data?.message) {
				const secondaryNumberError = secondaryNumberVlidationData?.data?.message;
				errors.secondary_number = secondaryNumberError;
			}

			return errors;
		},
	});

	// TODO: Need to make the common custom hook for the phone number validation
	const { data: phoneValidationData, isFetching: isPhoneDetailsFetching } = usePhoneNumberValidateQuery(
		formik.values.primary_number,
		'primary_number'
	);
	const { data: secondaryNumberVlidationData } = usePhoneNumberValidateQuery(
		formik.values.secondary_number,
		'secondary_number'
	);

	const commonFormProps = {
		formik,
		isEditing,
		isFormEdit,
		getBasicFormData,
		formId: formId,
	};

	const townsList = useMemo(() => {
		if (towns?.length) {
			return towns?.map((t) => ({
				id: t.id,
				value: `${t.towndescription_c} - ${t.towncode_c}`,
			}));
		}
		return [];
	}, [towns]);

	const updateBasicDetails = useCallback(
		(payload: BasicDetails) => {
			mutate(payload, {
				onSuccess: (data) => {
					if (data.id) {
						leadUserRefetch?.();
						const formTabs = isFormEdit ? [0, 1, 2] : [0, 1];
						const navigateUrl = `/${isFormEdit ? 'edit' : 'add'}-member-details/kyc-details`;
						setFormId(data.id);
						setActiveNavTab(formTabs);
						nav(navigateUrl, {
							state: {
								membershipNumber: state?.membershipNumber,
							},
						});
						handleTabClick(1);
					}
				},
			});
		},
		[handleTabClick, isFormEdit, mutate, nav, setActiveNavTab, setFormId, state?.membershipNumber, leadUserRefetch]
	);

	const handleNext = useCallback(() => {
		const townInfo = getTownInfo(towns, formik.values.permanent_town);
		const payload = {
			firstname: formik.values.first_name,
			middlename: formik.values.middle_name,
			lastname: formik.values.last_name,
			glue_consumption_per_month: Number(formik.values?.calculated_glue),
			monthlyconsumption_c: Number(formik.values?.average_glue),
			dateofbirth: formatDate(formik.values.birth_date),
			permanent_address1_c: formik.values.permanent_address,
			permanentstreet_c: formik.values.permanent_street,
			permanentzipcode_c: Number(formik.values.permanent_zipcode),
			permanentstate_c: formik.values.permanent_state,
			permanentcity_c: formik.values.permanent_city,
			permanent_address2_c: formik.values.local_address_chekbox
				? formik.values.permanent_address
				: formik.values.local_address,
			local_city_c: formik.values.local_address_chekbox ? formik.values.permanent_city : formik.values.local_city,
			local_state_c: formik.values.local_address_chekbox ? formik.values.permanent_state : formik.values.local_state,
			local_street_c: formik.values.local_address_chekbox ? formik.values.permanent_street : formik.values.local_street,
			local_pin_code_c: formik.values.local_address_chekbox
				? Number(formik.values.permanent_zipcode)
				: Number(formik.values.local_zipcode),
			permanent_address3_c: formik.values.work_address_chekbox
				? formik.values.permanent_address
				: formik.values.work_address,
			work_city_c: formik.values.work_address_chekbox ? formik.values.permanent_city : formik.values.work_city,
			work_street_c: formik.values.work_address_chekbox ? formik.values.permanent_street : formik.values.work_street,
			work_pin_code_c: formik.values.work_address_chekbox
				? Number(formik.values.permanent_zipcode)
				: Number(formik.values.work_zipcode),
			work_state_c: formik.values.work_address_chekbox ? formik.values.permanent_state : formik.values.work_state,
			religion: formik.values.religion,
			phone: formik.values.primary_number,
			AlternateMobileNo1: formik.values.secondary_number,
			language: formik.values.language,
			average_number_of_sites_in_a_year_c: Number(formik.values.sites_worked),
			competitionconsumption_c: Number(formik.values.competitionConsumption),
			weddinganniversary_c: formik.values.wedding_anniversary
				? formatDate(new Date(formik.values.wedding_anniversary))
				: null,
			email: formik.values.email,
			samaj: formik.values.samaj,
			town_code_c: townInfo?.towncode ?? '',
			town_name_c: townInfo?.townname ?? '',
			status: 'Kyc Details',
			additionenduser: {
				hobbies_c: 'dancing,singing',
			},
			total_year_of_experience_c: Number(formik.values.sites_competition),
		};
		updateBasicDetails(payload);
	}, [formik, towns, updateBasicDetails]);

	const handleSubmitClick = (): void => {
		formik.handleSubmit();
	};

	useEffect(() => {
		if (formik.values.unmarried_chekbox) {
			formik.setFieldValue('wedding_anniversary', '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.unmarried_chekbox]);

	useEffect(() => {
		if (formik.values.samaj_checkbox) {
			formik.setFieldValue('samaj', '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.samaj_checkbox]);

	useEffect(() => {
		if (phoneValidationData?.data?.exists && phoneValidationData?.data?.message) {
			formik.setFieldError('primary_number', phoneValidationData.data.message);
			formik.setFieldTouched('primary_number', true, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneValidationData]);

	useEffect(() => {
		if (secondaryNumberVlidationData?.data?.exists && secondaryNumberVlidationData?.data?.message) {
			formik.setFieldError('secondary_number', secondaryNumberVlidationData.data.message);
			formik.setFieldTouched('secondary_number', true, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [secondaryNumberVlidationData]);

	useEffect(() => {
		if (formId && getBasicFormData) {
			const isLocalAddressEqual = compareLocalAddresses(getBasicFormData);
			const isWorkAddressEqual = compareWorkAddresses(getBasicFormData);

			formik.setValues({
				...formik.values,
				first_name: getBasicFormData?.firstname,
				middle_name: getBasicFormData.middlename,
				last_name: getBasicFormData.lastname,
				average_glue: getBasicFormData.monthlyconsumption_c,
				calculated_glue: getBasicFormData.glue_consumption_per_month,
				birth_date:
					getBasicFormData?.dateofbirth && new Date(getBasicFormData?.dateofbirth) <= get18YearsAgo()
						? new Date(getBasicFormData?.dateofbirth)
						: get18YearsAgo(),
				permanent_address: getBasicFormData.permanent_address1_c,
				permanent_street: getBasicFormData.permanentstreet_c,
				permanent_zipcode: getBasicFormData.permanentzipcode_c,
				permanent_state: getBasicFormData.permanentstate_c,
				permanent_city: getBasicFormData.permanentcity_c,
				permanent_town: `${getBasicFormData.town_name_c} - ${getBasicFormData.town_code_c}`,
				local_address: getBasicFormData.permanent_address2_c,
				local_city: getBasicFormData.local_city_c,
				local_state: getBasicFormData.local_state_c,
				local_street: getBasicFormData.local_street_c,
				local_zipcode: getBasicFormData.local_pin_code_c?.toString(),
				work_address: getBasicFormData.permanent_address3_c,
				work_city: getBasicFormData.work_city_c,
				work_street: getBasicFormData.work_street_c,
				work_zipcode: getBasicFormData.work_pin_code_c,
				work_state: getBasicFormData.work_state_c,
				religion: getBasicFormData.religion,
				primary_number: getBasicFormData.phone,
				secondary_number: getBasicFormData.AlternateMobileNo1,
				language: getBasicFormData.language,
				sites_worked: getBasicFormData.average_number_of_sites_in_a_year_c?.toString(),
				sites_competition: getBasicFormData.total_year_of_experience_c?.toString(),
				wedding_anniversary: getBasicFormData.weddinganniversary_c,
				email: getBasicFormData.email,
				samaj: getBasicFormData.samaj,
				local_address_chekbox: isLocalAddressEqual,
				work_address_chekbox: isWorkAddressEqual,
				unmarried_chekbox: !getBasicFormData.weddinganniversary_c,
				samaj_checkbox: !getBasicFormData.samaj,
				competitionConsumption: Number(getBasicFormData?.competitionconsumption_c),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getBasicFormData, formId]);

	return (
		<div className="all-user-details-form">
			<Wrapper isLoaderVisible={isGetBasicFormFetching} isError={isGetBasicFormError}>
				<form onSubmit={formik.handleSubmit}>
					<ul className="user-details-wrapper row">
						<UpperFormSection {...commonFormProps} />
						<MiddleFormSection {...commonFormProps} townsList={townsList} />
						<BottomFormSection {...commonFormProps} />
					</ul>

					<SubmitButton
						isEditing={isEditing}
						isPending={isBasicFormPending}
						isDisabled={!formik.isValid || !formik.dirty || isBasicFormPending || isPhoneDetailsFetching}
						setIsEditing={setIsEditing}
						handleSubmitClick={handleSubmitClick}
					/>
				</form>
			</Wrapper>
		</div>
	);
};
