import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { setRxdb } from '@helper/utils';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { ProductDescriptionObject } from '@components/pcms';

export const useProductDescriptionListQuery = (): IQueryResponse<Array<ProductDescriptionObject>> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('product_description');

	const fetchProductDescriptionList = async (): Promise<Array<ProductDescriptionObject>> => {
		const response = await APIService.getData<Array<ProductDescriptionObject>>(
			`${APICONFIG.PCMS}/product-description/`,
			{
				limit: '0',
			}
		);
		if (response) {
			await setRxdb('product_description', response);
		}
		return response;
	};

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['productDescription'],
		queryFn: fetchProductDescriptionList,
		enabled: isDbChecked && shouldFetch,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
