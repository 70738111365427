import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { IDealerDetails } from '@components/delaerVisit';

interface IDealerVisitData {
	mutate: UseMutateFunction<IDealerDetails, Error, IDealerDetails, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useDealerVisitMutation = (dealerId: number): IDealerVisitData => {
	const createDealerVisit = async (visitData: IDealerDetails): Promise<IDealerDetails> => {
		return await APIService.postData<IDealerDetails>(`${APICONFIG.DEALER_VISITS}`, visitData);
	};
	const updateDealerVisit = async (visitData: IDealerDetails): Promise<IDealerDetails> => {
		return await APIService.putData<IDealerDetails>(`${APICONFIG.DEALER_VISITS}${dealerId}/`, visitData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: dealerId ? updateDealerVisit : createDealerVisit,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
