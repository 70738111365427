import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';
import { ErrorHandler } from '@helper/errorHandler';
import { ISiteSummaryDetail } from '@components/siteSummaryDetails';

export const useGetAllSiteDetails = (siteId: number): IQueryResponse<ISiteSummaryDetail> => {
	const fetchAllSiteDetails = async (): Promise<ISiteSummaryDetail> => {
		try {
			return await APIService.getData<ISiteSummaryDetail>(APICONFIG.SITE_LEAD_DETAILS + `${siteId}/`);
		} catch (error) {
			ErrorHandler(error);
			throw error;
		}
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['allSiteDetails', siteId],
		queryFn: fetchAllSiteDetails,
		enabled: !!siteId,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
