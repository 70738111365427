interface CompressOptions {
	quality?: number;
	maxSizeInBytes?: number;
	maxIterations?: number;
}

export const compressImage = (file: File, options: CompressOptions = {}): Promise<File> => {
	const { quality = 0.5, maxSizeInBytes = 1024 * 1024, maxIterations = 10 } = options;

	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (event: ProgressEvent<FileReader>): void => {
			const img = new Image();
			img.src = event.target?.result as string;
			img.onload = (): void => {
				const elem = document.createElement('canvas');
				elem.width = img.width;
				elem.height = img.height;

				const ctx = elem.getContext('2d');
				ctx?.drawImage(img, 0, 0, img.width, img.height);

				let currentQuality = quality;
				let iteration = 0;

				const compressRecursive = (): void => {
					ctx?.canvas.toBlob(
						(blob) => {
							if (blob) {
								const compressedFile = new File([blob], file.name, {
									type: file.type,
									lastModified: Date.now(),
								});

								if (compressedFile.size <= maxSizeInBytes || iteration >= maxIterations) {
									resolve(compressedFile);
								} else {
									currentQuality -= 0.1;
									iteration++;
									compressRecursive();
								}
							} else {
								reject(new Error('Canvas to Blob conversion failed'));
							}
						},
						file.type,
						currentQuality
					);
				};

				compressRecursive();
			};
			img.onerror = (error): void => reject(error);
		};
		reader.onerror = (error): void => reject(error);
	});
};
