export const gallerySchema = {
	title: 'Gallery schema',
	description: 'create a Gallery schema',
	version: 0,
	primaryKey: 'image_url_c',
	type: 'object',
	properties: {
		image_url_c: {
			type: 'string',
			maxLength: 250,
		},

		program_name_c: {
			type: 'string',
		},
		type_c: {
			type: 'string',
		},
	},
	required: ['image_url_c', 'program_name_c', 'type_c'],
};
