import { EventContentArg } from '@fullcalendar/core';
import { ReactElement } from 'react';
import barcodePng from '@assets/images/barcode.png';
import barcodeWebp from '@assets/images/barcode.webp';
import { MeetingsData } from '@services/hooks/dashboard/useMeetingsQuery';

export const RenderEventContent = (
	eventInfo: EventContentArg,
	setModalContent: (data: MeetingsData) => void,
	setModalOpen: (data: boolean) => void
): ReactElement => {
	const handleBarcode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.stopPropagation();
		setModalContent({
			id: +eventInfo.event.id,
			name: eventInfo.event.title,
			meeting_venue_c: eventInfo.event._def.extendedProps.venu,
			start_time_c: eventInfo.event._def.extendedProps.start_time_c,
			status: eventInfo.event._def.extendedProps.status,
			sfid: eventInfo.event._def.extendedProps?.sfid,
		});
		setModalOpen(true);
	};

	return (
		<div className="meeting-edit-card" key={eventInfo.event.id}>
			<div className="meeting-details">
				<div className="user-details">
					<span className="user-name" title={eventInfo.event.title}>
						{eventInfo.event.title}
					</span>
					<span className="address">{eventInfo.event._def.extendedProps.venu}</span>
				</div>

				<span className="edit-meeting">
					<span className="icon-edit-tag"></span>
				</span>
			</div>

			<div className="meeting-edit-bottom">
				<span className="time">{eventInfo.timeText}</span>
				{eventInfo.event._def.extendedProps?.sfid && (
					<div className="meeting-barcode">
						<button onClick={handleBarcode} className="pointer" aria-label="barcode">
							<picture>
								<source srcSet={barcodeWebp} type="image/webp" />
								<source srcSet={barcodePng} type="image/png" />
								<img src={barcodePng} alt="barcode" title="barcode" width="20" height="16" />
							</picture>
						</button>
					</div>
				)}
			</div>
		</div>
	);
};
