import { create } from 'zustand';
import { MeetingFilterObject, meetingInitialValues } from '@components/meetingModule/components/meeting-filter';

interface FilterStore {
	scheduledMeetingFilter: MeetingFilterObject;
	todayFilter: MeetingFilterObject;
	allMeetingFilter: MeetingFilterObject;
	updateFilter: (name: string, filterObject: MeetingFilterObject) => void;
	resetMeetingFilter: (name: string) => void;
}

const initialState = {
	scheduledMeetingFilter: meetingInitialValues,
	todayFilter: meetingInitialValues,
	allMeetingFilter: meetingInitialValues,
};

export const useMeetingFilterStore = create<FilterStore>((set) => ({
	...initialState,
	updateFilter: (name: string, meetingFilter: MeetingFilterObject): void => {
		set(() => ({ [name]: meetingFilter }));
	},
	resetMeetingFilter: (name: string): void => {
		set(() => ({ [name]: meetingInitialValues }));
	},
}));
