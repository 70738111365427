import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { CloseLeadsDetails } from '.';
import { APICONFIG } from '@config/api.config';

interface ICloseLeadsDetails {
	mutate: UseMutateFunction<CloseLeadsDetails, Error, { segment: string; basicFormData?: CloseLeadsDetails }, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}
interface IUpdateCloseLeads {
	segment: string;
	basicFormData?: CloseLeadsDetails;
}

export const useCloseLeadsMutation = (id: string | null): ICloseLeadsDetails => {
	const updateCloseLeads = async ({ segment }: IUpdateCloseLeads): Promise<CloseLeadsDetails> => {
		const url = `${APICONFIG.CLOSE_LEADS}/${id}/${segment}/`;
		return await APIService.putData<CloseLeadsDetails>(url);
	};

	const updateExistingUser = async ({ segment, basicFormData }: IUpdateCloseLeads): Promise<CloseLeadsDetails> => {
		const url = `${APICONFIG.CLOSE_LEADS}/${id}/${segment}/`;
		return await APIService.putData<CloseLeadsDetails>(url, basicFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: ({ segment, basicFormData }: { segment: string; basicFormData?: CloseLeadsDetails }) =>
			basicFormData ? updateExistingUser({ segment, basicFormData }) : updateCloseLeads({ segment }),
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
