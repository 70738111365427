import { RefObject, useEffect } from 'react';
export interface IFormTabNavigationProps {
	listRef: RefObject<HTMLUListElement | null>;
	currentActiveTab: number | RefObject<HTMLLIElement> | string;
}

export const useFormTabNavigation = (props: IFormTabNavigationProps): void => {
	const { listRef, currentActiveTab } = props;
	useEffect((): void => {
		const tab = document.querySelector('.tabs li.active') as HTMLElement;
		const glider = document.querySelector('.glider') as HTMLElement;
		if (glider && tab) {
			const currentElePosition = tab.offsetLeft;
			glider.style.transform = `translateX(${currentElePosition}px)`;
			glider.style.minWidth = `${tab.clientWidth}px`;
		}
		if (listRef.current) {
			const listRect = listRef.current.getBoundingClientRect();
			const itemRect = tab.getBoundingClientRect();

			const scrollLeft = listRef.current.scrollLeft;
			const itemOffsetLeft = itemRect.left - listRect.left + scrollLeft;

			listRef.current.scrollTo({
				left: itemOffsetLeft - listRect.width / 2 + itemRect.width / 2,
				behavior: 'smooth',
			});
		}
	}, [currentActiveTab, listRef]);
};
