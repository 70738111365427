export interface IGrade {
	name: string;
	is_active: boolean;
	remarks: string;
}

export const GradeIconMap = {
	SH: 'icon-sh',
	Marine: 'icon-marine',
	'Hi Per': 'icon-hi-per',
};

export * from './competitionConversionGrade';
