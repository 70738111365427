import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { DeletedDetails } from '.';
import { APICONFIG } from '@config/api.config';

interface IDeleteMember {
	mutate: UseMutateFunction<DeletedDetails, Error, string, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useDeleteMemberMutation = (id: string): IDeleteMember => {
	const deleteMemberData = async (memberId: string): Promise<DeletedDetails> => {
		return await APIService.deleteData(`${APICONFIG.MEETINGS_DETAILS}/${id}/delete-member/${memberId}/`);
	};

	const { mutate, error, isError, isPending } = useMutation<DeletedDetails, Error, string, unknown>({
		mutationFn: deleteMemberData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
