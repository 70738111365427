import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { SiteVisitData } from '.';
import useSiteLeadStore from '@store/siteLeadStore';

export const useGetVisitQuery = (): IQueryResponse<SiteVisitData> => {
	const { siteVisitId } = useSiteLeadStore();

	const fetchSiteVisitData = async (): Promise<SiteVisitData> => {
		const url = `${APICONFIG.SITE_VISIT}${siteVisitId}`;
		return await APIService.getData<SiteVisitData>(url);
	};

	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['taskListProducts'],
		queryFn: fetchSiteVisitData,
		refetchOnWindowFocus: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
