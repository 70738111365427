import { useEffect, useRef } from 'react';

export const useInfiniteScroll = (callback: () => void, targetRef?: React.RefObject<HTMLElement>): void => {
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		const targetElement = targetRef?.current;

		const handleScroll = (): void => {
			let scrolledToBottom = false;

			if (targetElement) {
				const { scrollTop, scrollHeight, clientHeight } = targetElement;
				scrolledToBottom = Math.ceil(scrollTop + clientHeight + 150) >= scrollHeight;
			} else {
				const { scrollTop, scrollHeight, clientHeight } = document.documentElement || document.body;
				scrolledToBottom = Math.ceil(scrollTop + clientHeight + 300) >= scrollHeight;
			}

			if (scrolledToBottom) {
				if (timeoutRef.current) clearTimeout(timeoutRef.current);
				timeoutRef.current = setTimeout(() => {
					callback();
				}, 500);
			}
		};

		const scrollTarget = targetElement || window;
		scrollTarget.addEventListener('scroll', handleScroll);

		return (): void => {
			scrollTarget.removeEventListener('scroll', handleScroll);
			if (timeoutRef.current) clearTimeout(timeoutRef.current);
		};
	}, [callback, targetRef]);
};
