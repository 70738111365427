import { ReactElement } from 'react';
import { IWrapperProps } from '@components/common/Wrapper/index';
import { ErrorResponse, Loader } from '@components/common';

export const Wrapper = (props: IWrapperProps): ReactElement => {
	const { isLoaderVisible, isError, children } = props;

	if (isLoaderVisible) return <Loader />;

	if (isError) return <ErrorResponse errorMessage={isError.message} />;

	return <>{children}</>;
};
