import { ReactElement, useMemo } from 'react';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';
import { IProductData } from '@services/hooks/calculator';
import { ProductList } from '../productList/productList';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { getCurrentQuarterNumber } from '@helper/utils';

export const CalculatorContainer = ({
	isLoading,
	isFetching,
	error,
	productData,
	bankedKg,
	onChangeQuantity,
}: {
	isLoading: boolean;
	isFetching?: boolean;
	error: Error | null;
	productData?: IProductData[];
	bankedKg: number;
	onChangeQuantity: (type: string, productId: number, manualQuantity?: number, skuId?: number) => void;
}): ReactElement => {
	const currentYear = useMemo((): string => {
		return new Date().getFullYear().toString().slice(-2);
	}, []);
	// TODO: dynamic year changes
	return (
		<div className="calculator-wrapper">
			<div className="title cm-heading">
				<div className="inner-content">
					Calculator for {getCurrentQuarterNumber('Q')} FY{currentYear}
				</div>
			</div>

			<div className="product-list">
				<div className="clac-title-section">
					<div className="list-heading-title">Product</div>
					<div className="content">Estimated Quantity</div>
				</div>
				{!isLoading && !isFetching && !productData && <NoDataFound />}
				<Wrapper isLoaderVisible={isFetching} isError={error}>
					<div className="clac-product-list">
						{productData &&
							productData?.length > 0 &&
							productData.map((product) => (
								<ProductList
									id={product.id}
									key={product.id}
									conversion_factor_for_kgs={product.conversion_factor_for_kgs}
									product_name={product.product_name}
									unit_of_measurement={product.unit_of_measurement}
									conversion_factor_for_points={product.conversion_factor_for_points}
									quantity={product.quantity}
									product_sku={product.product_sku}
									onChangeQuantity={onChangeQuantity}
								/>
							))}
					</div>
				</Wrapper>

				<div className="total-value">
					<div className="inner-total-value">
						<div className="label">Total Banked KG</div>
						<div className="clac-value">
							<span>{bankedKg.toFixed(2)} Kg</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
