export interface ParticipantsObject {
	firstname: string;
	lastname: string;
	id: number;
	account_id: number;
	name: string;
	phone: string;
	contactsfid: string;
	producthierarchycode_c: string;
	AlternateMobileNo1: null | string;
	clubname: null | string;
	campaign_id_c: null | string;
	personcontactid: string;
	enduserdesignation_c: null | string;
	member_id: number;
	sfid: string;
	membershipno_c: string;
	campaign_sfid: string;
	campaign_app_id_c: string;
	type: string;
	user_type_c: string;
	attendance_c: boolean;
	non_invited_c: boolean;
	recordtypename_c: string;
	contactid: string;
	leadid: null | string;
	is_repeat_attendee_c: boolean;
	isconverted_within_5_days_c: boolean;
	pidilite_employ_c: boolean;
	status: string;
	lead_app_id_c: null | string;
}

export interface InitialFeedback {
	obstacleFaced: string;
	learningForm: string;
}
export const defaultInitialFeedback: InitialFeedback = {
	obstacleFaced: '',
	learningForm: '',
};
