import { ReactElement } from 'react';
import { IBottomDrawerModelProps } from '@components/common';

export const BottomDrawerModel = (props: IBottomDrawerModelProps): ReactElement => {
	const { children, modelId, openModelStatus, className = '' } = props;

	return (
		<div
			className={`filter-drawer-wrapper filter-contractor-drawer ${className} ${openModelStatus ? 'show' : ''}`}
			id={modelId}
		>
			{children}
		</div>
	);
};
