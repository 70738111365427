import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '@assets/styles/pages/calculator.scss';
import { useCalculatorProductList } from '@services/hooks/calculator/useCalculatorProductList';
import {
	AdditionalDetailSection,
	EarningSection,
	HeaderSection,
	CalculatorContainer,
} from '@components/calculator/components';
import { IProductData, ISkuSlabCombination } from '@services/hooks/calculator';
import { quarterMonths } from '@config/constant';
import { useSlabCombinationCalculator } from '@hooks/useSlabCalculator';

export const Calculator = (): ReactElement => {
	const { state } = useLocation();

	const { data, isFetching, isLoading, error } = useCalculatorProductList(state?.membershipNumber);

	const [bankedKg, setBankedKg] = useState<number>(0);
	const [totalPoints, setTotalPoints] = useState<number>(0);
	const [productData, setProductData] = useState<IProductData[]>([]);
	const [pointSlabDataPoints, setPointsSlabDataPoints] = useState<number>(0);
	const [worthValue, setWorthValue] = useState<number>(0);
	const [panIndiaGiftDescription, setPanIndiaGiftDescription] = useState<string>('');
	const [slabCombinationOffers, setSlabCombinationOffers] = useState<ISkuSlabCombination[]>([]);
	const { calculateSlabCombinations } = useSlabCombinationCalculator(data, setTotalPoints);

	const monthYearText = useMemo((): string => {
		const date = new Date();
		const currentQuarter = Math.floor(date.getMonth() / 3);
		const currentYear = date.getFullYear();
		return `${quarterMonths[currentQuarter]}
		${currentQuarter === 0 ? `${currentYear - 1}-${currentYear % 100}` : currentYear}
		`;
	}, []);

	useEffect(() => {
		if (data?.product_data) {
			setProductData(
				data?.product_data.map((ele) => {
					ele.quantity = 0;
					if (ele.product_sku.length > 0) {
						ele.product_sku.map((sku) => {
							sku.quantity = 0;
						});
					}
					return ele;
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.product_data]);

	const onChangeQuantity = (type: string, productId: number, manualQuantity?: number, skuId?: number): void => {
		const updatedProductData = [...productData];
		const existingProductIndex = updatedProductData.findIndex((ele) => ele.id === productId);

		if (existingProductIndex !== -1) {
			const productConversionForKgs = updatedProductData[existingProductIndex].conversion_factor_for_kgs || 0;
			const productConversionForPoints = updatedProductData[existingProductIndex].conversion_factor_for_points || 0;
			const productQuantity = updatedProductData[existingProductIndex].quantity || 0;
			let tempTotalPoints = totalPoints;
			let tempBankedKg = bankedKg;
			let tempQuantity = 0;

			// sku product calculation
			if (skuId) {
				const existingSKUIndex = updatedProductData[existingProductIndex].product_sku.findIndex(
					(ele) => ele.id === skuId
				);
				if (existingSKUIndex !== -1) {
					let multiplicationFactor = 0;
					const selectedSku = updatedProductData[existingProductIndex].product_sku[existingSKUIndex];
					const skuQuantity = updatedProductData[existingProductIndex].product_sku[existingSKUIndex].quantity || 0;
					const skuConversionForPoints =
						updatedProductData[existingProductIndex].product_sku[existingSKUIndex].conversion_factor_for_points;
					if (type === 'manual') {
						selectedSku.quantity = manualQuantity;
						tempBankedKg += -(skuQuantity * productConversionForKgs) + (manualQuantity || 0) * productConversionForKgs;
						multiplicationFactor = skuConversionForPoints * selectedSku.sku_unit * (manualQuantity || 0);
						tempTotalPoints = totalPoints + multiplicationFactor - skuQuantity * skuConversionForPoints;
						updatedProductData[existingProductIndex].product_sku[existingSKUIndex].extraPoints = multiplicationFactor;
					} else {
						selectedSku.quantity =
							type === 'increase' ? skuQuantity + selectedSku.sku_unit : skuQuantity - selectedSku.sku_unit;
						const difference = selectedSku.sku_unit * productConversionForKgs;
						tempBankedKg += type === 'increase' ? difference : -difference;
						multiplicationFactor = skuConversionForPoints * selectedSku.sku_unit;
						tempTotalPoints = totalPoints + (type === 'increase' ? multiplicationFactor : -multiplicationFactor);
						updatedProductData[existingProductIndex].product_sku[existingSKUIndex].extraPoints =
							(updatedProductData[existingProductIndex].product_sku[existingSKUIndex].extraPoints || 0) +
							(type === 'increase' ? multiplicationFactor : -multiplicationFactor);
					}

					// sku slab calculation
					if (selectedSku.sku_slab && selectedSku.sku_slab.length > 0) {
						const skuKgs = (selectedSku.quantity || 0) * productConversionForKgs;
						selectedSku.sku_slab.map((slab) => {
							if (
								skuKgs >= slab.slab_from &&
								((!slab.slab_to && slab.max_earnable_points) || (slab.slab_to && skuKgs <= slab.slab_to))
							) {
								const extraPoints =
									slab.bonus_type === 'Flat Bonus'
										? slab.bonus_point
										: slab.max_earnable_points
											? skuKgs * slab.bonus_point < slab.max_earnable_points
												? skuKgs * slab.bonus_point
												: slab.max_earnable_points
											: skuKgs * slab.bonus_point;
								tempTotalPoints += extraPoints - (slab.extraPoints || 0);
								slab.extraPoints = extraPoints;
							} else {
								tempTotalPoints += -(slab.extraPoints || 0);
								slab.extraPoints = 0;
							}
						});
					}
					tempQuantity = productQuantity + (selectedSku.quantity || 0) - skuQuantity;
				}
			} else {
				// main product calculation
				if (type === 'manual') {
					tempQuantity = manualQuantity || 0;
					tempBankedKg += -(productQuantity * productConversionForKgs) + tempQuantity * productConversionForKgs;
					tempTotalPoints +=
						-(productQuantity * productConversionForPoints) + tempQuantity * productConversionForPoints;
				} else if (type === 'manualSku') {
					tempQuantity = manualQuantity || 0;
					tempBankedKg += (manualQuantity || 0) - productQuantity;
					let quantityLeft = manualQuantity;
					updatedProductData[existingProductIndex].product_sku
						.sort((a, b) => b.sku_unit - a.sku_unit)
						.forEach((el) => {
							if (quantityLeft && Math.floor(quantityLeft / el.sku_unit)) {
								el.quantity = Math.floor(quantityLeft / el.sku_unit) * el.sku_unit;
								quantityLeft -= el.quantity;
								const extraPoints = el.quantity * el.conversion_factor_for_points;
								tempTotalPoints += extraPoints - (el.extraPoints || 0);
								el.extraPoints = extraPoints;
							} else {
								tempTotalPoints -= el.extraPoints || 0;
								el.quantity = 0;
								el.extraPoints = 0;
							}

							el.sku_slab &&
								el.sku_slab.length > 0 &&
								el.sku_slab.forEach((slab) => {
									if (
										el.quantity &&
										el.quantity >= slab.slab_from &&
										((!slab.slab_to && slab.max_earnable_points) || (slab.slab_to && el.quantity <= slab.slab_to))
									) {
										const extraPoints =
											(!slab.slab_to && slab.max_earnable_points) ||
											(slab.bonus_type === 'Flat Bonus' ? slab.bonus_point : el.quantity * slab.bonus_point);
										tempTotalPoints += extraPoints - (slab.extraPoints || 0);
										slab.extraPoints = extraPoints;
									} else {
										tempTotalPoints += -(slab.extraPoints || 0);
										slab.extraPoints = 0;
									}
								});
						});
				} else {
					tempQuantity = type === 'increase' ? productQuantity + 1 : productQuantity - 1;
					tempBankedKg += type === 'increase' ? productConversionForKgs : -productConversionForKgs;
					tempTotalPoints +=
						type === 'increase' ? productConversionForPoints : type === 'decrease' ? -productConversionForPoints : 0;
				}
			}

			// point slab data calculation
			const slabFound = data?.points_slab_data.find(
				(slab) =>
					tempBankedKg >= slab.slab_from &&
					((!slab.slab_to && slab.max_earnable_points) || (slab.slab_to && tempBankedKg <= slab.slab_to))
			);
			if (slabFound) {
				const extraPoints =
					slabFound.bonus_type === 'Flat Bonus'
						? slabFound.bonus_point
						: slabFound.max_earnable_points
							? tempBankedKg * slabFound.bonus_point < slabFound.max_earnable_points
								? tempBankedKg * slabFound.bonus_point
								: slabFound.max_earnable_points
							: tempBankedKg * slabFound.bonus_point;
				tempTotalPoints += extraPoints - pointSlabDataPoints;
				setPointsSlabDataPoints(extraPoints);
			} else {
				tempTotalPoints += -pointSlabDataPoints;
				setPointsSlabDataPoints(0);
			}

			updatedProductData[existingProductIndex] = {
				...updatedProductData[existingProductIndex],
				quantity: tempQuantity,
			};
			setBankedKg(tempBankedKg);
			setTotalPoints(tempTotalPoints);
		}
		setProductData(updatedProductData);
		const combinationData = calculateSlabCombinations();
		combinationData && setSlabCombinationOffers(combinationData);
	};

	useEffect(() => {
		findPanIndiaSchemes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bankedKg]);

	const findPanIndiaSchemes = (): void => {
		if (data) {
			if (data.pan_india_gift_data && data.pan_india_gift_data.length > 0) {
				const slabFound = data.pan_india_gift_data.find(
					(slab) => bankedKg >= slab.slab_from && ((slab.slab_to && bankedKg <= slab.slab_to) || !slab.slab_to)
				);
				if (slabFound && slabFound.gift_description) {
					const worthTextFound = slabFound.gift_description.match(/\bworth\b.*$/);
					const worthDigitFound = worthTextFound && worthTextFound[0] && worthTextFound[0].match(/\d+/);
					const worthDigit = worthDigitFound && worthDigitFound[0];
					setWorthValue(worthDigit ? Number(worthDigit) : 0);
					setPanIndiaGiftDescription(
						worthTextFound ? slabFound.gift_description.replace(worthTextFound[0], '') : slabFound.gift_description
					);
				} else {
					setPanIndiaGiftDescription('');
				}
			}
		}
	};

	return (
		<section className="calculator-q4-section">
			<HeaderSection
				banking_qtd={data?.new_banking_qtd}
				points_qtd={data?.new_points_qtd}
				monthYearText={monthYearText}
			/>
			<div className="custom-container">
				<CalculatorContainer
					isFetching={isFetching}
					isLoading={isLoading}
					error={error}
					productData={productData}
					bankedKg={bankedKg}
					onChangeQuantity={onChangeQuantity}
				/>
				<EarningSection
					bankedKg={bankedKg}
					totalPoints={totalPoints}
					monthYearText={monthYearText}
					panIndiaGiftDescription={panIndiaGiftDescription}
					premierGiftDescription={data?.fpc_supervisor_c}
					worthValue={worthValue}
					slabCombinationOffers={slabCombinationOffers}
				/>
				<AdditionalDetailSection productData={productData} />
			</div>
		</section>
	);
};
