import React, { useCallback, useEffect, useRef, useState } from 'react';
import '@assets/styles/pages/qr-validate-page.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastType, useTosterStore } from '@store/toster';
import { useBarcodeValidateMutation } from '@services/hooks/contractor360/useValidateBarcodeMutaion';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { BarcodeValidate } from '.';
import { ViewMore } from '../viewMore';

export const QrCodeValidate: React.FC = () => {
	const { state } = useLocation();
	const { setToaster } = useTosterStore();
	const { mutate: barcode, isPending: barcodePending, error } = useBarcodeValidateMutation();
	const [couponCodeValidate, setCouponCodeValidate] = useState<BarcodeValidate>();
	const effectRan = useRef(false);
	const navigate = useNavigate();

	const handleSubmitBarcode = useCallback(
		(latitude: number, longitude: number): void => {
			const payload = {
				mobileNumber: state?.number,
				barcodes: state?.codes,
				Latitude: latitude,
				Longitude: longitude,
				Cluster_code: state.clusterCode,
			};

			barcode(payload, {
				onSuccess: (data) => {
					if (data) {
						const dataWithViewMore: BarcodeValidate = {
							...data,
							barCodes: data.barCodes.map((item) => ({ ...item, viewMore: false, membershipNumber: '' })),
						};
						setCouponCodeValidate(dataWithViewMore);
					}
				},
				onError: (error) => {
					setToaster(true, ToastType.error, error.message);
				},
			});
		},
		[barcode, setToaster, state]
	);

	useEffect(() => {
		if (effectRan.current) return;

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					handleSubmitBarcode(latitude, longitude);
				},
				(error) => {
					setToaster(true, ToastType.error, error.message);
				}
			);
		} else {
			setToaster(true, ToastType.error, 'Geolocation is not supported by this browser.');
		}

		effectRan.current = true;
	}, [handleSubmitBarcode, setToaster]);

	const handleNavigate = useCallback(() => {
		navigate('/contractor/points-statement', {
			state: {
				number: state?.number,
			},
		});
	}, [navigate, state?.number]);

	return (
		<section className="qr-validate-wrapper">
			<div className="container">
				<Wrapper isLoaderVisible={barcodePending} isError={error}>
					<>
						{couponCodeValidate?.status ? (
							<ul className="barcode-status-card row">
								{couponCodeValidate?.barCodes.map((item, index) => {
									return (
										<li className="d-col d-col-2" key={item.barCode}>
											<div>
												<div className="status-info">
													<span className="status-name">Barcode</span>
													<span className="status-code">{item.barCode}</span>
												</div>
												<div className="status-info">
													<span className="status-name">Status</span>
													<span className={`status-code  ${item.success ? 'status-success' : 'error-status'}`}>
														{item.success ? 'Success' : 'Error'}
													</span>
												</div>
												<div className="status-info">
													<span className="status-name">Message</span>
													<span className={`status-code ${item.success ? 'status-success' : 'error-status'}`}>
														{item.message}
													</span>
												</div>
												<ViewMore item={item} index={index} setCouponCodeValidate={setCouponCodeValidate} />
											</div>
										</li>
									);
								})}
							</ul>
						) : (
							<div className="error-message-main">{couponCodeValidate?.message}</div>
						)}
						<div className="submit-btn">
							<button className="btn btn-primary btn-medium" onClick={handleNavigate} disabled={barcodePending}>
								Continue
							</button>
						</div>
					</>
				</Wrapper>
			</div>
		</section>
	);
};
