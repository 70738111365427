import { CalendarDetails } from '@services/hooks/calendar';
import { create } from 'zustand';

interface ICalendarDetails {
	calendarDetails: CalendarDetails[];
	setCalendarData: (state: CalendarDetails[]) => void;
	fetchedMonths: Set<string>;
	setFetchedMonths: (state: string) => void;
}
const initialState = {
	calendarDetails: [],
	fetchedMonths: new Set<string>(),
};

export const useCalendarStore = create<ICalendarDetails>((set) => ({
	...initialState,
	setCalendarData: (item: CalendarDetails[]): void =>
		set((state) => ({ ...state, calendarDetails: [...state.calendarDetails, ...item] })),
	setFetchedMonths: (month: string): void =>
		set((state) => ({ ...state, fetchedMonths: new Set(state.fetchedMonths).add(month) })),
}));
