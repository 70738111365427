export const dealerSchema = {
	title: 'Delaer Schema',
	description: 'create a Dealer schema',
	version: 0,
	primaryKey: 'dealercode_c',
	type: 'object',
	properties: {
		dealercode_c: { type: 'string', primary: true, maxLength: 200 },
		dealername_c: { type: 'string' },
	},
	required: ['dealercode_c', 'dealername_c'],
};
