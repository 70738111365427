export interface GetOtpDetails {
	cmdi_code: string;
	otp?: string;
	message?: string;
}
export interface GetOtpObject {
	message: string | GetOtpDetails;
	phone_number: string;
}

export interface LoginForm {
	bdeCode: string;
	otp: string;
}

export const defaultOtpDetails: LoginForm = {
	bdeCode: '',
	otp: '',
};

export interface GetTokenObject {
	message: string | GetOtpDetails;
	user_type_id: number | null;
	user_subtype: string | null;
	usertype: string;
}
