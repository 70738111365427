import { ChangeEvent, ReactElement } from 'react';
import { useCompetitionConversionUsageQuery } from '@services/hooks/competition-conversion/useCompetitionConversionUsageQuery';
import { UsageIconMap } from '@components/competition-conversion/components/competitionConversionUsage/index';
import { CompetitionConversionHeader } from '@components/competition-conversion/components/compeetitionHeader';
import { useCompetitionConversionStore } from '@store/competitionConversion';
import { CompetitionNextActionButton } from '@components/competition-conversion/components/competitionNextActionButton';
import { Wrapper } from '@components/common/Wrapper/wrapper';

export const CompetitionConversionUsage = (): ReactElement => {
	const { data: usageList, error, isPending } = useCompetitionConversionUsageQuery();
	const { setReason, reason } = useCompetitionConversionStore();

	const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setReason(e.target.value);
	};

	return (
		<>
			<CompetitionConversionHeader title="Please select a reason for competition usage." />
			<div className="competition-usage-wrapper">
				<div className="competition-usage-form">
					<Wrapper isLoaderVisible={isPending} isError={error}>
						<ul className="row usage-card-list">
							{usageList?.length &&
								usageList?.map((usage) => {
									return (
										<li key={usage.name} className="competition-usage-card d-col d-col-6">
											<div className="cmn-radio card-details invisible-input-focus">
												<input
													type="radio"
													id={usage.name}
													onChange={handleChange}
													name="conversionUsage"
													value={usage.name}
													checked={reason === usage.name}
													tabIndex={0}
												/>
												<label htmlFor={usage.name}>
													<span className="hide">hide</span>
												</label>
												<div className="about-details">
													<span className={UsageIconMap[usage.name] || 'icon-smiley'}></span>
													<p className="h4">{usage.name}</p>
												</div>
											</div>
										</li>
									);
								})}
						</ul>
					</Wrapper>
				</div>
				<CompetitionNextActionButton buttonDisable={!reason} nextUrl="/competition-conversion/type-of-contractor" />
			</div>
		</>
	);
};
