export interface ITypeOfContractor {
	name: string;
	value: string;
	icon: string;
}
export const TypeOfContractor: ITypeOfContractor[] = [
	{
		name: 'Labor',
		value: 'labour',
		icon: 'icon-worker',
	},
	{
		name: 'Material + Labor',
		value: 'material_labour',
		icon: 'icon-worker-two',
	},
	{
		name: 'Percentage',
		value: 'percentage',
		icon: 'icon-percentage-tag',
	},
];

export * from './competitionConversionContract';
