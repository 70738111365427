import { useEndUserStore } from '@store/enduser';
import { useCallback } from 'react';

export interface IActiveGlider {
	gliderRef?: React.RefObject<HTMLLIElement> | null;
}

export interface ActiveGliderClickReturnType {
	handleTabClick: (value: number) => void;
}

export const useActiveGliderClick = (): ActiveGliderClickReturnType => {
	const { refGlider: gliderRef } = useEndUserStore();

	const handleTabClick = useCallback(
		(value: number) => {
			if (gliderRef?.current) {
				const tabs = gliderRef.current.parentElement?.querySelectorAll('.tabs li');
				if (tabs) {
					const currentTarget = tabs[value] as HTMLElement;
					tabs[value - 1]?.classList.remove('active');
					currentTarget?.classList?.add('active');
					const currentElePosition = currentTarget.offsetLeft;
					gliderRef.current.style.transform = `translateX(${currentElePosition}px)`;
				}
			}
		},
		[gliderRef]
	);

	return { handleTabClick };
};
