import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { WssNameObject } from '@components/pcms';

export const useGetWssNameQuery = (): IQueryResponse<Array<WssNameObject>> => {
	const fetchWssName = async (): Promise<Array<WssNameObject>> => {
		const response = await APIService.getData<Array<WssNameObject>>(`${APICONFIG.PCMS}/wss-name/`, {
			limit: '0',
		});

		return response;
	};

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['wssName'],
		queryFn: fetchWssName,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
