import { ReactElement, useEffect, useState } from 'react';
import {
	AllEndUserFilter,
	AllEndUserObject,
	AllEndUserResultType,
	AllEndUserSearchFilter,
	initialAllEndUsersFilter,
} from '.';
import { UserCard } from '../userCard/userCard';
import { useFilterStore } from '@store/index';
import { EndUserFilterStore, FilterObject } from '@store/memberList';
import { useInfiniteScroll } from '@components/common';
import { useRxData } from 'rxdb-hooks';
import { getFilter } from '@hooks/useFilterData';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { escapeRegExp } from '@helper/utils';
import { useAllEndUserQuery } from '@services/hooks/endusers/allEndUserQuery';

export const AllEndUserList = (): ReactElement => {
	const [searchElement, setSearchElement] = useState<string>('');
	const { isFetching: queryFetching, error } = useAllEndUserQuery();
	const {
		sortValue,
		updateSearch,
		updateSortObject,
		updateFilterObject,
		updateSortValue,
		updateSortActiveStatus,
		filterObject,
		search,
	} = useFilterStore((state) => state);
	const { allEndUserFilter, updateAllEndUserFilter } = EndUserFilterStore((state) => state);

	const getDataFromIndexDb = (
		filterObject: FilterObject,
		searchQuery: string
	): { selector: AllEndUserFilter & AllEndUserSearchFilter } => {
		const { selectorData } = getFilter(filterObject);
		const selector: AllEndUserFilter & AllEndUserSearchFilter = selectorData;

		const searchData = searchQuery ? escapeRegExp(searchQuery) : '';
		if (searchQuery) {
			selector.$or = [
				{ name: { $regex: `.*${searchData}.*`, $options: 'i' } },
				{ phone: { $regex: `.*${searchData}.*`, $options: 'i' } },
				{ membershipno: { $regex: `.*${searchData}.*`, $options: 'i' } },
			];
		}

		return { selector };
	};

	const { result, fetchMore, isFetching }: AllEndUserResultType = useRxData<AllEndUserObject>(
		'all',
		(collection) => {
			const { selector } = getDataFromIndexDb(filterObject, searchElement);
			let query = collection.find({ selector });

			// Sort the query based on sortValue and sortOrder
			if (sortValue) {
				query = query
					.sort({
						[sortValue]: sortValue === 'pwg_banking_qtd' || sortValue === 'glue_consumption_per_month' ? 'desc' : 'asc',
					})
					.sort({ createddate: 'desc' });
			} else {
				query = query.sort({ createddate: 'desc' });
			}

			return query;
		},
		{ pageSize: 20, pagination: 'Infinite' }
	);
	useEffect(() => {
		updateSortObject([
			{ value: 'priority', name: 'Priority Order' },
			{ value: 'glue_consumption_per_month', name: 'Glue Potential' },
			{ value: 'pwg_banking_qtd', name: 'PWG Banking in Quarter' },
		]);
		updateSortValue('');
		updateSortActiveStatus(false);
		updateSearch('');
		if (allEndUserFilter === null) {
			updateFilterObject({
				...initialAllEndUsersFilter,
			});
			return;
		}
		updateFilterObject(allEndUserFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		updateAllEndUserFilter(filterObject);
	}, [filterObject, updateAllEndUserFilter]);

	useEffect(() => {
		const getData = setTimeout(() => {
			setSearchElement(search);
		}, 500);
		return (): void => clearTimeout(getData);
	}, [search]);

	useInfiniteScroll(() => {
		fetchMore();
	});

	return (
		<Wrapper isLoaderVisible={queryFetching || (isFetching && result.length === 0)} isError={error}>
			<div className="user-card-list-wrapper">
				<ul className="user-card-list row">
					{result.length > 0 ? (
						result.map((endUser, index) => {
							return <UserCard key={endUser?._data.id} userObject={endUser._data} index={index} state={'/all'} />;
						})
					) : (
						<li className="d-col d-col-2">
							<p className="h3">No Data Found!</p>
						</li>
					)}
				</ul>
			</div>
		</Wrapper>
	);
};
