import { ReactElement } from 'react';
import { ITimePickerProps } from '.';
import Dropdown from '../formControl/dropdown/dropdown';
import { generateTimeOptions } from '@helper/utils';

export const TimePicker = (props: ITimePickerProps): ReactElement => {
	const timeOptions = props.minTime ? generateTimeOptions(props.minTime) : generateTimeOptions();
	const { formik, required, error, id, name, label, value, disabled, icon } = props;
	return (
		<Dropdown
			id={id}
			title={value}
			name={name}
			label={label}
			options={timeOptions}
			onBlur={formik.handleBlur}
			setFieldValue={formik.setFieldValue}
			disabled={disabled}
			required={required}
			error={error}
			icon={icon}
		/>
	);
};
