import { Fragment, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { IAdditionalDetailSectionProps } from '.';

export const AdditionalDetailSection = (props: IAdditionalDetailSectionProps): ReactElement => {
	const { productData } = props;

	// commented the navigation for the additional page confirmation from the client side
	return (
		<Fragment>
			<div className="addtional-details-wrapper">
				<NavLink
					to=""
					// to="/contractor/calculator/calculator-details"
					state={{ productData }}
					aria-label="addtional-details"
					className="addtional-details-btn"
				>
					<span className="title">Additional Details</span>
					<span className="icon-arrow-right-circle"></span>
				</NavLink>
			</div>
			<p className="clac-bottom-text">
				<span className="clac-red">*</span>Total Points Achieved - Sum of (Basic+ Backend Bonus + Campaign+ Scheme Slab
				points)
			</p>
			<div className="disclaimer-content">
				<p>
					<span>Disclaimer:</span> The scheme calculations are tentative in nature based on current schemes. Please get
					in touch with the concerned Fevicol employee for complete clarity on Fevicol Scheme benefits.
				</p>
				<p>
					<span className="clac-red">*</span> <span>Terms and Conditions:</span> The scheme calculations are tentative
					in nature based on current schemes. Please get in touch with the concerned Fevicol employee for complete
					clarity on Fevicol Scheme benefits.
				</p>
			</div>
		</Fragment>
	);
};
