import '@assets/styles/pages/error-screen.scss';
import { ReactElement, useCallback } from 'react';
import { NavLink, useNavigate, useRouteError } from 'react-router-dom';
import MpowerLogo from '../assets/images/mpower-logo-white.png';
import Octagon from '../assets/images/alert-octagon.png';

export interface ErrorInstance {
	data: string;
	status: number;
	error: {
		message: string;
	};
}

export const ErrorPage = (): ReactElement => {
	const error = useRouteError() as ErrorInstance;
	const navigate = useNavigate();

	const handleError = useCallback(() => {
		if (error.status === 404) {
			navigate('/');
			return;
		}
		window.location.reload();
	}, [error, navigate]);

	return (
		<>
			<section className="alert-message-section">
				<div className="about-content-wrapper">
					<div className="about-content">
						<NavLink to={'/'} aria-label="home page url" className="header-logo">
							<img src={MpowerLogo} alt="logo" title="logo" width="160" height="40" />
						</NavLink>

						<div className="error-message-content">
							<div className="alert-image">
								<img src={Octagon} alt="alert octagon" title="alert octagon" width="120" height="120" />
							</div>

							<h1>Oops!</h1>

							<p className="alert-message">
								{error.status === 404 ? 'Page not found' : 'Please click on ‘Try Again’ button to refresh.'}
							</p>
						</div>
					</div>

					<div className="try-again-button">
						<button type="button" aria-label="try again" onClick={handleError} className="btn btn-primary btn-medium">
							{error.status === 404 ? 'Go Home' : 'Try Again'}
						</button>
					</div>
				</div>
			</section>
		</>
	);
};
