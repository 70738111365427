import { RxCollectionCreator } from 'rxdb';

export const productDescriptionListSchema: RxCollectionCreator = {
	schema: {
		title: 'Product Description schema',
		description: 'create Product Description List schema',
		version: 0,
		primaryKey: 'prdgroup3_c',
		type: 'object',
		properties: {
			id: {
				type: 'number',
			},
			productgroup3description_c: { type: 'string' },
			prdgroup3_c: { type: 'string', maxLength: 200 },
		},
		required: ['id', 'prdgroup3_c', 'productgroup3description_c'],
	},
};
