import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import {
	AllMeetingParticipantsParams,
	AllParticipantsObject,
} from '@components/todayMeetingDetails/components/attendedMembers';

export const useGetParticipantsQuery = (
	parameters: AllMeetingParticipantsParams
): IQueryResponse<Array<AllParticipantsObject>> => {
	const fetchParticipantsList = async (): Promise<Array<AllParticipantsObject>> => {
		const response = await APIService.getData<Array<AllParticipantsObject>>(
			`${APICONFIG.MEETINGS_DETAILS}/${parameters.id}/all-participants`,
			{
				...(parameters.attendance_c && { attendance_c: `${parameters.attendance_c}` }),
				...(parameters.convert_lead_c && { convert_lead_c: `${parameters.convert_lead_c}` }),
				...(parameters.invited_c && { invited_c: `${parameters.invited_c}` }),
				...(parameters.non_invited_c && { non_invited_c: `${parameters.non_invited_c}` }),
				...(parameters.pidilite_employ_c && { pidilite_employ_c: `${parameters.pidilite_employ_c}` }),
			}
		);

		return response;
	};

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['Participants', { ...parameters }],
		queryFn: fetchParticipantsList,
		enabled: parameters.enable ?? true,
		refetchOnWindowFocus: false,
		retry: false,
		refetchInterval: 120000,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
