import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { TransactionDetails } from '@components/contractor360/components/qrScanner/components/qrCodeValidate';

interface IBarcodeDetails {
	mutate: UseMutateFunction<TransactionDetails, Error, string, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useGetMembershipNumber = (): IBarcodeDetails => {
	const getMembershipNumberData = async (barcode: string): Promise<TransactionDetails> => {
		return await APIService.getData<TransactionDetails>(`${APICONFIG.GET_MEMBERSHIP_NUMBER}`, {
			number: barcode,
			format: 'json',
		});
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: getMembershipNumberData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
