import { create } from 'zustand';

interface IContractor360Store {
	isSiteStatusModalOpen: boolean;
	status: string | null;
	siteId: number | null;
	setSiteStatusModal: (val: boolean, status?: string | null, siteId?: number | null) => void;
}

const initialState = {
	isSiteStatusModalOpen: false,
	status: null,
	siteId: null,
};

export const useContractor360Store = create<IContractor360Store>((set) => ({
	...initialState,
	setSiteStatusModal: (val: boolean, currentStatus?: string | null, id?: number | null): void =>
		set((state) => ({ ...state, isSiteStatusModalOpen: val, status: currentStatus, siteId: id })),
}));
