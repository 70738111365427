import { create } from 'zustand';

interface ICompetitionConversionStore {
	reason: string;
	grade: string;
	type_of_contract: string;
	setReason: (value: string) => void;
	setGrade: (value: string) => void;
	setTypeOfContract: (value: string) => void;
	reset: () => void;
}

const initialState = {
	reason: '',
	grade: '',
	type_of_contract: '',
};

export const useCompetitionConversionStore = create<ICompetitionConversionStore>((set) => ({
	...initialState,
	setReason: (value: string): void => set((state) => ({ ...state, reason: value })),
	setGrade: (value: string): void => set((state) => ({ ...state, grade: value })),
	setTypeOfContract: (value: string): void => set((state) => ({ ...state, type_of_contract: value })),
	reset: (): void => {
		set(initialState);
	},
}));
