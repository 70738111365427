import { ReactElement } from 'react';
import successImage from '@assets/images/success-image.png';
import { useSuccessTosterStore } from '@store/successToster';

interface ISuccessToster {
	onClose: () => void;
}

export const SuccessModal = (props: ISuccessToster): ReactElement => {
	const { onClose } = props;
	const { navigateUrl, message } = useSuccessTosterStore();

	const handleClose = (): void => {
		if (navigateUrl.length > 0) {
			window.location.href = navigateUrl;
			onClose();
			return;
		}
		onClose();
	};

	return (
		<div className="qr-code-wrapper success-message">
			<div className="success-image">
				<img src={successImage} alt="success" title=" success" width="200" height="140" />
			</div>

			<span className="h3">{message}</span>

			<button className="close" onClick={handleClose}>
				Close
			</button>
		</div>
	);
};
