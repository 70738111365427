import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { AllSiteSummaryFilterObject, useSiteSummaryListingStore } from '@store/siteSummaryListingFilterStore';
import { formatDateDDMMYY, isFilterValuePresent } from '@helper/utils';

export const SiteSummaryFilterTag = (): ReactElement => {
	const { allSiteSummaryFilter, updateFilter, resetSiteSummaryFilter } = useSiteSummaryListingStore();
	const renderFilterValue = (key: string, value: keyof AllSiteSummaryFilterObject): string => {
		if (key === 'lastactivitydate') {
			if (Array.isArray(value) && value.length === 2 && value[0] instanceof Date && value[1] instanceof Date) {
				const startDate = value[0];
				const endDate = value[1];
				return `${formatDateDDMMYY(startDate)} - ${formatDateDDMMYY(endDate)}`;
			}
		}
		if (key === 'competition_site') {
			return `Competition Site`;
		}
		if (key === 'adopted_site') {
			return `Adopted Site`;
		}
		return String(value);
	};
	const handleCross = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		key: string,
		filterData: AllSiteSummaryFilterObject
	): void => {
		e.preventDefault();
		let defaultValue;
		if (key === 'lastactivitydate') {
			defaultValue = [null, null];
		}
		if (!defaultValue) {
			defaultValue = '';
		}
		updateFilter({
			...filterData,
			[key]: defaultValue,
		});
	};

	if (!allSiteSummaryFilter || !Object.values(allSiteSummaryFilter).some(isFilterValuePresent)) return <></>;

	return (
		<div className="filter-tags">
			<ul className="tag-list">
				{Object.entries(allSiteSummaryFilter).map(([key, value]) => {
					if (isFilterValuePresent(value)) {
						return (
							<li key={key}>
								{`${renderFilterValue(key, value)}`}
								<NavLink
									to="#"
									aria-label="disable tag"
									onClick={(e) => {
										handleCross(e, key, allSiteSummaryFilter);
									}}
								>
									<span className="icon-cross"></span>
								</NavLink>
							</li>
						);
					}
				})}
				{Object.values(allSiteSummaryFilter).some(isFilterValuePresent) && (
					<NavLink to="#" aria-label="Clear All" className="clear-all-btn" onClick={resetSiteSummaryFilter}>
						Clear All
					</NavLink>
				)}
			</ul>
		</div>
	);
};
