import { PurchaseButton } from '@components/common';
import { ReactElement } from 'react';
import { ISchemeDiscussion } from '.';

export const SchemeDiscussion = (props: ISchemeDiscussion): ReactElement => {
	const { formik, slabData, fpcDetails } = props;

	const handleChange = (value: string): void => {
		formik.setFieldValue('slab', value);
	};

	return (
		<div className="scheme-discussion-wrapper">
			<h2 className="list-title">
				<span className="title-wrapper">
					<span className="title-number">2</span>
					<span className="title-name">Scheme Discussion</span>
				</span>
			</h2>

			<div className="scheme-discussion-about">
				<span className="scheme-title">Slab Scheme Update</span>
				<p className="scheme-about">
					{slabData?.data?.total_banked ?? '0'} KGs banked. Slab {slabData?.data?.slab ?? 'NA'}
					{slabData?.data?.slab && slabData?.data?.slab !== 'NA' ? 'KGs' : ''}. {slabData?.data?.remaining_kgs ?? '100'}{' '}
					KGs to go for {slabData?.data?.next_slab ?? '100-200'} KG slab.
				</p>

				<span className="purchas-btn-wrapper">
					<PurchaseButton
						label="Yes"
						icon="icon-thumbs-up"
						handleClick={() => handleChange('True')}
						isActive={formik.values.slab === 'True'}
					/>

					<PurchaseButton
						label="No"
						icon="icon-thumbs-down"
						handleClick={() => handleChange('False')}
						isActive={formik.values.slab === 'False'}
					/>
				</span>

				{fpcDetails?.data && (
					<div className="scheme-discussion-details">
						<div className="tier-status">
							<span className="title">FPC Tier Status</span>
							<span className={`status ${!fpcDetails?.data?.track ? 'danger-tag' : ''}`}>
								<span className="dot"></span>
								<span className="track"> {fpcDetails?.data?.track ? 'ON' : 'OFF'} Track</span>
							</span>
						</div>

						<span className="packaging-details">
							PWG YTD Achieved: {fpcDetails?.data?.pwg_ytd ? `${fpcDetails?.data?.pwg_ytd} Kg` : '-'}
						</span>
						<span className="packaging-details">
							Balance: {fpcDetails?.data?.balance ? `${fpcDetails?.data?.balance} Kg` : '-'}
						</span>
					</div>
				)}
			</div>
		</div>
	);
};
