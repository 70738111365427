import { DropdownOptions } from '@components/common/formControl/dropdown';

export interface IdefaultAddSiteLeadFormInitialValues {
	siteName: string | null;
	contractorName: string | null;
	contractorNumber: string | null;
	contractType?: string;
	gradeUsed: string | null;
	brandUsed: string | null;
	glueUsedAtSite: string | null;
	competitionReason: string | null;
	siteLocation: string | null;
	substratesCombinations: DropdownOptions[];
}

export const defaultAddSiteLeadFormInitialValues: IdefaultAddSiteLeadFormInitialValues = {
	siteName: '',
	contractorName: '',
	contractorNumber: '',
	contractType: '',
	gradeUsed: '',
	brandUsed: '',
	glueUsedAtSite: 'FV',
	competitionReason: '',
	siteLocation: '',
	substratesCombinations: [],
};

export type TGradeListDetails = {
	id: number;
	value: string;
};
export type TCompetitionListDetails = {
	id: number;
	value: string;
};

export const substratesOptions: DropdownOptions[] = [
	{ id: 1, value: 'NA' },
	{ id: 2, value: 'PVC/ WPC Board TO Paper Laminate/Veneer/Plywood/MDP' },
	{ id: 3, value: 'PVC/ WPC Board TO Charcoal Sheet/Rafter/Marble' },
	{ id: 4, value: 'Laminate TO Laminate/ Acrylic/ PVC Sheet' },
	{ id: 5, value: 'Plywood TO Acrylic/PVC/ Charcoal Sheet' },
	{ id: 6, value: 'MDF Board TO Acrylic/PVC/ Charcoal Sheet' },
	{ id: 7, value: 'Plywood TO PVC Edge Banding Tape' },
	{ id: 8, value: 'Plywood/Concrete Wall/ Aluminium TO HPL' },
	{ id: 9, value: 'Plywood/Concrete Wall/Aluminium TO Lowers' },
	{ id: 10, value: 'ACP TO Aluminium' },
];
