import { ReactElement } from 'react';
import { BankingTertiaryCard } from './bankingTertiaryDetailCard';
import { NavLink, useLocation } from 'react-router-dom';
import { IBankingTertiaryDetails } from '.';

export const BankingTertiaryDetails = (props: IBankingTertiaryDetails): ReactElement => {
	const { bankingDetails } = props;
	const { state } = useLocation();
	return (
		<section className="tertiary-section">
			<div className="container">
				<ul className="tertiary-details-card-list">
					<BankingTertiaryCard label="Tertiary Current Month" value={bankingDetails?.data?.tertiary_current_month} />
					<BankingTertiaryCard
						label="Tertiary Current Quarter"
						value={bankingDetails?.data?.tertiary_current_quarter}
					/>
				</ul>
				<div className="banking-history-card">
					<NavLink
						to={'/contractor/banking-history'}
						state={{
							number: state.number,
						}}
						aria-label="product banked tab"
						className="banking-history-card-wrapper"
					>
						<div className="left-content">
							<span className="icon-file" />
							<h2 className="h5">Banking History</h2>
						</div>

						<span className="icon-arrow-right-circle" />
					</NavLink>
				</div>
			</div>
		</section>
	);
};
