import { ReactElement, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { INavigatorTab, ITabList } from '@components/common';
import { useEndUserToggle } from '@hooks/useEndUserToggle';
import { useFilterStore } from '@store/index';

export const NavigatorTab = (props: INavigatorTab): ReactElement => {
	const { tabList, children, selectedFilterChild } = props;
	const { pathname } = useLocation();
	const { toggleTab } = useEndUserToggle();
	const { updateSearch } = useFilterStore();

	useEffect(() => {
		toggleTab();
		window.addEventListener('resize', toggleTab);
	}, [pathname, toggleTab]);

	const handleResetSearch = (): void => {
		updateSearch('');
	};

	return (
		<>
			<div className="tab-search-wrapper">
				<div className="tab-wrapper">
					<div className="tab-main">
						<div className="tab-inner">
							<ul className="tab">
								{tabList.map((tab: ITabList, index: number) => {
									return (
										<li key={tab.name + index}>
											<NavLink
												to={tab.pathUrl}
												aria-label={tab.name}
												end
												className={({ isActive }) => {
													return 'tablinks ' + (isActive ? 'active' : '');
												}}
												onClick={handleResetSearch}
											>
												<span className="icon">
													<span className={tab.tabIcon}></span>
												</span>
												<span className="tab-name">{tab.name}</span>
											</NavLink>
										</li>
									);
								})}
							</ul>
							<div className="sliding-indicator"></div>
						</div>
					</div>
				</div>
				{children}
			</div>
			{selectedFilterChild}
		</>
	);
};
