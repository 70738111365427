import { ReactElement, useCallback } from 'react';
import { IArchitectDetailProps, TAccordianDetails } from '.';
import { ActionButton, TextInput } from '@components/common';
import { keyDownEvent } from '@helper/utils';

export const AccordianDetailList = (props: IArchitectDetailProps): ReactElement => {
	const { formik, accordianListIndex, data, accordianName, title, isAddition, isEditing } = props;

	const handleAddArchitectDetails = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			e.preventDefault();
			formik.setFieldValue(accordianName, [
				...formik.values[accordianName],
				{
					id: formik.values[accordianName].length,
					name: '',
					number: '',
					isActive: true,
				},
			]);
		},
		[accordianName, formik]
	);
	const handleRemoveArchitectDetails = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, childIndex: number | undefined) => {
			e.preventDefault();
			if (formik.values[accordianName].length > 1) {
				const updatedArchitectDetails = formik.values[accordianName].filter(
					(_: TAccordianDetails, i: number) => i !== childIndex
				);
				formik.setFieldValue(accordianName, updatedArchitectDetails);
			}
		},
		[accordianName, formik]
	);

	const handleToggle = useCallback(() => {
		const updatedArchitectDetails = formik.values[accordianName].map((item: TAccordianDetails, index: number) => {
			if (index === accordianListIndex) {
				return {
					...item,
					isActive: !item.isActive,
				};
			}
			return item;
		});
		formik.setFieldValue(accordianName, updatedArchitectDetails);
	}, [accordianName, accordianListIndex, formik]);

	return (
		<li className="d-col d-col-2">
			<div className={`details-box-wrapper ${data?.isActive ? 'active' : ''}`}>
				<span className="details-title">{title}</span>
				<span
					aria-hidden={true}
					title="Toggle icon"
					aria-label="Toggle menu"
					className="icon-plus-circle plus-icon"
					onClick={handleToggle}
				></span>
				<div className="input-details-wrapper">
					<div className="input-details">
						<TextInput
							type="text"
							placeholder="Name"
							id={`${accordianName}_${accordianListIndex}`}
							name={`${accordianName}[${accordianListIndex}].name`}
							onBlur={formik.handleBlur}
							value={formik.values[accordianName][accordianListIndex]?.name}
							setFieldValue={formik.setFieldValue}
							error={
								formik.touched?.[accordianName]?.[accordianListIndex]?.name && formik.errors[accordianName]
									? formik.errors[accordianName][accordianListIndex]?.name
									: null
							}
							disabled={isEditing}
						/>
					</div>

					<div className="input-details">
						<TextInput
							type="text"
							placeholder="Number"
							id={`${accordianName}_${accordianListIndex}`}
							name={`${accordianName}[${accordianListIndex}].number`}
							onBlur={formik.handleBlur}
							value={formik.values[accordianName][accordianListIndex]?.number}
							setFieldValue={formik.setFieldValue}
							onKeyDown={keyDownEvent}
							disabled={isEditing}
							maxLength={10}
							error={
								formik.touched?.[accordianName]?.[accordianListIndex]?.number && formik.errors[accordianName]
									? formik.errors[accordianName][accordianListIndex]?.number
									: null
							}
						/>
					</div>
				</div>
				<div className="add-remove-wrapper">
					{formik.values[accordianName].length > 1 && accordianListIndex !== 0 && (
						<ActionButton
							label="Remove"
							handlerFn={handleRemoveArchitectDetails}
							className="remove-btn"
							childIndex={accordianListIndex}
							disabled={isEditing}
							action="remove"
						/>
					)}
					{isAddition &&
						accordianListIndex === formik.values[accordianName].length - 1 &&
						formik.values[accordianName].length < 3 && (
							<ActionButton label="Add More" handlerFn={handleAddArchitectDetails} disabled={isEditing} action="add" />
						)}
				</div>
			</div>
		</li>
	);
};
