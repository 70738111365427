import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';
import { IGrade } from '@components/competition-conversion/components/competitionConversionGrade';

export const useFetchGrades = (): IQueryResponse<Array<IGrade>> => {
	const fetchCompetitionConversionGradeData = async (): Promise<Array<IGrade>> => {
		return await APIService.getData<Array<IGrade>>(APICONFIG.COMPETITION_CONVERSION_GRADE, {
			limit: '0',
		});
	};

	const { data, error, isLoading, isPending } = useQuery({
		queryKey: ['competitionConversionGrade'],
		queryFn: fetchCompetitionConversionGradeData,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
	};
};
