import { create, StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { IPermissionModel } from '../authGuard';

interface IRolesPermissionState {
	permissions: IPermissionModel[];
	setPermissions: (permissions: IPermissionModel[]) => void;
}

const initialState: Omit<IRolesPermissionState, 'setPermissions'> = {
	permissions: [],
};

type RolesPermissionPersist = (
	config: StateCreator<IRolesPermissionState>,
	options: PersistOptions<IRolesPermissionState>
) => StateCreator<IRolesPermissionState>;

export const useRolesPermission = create<IRolesPermissionState>(
	(persist as RolesPermissionPersist)(
		(set) => ({
			...initialState,
			setPermissions: (permissions: IPermissionModel[]): void => set((state) => ({ ...state, permissions })),
		}),
		{
			name: 'permissions',
		}
	)
);
