import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { CalenderPopUp, defaultCalendarValues } from '@components/calendar';
import { SingleDatePicker } from '@components/common';
import TextInput from '@components/common/formControl/textInput/textInput';
import { TimePicker } from '@components/common/time-picker/time-picker';
import {
	calculateEndTimeAndDate,
	compareDatePartsOnly,
	convertDateToTime,
	convertTime,
	formatDate,
	formatTime,
	generateTimeOptions,
	isTimePast,
	roundToNearestQuarterHour,
} from '@helper/utils';
import { useValidation } from '@hooks/validations';
import { useCalendarMutation } from '@services/hooks/calendar/useCalendarMutation';
import { ToastType, useTosterStore } from '@store/toster';

export const AddVisitModal: React.FC<CalenderPopUp> = ({ dateObject, onClose, refetch, setData }) => {
	const { mutate, isPending } = useCalendarMutation(dateObject?.id);
	const { CalendarSchema } = useValidation();
	const { setToaster } = useTosterStore();
	const formik = useFormik({
		initialValues: defaultCalendarValues,
		onSubmit: () => {
			handleSubmitForm();
		},
		validationSchema: CalendarSchema,
	});

	const handleSubmitForm = useCallback(() => {
		const startDateTime = new Date(`${formik.values.startdate} ${formik.values.start_time_c}`);
		const endDateTime = new Date(`${formik.values.enddate} ${formik.values.end_time_c}`);

		if (
			compareDatePartsOnly(new Date(), new Date(formik.values.startdate)) &&
			isTimePast(formatTime(roundToNearestQuarterHour(new Date(formik.values.startdate))), formik.values.start_time_c)
		) {
			setToaster(true, ToastType.error, 'Unable to create past meetings');
			return;
		}

		if (endDateTime < startDateTime) {
			setToaster(true, ToastType.error, 'End date/time cannot be earlier than start date/time');
			return;
		}

		const startDate = new Date(formik.values.startdate);
		const endDate = new Date(formik.values.enddate);
		startDate.setHours(0, 0, 0, 0);
		endDate.setHours(0, 0, 0, 0);
		const payloads = [];

		while (startDate.getTime() <= endDate.getTime()) {
			const payload = {
				name: formik.values.title.trim(),
				meeting_venue_c: formik.values.meeting_venue_c.trim(),
				startdate: formatDate(startDate),
				enddate: formatDate(startDate),
				start_time_c:
					(generateTimeOptions() || []).find((item) => item.value === formik.values.start_time_c)?.id.toString() ?? '',
				end_time_c:
					(generateTimeOptions() || []).find((item) => item.value === formik.values.end_time_c)?.id.toString() ?? '',
			};
			payloads.push(payload);
			startDate.setDate(startDate.getDate() + 1);
		}

		payloads.forEach((payload) => {
			mutate(payload, {
				onSuccess: (data) => {
					if (data?.id) {
						setData({ startDate: formatDate(new Date(formik.values.startdate)), endDate: data.enddate });
						refetch && refetch();
						onClose();
					}
				},
				onError: () => {
					setToaster(true, ToastType.error, 'Unable to Create Meeting');
				},
			});
		});
	}, [formik.values, mutate, onClose, refetch, setData, setToaster]);

	useEffect(() => {
		if (
			compareDatePartsOnly(new Date(), new Date(formik.values.startdate)) &&
			isTimePast(formatTime(roundToNearestQuarterHour(new Date(formik.values.startdate))), formik.values.start_time_c)
		) {
			formik.setFieldValue('start_time_c', formatTime(roundToNearestQuarterHour(new Date(formik.values.startdate))));
		}
		if (formik.values.start_time_c && formik.values.startdate) {
			const startDate = new Date(formik.values.startdate);
			const { endTime, endDate } = calculateEndTimeAndDate(formik.values.start_time_c, startDate);
			!dateObject?.id && formik.setFieldValue('end_time_c', endTime);
			if (
				endDate.getTime() >= startDate.getTime() &&
				(endDate.getDate() !== startDate.getDate() ||
					endDate.getMonth() !== startDate.getMonth() ||
					endDate.getFullYear() !== startDate.getFullYear())
			) {
				formik.setFieldValue('enddate', endDate);
			} else if (endDate.getTime() < startDate.getTime()) {
				formik.setFieldValue('enddate', startDate);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.start_time_c, formik.values.startdate, dateObject?.id]);

	useEffect(() => {
		formik.resetForm();
		if (dateObject) {
			const { title, venu, start, end } = dateObject;
			formik.setValues(
				{
					...formik.values,
					title: title ?? '',
					meeting_venue_c: venu ?? '',
					startdate: start ?? '',
					enddate: end ? new Date(end).toISOString() : '',
					start_time_c: start && convertTime(convertDateToTime(start)),
					end_time_c: end && convertTime(convertDateToTime(end)),
				},
				true
			);
			formik.setFieldTouched('startdate');
			formik.setFieldTouched('enddate');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateObject]);

	return (
		<div className="">
			<form onSubmit={formik.handleSubmit}>
				<div className="popup-content">
					<div className="input-container">
						<div className="form-control">
							<TextInput
								id="title"
								name="title"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.title ?? ''}
								label="Title"
								error={formik.touched.title && formik.errors.title ? formik.errors.title : null}
								required={true}
								setFieldValue={formik.setFieldValue}
							/>
						</div>

						<div className="form-control margin-content">
							<TextInput
								id="meeting_venue_c"
								name="meeting_venue_c"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.meeting_venue_c ?? ''}
								label="Venue"
								error={
									formik.touched.meeting_venue_c && formik.errors.meeting_venue_c ? formik.errors.meeting_venue_c : null
								}
								required={true}
								setFieldValue={formik.setFieldValue}
							/>
						</div>

						<div className="box-wrapper">
							<div className="box-wrapper-list">
								<div className="form-control">
									<SingleDatePicker
										className="single-date-picker"
										label="Start Date"
										name="startdate"
										id="startdate"
										value={formik.values.startdate}
										error={formik.touched.startdate && formik.errors.startdate ? formik.errors.startdate : null}
										required={true}
										formik={formik}
										onChange={(value) => {
											formik.setFieldValue('enddate', value);
										}}
										minDate={dateObject?.id ? undefined : new Date()}
									/>
								</div>

								<div className="form-control">
									<SingleDatePicker
										label="End Date"
										className="single-date-picker"
										name="enddate"
										id="enddate"
										value={formik.values.enddate}
										error={formik.touched.enddate && formik.errors.enddate ? formik.errors.enddate : null}
										required={true}
										formik={formik}
										minDate={dateObject?.id ? undefined : new Date()}
										disabled={!!dateObject?.id}
									/>
								</div>

								<div className="form-control">
									<TimePicker
										name="start_time_c"
										id="start_time_c"
										label="Start Time"
										error={
											formik.touched.start_time_c && formik.errors.start_time_c ? formik.errors.start_time_c : null
										}
										required={true}
										value={formik.values.start_time_c}
										formik={formik}
										icon={'icon-clock'}
										minTime={
											compareDatePartsOnly(new Date(), new Date(formik.values.startdate))
												? formatTime(roundToNearestQuarterHour(new Date(formik.values.startdate)))
												: ''
										}
									/>
								</div>

								<div className="form-control">
									<TimePicker
										name="end_time_c"
										id="end_time_c"
										label="End Time"
										error={formik.touched.end_time_c && formik.errors.end_time_c ? formik.errors.end_time_c : null}
										required={true}
										value={formik.values.end_time_c}
										formik={formik}
										icon={'icon-clock'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="button-component">
						<button className="btn btn-primary btn-cancel" type="button" onClick={onClose}>
							Cancel
						</button>

						<button className="btn btn-primary" type="submit" disabled={!formik.isValid || isPending}>
							Submit
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};
