import * as Yup from 'yup';

// One To One Meeting Form Fields

export interface IOneToOneMeetingFields {
	endUser: Yup.StringSchema;
	typeOfActivity: Yup.StringSchema;
	otherTypeOfActivity: Yup.StringSchema;
	Product1Demo: Yup.StringSchema;
	meetingTime: Yup.StringSchema;
}

export const useOneToOneMeetingFields = (): IOneToOneMeetingFields => {
	const endUser = Yup.string().required('End User is required');
	const typeOfActivity = Yup.string().required('Type of Activity is required');
	const otherTypeOfActivity = Yup.string().when('typeOfActivity', {
		is: 'Others',
		then: (schema) => schema.required('Other Type of Activity is required'),
		otherwise: (schema) => schema.notRequired(),
	});
	const Product1Demo = Yup.string().required('Product 1 Demo is required');
	const meetingTime = Yup.string().required('Meeting Time is required');

	return {
		endUser,
		typeOfActivity,
		otherTypeOfActivity,
		Product1Demo,
		meetingTime,
	};
};

// Quick Add End User Form Fields

export interface IQuickAddEndUserFields {
	firstName: Yup.StringSchema;
	lastName: Yup.StringSchema;
	mobileNumber: Yup.StringSchema;
	endUserCategory: Yup.StringSchema;
	primaryDealer: Yup.StringSchema;
	wssTerritory: Yup.StringSchema;
	zipcode: Yup.StringSchema;
}

export const useQuickAddEndUserFields = (): IQuickAddEndUserFields => {
	const firstName = Yup.string()
		.required('First Name is required')
		.matches(/^(?!\s)(?=.*[a-zA-Z])[a-zA-Z\s.]+$/, 'Only English characters are allowed in the first name');
	const lastName = Yup.string()
		.required('Last Name is required')
		.matches(/^(?!\s)(?=.*[a-zA-Z])[a-zA-Z\s.]+$/, 'Only English characters are allowed in the last name');
	const mobileNumber = Yup.string()
		.matches(/^[1-9]\d{9}$/, 'Mobile Number must be 10 digits long and not start with 0')
		.required('Mobile Number is required');
	const endUserCategory = Yup.string().required('End User Category is required');
	const primaryDealer = Yup.string().required('Primary Dealer is required');
	const wssTerritory = Yup.string().required('WSS Territory is required');
	const zipcode = Yup.string().min(6).required('Zipcode is required');

	return {
		firstName,
		lastName,
		mobileNumber,
		endUserCategory,
		primaryDealer,
		wssTerritory,
		zipcode,
	};
};
