import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';
import { setRxdb } from '@helper/utils';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { IDealerVisits } from '@components/delaerVisit';

export const useDealerVisits = (): IQueryResponse<IDealerVisits[]> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('dealer_visits');
	const fetchDealerVisitData = async (): Promise<IDealerVisits[]> => {
		const response = await APIService.getData<IDealerVisits[]>(APICONFIG.DEALER_VISITS, {
			limit: '0',
		});
		if (response) {
			const data = response.map((item) => {
				return { ...item, id: item.id.toString(), createddate: item.createddate ?? new Date().toDateString() };
			});
			await setRxdb('dealer_visits', data);
		}
		return response;
	};

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['dealerVisit'],
		queryFn: fetchDealerVisitData,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
