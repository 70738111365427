import { IProductData } from '@services/hooks/calculator';
import React, { ReactElement } from 'react';

interface IOtherProps {
	onChangeQuantity: (type: string, productId: number, manualQuantity?: number, skuId?: number) => void;
}

export const ProductList = ({
	id,
	product_name,
	unit_of_measurement,
	quantity,
	product_sku,
	onChangeQuantity,
}: IProductData & IOtherProps): ReactElement => {
	return (
		<React.Fragment key={id}>
			<div className={`clac-product-box ${product_sku.length ? 'child-main-wrapper' : 'main-box'}`}>
				<div className="clac-product-name">{product_name}</div>
				<div className="clac-product-qty">
					<div className="clac-counter-box">
						<button
							className="input-btn"
							onClick={() => {
								const qty = quantity || 0;
								if (!(qty - 1 < 0)) {
									onChangeQuantity('decrease', id);
								}
							}}
						>
							<span className="icon-minus"></span>
						</button>
						<input
							type="text"
							value={quantity}
							className="clac-qty-count"
							onChange={(e) => {
								const value = e.target.value;
								if ((value && /^[0-9]+$/.test(value) && value.length < 5) || !value) {
									onChangeQuantity(product_sku.length ? 'manualSku' : 'manual', id, Number(value));
								}
							}}
						/>
						<button
							className="input-btn"
							onClick={() => {
								const qty = quantity || 0;
								if (String(qty + 1).length < 5) {
									onChangeQuantity('increase', id);
								}
							}}
						>
							<span className="icon-plus"></span>
						</button>
					</div>
					<div className="clac-product-unit">{unit_of_measurement}</div>
				</div>
			</div>
			{product_sku.length > 0 &&
				product_sku.map((sku) => {
					return (
						<div key={sku.id} className={`clac-product-box`}>
							<div className="clac-product-name">{sku.sku_name}</div>
							<div className="clac-product-qty">
								<div className="clac-counter-box">
									<button
										className="input-btn"
										onClick={() => {
											const qty = sku.quantity || 0;
											if (!(qty - 1 < 0)) {
												onChangeQuantity('decrease', id, 0, sku.id);
											}
										}}
									>
										<span className="icon-minus"></span>
									</button>
									<input
										type="text"
										disabled={sku.sku_unit !== 1}
										onChange={(e) => {
											const value = e.target.value;
											if ((value && /^[0-9]+$/.test(value) && value.length < 5) || !value) {
												onChangeQuantity('manual', id, Number(value), sku.id);
											}
										}}
										value={sku.quantity}
										className="clac-qty-count"
									/>
									<button
										className="input-btn"
										onClick={() => {
											const qty = sku.quantity || 0;
											if (String(qty + 1).length < 5) {
												onChangeQuantity('increase', id, 0, sku.id);
											}
										}}
									>
										<span className="icon-plus"></span>
									</button>
								</div>
								<div className="clac-product-unit">{unit_of_measurement}</div>
							</div>
						</div>
					);
				})}
		</React.Fragment>
	);
};
