import { useEffect } from 'react';

export interface IOutsideDialogClose {
	dialogRef: React.RefObject<HTMLDivElement | HTMLDialogElement>;
	callBackFunction: () => void;
}
export const useOutsideDialogClose = (props: IOutsideDialogClose): void => {
	const { dialogRef, callBackFunction } = props;
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent): void => {
			if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
				callBackFunction();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return (): void => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
