import '@assets/styles/pages/visit-record.scss';
import { ReactElement, useMemo } from 'react';
import VisitRecordImage from '@assets/images/check-circle 1.png';
import { useNavigate } from 'react-router-dom';
import useSiteLeadStore from '@store/siteLeadStore';
import { useSetMainHeader } from '@hooks/useSetMainHeader';
import { getCurrentQuarterNumber } from '@helper/utils';

export const VisitRecord = (): ReactElement => {
	useSetMainHeader();
	const nav = useNavigate();
	const { clearSiteLeadPersistedState, siteDetails } = useSiteLeadStore();

	const currentYear = useMemo((): string => {
		return new Date().getFullYear().toString().slice(-2);
	}, []);

	const handleClick = (): void => {
		clearSiteLeadPersistedState();
		nav('/');
	};
	return (
		<section className="visit-recorded-section">
			<div className="container">
				<div className="visit-recorded-wrapper">
					<picture>
						<img src={VisitRecordImage} alt="check circle" title="success visit" width="200" height="200" />
					</picture>

					<h1 className="title">Visit Recorded</h1>
					<p className="text-center">
						Successfully recorded a visit for {siteDetails?.name} in {getCurrentQuarterNumber('Q')} FY{currentYear}
					</p>

					<div className="btn-wrapper">
						<button type="button" className="btn btn-primary btn-medium" id="dashboard-btn" onClick={handleClick}>
							Go to Dashboard
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};
