import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PincodeData } from '.';
import { IPincodeDetails } from '@services/api';
import { ToastType, useTosterStore } from '@store/toster';

export const usePincodeQuery = (): IPincodeDetails => {
	const [pincode, setPincode] = useState<string>('');
	const [state, setState] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [isError, setIsError] = useState<boolean>(false);
	const [isValidatingZipcode, setIsValidatingZipcode] = useState<boolean>(false);
	const { setToaster } = useTosterStore();

	const fetchPincodeData = async (): Promise<PincodeData[]> => {
		const result = await fetch(`https://api.postalpincode.in/pincode/${pincode}`).then((res) => res.json());
		return result;
	};

	const { data, isLoading, refetch, isFetching } = useQuery({
		queryKey: ['pincodeDetails', pincode],
		queryFn: fetchPincodeData,
		enabled: false,
	});

	useEffect(() => {
		if (data && data.length > 0) {
			const pincodeData = data[0];
			if (pincodeData.Status !== 'Success' || !pincodeData.PostOffice) {
				setToaster(true, ToastType.error, 'Invalid zip code!');
				setIsError(true);
				setState('');
				setCity('');
				return;
			}
			setState(pincodeData.PostOffice[0]?.State);
			setCity(pincodeData.PostOffice[0]?.District);
			setIsError(false);
			setIsValidatingZipcode(false);
		}
	}, [data, setToaster]);

	useEffect(() => {
		if (pincode.length === 6) {
			const timeoutId = setTimeout(() => {
				refetch();
			}, 1500);

			return (): void => clearTimeout(timeoutId);
		}
		setState('');
		setCity('');
		setIsValidatingZipcode(true);
	}, [pincode, refetch]);

	return { pincode, setPincode, state, city, isLoading, isError, isFetching, isValidatingZipcode };
};

export default usePincodeQuery;
