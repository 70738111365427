import { ReactElement } from 'react';

export const Loader = (): ReactElement => {
	return (
		<div className="loading">
			<span className="loading-dot"></span>
			<span className="loading-dot"></span>
			<span className="loading-dot"></span>
		</div>
	);
};
