import { ReactElement } from 'react';
import { RedemptionObject } from '.';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';

export const RedemptionData = (props: { redemptionData: RedemptionObject[] }): ReactElement => {
	const { redemptionData } = props;
	return (
		<div className="redemption-card-wrapper">
			<ul className="redemption-card-list row">
				{redemptionData?.length > 0 ? (
					redemptionData?.map((item) => (
						<li className="d-col d-col-2" key={item?.id}>
							<div className="redemption-user-card">
								<div className="redemption-card-upper-content">
									<span>{item?.name?.length ? item?.name : '--'} </span>
									<div className="points-details">
										<p>Qt- {item?.quantity} </p>
										<span className="points">{parseInt(item?.amount)} Pts</span>
									</div>
								</div>
								<div className="redemption-card-bottm-content">
									<span className="delivery-dates">
										<span>Order Date- {item?.orderDate}</span>
										<span>Delivery Date- {item?.billingDate}</span>
									</span>
									<span className="delivery-status">{item?.deliveryStatus}</span>
								</div>
							</div>
						</li>
					))
				) : (
					<NoDataFound message="No data found please find with other dates." />
				)}
			</ul>
		</div>
	);
};
