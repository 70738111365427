import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { ErrorHandler } from '@helper/errorHandler';

interface ILogoutDetails {
	message: string;
	data: null;
}

interface ILogout {
	mutate: UseMutateFunction<ILogoutDetails, Error, string, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useLogoutMutation = (): ILogout => {
	const logout = async (token: string): Promise<ILogoutDetails> => {
		try {
			return await APIService.postData<ILogoutDetails>(
				APICONFIG.LOGOUT,
				{
					refreshToken: token,
				},
				{
					'Clear-Site-Data': `"cache", "cookies", "storage"`,
				}
			);
		} catch (error) {
			ErrorHandler(error);
			return {
				message: 'Error during logout!',
				data: null,
			};
		}
	};

	const { mutate, error, isError, isPending } = useMutation<ILogoutDetails, Error, string>({
		mutationFn: logout,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
