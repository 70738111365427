import { IPermissionModel } from './';

export const IMRPermission: IPermissionModel[] = [
	{ name: 'Dashboard', route: '/', components: ['meeting-banner', 'user-sufficiency-summary'] },
	{ name: 'EndUsers', route: '/end-users', components: [] },
	{ name: 'NewLeads', route: '/end-users/new-leads', components: [] },
	{ name: 'All', route: '/end-users/all', components: [] },
	{ name: 'Scheme', route: '/scheme', components: [] },
	{ name: 'SiteSummary', route: '/site-summary', components: [] },
	{ name: 'Profile', route: '/profile', components: [] },
	{ name: 'CompetitionConversion', route: '/competition-conversion', components: [] },
	{ name: 'TypeOfContractor', route: '/competition-conversion/type-of-contractor', components: [] },
	{ name: 'GradeOfCompetition', route: '/competition-conversion/grade-of-competition', components: [] },
	{ name: 'ProductsRecommended', route: '/competition-conversion/products-recommended', components: [] },
	{ name: 'Gallery', route: '/gallery', components: [] },
	{ name: 'PCMS', route: '/pcms', components: [] },
	{ name: 'HelpAndSupport', route: '/help-and-support', components: [] },
	{ name: 'AddMemberDetails', route: '/add-member-details', components: [] },
	{ name: 'BasicDetails', route: '/add-member-details/basic-details', components: [] },
	{ name: 'KycDetails', route: '/add-member-details/kyc-details', components: [] },
	{ name: 'MoreDetails', route: '/add-member-details/more-details', components: [] },
	{ name: 'EditMemberDetails', route: '/edit-member-details', components: [] },
	{ name: 'EditBasicDetails', route: '/edit-member-details/basic-details', components: [] },
	{ name: 'EditKycDetails', route: '/edit-member-details/kyc-details', components: [] },
	{ name: 'EditMoreDetails', route: '/edit-member-details/more-details', components: [] },
	{ name: 'Contractor', route: '/contractor', components: [] },
	{ name: 'BankingDetails', route: '/contractor/banking-details', components: [] },
	{ name: 'Calculator', route: '/contractor/calculator', components: [] },
	{ name: 'CalculatorAdditionalDetails', route: '/contractor/calculator/calculator-details', components: [] },
	{ name: 'BankingHistory', route: '/contractor/banking-history', components: [] },
	{ name: 'PointsStatement', route: '/contractor/points-statement', components: [] },
	{ name: 'RedemptionHistory', route: '/contractor/redemption-history', components: [] },
	{ name: 'QrCodeScanner', route: '/contractor/qr-code-scanner', components: [] },
	{ name: 'QrCodeCheck', route: '/contractor/qr-code-check', components: [] },
	{ name: 'QrCodeValidate', route: '/contractor/qr-code-validate', components: [] },
	{ name: 'SiteSummary', route: '/contractor/site-summary', components: [] },
	{ name: 'Calendar', route: '/calendar', components: [] },
	{ name: 'AddNewSite', route: '/add-new-site', components: [] },
	{ name: 'ActivityModule', route: '/activity-module', components: [] },
	{ name: 'UserSufficiencySummary', route: '/user-sufficiency-summary', components: [] },
	{ name: 'SiteLeadOverview', route: '/site-lead-overview', components: [] },
	{ name: 'SiteDetails', route: '/site-lead-overview/site-details', components: [] },
	{ name: 'VisitDetails', route: '/site-lead-overview/visit-details', components: [] },
	{ name: 'AddPcmsRegistration', route: '/add-pcms-registration', components: [] },
	{ name: 'EditPcmsRegistration', route: '/edit-pcms-registration', components: [] },
	{ name: 'StructureQuestionnaires', route: '/structured-questionnaires', components: [] },
	{ name: 'FieldMarketingContent', route: '/field-marketing-content', components: [] },
];
