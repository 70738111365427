export const profileSchema = {
	title: 'profile schema',
	description: 'Profile Schema',
	version: 2,
	primaryKey: 'sfid',
	type: 'object',
	properties: {
		sfid: {
			type: 'string',
			maxLength: 250,
			primary: true,
		},
		id: {
			type: 'number',
		},
		name: {
			type: 'string',
		},
		usertype_c: {
			type: 'string',
		},
		recordtypeid: {
			type: 'string',
		},
		phone_number_c: {
			type: 'string',
		},
		recordtypename_c: {
			type: 'string',
		},
		alternate_mobile_c: {
			type: ['string', 'null'],
		},
		role: {
			type: ['string', 'null'],
		},
		employee_id_c: {
			type: 'string',
		},
		mdicode_c: {
			type: 'string',
		},
		bde_name_c: {
			type: 'string',
		},
		cluster_c: {
			type: 'string',
		},
		divisionid_c: {
			type: 'number',
		},
		salesgroupid_c: {
			type: 'string',
		},
		profile_image_c: {
			type: 'string',
		},
		email_c: {
			type: 'string',
		},
		cluster_name_c: {
			type: ['string', 'null'],
		},
		division_c: {
			type: ['string', 'null'],
		},
		user_division_name_c: {
			type: ['string', 'null'],
		},
	},
	required: ['sfid'],
};
