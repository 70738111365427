import { ReactElement, useEffect, useState } from 'react';
import { LeadUserFilter, LeadUserObject, LeadUserSearchFilter } from '.';
import { UserCard } from '../userCard/userCard';
import { useFilterStore, initialFilterObject } from '@store/index';
import { getFilter } from '@hooks/useFilterData';
import { EndUserFilterStore, FilterObject } from '@store/memberList';
import { useInfiniteScroll } from '@components/common';
import { useRxData } from 'rxdb-hooks';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { escapeRegExp } from '@helper/utils';
import { useLeadUserQuery } from '@services/hooks/endusers/leadUserQuery';
import { useCloseLeadsPopup } from '@store/closeLeadsPopupStore';
import { CloseLeadsModal } from '@components/common/close-leads/closeLeadsPopUp';
import { Modal } from '@components/common/modal/modal';

export const LeadList = (): ReactElement => {
	const [searchElement, setSearchElement] = useState<string>('');
	const { isOpen, setModal } = useCloseLeadsPopup((state) => state);
	const { isFetching: queryFetching, error } = useLeadUserQuery();
	const {
		sortValue,
		updateSortObject,
		updateFilterObject,
		filterObject,
		search,
		updateSearch,
		updateSortValue,
		updateSortActiveStatus,
	} = useFilterStore((state) => state);
	const { leadUserModuleFilter, updateLeadUserModuleFilter } = EndUserFilterStore((state) => state);

	const getDataFromIndexDb = (
		filterObject: FilterObject,
		searchQuery: string
	): { selector: LeadUserFilter & LeadUserSearchFilter } => {
		const { selectorData } = getFilter(filterObject);
		const selector: LeadUserFilter & LeadUserSearchFilter = selectorData;
		if (searchQuery) {
			const searchData = searchQuery ? escapeRegExp(searchQuery) : '';

			selector.$or = [
				{ name: { $regex: `.*${searchData}.*`, $options: 'i' } },
				{ phone: { $regex: `.*${searchData}.*`, $options: 'i' } },
			];
		}
		return { selector };
	};

	const { result, fetchMore, isFetching } = useRxData<LeadUserObject>(
		'new_leads',
		(collection) => {
			const { selector } = getDataFromIndexDb(filterObject, searchElement);
			let query = collection.find({ selector });

			// Sort the query based on sortValue and sortOrder
			if (sortValue) {
				query = query.sort({ [sortValue]: sortValue === 'createddate' ? 'desc' : 'asc' });
			} else {
				query = query.sort({ createddate: 'desc' });
			}
			return query;
		},
		{ pageSize: 20, pagination: 'Infinite' }
	);

	useEffect(() => {
		updateSortObject([
			{ value: 'name', name: 'Name' },
			{ value: 'createddate', name: 'Create Date' },
		]);
		updateSortValue('');
		updateSortActiveStatus(false);
		updateSearch('');

		if (leadUserModuleFilter === null) {
			updateFilterObject({
				...initialFilterObject,
				dateOfVisit: { flagged: true, startDate: null, endDate: null },
				singleDropDown: {
					flagged: true,
					name: 'Source',
					data: [
						{ value: 'Dealer', id: 1 },
						{ value: 'FCC', id: 2 },
						{ value: 'BDE', id: 3 },
						{ value: 'IMR', id: 4 },
					],
					dropDownData: '',
				},
			});
			return;
		}
		updateFilterObject(leadUserModuleFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		updateLeadUserModuleFilter(filterObject);
	}, [filterObject, updateLeadUserModuleFilter]);

	useEffect(() => {
		const getData = setTimeout(() => {
			setSearchElement(search);
		}, 500);
		return (): void => clearTimeout(getData);
	}, [search]);

	useInfiniteScroll(() => {
		fetchMore();
	});

	return (
		<>
			<Wrapper isLoaderVisible={queryFetching || (isFetching && result.length === 0)} isError={error}>
				<div className="user-card-list-wrapper">
					<ul className="user-card-list row">
						{result.length > 0 ? (
							result.map((endUser, index) => {
								return (
									<UserCard key={endUser?._data?.id} userObject={endUser?._data} index={index} state={'/new-leads'} />
								);
							})
						) : (
							<li className="d-col d-col-2">
								<p className="h3">No Data Found!</p>
							</li>
						)}
					</ul>
				</div>
			</Wrapper>
			<Modal
				modalOpen={isOpen}
				modalId={'closeLeads'}
				onModalClose={() => {
					setModal(false);
				}}
				className="dialog-sm close-site-popup dialog-conform"
			>
				<CloseLeadsModal />
			</Modal>
		</>
	);
};
