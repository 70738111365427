export const overlayRemoveFadeAction = (fn: () => void, id = 'overlay'): void => {
	const overlay = document.getElementById(id) as HTMLElement;
	document.body.classList.remove('overflow-hidden');
	overlay.classList.remove('show');
	fn();
};

export const overlayAddFadeAction = (fn: () => void, id = 'overlay'): void => {
	const overlay = document.getElementById(id) as HTMLElement;
	document.body.classList.add('overflow-hidden');
	overlay.classList.add('show');
	fn();
};
