import { memo, ReactElement, useEffect } from 'react';
import { IAddressFieldsProps } from '.';
import Dropdown from '../dropdown/dropdown';
import TextInput from '../textInput/textInput';
import { statesDropdownData } from '@config/constant';
import { keyDownEvent } from '@helper/utils';
import usePincodeQuery from '@services/hooks/enduser-details/usePincodeQuery';

const AddressFields = (props: IAddressFieldsProps): ReactElement => {
	const {
		formik,
		townName,
		addressName,
		zipcodeName,
		streetName,
		cityName,
		stateName,
		addressLabel,
		mainFieldName,
		townsList,
		townLabel = 'Town',
		isEditing,
	} = props;
	const { setPincode, state, city } = usePincodeQuery();

	const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>, field?: string): void => {
		setPincode(event.target.value);
		field && formik.setFieldValue(field, event.target.value);
	};

	useEffect(() => {
		if (state && city && mainFieldName) {
			formik.setFieldValue(`${mainFieldName}_state`, state);
			formik.setFieldValue(`${mainFieldName}_city`, city);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, city, mainFieldName]);
	return (
		<>
			{mainFieldName !== 'local' &&
				mainFieldName !== 'work' &&
				mainFieldName !== 'workshop' &&
				mainFieldName !== 'factory' &&
				townName &&
				townsList && (
					<li className="d-col d-col-2">
						<Dropdown
							id={townName}
							name={townName}
							label={townLabel}
							title={formik.values[townName]}
							error={formik.touched[townName] && formik.errors[townName] ? formik.errors[townName] : null}
							options={townsList}
							onBlur={formik.handleBlur}
							setFieldValue={formik.setFieldValue}
							disabled={isEditing}
							required
						/>
					</li>
				)}

			<li className="form-control d-col d-col-2">
				<TextInput
					id={addressName}
					name={addressName}
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values[addressName]}
					setFieldValue={formik.setFieldValue}
					label={addressLabel}
					error={formik.touched[addressName] && formik.errors[addressName] ? formik.errors[addressName] : null}
					disabled={isEditing}
					required
				/>
			</li>

			<li className="d-col">
				<div className="box-wrapper">
					<ul className="box-wrapper-list">
						<li className="form-control">
							<TextInput
								id={zipcodeName}
								name={zipcodeName}
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values[zipcodeName]}
								setFieldValue={formik.setFieldValue}
								label="Zip Code"
								error={formik.touched[zipcodeName] && formik.errors[zipcodeName] ? formik.errors[zipcodeName] : null}
								onKeyDown={keyDownEvent}
								mainFieldName={mainFieldName}
								disabled={isEditing}
								maxLength={6}
								required
								onChange={handleTextInputChange}
							/>
						</li>

						{mainFieldName !== 'factory' && streetName && (
							<li className="form-control">
								<TextInput
									id={streetName}
									name={streetName}
									type="text"
									onBlur={formik.handleBlur}
									value={formik.values[streetName]}
									setFieldValue={formik.setFieldValue}
									label="Street"
									error={formik.touched[streetName] && formik.errors[streetName] ? formik.errors[streetName] : null}
									disabled={isEditing}
									required
								/>
							</li>
						)}

						<li className="form-control">
							<TextInput
								id={cityName}
								name={cityName}
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values[cityName]}
								setFieldValue={formik.setFieldValue}
								label="City"
								error={formik.touched[cityName] && formik.errors[cityName] ? formik.errors[cityName] : null}
								disabled={isEditing}
								required
							/>
						</li>

						<li className="d-col d-col-2">
							<Dropdown
								id={stateName}
								name={stateName}
								label="State"
								title={formik.values[stateName]}
								error={formik.touched[stateName] && formik.errors[stateName] ? formik.errors[stateName] : null}
								options={statesDropdownData}
								onBlur={formik.handleBlur}
								setFieldValue={formik.setFieldValue}
								disabled={isEditing}
								required
							/>
						</li>
					</ul>
				</div>
			</li>
		</>
	);
};

export default memo(AddressFields);
