import { useCallback } from 'react';
import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { CalendarDetails, QueryParams } from '.';
import { IQueryResponse } from '@services/api';
import { useCalendarStore } from '@store/calendarStore';
import { getMonthStartAndEndDate } from '@helper/utils';

type ICalendarParams = {
	startdate__gte: string;
	enddate__lte: string;
	limit: string;
};

export const useCalendarQuery = (date: QueryParams, force: boolean): IQueryResponse<Array<CalendarDetails>> => {
	const { fetchedMonths, setCalendarData, setFetchedMonths } = useCalendarStore();

	const getMonthKey = useCallback(
		(startDate: string, endDate: string): string => {
			const start = new Date(startDate);
			const end = new Date(endDate);
			const startMonth = `${start.getFullYear()}-${('0' + (start.getMonth() + 1)).slice(-2)}`;
			const endMonth = `${end.getFullYear()}-${('0' + (end.getMonth() + 1)).slice(-2)}`;
			if (!fetchedMonths.has(startMonth)) {
				return startMonth;
			}
			return endMonth;
		},
		[fetchedMonths]
	);

	const fetchCalendarDetails = useCallback(async (): Promise<Array<CalendarDetails>> => {
		const monthKey = getMonthKey(date.startDate, date.endDate);

		if (force) {
			const response = await APIService.getData<Array<CalendarDetails>>(`${APICONFIG.ALL_CALENDAR_DETAILS}`, {
				startdate__gte: date.startDate,
				enddate__lte: date.endDate,
				limit: '0',
			});
			setCalendarData(response);
			return response;
		}

		if (fetchedMonths.has(monthKey)) {
			return [];
		}

		const { startDate, endDate } = getMonthStartAndEndDate(monthKey);

		const queryParams: ICalendarParams = {
			startdate__gte: startDate,
			enddate__lte: endDate,
			limit: '0',
		};

		const response = await APIService.getData<Array<CalendarDetails>>(`${APICONFIG.ALL_CALENDAR_DETAILS}`, queryParams);
		setCalendarData(response);
		setFetchedMonths(monthKey);

		return response;
	}, [getMonthKey, date.startDate, date.endDate, fetchedMonths, setCalendarData, setFetchedMonths, force]);

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['CalendarDetails', date],
		queryFn: fetchCalendarDetails,
		enabled: Boolean(date?.startDate) && Boolean(date?.endDate),
		refetchOnWindowFocus: false,
		retry: false,
	});

	return { data, error, isLoading, isPending, refetch };
};
