import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { StructuredFeedbackObj } from '.';

export const StructuredFeedback = (): ReactElement => {
	return (
		<section className="structured-feedback-section">
			<div className="container">
				<ul className="structured-feedback-wrapper row">
					{StructuredFeedbackObj?.map((item) => (
						<li key={item?.id} className="d-col d-col-4">
							<NavLink to={item?.url} aria-label={item?.label} className="stucture-feedback-card" target="_blank">
								<span className="feedback-title">{item?.label}</span>

								<span className="arrow-icon icon-arrow-right-circle"></span>
							</NavLink>
						</li>
					))}
				</ul>

				<div className="banking-history-card">
					<NavLink
						aria-label="product banked tab"
						className="banking-history-card-wrapper"
						to="https://forms.office.com/r/BUuY4n4tJA"
						target="_blank"
					>
						<div className="left-content">
							<span className="icon-file"></span>
							<h2 className="h5">New Gift Suggestions</h2>
						</div>
						<span className="icon-arrow-right-circle"></span>
					</NavLink>
				</div>
			</div>
		</section>
	);
};
