export interface ITabToggle {
	toggleTab: () => void;
}

const moveIndicator = (): void => {
	const indicator = document?.querySelector('.sliding-indicator') as HTMLElement;
	const activeTab = document?.querySelector('.tablinks.active') as HTMLElement;
	const icon = activeTab?.querySelector('.icon') as HTMLElement;
	const container = document?.querySelector('.tab-inner') as HTMLElement;

	if (!indicator || !container || !icon || !activeTab) return;

	container.style.display = 'none';
	container.getBoundingClientRect();
	container.style.display = '';

	const updateIndicatorPosition = (): void => {
		const containerRect = container.getBoundingClientRect();
		const iconRect = icon?.getBoundingClientRect();
		const leftPosition = iconRect?.left - containerRect?.left;

		if (!indicator || leftPosition === undefined) return;

		indicator.style.width = '20px';
		indicator.style.left = `${leftPosition}px`;
	};

	requestAnimationFrame(updateIndicatorPosition);
};
export const useEndUserToggle = (): ITabToggle => {
	return {
		toggleTab: moveIndicator,
	};
};
