import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { TownList } from '.';
import { setRxdb } from '@helper/utils';
import { useCheckRxdb } from '@hooks/checkRxdb';

export const useTownDetailsQuery = (): IQueryResponse<Array<TownList>> => {
	const { shouldFetch, isDbChecked } = useCheckRxdb('towns');

	const fetchTownDetailsData = async (): Promise<Array<TownList>> => {
		const response = await APIService.getData<Array<TownList>>(APICONFIG.TOWN_LIST_DETAILS + `?limit=${0}&version=v2`);
		if (response) {
			await setRxdb('towns', response);
		}
		return response;
	};

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['townList'],
		queryFn: fetchTownDetailsData,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
