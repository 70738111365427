import { ReactElement } from 'react';

export const ClubRecommendation = (): ReactElement => {
	return (
		<div className="club-validation-modal">
			<div className="note-content">
				<p>Please meet the club every quarter.</p>
				<p>This is the first meeting.</p>
			</div>
		</div>
	);
};
