import { RxCollectionCreator } from 'rxdb';

export const oneToOneMeetinsListSchema: RxCollectionCreator = {
	schema: {
		title: 'one to one meetings schema',
		description: 'One To One Meetings Schema',
		version: 0,
		primaryKey: 'id',
		type: 'object',
		properties: {
			id: { type: 'string', maxLength: 250 },
			sfid: { type: 'string' },
			type: { type: ['string', 'null'] },
			dealer_code_c: { type: ['string', 'null'] },
			dealer_name_c: { type: ['string', 'null'] },
			user_name_c: { type: ['string', 'null'] },
			wss_teritorry_code_c: { type: ['string', 'null'] },
			verification_status_c: { type: ['string', 'null'] },
			wss_teritorry_name_c: { type: ['string', 'null'] },
			startdatetime: { type: ['string', 'null'] },
			product1_demoed_code_c: { type: ['string', 'null'] },
			product2_demoed_code_c: { type: ['string', 'null'] },
			product1_demoed_c: { type: ['string', 'null'] },
			product2_demoed_c: { type: ['string', 'null'] },
			activities_done_at_site_c: { type: ['string', 'null'] },
			event_image1_c: { type: ['string', 'null'] },
			event_image_c: { type: ['string', 'null'] },
			event_image2_c: { type: ['string', 'null'] },
			event_image4_c: { type: ['string', 'null'] },
			event_image5_c: { type: ['string', 'null'] },
			mobile_no_c: { type: ['string', 'null'] },
			membership_number_c: { type: ['string', 'null'] },
			whoid: { type: ['string', 'null'] },
			assigned_cmdi_c: { type: ['string', 'null'] },
			recordtypeid: { type: ['string', 'null'] },
			subject: { type: ['string', 'null'] },
			other_suggestions_c: { type: ['string', 'null'] },
			eventsubtype: { type: ['string', 'null'] },
			category_c: { type: ['string', 'null'] },
			town_code_c: { type: ['string', 'null'] },
			town_name_c: { type: ['string', 'null'] },
			meeting_subject_c: { type: ['string', 'null'] },
			attendance_c: { type: ['string', 'null'] },
			description: { type: ['string', 'null'] },
		},
		required: ['id'],
	},
};
