import { ITabList } from '@components/common/navigator-tab';
import '@assets/styles/pages/atteded-meeting.scss';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AllParticipantsObject } from '.';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';
import { useEndUserToggle } from '@hooks/useEndUserToggle';

export const AttendedMembers = (): ReactElement => {
	const AttendedTabList: ITabList[] = [
		{
			key: 1,
			name: 'Invited (Non-Attended)',
			pathUrl: '/meetings',
			tabIcon: 'icon-user',
		},
		{
			key: 2,
			name: 'Attended (Non-Invited)',
			pathUrl: '/meetings/todays-meetings',
			tabIcon: 'icon-user',
		},
		{
			key: 3,
			name: 'Attended (Invited)',
			pathUrl: '/meetings/all-meetings',
			tabIcon: 'icon-user',
		},
	];

	const { toggleTab } = useEndUserToggle();

	const [activeTab, setActiveTab] = useState(0);
	const { state } = useLocation();

	const invitedParticipants: AllParticipantsObject[] | [] =
		state?.participants?.filter(
			(item: AllParticipantsObject) => item.non_invited_c === false && item.attendance_c === false
		) ?? [];

	const kycPending: AllParticipantsObject[] | [] =
		state?.participants?.filter(
			(item: AllParticipantsObject) =>
				item.non_invited_c === true && item.attendance_c === true && item.convert_lead_c === false
		) ?? [];
	const attendedNonInv: AllParticipantsObject[] | [] =
		state?.participants?.filter(
			(item: AllParticipantsObject) => item.non_invited_c === true && item.attendance_c === true
		) ?? [];
	const attendedInv: AllParticipantsObject[] | [] =
		state?.participants?.filter(
			(item: AllParticipantsObject) => item.non_invited_c === false && item.attendance_c === true
		) ?? [];

	const getParticipants = (id: number): AllParticipantsObject[] => {
		const mergeUnique = (arr1: AllParticipantsObject[], arr2: AllParticipantsObject[]): AllParticipantsObject[] => {
			const combined = [...arr1, ...arr2];
			const unique = new Map(combined.map((item) => [item.id, item]));
			return Array.from(unique.values());
		};

		switch (id) {
			case 0:
				return invitedParticipants;
			case 1:
				return mergeUnique(kycPending, attendedNonInv);
			default:
				return attendedInv;
		}
	};

	useEffect(() => {
		toggleTab();
		window.addEventListener('resize', toggleTab);
	}, [toggleTab, activeTab]);

	const handleResetSearch = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number): void => {
		e.preventDefault();
		setActiveTab(index);
	};

	useEffect(() => {
		setActiveTab(state.cardKey);
	}, [state.cardKey]);

	return (
		<section className="attended-member-section">
			<div className="container">
				<div className="tab-search-wrapper">
					<div className="tab-wrapper">
						<div className="tab-main">
							<div className="tab-inner">
								<ul className="tab">
									{AttendedTabList.map((tab: ITabList, index: number) => {
										return (
											<li key={tab.name + index}>
												<a
													href={tab.pathUrl}
													aria-label={tab.name}
													className={`tablinks ${activeTab === index && 'active'} `}
													onClick={(e) => handleResetSearch(e, index)}
												>
													<span className="icon">
														<span className={tab.tabIcon}></span>
													</span>
													<span className="tab-name">{tab.name}</span>
												</a>
											</li>
										);
									})}
								</ul>
								<div className="sliding-indicator"></div>
							</div>
						</div>
					</div>
				</div>
				<ul className="attendance-details row">
					{getParticipants(activeTab).length > 0 ? (
						getParticipants(activeTab).map((item) => {
							return (
								<li className="d-col d-col-2" key={item.id}>
									<div className="attendance-details-card">
										<div className="user-details">
											<p className="h3">{item.name}</p>
											<span className="number">{item.phone}</span>
										</div>
										{kycPending?.find((data) => data.id === item.id) ? (
											<span role={'button'} tabIndex={0} className="user-icon not-part">
												<span className="icon-non-kyc-icon"></span>
											</span>
										) : (
											<span className="user-icon">
												<span className="icon-user"></span>
											</span>
										)}
									</div>
								</li>
							);
						})
					) : (
						<NoDataFound />
					)}
				</ul>
			</div>
		</section>
	);
};
