import { ReactElement } from 'react';
import { ICompetitionNextActionButtonProps } from '@components/competition-conversion/components/competitionNextActionButton/index';
import { useNavigate } from 'react-router-dom';

export const CompetitionNextActionButton = (props: ICompetitionNextActionButtonProps): ReactElement => {
	const { nextUrl, buttonDisable, buttonText = 'Next', id = 'next-page' } = props;
	const nav = useNavigate();
	const nextHandle = (): void => {
		nav(nextUrl);
	};

	return (
		<div className="submit-btn">
			<button
				type="button"
				onClick={nextHandle}
				disabled={buttonDisable}
				className="btn btn-medium btn-primary"
				id={id}
			>
				{buttonText}
			</button>
		</div>
	);
};
