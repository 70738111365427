import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { APPCONFIG } from '@config/app.config';
import useSiteLeadStore from '@store/siteLeadStore';
import { ActivityModule } from '@components/siteLeads/components';

export const useActivityModuleQuery = (): IQueryResponse<ActivityModule> => {
	const { productId } = useSiteLeadStore();

	const getActivityModuleData = async (): Promise<ActivityModule> => {
		const url = `${APPCONFIG.baseUrl}${APPCONFIG.apiVersion}/site-leads/wwa/activity-module/${productId}/`;
		return await APIService.getData<ActivityModule>(url);
	};
	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['activityModule', productId],
		queryFn: getActivityModuleData,
		enabled: !!productId,
		refetchOnWindowFocus: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
