import { ReactElement, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Header, Footer, Loader } from '@components/common';
import { convertPathNameToTitle } from '@helper/utils';
import { endUserPaths } from '@components/members';
import { CheckServiceWorkerUpdates } from './checkServiceWorkerUpdates';
import { useRouteHandler } from '../authGuard';

export const RootLayout = (): ReactElement => {
	const { pathname } = useLocation();
	const { isAuthenticated, permissionAndSessionCheck } = useRouteHandler();

	const getTitleFromPathname = (pathname: string): string => {
		if (endUserPaths.includes(pathname)) {
			return 'End Users';
		}
		return convertPathNameToTitle(pathname);
	};

	if (!isAuthenticated() && pathname !== '/login') {
		return (
			<Suspense fallback={<Loader />}>
				<Navigate to="/login" replace />
			</Suspense>
		);
	}

	return (
		<>
			{isAuthenticated() && <Header menuHeading={getTitleFromPathname(pathname)} />}
			<main>
				<Suspense fallback={<Loader />}>{permissionAndSessionCheck()}</Suspense>
			</main>
			<div id="overlay" className="overlay"></div>
			{isAuthenticated() && <Footer />}
			<CheckServiceWorkerUpdates />
		</>
	);
};
