import { FormikValues, useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TextInput from '@components/common/formControl/textInput/textInput';
import { useValidation } from '@hooks/validations';
import { defaultQuickUser, MeetingsPopUp } from '.';
import { keyDownEvent } from '@helper/utils';
import { useQuickAddEndUserMutation } from '@services/hooks/meetings/useQuickAddEndUserMutation';
import { usePhoneNumberValidateQuery } from '@services/hooks/enduser-details/usePhoneNumberValidateQuery';
import { useDebounce } from '@hooks/useDebounce';
import { ToastType, useTosterStore } from '@store/toster';
import { GetMembers } from '@components/members/components/getMembers/getMembers';
import Dropdown from '@components/common/formControl/dropdown/dropdown';
import { Categories, DesignationList } from '@config/constant';
import { AllEndUserObject } from '@components/members/components/allEndUserList';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';
import { useQuickAddNonEndUserMutation } from '@services/hooks/meetings/useNonEndUserMember';
import { QuickAddNonEndUser } from '@services/hooks/meetings';
import { useQuickAddAllUserMutation } from '@services/hooks/meetings/useQuickAddAllUser';

export const AddQuickUserModal: React.FC<MeetingsPopUp> = ({
	dateObject,
	onClose,
	refetch,
	details,
	isForQuickUser,
	participants,
}) => {
	const { mutate, isPending } = useQuickAddEndUserMutation(dateObject?.id, details?.leadId);
	const { mutate: quickAddUser, isPending: isAddUserPending } = useQuickAddAllUserMutation(dateObject?.id);
	const { mutate: nonEndUserMutation, isPending: nonEndUserFetching } = useQuickAddNonEndUserMutation(dateObject?.id);

	const { AddUserSchema, NonEndUserSchema } = useValidation();
	const { setToaster } = useTosterStore();
	const [search, setSearch] = useState('');
	const { result } = GetMembers(search);

	const formik = useFormik({
		initialValues: defaultQuickUser,
		onSubmit: () => {
			isForQuickUser ? handleNonEndUser() : handleSubmitForm();
		},
		validationSchema: isForQuickUser ? NonEndUserSchema : AddUserSchema,
		validate: async () => {
			const errors: Partial<FormikValues> = {};
			if (phoneValidationData?.data?.exists && phoneValidationData?.data?.message) {
				const phoneNumberError = phoneValidationData?.data?.message;
				errors.primary_number = phoneNumberError;
			}
			if (secondaryPhoneValidationData?.data?.exists && secondaryPhoneValidationData?.data?.message) {
				const phoneNumberError = secondaryPhoneValidationData?.data?.message;
				errors.primary_number = phoneNumberError;
			}

			return errors;
		},
	});

	const {
		data: phoneValidationData,
		refetch: refetchPhoneValidation,
		isPending: isPhoneNumberFetching,
	} = usePhoneNumberValidateQuery(formik.values.primary_number);

	const { data: secondaryPhoneValidationData, refetch: refetchSecondaryPhoneValidation } = usePhoneNumberValidateQuery(
		formik.values.secondary_number
	);
	const handlePhoneNumberChange = useDebounce(
		(e: React.ChangeEvent<HTMLInputElement>, isSecondary: boolean = false) => {
			if (e.target.value.length === 10 && !isForQuickUser && !details?.leadId.length) {
				if (isSecondary) {
					refetchSecondaryPhoneValidation?.();
				} else {
					refetchPhoneValidation?.();
				}
			}
		},
		0
	);
	const participantsList = useMemo(() => {
		if (participants?.length) {
			return participants?.map((t) => ({
				id: t.id,
				value: `${t.name} - ${t.phone}`,
			}));
		}
		return [];
	}, [participants]);

	useEffect(() => {
		if (phoneValidationData?.data?.exists && phoneValidationData?.data?.message) {
			formik.setFieldError('primary_number', phoneValidationData.data.message);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneValidationData]);

	useEffect(() => {
		if (details) {
			const newValues = {
				...formik.values,
				firstName: details.firstName,
				lastName: details.lastName,
				primary_number: details.primary_number,
				secondary_number: details.secondary_number ?? '',
				productHierarchyCode: details.productHierarchyCode,
			};

			formik.setValues(newValues, true);
			formik.validateForm(newValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [details]);

	const handleSubmitForm = useCallback(() => {
		const payload = {
			firstname: formik.values.firstName.trim(),
			lastname: formik.values.lastName.trim(),
			phone: formik.values.primary_number.toString(),
			secondarymobileno_c: formik?.values?.secondary_number?.toString() || '',
			producthierarchycode_c: formik.values.productHierarchyCode,
		};
		setSearch('');

		mutate(payload, {
			onSuccess: (data) => {
				if (data) {
					refetch && refetch();
					setToaster(
						true,
						ToastType.success,
						'Participants' +
							(details?.leadId ? 'Updated' : 'Added') +
							'Successfully! Changes will be updated in a moment'
					);
					formik.resetForm();
					onClose();
				}
			},
			onError: (error) => {
				setToaster(true, ToastType.error, error.message);
			},
		});
	}, [details?.leadId, formik, mutate, onClose, refetch, setToaster]);

	const handleClose = useCallback(() => {
		formik.resetForm();
		onClose();
		setSearch('');
	}, [formik, onClose]);

	const handleNonEndUser = useCallback(() => {
		const newPayload: QuickAddNonEndUser = {
			firstname: formik.values.firstName.trim(),
			lastname: formik.values.lastName.trim(),
			phone: formik.values.primary_number.toString(),
			secondarymobileno_c: formik.values.secondary_number?.toString() || '',
			producthierarchycode_c: formik.values.productHierarchyCode,
			non_end_category_c: formik.values.catagories,
		};

		if (formik.values.catagories === 'Pidilite Employee') {
			newPayload.enduserdesignation_c = formik.values.designation;
		}
		if (formik.values.designation === 'Helper/Junior Staff') {
			newPayload.natureofwork_c = formik.values.natureOfWork;
			newPayload.endusercategory_c =
				participants
					?.find((item) => item.phone === formik.values.userToBeLinked.split('-').pop())
					?.member_id.toString() ?? formik.values.userToBeLinked.split('-').pop();
		}

		nonEndUserMutation(newPayload, {
			onSuccess: (data) => {
				if (data) {
					refetch && refetch();
					setToaster(
						true,
						ToastType.success,
						'Participants' +
							(details?.leadId ? 'Updated' : 'Added') +
							'Successfully! Changes will be updated in a moment'
					);
					handleClose();
				}
			},
			onError: (error) => {
				setToaster(true, ToastType.error, error.message);
			},
		});
	}, [details?.leadId, formik.values, handleClose, nonEndUserMutation, participants, refetch, setToaster]);

	const handleQuickAddEndUser = (item: AllEndUserObject): void => {
		const payload = {
			contactid: item?.personcontactid,
			membershipno: item?.membershipno,
		};

		quickAddUser(payload, {
			onSuccess: (data) => {
				if (data) {
					refetch && refetch();
					setToaster(true, ToastType.success, 'Participants Added Successfully! Changes will be updated in a moment');
					handleClose();
				}
			},
			onError: (error) => {
				setToaster(true, ToastType.error, error.message);
			},
		});
	};

	useEffect(() => {
		if (formik.values.catagories !== 'Pidilite Employee') {
			formik.setFieldValue('designation', '');
			formik.setFieldValue('natureOfWork', '');
			formik.setFieldValue('userToBeLinked', '');
		}

		if (formik.values.designation !== 'Helper/Junior Staff') {
			formik.setFieldValue('natureOfWork', '');
			formik.setFieldValue('userToBeLinked', '');
		}
		formik.validateForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.catagories, formik.values.designation]);

	return (
		<div className="quick-add-user-wrapper">
			<div className="quick-add-heading">
				{!isForQuickUser && details?.leadId === undefined ? (
					<>
						<div className="close-icon-btn-drawer">
							<button type="button" aria-label="close button" onClick={handleClose}>
								<span className="icon-x-circle"></span>
							</button>
						</div>

						<div className="main-search-filter-box">
							<div className="search-filter-box">
								<div className="inner-search-filter-wrapper">
									<input
										type="text"
										placeholder="Search by Name / Mobile No"
										id="search-boxes"
										value={search}
										onChange={(e) => setSearch(e.target.value)}
									/>
								</div>
								<button type="button" aria-label="search" className="search-box-btn">
									<span className="icon-search"></span>
								</button>
							</div>

							{search && (
								<div className="filter-box-wrapper-list show">
									{result.length > 0 ? (
										<ul className="filter-box-list">
											{result.map((item) => (
												<li key={item.id}>
													<div
														className="filter-box-content"
														role="button"
														tabIndex={0}
														onClick={() => handleQuickAddEndUser(item)}
													>
														<span className="name-user">{item.name}</span>
														<span className="number-user">{item.phone}</span>
													</div>
												</li>
											))}
										</ul>
									) : (
										<ul>
											<NoDataFound />
										</ul>
									)}
								</div>
							)}
						</div>

						<span className="or cmn-heading">
							<span>or</span>
						</span>

						<span className="h3">Quick Add End User</span>
					</>
				) : (
					<>
						<div className="close-icon-btn-drawer">
							<button type="button" aria-label="close button" onClick={handleClose}>
								<span className="icon-x-circle"></span>
							</button>
						</div>
						<span className="h3">Add Attendees</span>
					</>
				)}
			</div>

			<form onSubmit={formik.handleSubmit}>
				<div className="quick-add-user-form">
					<ul className="row">
						{isForQuickUser && (
							<>
								<li className="form-control d-col ">
									<Dropdown
										id="catagories"
										name="catagories"
										label="Catagories"
										onBlur={formik.handleBlur}
										title={formik.values.catagories ?? ''}
										options={Categories}
										setFieldValue={formik.setFieldValue}
										required={false}
										error={formik.touched.catagories && formik.errors.catagories ? formik.errors.catagories : null}
									/>
								</li>
								{formik.values.catagories === 'Pidilite Employee' && (
									<li className="form-control d-col ">
										<Dropdown
											id="designation"
											name="designation"
											label="Designation"
											onBlur={formik.handleBlur}
											title={formik.values.designation}
											options={DesignationList}
											setFieldValue={formik.setFieldValue}
											required={true}
											error={formik.touched.designation && formik.errors.designation ? formik.errors.designation : null}
										/>
									</li>
								)}
								{formik.values.catagories === 'Helper/Junior Staff' && (
									<li className="form-control d-col ">
										<TextInput
											id="natureOfWork"
											name="natureOfWork"
											type="text"
											onBlur={formik.handleBlur}
											value={formik.values.natureOfWork ?? ''}
											label="Nature of work"
											placeholder="Please Enter Nature of Work"
											setFieldValue={formik.setFieldValue}
											error={
												formik.touched.natureOfWork && formik.errors.natureOfWork ? formik.errors.natureOfWork : null
											}
											required={true}
										/>
									</li>
								)}
							</>
						)}
						<li className="form-control d-col ">
							<TextInput
								id="firstName"
								name="firstName"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.firstName ?? ''}
								label="First Name"
								placeholder="Please Enter First Name"
								error={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
								required={true}
								setFieldValue={formik.setFieldValue}
							/>
						</li>

						<li className="form-control d-col  ">
							<TextInput
								id="lastName"
								name="lastName"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.lastName ?? ''}
								label="Last Name"
								placeholder="Please Enter Last Name"
								error={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
								required={true}
								setFieldValue={formik.setFieldValue}
							/>
						</li>

						<li className="form-control d-col  ">
							<TextInput
								id="primary_number"
								name="primary_number"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.primary_number ?? ''}
								label="Mobile Number"
								error={
									formik.touched.primary_number && formik.errors.primary_number ? formik.errors.primary_number : null
								}
								required={true}
								setFieldValue={formik.setFieldValue}
								placeholder="Please Enter Phone Number"
								maxLength={10}
								onKeyDown={keyDownEvent}
								onChange={(e) => handlePhoneNumberChange(e)}
							/>
						</li>

						{!isForQuickUser && (
							<li className="form-control d-col  ">
								<TextInput
									id="secondary_number"
									name="secondary_number"
									type="text"
									onBlur={formik.handleBlur}
									value={formik.values.secondary_number ?? ''}
									label="Alternate Mobile Number"
									placeholder="Please Enter Alternate Mobile Number"
									error={
										formik.touched.secondary_number && formik.errors.secondary_number
											? formik.errors.secondary_number
											: null
									}
									setFieldValue={formik.setFieldValue}
									maxLength={10}
									onKeyDown={keyDownEvent}
									onChange={(e) => handlePhoneNumberChange(e, true)}
								/>
							</li>
						)}
						{isForQuickUser && formik.values.catagories === 'Helper/Junior Staff' && (
							<li className="form-control d-col ">
								<Dropdown
									id="userToBeLinked"
									name="userToBeLinked"
									label="User To Be Linked"
									onBlur={formik.handleBlur}
									title={formik.values.userToBeLinked ?? ''}
									options={participantsList}
									setFieldValue={formik.setFieldValue}
									required
									error={
										formik.touched.userToBeLinked && formik.errors.userToBeLinked ? formik.errors.userToBeLinked : null
									}
								/>
							</li>
						)}
						<li className="form-control d-col  ">
							<TextInput
								id="productHierarchyCode"
								name="productHierarchyCode"
								type="text"
								onBlur={formik.handleBlur}
								value={formik.values.productHierarchyCode ?? ''}
								label="Product Hierarchy Code"
								placeholder="Please Enter Hierarchy Code"
								error={
									formik.touched.productHierarchyCode && formik.errors.productHierarchyCode
										? formik.errors.productHierarchyCode
										: null
								}
								setFieldValue={formik.setFieldValue}
								disabled
							/>
						</li>
					</ul>
					<div className="button-component">
						<button className="btn btn-primary btn-primary-2" type="button" onClick={handleClose}>
							Cancel
						</button>

						<button
							className="btn btn-primary"
							type="submit"
							disabled={
								!formik.isValid ||
								isPending ||
								isAddUserPending ||
								nonEndUserFetching ||
								(isForQuickUser || details?.leadId?.length !== 0 ? false : isPhoneNumberFetching)
							}
						>
							Submit
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};
