import { useMemo } from 'react';
import { usePermission } from './usePermission';

export const useComponentLevelAccessCheck = (componentName: string, routeLevelCheck = false): boolean => {
	const permissions = usePermission();

	return useMemo(() => {
		if (!permissions || permissions.length === 0) {
			return false;
		}

		if (routeLevelCheck) {
			return permissions.some((permission) => permission?.route?.includes(componentName));
		}

		if (permissions.some((permission) => permission.components.includes(componentName))) {
			return false;
		}

		return permissions.some((permission) => !permission.components.length);
	}, [permissions, componentName, routeLevelCheck]);
};
