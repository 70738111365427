import { compressImage } from '@helper/imageCompression';

interface UploadOptions {
	maxSizeInBytes?: number;
	quality?: number;
	maxIterations?: number;
}

export const uploadCompressFile = async (
	file: File,
	preSignedURL: string,
	options: UploadOptions = {}
): Promise<Response | undefined> => {
	const { maxSizeInBytes = 2 * 1024 * 1024, quality = 0.8, maxIterations = 10 } = options;
	const chunkSize = 5 * 1024 * 1024;

	let fileToUpload = file;

	if (file.type.startsWith('image/')) {
		try {
			fileToUpload = await compressImage(file, { maxSizeInBytes, quality, maxIterations });
			console.log(`Image compressed from ${file.size} to ${fileToUpload.size} bytes`);
		} catch (error) {
			console.error('Error compressing image:', error);
		}
	}

	const fileSize = fileToUpload.size;
	const chunks = Math.ceil(fileSize / chunkSize);

	try {
		let lastResponse: Response | undefined;
		for (let i = 0; i < chunks; i++) {
			const start = i * chunkSize;
			const end = Math.min(fileSize, start + chunkSize);
			const chunk = fileToUpload.slice(start, end);

			const headers = {
				'x-ms-blob-type': 'BlockBlob',
				'Content-Type': fileToUpload.type,
			};

			lastResponse = await fetch(preSignedURL, {
				method: 'PUT',
				body: chunk,
				headers: headers,
			});

			if (!lastResponse.ok) {
				throw new Error(`HTTP error! status: ${lastResponse.status}`);
			}
		}
		return lastResponse;
	} catch (error) {
		console.error('Error uploading file:', error);
		throw error;
	}
};
