import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IFCPDetails } from '@components/siteLeads/components/taskList';
import { APICONFIG } from '@config/api.config';

export const useFPCDetailsQuery = (membershipno?: string): IQueryResponse<IFCPDetails> => {
	const fetchFPCDetails = async (): Promise<IFCPDetails> => {
		return await APIService.getData<IFCPDetails>(APICONFIG.FPC_DETAILS, {
			membershipno: membershipno ?? '',
		});
	};

	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['fpcDetails', membershipno],
		queryFn: fetchFPCDetails,
		enabled: !!membershipno,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
