import { ReactElement, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { keysSiteDetailsStore, useSiteAllDetailsStore } from '@store/siteAllDetailsStore';
import { SiteAllDetailsTabHeading } from '@components/siteSummaryDetails/components';
import { useFormTabNavigation } from '@hooks/useFormTabNavigation';

export const SiteDetailsTab = (): ReactElement => {
	const { setKeyBaseValue, currentActiveTab } = useSiteAllDetailsStore();
	const listRef = useRef<HTMLUListElement | null>(null);
	useFormTabNavigation({ listRef: listRef, currentActiveTab: currentActiveTab });

	return (
		<div className="sliding-tab-wrapper">
			<ul className="tabs" ref={listRef}>
				{SiteAllDetailsTabHeading.map((tab) => (
					<li key={tab.label} className={`${tab.key === currentActiveTab ? 'active' : ''}`}>
						<NavLink
							to={''}
							onClick={() => setKeyBaseValue(keysSiteDetailsStore.currentActiveTab, tab.key)}
							className="tab"
						>
							<span className="icon-user"></span>
							{tab.label}
						</NavLink>
					</li>
				))}
				<li className="glider"></li>
			</ul>
		</div>
	);
};
