import { useRxData } from 'rxdb-hooks';
import { escapeRegExp } from '@helper/utils';
import { RxCollection, RxDocument } from 'rxdb';
import { useCallback } from 'react';
import { AllEndUserObject, AllEndUserSearchFilter } from '../allEndUserList';

export interface IMeetingReturn {
	result: RxDocument<AllEndUserObject>[];
	isFetching: boolean;
	fetchMore: () => void;
}

export const GetMembers = (search: string): IMeetingReturn => {
	const queryConstructor = useCallback(
		(collection: RxCollection<AllEndUserObject>) => {
			const selector: AllEndUserSearchFilter = {
				$or: [
					{ name: { $regex: `.*${escapeRegExp(search)}.*`, $options: 'i' } },
					{ phone: { $regex: `.*${escapeRegExp(search)}.*`, $options: 'i' } },
					{ membershipno: { $regex: `.*${escapeRegExp(search)}.*`, $options: 'i' } },
				],
			};

			return collection
				.find({
					selector,
				})
				.sort({ createddate: 'desc' });
		},
		[search]
	);

	const { result, isFetching, fetchMore } = useRxData<AllEndUserObject>('all', queryConstructor);

	return { result, isFetching, fetchMore };
};
