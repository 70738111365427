import { DropdownOptions } from '@components/common/formControl/dropdown';
import { formatTime, roundToNearestQuarterHour } from '@helper/utils';

export * from './addEditOneToOneMeeting';

export interface IOneToOneMeetingFormInitialValues {
	oneToOneConnect: string;
	endUser: string;
	wssTerritory: string;
	town: string;
	typeOfActivity?: string | null;
	otherTypeOfActivity?: string | null;
	meetingDate: string;
	meetingTime: string;
	Product1Demo: string;
	Product2Demo: string;
	productImages: File[];
}

const now = new Date();
const startDate = roundToNearestQuarterHour(now);

export const oneToOneMeetingFormInitialValues: IOneToOneMeetingFormInitialValues = {
	oneToOneConnect: 'End User',
	endUser: '',
	wssTerritory: '',
	town: '',
	typeOfActivity: '',
	otherTypeOfActivity: '',
	meetingDate: new Date().toISOString(),
	meetingTime: formatTime(startDate),
	Product1Demo: '',
	Product2Demo: '',
	productImages: [],
};

export const typeOfActivitiesList: DropdownOptions[] = [
	{ id: 1, value: 'Demonstration (NPD-DPCR)' },
	{ id: 2, value: 'Vruddhi - Range Selling' },
	{ id: 3, value: 'Others' },
];

export const formatDateTime = (inputDateTime: string): string => {
	const [datePart, timePart] = inputDateTime.split(' ');
	const [year, month, day] = datePart.split('-');
	const [hour, minute] = timePart.split(':');

	const date = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), parseInt(minute)));

	return date.toISOString();
};

export const extractTime = (isoDateTime: string): string => {
	const date = new Date(isoDateTime);
	return date.toLocaleTimeString('en-US', {
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
		timeZone: 'UTC',
	});
};
