import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

interface IBankingProductItem {
	selectedProduct: string | number;
	product: string | number;
	handleProductItem: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const BankingProductItem = (props: IBankingProductItem): ReactElement => {
	const { product, selectedProduct, handleProductItem } = props;
	return (
		<div className={`product-wrapper ${selectedProduct === product ? 'active-product' : ''}`}>
			<NavLink to="/" data-tab={product} aria-label="product details link" onClick={handleProductItem}>
				{product}
			</NavLink>
		</div>
	);
};
