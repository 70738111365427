export interface IExpenseObject {
	meetingExpense: string | number;
	giftCost: string | number;
	otherCost: string | number;
	foodCost: string | number;
	venueCost: string | number;
}

export const initialExpenseValues: IExpenseObject = {
	meetingExpense: '',
	giftCost: '',
	otherCost: '',
	foodCost: '',
	venueCost: '',
};
