import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

export const HelpAndSupport = (): ReactElement => {
	return (
		<section className="help-support-section">
			<div className="help-support-content-wrapper">
				<span className="h3">Write to us at</span>
				<div className="custom-email-btn">
					<NavLink to="mailto:mpowersupport@pidilite.com" aria-label="support eamil">
						mpowersupport@pidilite.com
					</NavLink>
				</div>

				<div className="support-contact">
					<NavLink to="tel:+919967347908" aria-label="support contect">
						<span className="icon-call icons" />
						<span className="content">9967347908</span>
					</NavLink>
				</div>

				<div className="cmn-heading">
					<span>Or</span>
				</div>

				<span className="h3">Contact us on MyPidilite Helpline</span>

				<div className="custom-email-btn">
					<NavLink to="mailto:mpowersupport@pidilite.com" aria-label="support eamil">
						mpowersupport@pidilite.com
					</NavLink>
				</div>

				<div className="contact-list">
					<div className="support-contact">
						<NavLink to="tel:+919967347908" aria-label="support contect">
							<span className="icon-call icons" />
							<span className="content">9967347908</span>
						</NavLink>
					</div>

					<div className="support-contact">
						<NavLink to="tel:+919967347908" aria-label="support contect">
							<span className="icon-call icons" />
							<span className="content">9967347908</span>
						</NavLink>
					</div>
				</div>
			</div>
		</section>
	);
};
