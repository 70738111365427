import { TextInput } from '@components/common';
import { ReactElement } from 'react';
import { DealerWhoRaisedAComplaintProps } from '.';
import { keyDownEvent } from '@helper/utils';

export const SiteVisitForm = (props: DealerWhoRaisedAComplaintProps): ReactElement => {
	const { formik, isEditing } = props;

	return (
		<>
			<li className="form-control d-col d-col-2 ">
				<TextInput
					id="applicator_name"
					name="applicator_name"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.applicator_name ?? ''}
					label="Name of the Applicator/Contractor"
					placeholder="Name of the Applicator/Contractor"
					error={formik.touched.applicator_name && formik.errors.applicator_name ? formik.errors.applicator_name : null}
					setFieldValue={formik.setFieldValue}
					required
					disabled={isEditing}
				/>
			</li>
			<li className="form-control d-col d-col-2 ">
				<TextInput
					id="stage_of_application_during_complaint_c"
					name="stage_of_application_during_complaint_c"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.stage_of_application_during_complaint_c ?? ''}
					label="Stage of application during complaint"
					placeholder="Stage of application during complaint"
					error={
						formik.touched.stage_of_application_during_complaint_c &&
						formik.errors.stage_of_application_during_complaint_c
							? formik.errors.stage_of_application_during_complaint_c
							: null
					}
					setFieldValue={formik.setFieldValue}
					required
					disabled={isEditing}
				/>
			</li>
			<li className="form-control d-col d-col-2 ">
				<TextInput
					id="application_surface_c"
					name="application_surface_c"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.application_surface_c ?? ''}
					label="Application Surface"
					placeholder="Application Surface"
					error={
						formik.touched.application_surface_c && formik.errors.application_surface_c
							? formik.errors.application_surface_c
							: null
					}
					setFieldValue={formik.setFieldValue}
					required
					disabled={isEditing}
				/>
			</li>
			<li className="form-control d-col d-col-2 ">
				<TextInput
					id="area_in_sqft_c"
					name="area_in_sqft_c"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.area_in_sqft_c ?? ''}
					label="Area (in sqft)"
					placeholder="Area (in sqft)"
					error={formik.touched.area_in_sqft_c && formik.errors.area_in_sqft_c ? formik.errors.area_in_sqft_c : null}
					setFieldValue={formik.setFieldValue}
					required
					disabled={isEditing}
					onKeyDown={keyDownEvent}
				/>
			</li>
			<li className="form-control d-col d-col-2 ">
				<TextInput
					id="observation_c"
					name="observation_c"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.observation_c ?? ''}
					label="Observation"
					placeholder="Observation"
					error={formik.touched.observation_c && formik.errors.observation_c ? formik.errors.observation_c : null}
					setFieldValue={formik.setFieldValue}
					required
					disabled={isEditing}
				/>
			</li>
		</>
	);
};
