import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { IOneToOneMeetingsList } from '@components/onetoonemeetings';

export const useRetrieveMeetingDetails = (id: number): IQueryResponse<IOneToOneMeetingsList> => {
	const fetchMeetingDetails = async (): Promise<IOneToOneMeetingsList> => {
		const url = `${APICONFIG.ONE_TO_ONE_MEETINGS_LIST}/${id}`;
		return await APIService.getData<IOneToOneMeetingsList>(url);
	};

	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['retrieveMeetingDetails', id],
		queryFn: fetchMeetingDetails,
		enabled: !!id,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
