import { IMeetingDetails } from '@components/meeting-details';
import { formatDateDDMMYY, formateTimeTo12HrFormate } from '@helper/utils';
import { ReactElement } from 'react';

export const MeetingTimeDetails = (props: IMeetingDetails): ReactElement => {
	const { data } = props;
	return (
		<div className="meeting-time-details">
			<div className="meeting-inner-wrapper">
				<span className="icon">
					<span className="icon-calendar" />
				</span>

				<div className="meeting-time-date-details">
					<span className="time-date-info">
						<span className="time-date-title h5">Meeting Start Date</span>
						<span className="time-date-about">
							{data?.startdate ? formatDateDDMMYY(new Date(data?.startdate)) : '--'}
						</span>
					</span>

					<span className="time-date-info">
						<span className="time-date-title h5">Meeting End Date</span>
						<span className="time-date-about">{data?.enddate ? formatDateDDMMYY(new Date(data?.enddate)) : '--'}</span>
					</span>
				</div>
			</div>

			<div className="meeting-inner-wrapper">
				<span className="icon">
					<span className="icon-clock" />
				</span>

				<div className="meeting-time-date-details">
					<span className="time-date-info">
						<span className="time-date-title h5">Meeting Start Time</span>
						<span className="time-date-about">{formateTimeTo12HrFormate(data?.start_time_c)}</span>
					</span>

					<span className="time-date-info">
						<span className="time-date-title h5">Meeting End Time</span>
						<span className="time-date-about">{formateTimeTo12HrFormate(data?.end_time_c)}</span>
					</span>
				</div>
			</div>
		</div>
	);
};
