import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { QuickAddEndUser } from '.';

interface IMeetingDetails {
	mutate: UseMutateFunction<QuickAddEndUser, Error, QuickAddEndUser, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useQuickAddEndUserMutation = (id: string, updateMemberId?: string): IMeetingDetails => {
	const postMeetingsDetailsData = async (meetingFormData: QuickAddEndUser): Promise<QuickAddEndUser> => {
		const url = `${APICONFIG.MEETINGS_DETAILS}/${id}/add-quick-new-member/`;
		return await APIService.postData<QuickAddEndUser>(url, meetingFormData);
	};
	const putUpdateMember = async (meetingFormData: QuickAddEndUser): Promise<QuickAddEndUser> => {
		const url = `${APICONFIG.MEETINGS_DETAILS}/${id}/update-lead/${updateMemberId}/`;
		return await APIService.putData<QuickAddEndUser>(url, meetingFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: updateMemberId ? putUpdateMember : postMeetingsDetailsData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
