import { FilterObject, SelectorFilter } from '@store/memberList';

export const getFilter = (
	filterObject: FilterObject
): {
	selectorData: SelectorFilter;
} => {
	const { startDate, endDate } = filterObject.dateOfVisit;
	const visitStatus = filterObject.visitStatus.visitStatus;
	const secondaryData = filterObject.checkSecondary;
	const customData = filterObject.customSelect.customData.map((item) => item.value);
	const { toggleData } = filterObject.customToggle;
	const { kycStatus } = filterObject.kycStatus;
	const sourceList = filterObject.singleDropDown.dropDownData;
	const selector: SelectorFilter = {};

	if (startDate && endDate) {
		selector.createddate = { $gte: startDate.toISOString(), $lte: endDate.toISOString() };
	}
	if (visitStatus.length > 0) {
		selector.visitstatus = { $eq: visitStatus === 'Visited' ? 'Site Visited' : 'Site Not Visited' };
	}

	secondaryData.forEach((data, index) => {
		if (data.secondaryData.length > 0) {
			let key = '';
			switch (index) {
				case 0:
					key = 'contractorclass';
					break;
				case 1:
					key = 'contractorconsumption';
					break;
			}
			selector[key] = { $in: data.secondaryData };
		}
	});

	if (customData.length > 0) {
		selector.clubname = { $in: customData };
	}
	if (kycStatus.length > 0) {
		selector.kyc_id_proof_verification = { $eq: kycStatus === 'Verified' ? 'Verified' : 'Not-Verified' };
	}

	if (sourceList.length > 0) {
		selector.source = { $eq: sourceList };
	}
	toggleData.forEach((item) => {
		if (item === 'OB') {
			selector.is_a_ob = { $eq: true };
		}
		if (item === 'ZBC') {
			selector.is_zbc = { $eq: true };
		}
		if (item === 'Degrowing') {
			selector.is_de_growing = { $eq: true };
		}
	});

	return { selectorData: selector };
};
