import { useVideoPlayerStore } from '@store/videoPlayerStore';
import { ReactElement } from 'react';
import { IPlayButton } from '.';

export const PlayButton = (props: IPlayButton): ReactElement => {
	const { url } = props;
	const { setVideoPlayerState } = useVideoPlayerStore();

	const handlePlayVideo = (): void => {
		setVideoPlayerState(true, url);
	};
	return (
		<button className="video-btn" type="button" onClick={handlePlayVideo}>
			<span className="icon-play"></span>
		</button>
	);
};
