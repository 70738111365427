import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { PresignedURLDetails } from '../enduser-details';
import { uploadCompressFile } from '@helper/fileUpload';

interface UploadSiteLeadImages {
	mutate: UseMutateFunction<unknown, Error, File[] | null | undefined, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useUploadSiteImagesMutation = (PresignedURLData?: PresignedURLDetails[]): UploadSiteLeadImages => {
	const putSiteImagesData = async (filesData?: File[] | null): Promise<unknown[]> => {
		if (PresignedURLData && filesData) {
			const uploadPromises = PresignedURLData.map((urlData, index) => {
				const file = filesData[index];
				if (file) {
					return uploadCompressFile(file, urlData.pre_signed_url);
				}
				return Promise.resolve(null);
			});

			return Promise.all(uploadPromises);
		}
		return [];
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: putSiteImagesData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
