import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';
import { setRxdb } from '@helper/utils';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { IWSSTerritoryList } from '@components/onetoonemeetings';
import { ErrorHandler } from '@helper/errorHandler';

export const useWssTerritoryListQuery = (): IQueryResponse<IWSSTerritoryList[]> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('wss_territory_list');

	const fetchWssTerritoryList = async (): Promise<IWSSTerritoryList[]> => {
		try {
			const response = await APIService.getData<IWSSTerritoryList[]>(APICONFIG.WSS_TERRITORY_LIST, {
				limit: '0',
			});
			if (response) {
				await setRxdb('wss_territory_list', response);
			}
			return response;
		} catch (error) {
			ErrorHandler(error);
			return [];
		}
	};

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['wssTerritoryList'],
		queryFn: fetchWssTerritoryList,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
