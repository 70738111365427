import { PcmsObject } from '@components/pcms/components/addEditPcmsRegistrationForm';
import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';

export const useGetPcmsQuery = (id?: string): IQueryResponse<PcmsObject> => {
	const fetchPcmsObject = async (): Promise<PcmsObject> => {
		if (!id) {
			throw new Error('ID is required to fetch PCMS object');
		}
		const response = await APIService.getData<PcmsObject>(`${APICONFIG.PCMS}/details/${id}`);
		return response;
	};

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['PCMSObject', id],
		queryFn: fetchPcmsObject,
		enabled: !!id, // Only run the query if id is truthy
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
