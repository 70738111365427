export const TownListSchema = {
	title: 'Town schema',
	description: 'create a Town List schema',
	version: 0,
	primaryKey: 'towncode_c',
	type: 'object',
	properties: {
		id: {
			type: 'number',
		},
		towncode_c: { type: 'string', primary: true, maxLength: 200 },
		towndescription_c: { type: 'string' },
	},
	required: ['id', 'towncode_c', 'towndescription_c'],
};
