import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { SiteLeadData } from '.';

interface IVisitFeedbackData {
	mutate: UseMutateFunction<SiteLeadData, Error, SiteLeadData, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useVisitFeedbackMutation = (): IVisitFeedbackData => {
	const postVisitFeedbackData = async (siteLeadFormData: SiteLeadData): Promise<SiteLeadData> => {
		return await APIService.postData<SiteLeadData>(APICONFIG.VISIT_FEEDBACK, siteLeadFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: postVisitFeedbackData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
