import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IDataAPIResponse, IQueryResponse } from '@services/api';
import { ICCProductRecommended } from '@components/competition-conversion/components/competitionConversionProduct';

export type ICCProductRecommendedRequestParams = {
	grade: string;
	reason: string;
	type_of_contract: string;
};
export const useFetchProductsRecommended = (
	props: ICCProductRecommendedRequestParams
): IQueryResponse<IDataAPIResponse<ICCProductRecommended[]>> => {
	const { grade, type_of_contract, reason } = props;
	const fetchCompetitionConversionProductRecommendedData = async (): Promise<
		IDataAPIResponse<ICCProductRecommended[]>
	> => {
		return await APIService.getData<IDataAPIResponse<ICCProductRecommended[]>>(
			APICONFIG.COMPETITION_CONVERSION_PRODUCT_RECOMMENDED,
			{
				grade: grade,
				type_of_contract: type_of_contract,
				reason: reason,
			}
		);
	};

	const { data, error, isLoading, isPending } = useQuery({
		queryKey: ['competitionConversionProductRecommended'],
		queryFn: fetchCompetitionConversionProductRecommendedData,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
	};
};
