import { TextInput } from '@components/common';
import { FormikProps } from 'formik';
import { ReactElement } from 'react';

interface ICustomTextInputPropd<T> {
	id: string;
	label?: string;
	formik: FormikProps<T>;
	required?: boolean;
	maxLength?: number;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => boolean;
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	isDisabled?: boolean;
	dataAttributes?: { [key: string]: string };
}

export const CustomTextInput = <T,>(props: ICustomTextInputPropd<T>): ReactElement => {
	const { id, label, formik, maxLength, required = false, onKeyDown, handleChange, isDisabled, dataAttributes } = props;

	return (
		<TextInput
			id={id}
			name={id}
			onBlur={formik.handleBlur}
			type="text"
			value={formik.values[id] ?? ''}
			setFieldValue={formik.setFieldValue}
			label={label}
			error={formik.touched[id] && formik.errors[id] ? formik.errors[id] : null}
			required={required}
			maxLength={maxLength}
			onKeyDown={onKeyDown}
			onChange={handleChange}
			disabled={isDisabled}
			dataAttributes={dataAttributes}
		/>
	);
};
