import { BannerData, ISlider } from '@components/dashboard/components/banner';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useWindowWidth from '@hooks/useWindowWidth';
import { MeetingsData } from '@services/hooks/dashboard/useMeetingsQuery';

export const DotSlider = (props: ISlider): ReactElement => {
	const { bannerSliderRef, dotSliderRef, rightButtonRef, leftButtonRef, isMultiple, sliderId } = props;
	const { bannerData } = props;
	const currentIndexRef = useRef(0);
	const [newActiveIndex, setNewActiveIndex] = useState(0);

	const { width } = useWindowWidth();

	const handleAddorRemoveClassList = useCallback(
		(activeIndex: number) => {
			const bannerSlider = bannerSliderRef.current;
			const dotSlider = dotSliderRef.current;

			if (bannerSlider && dotSlider) {
				const slideWidth = (bannerSlider.children[0] as HTMLElement).offsetWidth;
				const scrollPosition = activeIndex * (slideWidth + 10);

				bannerSlider.scrollTo({
					left: scrollPosition,
					behavior: 'smooth',
				});

				if (isMultiple) {
					bannerSlider.children[activeIndex - 1]?.classList?.remove('active');
					bannerSlider.children[activeIndex + 1]?.classList?.add('active');
					bannerSlider.children[activeIndex + 2]?.classList?.remove('active');
					dotSlider.querySelector('.active')?.classList?.remove('active');
					dotSlider.children[activeIndex]?.classList?.add('active');
				}
				bannerSlider.children[activeIndex]?.classList.add('active');
				dotSlider.querySelector('.active')?.classList?.remove('active');
				dotSlider.children[activeIndex]?.classList.add('active');

				currentIndexRef.current = activeIndex;
				setNewActiveIndex(activeIndex);
			}
		},
		[bannerSliderRef, dotSliderRef, isMultiple]
	);

	const handleNextSlide = useCallback(() => {
		if (!bannerData || bannerData.length === 0) return;
		const newIndex = newActiveIndex < bannerData.length - 2 ? newActiveIndex + 1 : 0;
		setNewActiveIndex(newIndex);
		handleAddorRemoveClassList(newIndex);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newActiveIndex, handleAddorRemoveClassList]);

	const handlePrevSlide = useCallback(() => {
		if (!bannerData || bannerData.length === 0) return;
		const newIndex = newActiveIndex > 0 ? newActiveIndex - 1 : bannerData.length - 2;
		setNewActiveIndex(newIndex);
		handleAddorRemoveClassList(newIndex);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newActiveIndex, handleAddorRemoveClassList]);

	const handleSliderChange = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			e.preventDefault();
			const currentIndex = e.target as HTMLElement;
			handleAddorRemoveClassList(+currentIndex.id);
		},
		[handleAddorRemoveClassList]
	);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-expect-error
	const scrollHandler = useCallback((e): void => {
		const atSnappingPoint = e.target.scrollLeft % e.target.offsetWidth === 0;
		const timeOut = atSnappingPoint ? 0 : 150;

		clearTimeout(e.target.scrollTimeout);

		const isInViewport = (element: HTMLAnchorElement): boolean => {
			const rect = element.getBoundingClientRect();
			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		};

		e.target.scrollTimeout = setTimeout(function () {
			const slider = e.target;
			if (!slider) return;
			const slides = slider.querySelectorAll(`.${sliderId}`);

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			slides.forEach((el) => {
				if (isInViewport(el)) {
					const _id = el.id.slice(-1);
					handleAddorRemoveClassList(+_id);
				}
			});
		}, timeOut);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const handleNext = (): void => handleNextSlide();
		const handlePrev = (): void => handlePrevSlide();

		const rightButton = rightButtonRef?.current;
		const leftButton = leftButtonRef?.current;
		if (!bannerData || bannerData.length === 0) return;
		if (rightButton && leftButton) {
			rightButton.classList.toggle('visible', newActiveIndex !== bannerData.length - 2);
			rightButton.classList.toggle('invisible', newActiveIndex === bannerData.length - 2);

			leftButton.classList.toggle('visible', newActiveIndex !== 0);
			leftButton.classList.toggle('invisible', newActiveIndex === 0);

			rightButton.addEventListener('click', handleNext);
			leftButton.addEventListener('click', handlePrev);
		}

		return (): void => {
			if (rightButton && leftButton) {
				rightButton.removeEventListener('click', handleNext);
				leftButton.removeEventListener('click', handlePrev);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleNextSlide, handlePrevSlide, leftButtonRef, newActiveIndex, rightButtonRef]);

	useEffect(() => {
		const bannerSlider = bannerSliderRef.current;
		if (!bannerData || bannerData.length === 0) return;
		if (bannerSlider && !isMultiple) {
			const autoSlide = (): void => {
				currentIndexRef.current = (+currentIndexRef.current + 1) % bannerData.length;
				handleAddorRemoveClassList(+currentIndexRef.current);
			};

			const intervalId = setInterval(autoSlide, 5000);

			return (): void => {
				if (intervalId) clearInterval(intervalId);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleAddorRemoveClassList, isMultiple, bannerSliderRef]);

	useEffect(() => {
		const bannerSlider = bannerSliderRef.current;
		if (width && width < 767) {
			bannerSlider?.addEventListener('scroll', scrollHandler);
		}
	}, [bannerSliderRef, scrollHandler, width]);

	const sliderDots = useMemo<BannerData[] | MeetingsData[]>(() => {
		if (!bannerData) return;
		let data = JSON.parse(JSON.stringify(bannerData));
		if (data && data.length > 0 && isMultiple) {
			if (width > 767) {
				data = data.slice(0, data.length - 1);
			}
		}
		return data;
	}, [bannerData, isMultiple, width]);

	return (
		<>
			{sliderDots?.length ? (
				sliderDots?.map((banner, index: number) => {
					return (
						<a
							key={banner.id + index}
							href="/"
							id={'' + index}
							aria-label={`Slide ${index + 1}`}
							data-slide={`slide-${index + 1}`}
							className={index === 0 ? 'active' : ''}
							onClick={handleSliderChange}
						>
							Slide {index + 1}
						</a>
					);
				})
			) : (
				<></>
			)}
		</>
	);
};
