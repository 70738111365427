import { create } from 'zustand';

interface IStepperStore {
	currentStep: number;
	setCurrentStep: (step: number) => void;
}

const initialState = {
	currentStep: 0,
};

export const useStepperStore = create<IStepperStore>((set) => ({
	...initialState,
	setCurrentStep: (step: number): void => set((state) => ({ ...state, currentStep: step })),
}));

export default useStepperStore;
