import { RxCollectionCreator } from 'rxdb';

export const pcmsSchema: RxCollectionCreator = {
	schema: {
		title: 'pcms schema',
		description: 'create a pcms schema',
		version: 1,
		primaryKey: 'id',
		type: 'object',
		properties: {
			id: {
				type: 'string',
				maxLength: 250,
			},
			complaint_number_c: {
				type: ['string', 'null'],
				default: null,
			},
			sfid: {
				type: 'string',
				maxLength: 250,
			},
			complaint_originator_c: {
				type: ['string', 'null'],
				default: null,
			},
			customer_name_c: {
				type: ['string', 'null'],
				default: null,
			},
			courier_company_ref_id: {
				type: ['string', 'null'],
				default: null,
			},
			attachment_courier_receipt_c: {
				type: ['string', 'null'],
				default: null,
			},
			createddate: {
				type: ['string', 'null'],
				default: null,
			},
			nature_of_complaints_c: {
				type: ['string', 'null'],
				default: null,
			},
			sku_names_c: {
				type: ['string', 'null'],
				default: null,
			},
			external_closure_status_c: {
				type: ['string', 'null'],
				default: null,
			},

			stage_of_application_during_complaint_c: {
				type: ['string', 'null'],
				default: null,
			},
			subject: {
				type: ['string', 'null'],
				default: null,
			},
			pictures_videos_c: {
				type: 'array',
				items: {
					type: 'string',
				},
			},
			mode_of_settlement_c: { type: ['string', 'null'], default: null },
			quantity_approved_c: { type: ['string', 'null'], default: null },
			total_settlement_c: { type: ['string', 'null'], default: null },
			hc_lastop: { type: ['string', 'null'], default: null },
		},
		required: ['id'],
	},
	migrationStrategies: {
		1: async (oldDoc) => {
			return {
				...oldDoc,
			};
		},
	},
};
