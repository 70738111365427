import { ReactElement } from 'react';
import ellipsePng from '@assets/images/ellipse.png';

export const HeaderSection = ({
	banking_qtd,
	points_qtd,
	monthYearText,
}: {
	banking_qtd: number | undefined | null;
	points_qtd: number | undefined | null;
	monthYearText: string;
}): ReactElement => {
	return (
		<div className="container">
			<div className="calculator-header">
				<div className="header-title-wrapper">
					<div className="calc-title">
						Total Banked Kg ({monthYearText})
						<img src={ellipsePng} alt="ellipse" title="ellipse" width="16" height="16" className="bg-ellipse" />
					</div>
					<span className="point">{banking_qtd ?? '--'}</span>
				</div>
				<div className="header-title-wrapper">
					<div className="calc-title">
						Total Points Achieved ({monthYearText})
						<img src={ellipsePng} alt="ellipse" title="ellipse" width="16" height="16" className="bg-ellipse" />
					</div>
					<span className="point">{points_qtd ?? '--'}</span>
				</div>
			</div>
		</div>
	);
};
