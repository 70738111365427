import { ReactElement, useEffect, useMemo } from 'react';
import { DealersList, IPrimaryDealerProps, TPrimaryDetails } from '.';
import { ActionButton, Dropdown, TextInput } from '@components/common';
import { useAddRemoveAction } from '@hooks/useAddRemoveAction';
import { useRxData } from 'rxdb-hooks';
import { Dealers } from '@services/hooks/enduser-details';

export const PrimaryDetailList = (props: IPrimaryDealerProps): ReactElement => {
	const { formik, primaryListIndex, fieldName, isEditing, dealer } = props;
	const { result: dealerList } = useRxData<Dealers>('dealer', (collection) => collection.find());

	const addNewDeatils = {
		id: formik.values[fieldName].length,
		dealer_code: null,
		dealer_name: null,
	};

	const { handleAddDetails, handleRemoveDetails } = useAddRemoveAction({
		formik,
		addNewDeatils,
		fieldName,
	});

	const dealersList = useMemo((): DealersList[] => {
		if (dealerList?.length) {
			return dealerList.map((d, index) => ({
				id: index,
				value: `${d._data?.dealercode_c} -  ${d._data?.dealername_c}`,
				name: d._data?.dealername_c,
			}));
		}
		return [];
	}, [dealerList]);

	useEffect(() => {
		const updatedDealerName = dealersList?.find(
			(t: DealersList) => t.value === formik.values[fieldName][primaryListIndex].dealer_code
		)?.name;
		if (updatedDealerName) {
			formik.setFieldValue(`${fieldName}[${primaryListIndex}].dealer_name`, updatedDealerName);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dealersList, formik.values[fieldName][primaryListIndex].dealer_code]);

	const filteredDealers = useMemo(() => {
		const selectedDealerCodes = formik.values[fieldName].map((detail: TPrimaryDetails) => detail.dealer_code);
		return dealersList?.filter((dealer: DealersList) => !selectedDealerCodes.includes(dealer.value));
	}, [dealersList, fieldName, formik.values]);

	return (
		<li className="d-col">
			<div className="multi-input-box">
				<div className="inputs-wrap">
					<div>
						<Dropdown
							id={`dealer_code_${primaryListIndex}`}
							name={`${fieldName}[${primaryListIndex}].dealer_code`}
							label="Dealer Code"
							title={dealer?.dealer_code?.split('-')[0] ?? ''}
							onBlur={formik.handleBlur}
							options={filteredDealers}
							allOptions={dealersList}
							setFieldValue={formik.setFieldValue}
							error={
								formik.touched[fieldName] &&
								formik.touched[fieldName][primaryListIndex]?.dealer_code &&
								formik.errors[fieldName]
									? formik.errors[fieldName][primaryListIndex]?.dealer_code
									: null
							}
							disabled={isEditing}
							required
						/>
					</div>

					<div>
						<div className="form-control">
							<TextInput
								type="text"
								id={`dealer_name_${primaryListIndex}`}
								name={`${fieldName}[${primaryListIndex}].dealer_name`}
								onBlur={formik.handleBlur}
								value={dealer?.dealer_name ?? ''}
								setFieldValue={formik.setFieldValue}
								label="Dealer Name"
								required
								disabled
							/>
						</div>
					</div>
				</div>
				<div className="add-multi-input">
					{formik.values[fieldName].length > 1 && primaryListIndex !== 0 && (
						<ActionButton
							label="Remove"
							handlerFn={handleRemoveDetails}
							className="remove-btn"
							childIndex={primaryListIndex}
							action="remove"
							disabled={isEditing}
						/>
					)}
					{primaryListIndex === formik.values[fieldName].length - 1 && formik.values[fieldName].length < 3 && (
						<ActionButton label="Add More" handlerFn={handleAddDetails} disabled={isEditing} action="add" />
					)}
				</div>
			</div>
			<p className="error-message">Please Enter the valid field</p>
		</li>
	);
};
