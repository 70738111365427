import { ReactElement, useMemo } from 'react';
import '@assets/styles/pages/calculator-additional-details.scss';
import { useLocation } from 'react-router-dom';
import { IProductRow, productsHeader, ProductsReturnType } from '.';
import { IProductData, IProductSku } from '@services/hooks/calculator';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';

export const AdditionalDetails = (): ReactElement => {
	const { state } = useLocation();

	const products = useMemo((): ProductsReturnType => {
		const rows: IProductRow[] = [];
		let calculatedTotalPoints = 0;
		let calculatedTotalKg = 0;

		state?.productData?.forEach((item: IProductData) => {
			if (item?.quantity && item?.quantity > 0) {
				const pointsMultiplier = item.unit_of_measurement !== 'KG' ? item.conversion_factor_for_kgs ?? 1 : 1;
				rows.push({
					id: `${item.id}-${item?.product_name}`,
					product_name: item?.product_name,
					quantity: item?.quantity?.toString() + ' ' + item?.unit_of_measurement,
					totalPoints: (item.quantity * (item?.conversion_factor_for_points ?? 1) * pointsMultiplier).toFixed(2),
					pointsPerKg: item?.conversion_factor_for_points?.toString(),
				});
				calculatedTotalPoints += item?.quantity * (item?.conversion_factor_for_points ?? 1);
				calculatedTotalKg += item?.quantity;
			}

			if (item?.product_sku && item?.product_sku?.length > 0) {
				item?.product_sku?.forEach((sku: IProductSku) => {
					if (sku?.quantity && sku?.quantity > 0) {
						rows.push({
							id: `${sku.id}-${sku?.sku_name}`,
							product_name: '  ' + sku?.sku_name,
							quantity: sku?.quantity?.toString() + ' ' + item?.unit_of_measurement,
							totalPoints: sku?.quantity * sku?.conversion_factor_for_points,
							pointsPerKg: sku?.conversion_factor_for_points?.toString(),
						});
						calculatedTotalPoints += sku?.quantity * sku?.conversion_factor_for_points;
						calculatedTotalKg += sku?.quantity;
					}
				});
			}
		});

		return {
			rows,
			totalKg: calculatedTotalKg,
			totalPoints: calculatedTotalPoints,
		};
	}, [state?.productData]);

	const renderProductsRow = (productRow: IProductRow): JSX.Element => {
		return (
			<tr key={productRow?.id}>
				<td>
					<div className="product-wrapper">
						<a
							data-tab={productRow?.product_name}
							aria-label={productRow?.product_name}
							href="/"
							onClick={(e) => e.preventDefault()}
						>
							{productRow?.product_name}
						</a>
					</div>
				</td>
				<td>
					<div className="inner-table-data">{productRow?.quantity}</div>
				</td>
				<td>
					<div className="inner-table-data">{productRow?.totalPoints}</div>
				</td>
				<td>
					<div className="inner-table-data">{productRow?.pointsPerKg}</div>
				</td>
			</tr>
		);
	};

	return (
		<section className="category-product-details-section">
			<div className="product-list-table">
				<div className="table-container">
					<div className="product-data-table additional-details-table">
						<table aria-label="product details">
							<thead>
								<tr>
									{productsHeader.map((header) => (
										<th key={header.id}>{header.name}</th>
									))}
								</tr>
							</thead>

							<tbody>
								{products?.rows.length !== 0 ? (
									products?.rows?.map((row) => renderProductsRow(row))
								) : (
									<NoDataFound message="No Data!" />
								)}
							</tbody>

							<tfoot>
								<tr className="footer-table-content">
									<td>
										<div className="product-wrapper">
											<a data-tab="Terminator" aria-label="product details link" className="" href="/">
												Total Points
											</a>
										</div>
									</td>
									<td>
										<div className="inner-table-data">{products?.totalKg}</div>
									</td>
									<td>
										<div className="inner-table-data">{products?.totalPoints}</div>
									</td>
									<td>
										<div className="inner-table-data">-</div>
									</td>
								</tr>
								<tr className="footer-table-content">
									<td>
										<div className="product-wrapper">
											<a data-tab="Terminator" aria-label="product details link" className="" href="/">
												Total Scheme Slab Points
											</a>
										</div>
									</td>
									<td>
										<div className="inner-table-data">-</div>
									</td>
									<td>
										<div className="inner-table-data">-</div>
									</td>
									<td>
										<div className="inner-table-data">-</div>
									</td>
								</tr>
								<tr className="footer-table-content">
									<td>
										<div className="product-wrapper">
											<a data-tab="Terminator" aria-label="product details link" className="" href="/">
												Total Points from Other Schemes
											</a>
										</div>
									</td>
									<td>
										<div className="inner-table-data">-</div>
									</td>
									<td>
										<div className="inner-table-data">-</div>
									</td>
									<td>
										<div className="inner-table-data">-</div>
									</td>
								</tr>
								<tr>
									<td>
										<div className="product-wrapper">
											<a data-tab="Terminator" aria-label="product details link" className="" href="/">
												Grand Total
											</a>
										</div>
									</td>
									<td>
										<div className="inner-table-data">{products?.totalKg}</div>
									</td>
									<td>
										<div className="inner-table-data">{products?.totalPoints}</div>
									</td>
									<td>
										<div className="inner-table-data">-</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</section>
	);
};
