import { TextInput } from '@components/common';
import { ReactElement, useMemo } from 'react';
import { keyDownEvent } from '@helper/utils';
import { DealerWhoRaisedAComplaintProps } from '.';
import Dropdown from '@components/common/formControl/dropdown/dropdown';
import { TypesOfComplaints, YesNo } from '@config/constant';
import { useGetWssNameQuery } from '@services/hooks/pcms-registration/useGetWssName';

interface DealerDetailsProps extends DealerWhoRaisedAComplaintProps {
	onShowMapHandler: (setAddress: boolean) => void;
}
export const DealerWhoRaisedAComplaintForm = (props: DealerDetailsProps): ReactElement => {
	const { formik, isEditing, onShowMapHandler } = props;

	const { data } = useGetWssNameQuery();

	const WssName = useMemo(
		() =>
			data?.map((item) => ({
				id: item.customercode_c,
				value: `${item.name} - ${item.customercode_c}`,
			})),
		[data]
	);
	return (
		<>
			<li className="form-control d-col d-col-2">
				<TextInput
					id="customer_name_c"
					name="customer_name_c"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.customer_name_c ?? ''}
					label="Customer Name"
					error={formik.touched.customer_name_c && formik.errors.customer_name_c ? formik.errors.customer_name_c : null}
					setFieldValue={formik.setFieldValue}
					required
					placeholder="Customer Name"
					disabled={isEditing}
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<TextInput
					id="registered_mobile_number_c"
					name="registered_mobile_number_c"
					type="text"
					label="Customer Registered Mobile Number"
					onBlur={formik.handleBlur}
					value={formik.values.registered_mobile_number_c ?? ''}
					setFieldValue={formik.setFieldValue}
					error={
						formik.touched.registered_mobile_number_c && formik.errors.registered_mobile_number_c
							? formik.errors.registered_mobile_number_c
							: null
					}
					disabled={isEditing}
					required
					maxLength={10}
					onKeyDown={keyDownEvent}
					placeholder="Customer Registered Mobile Number"
				/>
			</li>
			<li className="form-control d-col d-col-2">
				<Dropdown
					setFieldValue={formik.setFieldValue}
					options={TypesOfComplaints}
					id="type_of_complaint_c"
					label="Type of Complainant"
					title={formik.values.type_of_complaint_c}
					name="type_of_complaint_c"
					error={
						formik.touched.type_of_complaint_c && formik.errors.type_of_complaint_c
							? formik.errors.type_of_complaint_c
							: null
					}
					required
					onBlur={formik.handleBlur}
					placeholder="Type of Complainant"
					disabled={isEditing}
				/>
			</li>
			<li className="form-control d-col d-col-2">
				<TextInput
					id="dealer_membership_code_c"
					name="dealer_membership_code_c"
					type="text"
					label="Dealer/User Membership Code"
					onBlur={formik.handleBlur}
					value={formik.values.dealer_membership_code_c ?? ''}
					setFieldValue={formik.setFieldValue}
					error={
						formik.touched.dealer_membership_code_c && formik.errors.dealer_membership_code_c
							? formik.errors.dealer_membership_code_c
							: null
					}
					required
					placeholder="Dealer/User Membership Code"
					disabled={isEditing}
				/>
			</li>
			<li className="form-control d-col d-col-2">
				<Dropdown
					options={WssName ?? []}
					id="wss_names_c"
					name="wss_names_c"
					label="WSS Name"
					title={formik.values.wss_names_c}
					error={formik.touched.wss_names_c && formik.errors.wss_names_c ? formik.errors.wss_names_c : null}
					required
					placeholder="WSS Name"
					setFieldValue={formik.setFieldValue}
					onBlur={formik.handleBlur}
					onChange={(e) => {
						formik.setFieldValue('wss_codes_c', e.id);
					}}
					disabled={isEditing}
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<TextInput
					id="wss_codes_c"
					name="wss_codes_c"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.wss_codes_c ?? ''}
					setFieldValue={formik.setFieldValue}
					label="WSS Code"
					error={formik.touched.wss_codes_c && formik.errors.wss_codes_c ? formik.errors.wss_codes_c : null}
					disabled
					placeholder="WSS Code"
				/>
			</li>

			<li className="d-col">
				<div className="location-wrapper">
					<div className="form-control">
						<TextInput
							id="address_c"
							name="address_c"
							type="text"
							onBlur={formik.handleBlur}
							value={formik.values.address_c ?? ''}
							setFieldValue={formik.setFieldValue}
							label="Site location"
							placeholder="Site Location"
							error={formik.touched.address_c && formik.errors.address_c ? formik.errors.address_c : null}
							disabled={isEditing}
						/>
					</div>

					<div className="location-icons">
						<button
							className="location-btn"
							aria-label="location map link"
							type="button"
							onClick={() => onShowMapHandler(false)}
							disabled={isEditing}
						>
							<span className="icon-crosshair"></span>
						</button>
						<button
							className="location-btn"
							aria-label="location map link"
							type="button"
							onClick={() => onShowMapHandler(true)}
							disabled={isEditing}
						>
							<span className="icon-map-pin"></span>
						</button>
					</div>
				</div>
			</li>

			<li className="form-control d-col d-col-2">
				<Dropdown
					options={YesNo}
					id="site_under_warranty_c"
					name="site_under_warranty_c"
					title={formik.values.site_under_warranty_c}
					error={
						formik.touched.site_under_warranty_c && formik.errors.site_under_warranty_c
							? formik.errors.site_under_warranty_c
							: null
					}
					required
					setFieldValue={formik.setFieldValue}
					onBlur={formik.handleBlur}
					label="Site under warranty"
					placeholder="Site under Warranty"
					disabled={isEditing}
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<TextInput
					id="what_is_the_warranty_certificate_number_c"
					name="what_is_the_warranty_certificate_number_c"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.what_is_the_warranty_certificate_number_c ?? ''}
					setFieldValue={formik.setFieldValue}
					label="What is the Warranty Certificate Number?"
					placeholder="What is the Warranty Certificate Number"
					error={
						formik.touched.what_is_the_warranty_certificate_number_c &&
						formik.errors.what_is_the_warranty_certificate_number_c
							? formik.errors.what_is_the_warranty_certificate_number_c
							: null
					}
					onKeyDown={keyDownEvent}
					disabled={isEditing}
					required={formik.values.site_under_warranty_c === 'Yes'}
				/>
			</li>
		</>
	);
};
