import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';

import { MeetingDetailsObject } from '@components/meeting-details';
import { APICONFIG } from '@config/api.config';

export const useGetMeetingQuery = (id: string | undefined): IQueryResponse<MeetingDetailsObject> => {
	const fetchMeetingListData = async (): Promise<MeetingDetailsObject> => {
		if (!id) {
			throw new Error('Meeting ID is required');
		}
		return APIService.getData<MeetingDetailsObject>(`${APICONFIG.MEETINGS_DETAILS}/${id}`);
	};

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['MeetingList', id],
		queryFn: fetchMeetingListData,
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!id,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
