import { IPermissionModel } from './';

export const TSIPermission: IPermissionModel[] = [
	{ name: 'Dashboard', route: '/', components: ['user-sufficiency-summary', 'member-module'] },
	{ name: 'Profile', route: '/profile', components: [] },
	{ name: 'CompetitionConversion', route: '/competition-conversion', components: [] },
	{ name: 'TypeOfContractor', route: '/competition-conversion/type-of-contractor', components: [] },
	{ name: 'GradeOfCompetition', route: '/competition-conversion/grade-of-competition', components: [] },
	{ name: 'ProductsRecommended', route: '/competition-conversion/products-recommended', components: [] },
	{ name: 'Meetings', route: '/meetings', components: [] },
	{ name: 'AllMeetings', route: '/meetings/all-meetings', components: [] },
	{ name: 'DealerVisitSummary', route: '/dealer-visit-summary', components: [] },
	{ name: 'PCMS', route: '/pcms', components: [] },
	{ name: 'HelpAndSupport', route: '/help-and-support', components: [] },
	{ name: 'MeetingsConducted', route: '/meetings-conducted', components: [] },
	{ name: 'ExpenseDetails', route: '/meetings-conducted/expense-details', components: [] },
	{ name: 'CompletedRecord', route: '/meetings-conducted/completed-record', components: [] },
	{ name: 'MeetingDetails', route: '/meeting-details', components: [] },
	{ name: 'ManageParticipants', route: '/meeting-details/manage-participants', components: [] },
	{ name: 'AddNewMeeting', route: '/add-new-meeting', components: [] },
	{ name: 'ManageParticipants', route: '/add-new-meeting/manage-participants', components: [] },
	{ name: 'EditMeeting', route: '/edit-meeting', components: [] },
	{ name: 'ManageParticipants', route: '/edit-meeting/manage-participants', components: [] },
	{ name: 'Calendar', route: '/calendar', components: [] },
	{ name: 'AddDealerVisit', route: '/add-dealer-visit', components: [] },
	{ name: 'EditDealerVisit', route: '/edit-dealer-visit', components: [] },
	{ name: 'AddPcmsRegistration', route: '/add-pcms-registration', components: [] },
	{ name: 'EditPcmsRegistration', route: '/edit-pcms-registration', components: [] },
	{ name: 'StructureQuestionnaires', route: '/structured-questionnaires', components: [] },
	{ name: 'TodayMeetings', route: '/today-meetings', components: [] },
	{ name: 'AttendanceDetails', route: '/today-meetings/attendance-details', components: [] },
	{ name: 'TodaysMeetings', route: '/meetings/todays-meetings', components: [] },
	{ name: 'OneToOneMeetingSummary', route: '/one-to-one-meeting-summary', components: [] },
	{ name: 'AddOneToOneMeeting', route: '/add-one-to-one-meeting', components: [] },
	{ name: 'EditOneToOneMeeting', route: '/edit-one-to-one-connect', components: [] },
];
