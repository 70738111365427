import { DropdownOptions } from '@components/common/formControl/dropdown';

export interface MeetingFilterObject {
	filter_date: [Date | null, Date | null];
	club: DropdownOptions[];
	month: string;
	meetingStatus: DropdownOptions[];
	meetingType: string;
	meetingTheme: string;
}

export interface MeetingFilterProps {
	setFilterOpen: () => void;
}

export const meetingInitialValues: MeetingFilterObject = {
	club: [],
	filter_date: [null, null],
	month: '',
	meetingStatus: [],
	meetingType: '',
	meetingTheme: '',
};
