// {{uat_url}}apis/v.3.0/meetings/wwa/meetings/12731/add-non-end-user-member/import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { QuickAddNonEndUser } from '.';
import { APIService } from '@services/api/common-services';

interface IMeetingDetails {
	mutate: UseMutateFunction<QuickAddNonEndUser, Error, QuickAddNonEndUser, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useQuickAddNonEndUserMutation = (id: string): IMeetingDetails => {
	const postMeetingsDetailsData = async (meetingFormData: QuickAddNonEndUser): Promise<QuickAddNonEndUser> => {
		const url = `${APICONFIG.MEETINGS_DETAILS}/${id}/add-non-end-user-member/`;
		return await APIService.postData<QuickAddNonEndUser>(url, meetingFormData);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: postMeetingsDetailsData,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
