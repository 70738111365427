import { IQueryResponse } from '@services/api';
import { ClubDetails } from '../enduser-details';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { APIService } from '@services/api/common-services';
import { APICONFIG } from '@config/api.config';
import { useQuery } from '@tanstack/react-query';
import { setRxdb } from '@helper/utils';

export const useClubDropDownQuery = (): IQueryResponse<Array<ClubDetails>> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('club');

	const fetchClubListData = async (): Promise<Array<ClubDetails>> => {
		const response = await APIService.getData<Array<ClubDetails>>(APICONFIG.CLUB_DROPDOWN, {
			limit: '0',
		});
		if (response) {
			await setRxdb('club', response);
		}
		return response;
	};

	const { data, error, isLoading, isPending, refetch, isFetching } = useQuery({
		queryKey: ['clubList'],
		queryFn: fetchClubListData,
		enabled: isDbChecked && shouldFetch,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
		isFetching,
	};
};
